import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";

class FormComboboxField extends React.Component {
  state = {
    labelWidth: 0
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
    });
  }

  render() {
    const {
      id,
      name,
      error,
      value,
      onChange,
      classes,
      options,
      required
    } = this.props;
    return (
      <FormControl
        fullWidth
        //variant="outlined"
        className={classes.formControl}
        error={error ? true : false}
      >
        <InputLabel
          htmlFor={id}
          ref={ref => {
            this.InputLabelRef = ref;
          }}
        >
          {required ? `${name} *` : name}
        </InputLabel>
        <Select
          value={value}
          onChange={evt => onChange({ [id]: evt.target.value })}
          name={id}
          //renderValue={value => `⚠️  - ${value}`}
          input={
            <OutlinedInput
              id={id}
              name={id}
              labelWidth={this.state.labelWidth}
            />
          }
        >
          <MenuItem value="">
            <em>Válasszon</em>
          </MenuItem>
          {options.map((option, key) => {
            return (
              <MenuItem key={key} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
        {error ? <FormHelperText>{error || ""}</FormHelperText> : ""}
      </FormControl>
    );
  }
}

FormComboboxField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

export default FormComboboxField;
