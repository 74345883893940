import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import uteStyle from "common/assets/style/uteStyle";
import DataGrid from "common/components/DataGrid/DataGrid";
import User from "./User";
import strings from "variables/strings.json";
import restHelper from "common/utils/restHelper";
import ModalConfirm from "common/components/Modals/ModalConfirm";
import dateHelper from "common/utils/dateHelper";
import UserPrintModal from "./UserPrintModal";
import PrintIcon from "@material-ui/icons/Print";

class Users extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("users");
    this.modal = null;

    this.state = {
      access: access ? access : "",
      definition: {
        id: "users",
        url: "users",
        avatar: "avatar_file_id",
        attributes: strings.attributes.users,
        columns: [
          { id: "name", detailLink: true },
          { id: "email" },
          { id: "password1", type: "password", visible: false },
          { id: "password2", type: "password", visible: false },
          /*{
            id: "role",
            type: "select",
            options: roles
          },*/
          {
            id: "role",
            type: "select",
            search: ["user_role.name"],
            datasource: {
              controller: "roles",
              objectField: "user_role",
              valueField: "id",
              search: ["role.name"],
              label: "name",
              sort: { "role.name": "asc" }
            }
          },
          {
            id: "zip_id",
            type: "select",
            datasource: {
              controller: "zips",
              objectField: "zip",
              valueField: "id",
              search: ["zip.name", "zip.zip"],
              label: item => {
                return item ? item.zip + ", " + item.name : "";
              }
            }
          },
          { id: "address", name: "Cím" },
          {
            id: "task_id",
            type: "select",
            //visible: false,
            datasource: {
              controller: "tasks",
              objectField: "task",
              valueField: "id",
              search: ["task.name"],
              filter: [["unit", "=", 1]],
              label: "name"
            }
          },
          { id: "hourly_price", type: "currency" /*, visible: false*/ },
          {
            id: "subcontractor_id",
            type: "select",
            search: "subcontractor.name",
            datasource: {
              controller: "customers",
              objectField: "subcontractor",
              valueField: "id",
              search: ["customer.name"],
              filter: [
                ["customer.active", "exact", true],
                ["customer.subcontractor", "exact", true]
              ],
              sort: { "customer.name": "asc" },
              label: "name"
            }
          },
          {
            id: "birth_date",
            type: "unixDate",
            visible: false,
            changeValues: item => {
              if (!item.birth_date) {
                return;
              }
              const unixDate = dateHelper.dateToUnix(item.birth_date);

              if (!unixDate) {
                return;
              }
              restHelper
                .index(
                  `users/check-birth-date/${unixDate}${
                    item.id ? `/${item.id}` : ""
                  }`
                )
                .then(users => {
                  if (users.length > 0) {
                    this.modal
                      .open(
                        "Figyelmeztetés",
                        <div>
                          {users.map((u, index) => {
                            return (
                              <div key={index}>
                                {u.name} - {dateHelper.unixToDate(u.birth_date)}
                              </div>
                            );
                          })}
                        </div>,
                        "Azonos születési dátummal már van felhasználó az adatbázisban",
                        "Ok",
                        null
                      )
                      .then(() => {
                        this.modal.close();
                      });
                  }
                });
            }
          },
          { id: "birth_place", visible: false },
          { id: "mothers_name", visible: false },
          { id: "tax_number", visible: false },
          { id: "phone", visible: false },
          { id: "company_phone", visible: false },
          { id: "identity_card", visible: false },
          { id: "passport_number", visible: false },
          { id: "ssn", visible: false },
          //{ id: "job", visible: false },
          { id: "job_start_date", type: "unixDate", visible: false },
          { id: "job_end_date", type: "unixDate", visible: false },
          { id: "medical_state_exp", type: "unixDate", visible: false },
          { id: "tax_amount", type: "currency", visible: false },
          { id: "is_owner", type: "checkbox", visible: false },
          { id: "active", type: "checkbox", visible: false },
          { id: "avatar_file_id", type: "file", visible: false }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const { user, onChildEdit, onChildCancelEdit, breadcrumbs } = this.props;
    return (
      <div>
        <ModalConfirm onRef={ref => (this.modal = ref)} disabelCancel />
        <DataGrid
          user={user}
          onEdit={onChildEdit}
          onCancelEdit={onChildCancelEdit}
          breadcrumbs={breadcrumbs}
          definition={definition}
          startEditRef={ref => (this.startEdit = ref)}
          FormComponent={User}
          ViewComponent={User}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("users", column.id);
          }}
          access={this.state.access}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return item.name;
            } else {
              return "Új felhasználó";
            }
          }}
          toolbarButtons={[
            {
              id: "print-modal",
              tooltip: "Felhasználó nyomtatása",
              modalComponent: UserPrintModal,
              Icon: PrintIcon,
              color: "primary",
              onlyForm: true,
              yesText: "Nyomtatás",
              maxWidth: "lg"
            }
          ]}
        />
      </div>
    );
  }
}

Users.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(uteStyle, { withTheme: true })(Users);
