import React from "react";
//import ReactDOM from "react-dom";
import PropTypes from "prop-types";
//import ReactTable from "react-table";
//import TextField from "@material-ui/core/TextField";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
//import Assignment from "@material-ui/icons/Assignment";
//import Dvr from "@material-ui/icons/Email";
//import Phone from "@material-ui/icons/Phone";
//import EditIcon from "@material-ui/icons/Edit";
//import Close from "@material-ui/icons/Close";
//import "react-table/react-table.css";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid";
import PreviewFooter from "common/components/DataPreview/PreviewFooter";
import dataPreviewStyle from "common/assets/style/dataPreviewStyle";
import dateHelper from "common/utils/dateHelper";
import formatHelper from "common/utils/formatHelper";
import Typography from "@material-ui/core/Typography/Typography";

/**
 * Formos adat megjelenítés és szerkesztés kezelése
 */
class DataPreviewOld extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  renderSection = section => {
    if (!section.data || section.data.length === 0) {
      return (
        <Grid item={true} xs={12}>
          {"Nincsenek " + section.title.toLowerCase() + "."}
        </Grid>
      );
    }
    switch (section.type) {
      case "title":
        return "";
      case "form":
        return this.renderFormSection(section);
      case "table":
        return this.renderTableSection(section);
      default:
        return "";
    }
  };

  renderFormSection = section => {
    return section.columns.map((column, key) => {
      return (
        <Grid key={key} item={true} xs={12} md={6}>
          {section.data[column.id]}
        </Grid>
      );
    });
  };

  getColumn(row, column) {
    if (column.value) {
      if (typeof column.value === "function") {
        return column.value(row);
      } else {
        return column.value;
      }
    }
    if (column.id.indexOf(".") >= 0) {
      const names = column.id.split(".");
      return row[names[0]] && row[names[0]][names[1]]
        ? this.viewValue(column.type, row[names[0]][names[1]])
        : "";
    } else {
      return this.viewValue(column.type, row[column.id]);
    }
  }

  renderTableSection = section => {
    return section.data.map((row, key) => {
      return (
        <Grid key={key} item={true} xs={12}>
          <Grid
            container={true}
            //justify="center"
            //className={classes.container}
            spacing={2}
          >
            {section.columns.map((column, key) => {
              //const visible = column.visible === false ? null : column.access;

              /* section.fieldAccessHandler
                  ? section.fieldAccessHandler(column)
                  :*/

              if (column.visible === false) {
                return "";
              }
              return (
                <Grid key={key} item={true} xs={12} md={2}>
                  {this.getColumn(row, column)}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      );
    });
  };

  viewValue(type, value) {
    let cell = null;
    switch (type) {
      case "unixDate":
        cell = dateHelper.unixToDate(value);
        break;
      case "unixTime":
        cell = dateHelper.unixToTime(value);
        break;
      case "currency":
        cell = formatHelper.currency(value);
        break;
      default:
        cell = value;
        break;
    }
    return cell;
  }

  render() {
    const { sections, classes, buttons, item } = this.props;

    return (
      <div
        className={classes.formContainer}
        /*className={
          editIndex === null ? classes.fullContainer : classes.container
        }*/
      >
        <Grid
          container={true}
          //justify="center"
          //className={classes.container}
          spacing={2}
        >
          {sections.map((section, key) => {
            return (
              <Grid key={key} item={true} xs={12}>
                <Typography variant="h6">{section.title}</Typography>
                <Grid
                  container={true}
                  //justify="center"
                  //className={classes.container}
                  spacing={1}
                >
                  {this.renderSection(section)}
                </Grid>
              </Grid>
            );
          })}
        </Grid>

        {item ? (
          <PreviewFooter item={item} typoClassName={classes.footerTypo} />
        ) : (
          ""
        )}
        {buttons
          ? buttons.map((button, key) => {
            return (
              <Button
                key={key}
                onClick={button.onClick}
                className={classes.submitButton}
                color={button.color ? button.color : "primary"}
                variant="contained"
                style={button.style ? button.style : null}
              >
                {button.title}
                </Button>
            );
          })
          : ""}
      </div>
    );
  }
}

DataPreviewOld.propTypes = {
  classes: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  buttons: PropTypes.array,
  item: PropTypes.object
};
//export default DataForm;
export default withStyles(dataPreviewStyle)(DataPreviewOld);
