const percentColors = [
  { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
  { pct: 0.5, color: { r: 0xff, g: 0xaa, b: 0 } },
  { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } }
];

const formatHelper = {
  currenyFormat: (x, defaultValue) => {
    if (!x) {
      return defaultValue ? defaultValue : "";
    }
    return (
      Math.round(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Ft"
    );
  },

  currency: (val, defaultValue, deviza = "Ft") => {
    if (!val && val !== 0) {
      return defaultValue ? defaultValue : ""; //"0 Ft";
    }
    val = Math.round(val * 100) / 100;
    val += "";
    let x = val.split(".");
    let x1 = x[0];
    let x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1 $2");
    }
    return x1 + x2 + " " + deviza;
  },
  fileSize: value => {
    const val = parseInt(value);
    if (isNaN(val)) {
      return "0 Byte";
    }

    if (val < 1024) {
      return val + " Byte";
    } else if (val < 1048576) {
      return Math.round((val / 1024) * 100) / 100 + " KByte";
    } else {
      return Math.round((val / 1048576) * 100) / 100 + " MByte";
    }
  },
  numberFormat: value => (value ? Math.round(value * 100) / 100 : null),

  getColorForPercentage: pct => {
    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
    // or output as hex if preferred
  }
};

export default formatHelper;
