import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import uteStyle from "common/assets/style/uteStyle";
import DataForm from "common/components/DataForm/DataForm";
import restHelper from "common/utils/restHelper";
import strings from "variables/strings.json";
import { Switch, FormControlLabel } from "@material-ui/core";

/*const roles = [
  { value: 1, label: "Admin" },
  { value: 2, label: "Adminisztrátor" },
  { value: 3, label: "Munkavezető" },
  { value: 4, label: "Segédmunkás" }
];*/

class SettingsMenu extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;

    this.state = {
      themeType: localStorage.getItem("themeType") || "light"
    };
  }

  componentDidMount() {}

  handleChangeThemeType = evt => {
    const themeType = evt.target.checked ? "dark" : "light";
    localStorage.setItem("themeType", themeType);
    this.props.reloadApp();
    this.setState({ themeType });
  };

  render() {
    const { themeType } = this.state;
    const { user } = this.props;
    return (
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={themeType === "dark"}
              onChange={this.handleChangeThemeType}
              color="primary"
            />
          }
          label="Sötét téma"
        />
      </div>
    );
  }
}

SettingsMenu.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(uteStyle, { withTheme: true })(SettingsMenu);
