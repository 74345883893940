import React, { Fragment } from "react";
import PropTypes from "prop-types";
//import "react-table/react-table.css";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css"; // For some basic styling. (OPTIONAL)
import dateHelper from "common/utils/dateHelper";
import { ButtonGroup, Hidden } from "@material-ui/core";

/**
 * Formos adat megjelenítés és szerkesztés kezelése
 */
class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.parseValue(props.value),
      name: this.parseName(props.value),
      open: false
    };
  }

  parseValue = value => {
    if (typeof value === "string") {
      return dateHelper.getRange(value);
    } else {
      return value;
    }
  };

  parseName = range => {
    if (typeof range === "string") {
      switch (range) {
        case "today":
          return "Ma";
        case "thisWeek":
          return "Ez a hét";
        case "thisMonth":
          return "Ez a hónap";
        case "thisYear":
          return "Idén";
        case "lastWeek":
          return "Előző hét";
        case "lastMonth":
          return "Előző hónap";
        case "last7Days":
          return "Elmúlt 7 nap";
        case "last30Days":
          return "Elmúlt 30 nap";
        case "lastYear":
          return "Előző év";
        default:
          return "Egyéni időszak";
      }
    } else if (range) {
      return "Egyéni időszak";
    } else {
      return null;
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextName = this.parseName(nextProps.value);
    const name = this.parseName(this.props.value);
    if (nextName !== name) {
      this.setState({ name: nextName });
    }
    //this.setState({ value: nextProps.value });
  }

  onOpen = () => this.setState({ open: true });

  onClose = () => {
    //const v = value ? value : this.state.value;
    if (!this.unMounted) {
      // This is bad.
      this.setState({ open: false });
    }
    /*
    if (this.props.value !== v) {
      console.log("dirty", value, this.state.value);
      if (v) {
        //this.props.onChange(d);
      }

      this.setState({ value: v, open: false });
    } else {
      this.setState({ open: false });
    }*/
  };

  componentWillUnmount() {
    this.unMounted = true;
  }

  onSelect = (value, rangeKey) => {
    this.props.onChange(value, typeof rangeKey === "string" ? rangeKey : null);
    this.onClose();
  };

  clearDates = evt => {
    evt.stopPropagation();
    this.props.onChange(null);
    this.onClose();
  };

  textValue = value => {
    if (!value) {
      return "";
    }
    const v = this.parseValue(value);
    if (!v || !v.start || !v.end) {
      return "";
    }

    const start = dateHelper.unixToDate(v.start.unix());
    const end = dateHelper.unixToDate(v.end.unix());
    return start + "-" + end;
  };

  render() {
    const { label, disableMaterailProps, ...other } = this.props;
    const InputComponent = this.props.InputComponent
      ? this.props.InputComponent
      : TextField;
    const { open } = this.state;
    const value = this.parseValue(this.props.value);
    //console.log("YEEEE", value, this.props.value);
    const buttonProps = {
      size: "small",
      color: "primary",
      mini: "true",
      //variant: "extendedFab",
      style: {
        display: "inline-block",
        fontSize: 10
      }
    };
    const RangeButton = ({ title, value, ...restProps }) => (
      <Button
        {...restProps}
        //{...buttonProps}
        size="small"
        color="primary"
        onClick={() => this.onSelect(dateHelper.getRange(value), value)}
        variant={selectedRange === value ? "contained" : "outlined"}
        style={{ width: "100%" }}
      >
        {title}
      </Button>
    );

    let selectedRange = null;
    if (typeof this.props.value === "string") {
      selectedRange = this.props.value;
    }

    const materialProps = disableMaterailProps
      ? {}
      : {
        margin: "normal",
        //variant: "outlined",
        helperText: this.props.helperText
          ? this.props.helperText
          : this.state.name
      };

    return (
      <div>
        <InputComponent
          onClick={this.props.disabled ? null : this.onOpen}
          value={this.textValue(value)}
          label={label}
          error={this.props.helperText ? true : false}
          disabled={this.props.disabled}
          fullWidth
          {...materialProps}
        />
        <Dialog
          onClose={this.onClose}
          aria-labelledby="simple-dialog-title"
          open={open}
          //{...other}
          //fullWidth={true}
          maxWidth={false}
        >
          {label ? (
            <DialogTitle id="simple-dialog-title">{label}</DialogTitle>
          ) : (
            ""
          )}
          {open ? (
            <Fragment>
              <Hidden mdUp>
                <DateRangePicker
                  onSelect={this.onSelect}
                  {...other}
                  value={value}
                  firstOfWeek={1}
                  numberOfCalendars={1}
                  locale="hu"
                />
              </Hidden>
              <Hidden smDown>
                <DateRangePicker
                  onSelect={this.onSelect}
                  {...other}
                  value={value}
                  firstOfWeek={1}
                  locale="hu"
                />
              </Hidden>
            </Fragment>
          ) : (
            ""
          )}
          <Hidden smDown>
            <ButtonGroup style={{ marginTop: 16 }}>
              <Button
                size="small"
                onClick={this.clearDates}
                color="secondary"
                style={{ width: "100%" }}
              >
                Töröl
              </Button>
              <RangeButton title="Ma" value="today" />
              <RangeButton title="Ez a hét" value="thisWeek" />
              <RangeButton title="Előző hét" value="lastWeek" />
              <RangeButton title="Elmúlt 7 nap" value="last7Days" />
              <RangeButton title="Ez a hónap" value="thisMonth" />
              <RangeButton title="Elmúlt 30 nap" value="last30Days" />
              <RangeButton title="Előző hónap" value="lastMonth" />
              <RangeButton title="Idén" value="thisYear" />
              <RangeButton title="Előző év" value="lastYear" />
            </ButtonGroup>
          </Hidden>
          <Hidden mdUp>
            <Fragment>
              <ButtonGroup style={{ marginTop: 16 }}>
                <Button
                  size="small"
                  onClick={this.clearDates}
                  color="secondary"
                  style={{ width: "100%" }}
                >
                  Töröl
                </Button>
                <RangeButton title="Ma" value="today" />
                <RangeButton title="Ez a hét" value="thisWeek" />
                <RangeButton title="Előző hét" value="lastWeek" />
                <RangeButton title="Elmúlt 7 nap" value="last7Days" />
              </ButtonGroup>
              <ButtonGroup>
                <RangeButton title="Ez a hónap" value="thisMonth" />
                <RangeButton title="Elmúlt 30 nap" value="last30Days" />
                <RangeButton title="Előző hónap" value="lastMonth" />
                <RangeButton title="Idén" value="thisYear" />
                <RangeButton title="Előző év" value="lastYear" />
              </ButtonGroup>
            </Fragment>
          </Hidden>
        </Dialog>
      </div>
    );
  }
}

DatePicker.propTypes = {
  InputComponent: PropTypes.any,
  label: PropTypes.string,
  disableMaterailProps: PropTypes.bool
};
export default DatePicker;
//export default withStyles(dataPreviewStyle)(PreviewItem);
