import React from "react";
import PropTypes from "prop-types";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
//import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
// core components
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import modalStyle from "common/assets/style/modalStyle.jsx";
import settings from "variables/settings";
import { Typography } from "@material-ui/core";

/*function Transition(props) {
  return <Slide direction="down" {...props} />;
}*/

class ModalConfirm extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      open: false,
      title: "",
      subject: null,
      body: "",
      loading: false,
      yesText: "Yes",
      cancelText: "Cancel",
      noText: "No",
      error: null,
      disableCancelByOpen: null,
      icon: WarningIcon
    };
    this.okbtn = React.createRef();
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
    clearTimeout(this.afterCloseTimer);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.open && !prevState.open) {
      this.okbtn.current && this.okbtn.current.focus();
      //console.log(this.okbtn);
    }
  }

  loading = loading => {
    this.setState({ loading: loading });
  };

  open = (
    title,
    body,
    subject,
    yesText,
    cancelText,
    noText,
    icon,
    disableCancelByOpen
  ) => {
    return new Promise((resolve, reject) => {
      this.setState({
        open: true,
        title: title,
        subject: subject,
        body: body,
        loading: false,
        yesText: yesText ? yesText : this.state.yesText,
        cancelText: cancelText ? cancelText : this.state.cancelText,
        noText: noText,
        icon: icon ? icon : this.state.icon,
        error: null,
        disableCancelByOpen
      });
      this.resolve = resolve;
      this.reject = reject;
    });
  };

  resolve = () => {};

  reject = () => {};

  close = () => {
    this.setState({
      open: false,
      loading: false
    });
    this.afterClose();
    //this.reject();
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  afterClose = () => {
    this.afterCloseTimer = setTimeout(() => {
      this.setState({
        title: "",
        subject: null,
        body: "",
        yesText: "Yes",
        cancelText: "Cancel",
        noText: null,
        error: null,
        icon: WarningIcon
      });
    }, 200);
  };

  hide = () => {
    this.setState({
      open: false
    });
  };

  error = error => {
    this.setState({
      error: error
    });
  };

  cancel = () => {
    this.setState({
      open: false,
      loading: false
    });
    //this.reject();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  accept = result => {
    this.resolve(result);
    if (this.props.onAccept) {
      this.props.onAccept(result);
    }
  };

  render() {
    const { classes, disabelCancel } = this.props;
    const {
      open,
      title,
      body,
      subject,
      loading,
      yesText,
      cancelText,
      noText,
      error,
      disableCancelByOpen,
      icon: Icon
    } = this.state;
    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={open}
        //transition={Transition}
        keepMounted
        onClose={this.close}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color={settings.actionColor}
            onClick={this.close}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <Icon className={classes.titleIcon} />
          <h3 className={classes.modalTitle}>{title}</h3>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          {subject ? (
            <div style={{ textAlign: "center", paddingBottom: 10 }}>
              <b>{subject}</b>
            </div>
          ) : (
            ""
          )}
          <div style={{ textAlign: "center" }}>{body}</div>
          {error && (
            <Typography
              color="error"
              variant="body2"
              className={classes.modalError}
            >
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          {!disabelCancel && !disableCancelByOpen && (
            <Button
              onClick={this.cancel}
              color="default"
              disabled={loading === true}
            >
              {cancelText}
            </Button>
          )}
          {!disabelCancel && !disableCancelByOpen && " "}
          {noText ? (
            <Button
              onClick={() => this.accept(false)}
              color="default"
              disabled={loading === true}
            >
              {noText}
            </Button>
          ) : (
            " "
          )}
          <Button
            onClick={() => this.accept(true)}
            color={settings.actionColor}
            disabled={loading === true}
            ref={this.okbtn}
            endIcon={
              loading ? (
                <CircularProgress
                  className={classes.progress}
                  color="primary"
                  size={16}
                />
              ) : (
                undefined
              )
            }
          >
            {yesText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ModalConfirm.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  onRef: PropTypes.func,
  disabelCancel: PropTypes.bool
};

export default withStyles(modalStyle)(ModalConfirm);
