import React from "react";
//import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import settings from "variables/settings";

class GridCheckboxField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.value ? true : false,
      indeterminate:
        props.value === null || props.value === undefined ? true : false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.checked !== this.state.checked) {
      this.setState({
        checked: nextProps.value ? true : false,
        indeterminate:
          nextProps.value === null || nextProps.value === undefined
            ? true
            : false
      });
    }
  }

  onChange = evt => {
    const { id, onChange } = this.props;
    this.setState({ checked: evt.target.checked });
    onChange({ [id]: evt.target.checked });
  };

  render() {
    const { id, access /*, classes*/ } = this.props;

    const { checked, indeterminate } = this.state;

    return (
      <Checkbox
        //tabIndex={-1}
        indeterminate={indeterminate}
        style={{ padding: 4, marginTop: 2 }}
        disabled={access !== "w" ? true : false}
        color={settings.actionColor}
        id={id}
        checked={checked}
        inputProps={{ "aria-label": "indeterminate checkbox" }}
        //onClick={() => props.onToggle(props.name)}
        onChange={this.onChange}
        //checkedIcon={<Check className={props.classes.checkedIcon} />}
        //icon={<Check className={props.classes.uncheckedIcon} />}
        //classes={{ checked: props.classes.checked }}
      />
    );
  }
}

GridCheckboxField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.bool,
  error: PropTypes.string,
  access: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default GridCheckboxField;
