import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import InputBase from "@material-ui/core/InputBase";

function limit(val, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = "0" + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = "00";

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

function timestamp(val) {
  let month = limit(val.substring(0, 2), "23");
  let year = limit(val.substring(2, 4), "59");

  return month + (year.length ? ":" + year : "");
}

const formattedTypes = {
  unixTime: true,
  currency: true,
  integer: true,
  float: true
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  //a formatnak megfelelően hozzáadjuk az egyedi tulajdonságokat
  let customProps = {};
  switch (props.component_type) {
    case "unixTime":
      customProps.format = timestamp;
      customProps.placeholder = "00:00";
      break;
    case "currency":
      customProps.suffix = " " + props.currency_type;
      //customProps.format = formatHelper.currency;
      customProps.thousandSeparator = " ";
      customProps.decimalScale = 4;
      customProps.placeholder = " " + props.currency_type;
      break;
    case "integer":
      //customProps.suffix = " Ft";
      //customProps.format = formatHelper.currency;
      //customProps.thousandSeparator = " ";
      customProps.decimalScale = 0;
      //customProps.placeholder = " Ft";
      break;
    case "float":
      //customProps.suffix = " Ft";
      //customProps.format = formatHelper.currency;
      customProps.thousandSeparator = " ";
      customProps.decimalScale = 3;
      //customProps.placeholder = " Ft";
      break;

    default:
      //customProps.format = props.format;
      break;
  }

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      {...customProps}
      //thousandSeparator
      //prefix="$"
      //format="##:##"
      //format={formatter(props.format)}
      //placeholder={props.placeholder}
      //mask={["0", "0", "0", "0"]}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

class FormTextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value });
    }
  }

  onKeyDown = evt => {
    if (evt.key === "Enter") {
      return this.props.onSave && this.props.onSave();
    } else if (evt.key === "Escape") {
      this.props.onCancel && this.props.onCancel();
      return false;
    }
    return false;
  };

  onBlur = evt => {
    const { id, onChange, onBlur } = this.props;
    const { value } = this.state;
    //console.log(value, evt.target.value);
    evt.persist();

    clearTimeout(this.timeout);

    if (value !== this.props.value) {
      onChange && onChange({ [id]: value });
    }

    onBlur &&
      setTimeout(() => {
        onBlur(evt);
      }, 100);
  };

  onChange = evt => {
    //console.log(evt.target.value);
    const { id, onChange, type } = this.props;
    evt.persist && evt.persist();
    clearTimeout(this.timeout);
    const context = this;
    this.setState({ value: evt.target.value }, () => {
      context.timeout = setTimeout(() => {
        onChange &&
          onChange({
            [id]:
              type === "currency"
                ? parseFloat(evt.target.value)
                : evt.target.value
          });
      }, 500);
    });
  };

  render() {
    const {
      id,
      name,
      error,
      value: propValue,
      onChange,
      onBlur,
      type,
      placeholder,
      access,
      dirty,
      classes,
      helperText,
      multiline,
      currencyType,
      item,
      InputProps: MaterialInputPops,
      rows,
      required,
      viewMode
    } = this.props;
    const { value } = this.state;
    let InputProps = {};
    if (formattedTypes[type]) {
      InputProps.inputComponent = NumberFormatCustom;
    }
    let currency_type = "Ft";
    if (currencyType) {
      currency_type = "Ft";
      if (typeof currencyType === "function") {
        currency_type = currencyType({ original: item });
      } else {
        currency_type = currencyType;
      }
    }

    let ip = {
      component_type: type,
      currency_type,
      autoComplete: "off",
      autoCapitalize: "off",
      autoCorrect: "off",
      spellCheck: "false"
    };

    if (type === "password") {
      //ip.type = "password";
      ip.style = {
        fontFamily: "text-security-disc"
      };
    }
    //console.log(id, dirty);
    return viewMode ? (
      <InputBase
        //inputRef={ref => (this.email = ref)}
        id={id}
        label={required ? `${name} *` : name}
        //helperText={error || helperText || ""}
        error={error ? true : false}
        //defaultValue={defaultValue}
        //InputLabelProps={inputLabelProps}
        className={dirty && !error ? classes.dirtyTextField : ""}
        fullWidth
        disabled={access !== "w" ? true : false}
        //defaultValue={value ? value : ""}
        value={value || value === 0 ? value : ""}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        //margin="normal"
        ////variant="outlined"
        placeholder={placeholder}
        inputProps={ip}
        multiline={multiline}
        rows={rows}
        // eslint-disable-next-line
        //InputProps={{...InputProps, ...MaterialInputPops}}
        {...{ ...InputProps, ...MaterialInputPops }}
      />
    ) : (
      <TextField
        //inputRef={ref => (this.email = ref)}
        id={id}
        label={required ? `${name} *` : name}
        helperText={error || helperText || ""}
        error={error ? true : false}
        //defaultValue={defaultValue}
        //InputLabelProps={inputLabelProps}
        className={dirty && !error ? classes.dirtyTextField : ""}
        fullWidth
        disabled={access !== "w" ? true : false}
        //defaultValue={value ? value : ""}
        value={value || value === 0 ? value : ""}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        margin="normal"
        //variant="outlined"
        placeholder={placeholder}
        inputProps={ip}
        multiline={multiline}
        rows={rows}
        // eslint-disable-next-line
        InputProps={{...InputProps, ...MaterialInputPops}}
      />
    );
  }
}

FormTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  format: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  access: PropTypes.string,
  helperText: PropTypes.string,
  dirty: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number
};

export default FormTextField;
