import React from "react";
import DataGridForm from "common/components/DataGrid/DataGridForm";
import UserEmployments from "./UserEmployments";

const User = props => (
  <DataGridForm
    {...props}
    tabs={{
      id: "users",
      documents: true,
      histories: true,
      items: {
        "user-employments": {
          itemKey: "user_id",
          component: UserEmployments
          //badgeContent: "item_count"
        }
      }
    }}
  />
);

export default User;
