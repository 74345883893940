import React from "react";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import urlHelper from "common/utils/urlHelper";

const StyledBadge = withStyles(theme => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&.offline": {
      backgroundColor: theme.palette.error.main,
      "&::after": {
        border: "1px solid " + theme.palette.error.main
      }
    },
    "&.away": {
      backgroundColor: theme.palette.yellow.main,
      "&::after": {
        border: "1px solid " + theme.palette.yellow.main
      }
    },
    "&.inactive": {
      backgroundColor: theme.palette.grey[500],
      "&::after": {
        border: "1px solid " + theme.palette.grey[500]
      }
    },
    "&.animating": {
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""'
      }
    }
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0
    }
  }
}))(Badge);

const SmallAvatar = withStyles(theme => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`
  }
}))(Avatar);

/*const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));*/

export default function BadgeAvatar({
  user,
  src,
  status,
  onClick,
  invisible,
  animating
}) {
  //const classes = useStyles();

  return (
    <StyledBadge
      invisible={invisible}
      overlap="circle"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      variant="dot"
      classes={{
        badge: `${animating ? "animating" : ""} ${status || " online"}`
      }}
    >
      <Avatar
        alt={user.name}
        src={
          user.avatar_file
            ? urlHelper.downloadLink(user.avatar_file.id)
            : undefined
        }
        onClick={onClick}
        //style={{ cursor: "pointer" }}
      />
    </StyledBadge>
  );
}
