const errorPageStyle = theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    height: "100vh",
  },
  paper: {
    marginTop: 30,
    padding: 30
  },
  message: {
    color: theme.palette.text.primary,
  }
});

export default errorPageStyle;