import React from "react";

const ZipIcon = props => (
  <svg
    style={{ width: 20, height: 20, paddingBottom: 3, fill: "currentColor" }}
    className={props.className}
    viewBox="0 0 50 50"
  >
    <path d="M 25 2 C 24.846625 2 24.693734 2.0349687 24.552734 2.1054688 L 2.5527344 13.105469 C 2.2147344 13.275469 2 13.621 2 14 L 2 16 C 2 16.347 2.1806094 16.668563 2.4746094 16.851562 C 2.7686094 17.034562 3.1372656 17.049531 3.4472656 16.894531 L 25 6.1171875 L 46.552734 16.894531 C 46.693734 16.965531 46.847 17 47 17 C 47.183 17 47.364391 16.950563 47.525391 16.851562 C 47.819391 16.668563 48 16.347 48 16 L 48 14 C 48 13.621 47.786266 13.275469 47.447266 13.105469 L 25.447266 2.1054688 C 25.306766 2.0349687 25.153375 2 25 2 z M 25 8.3535156 L 4.3417969 18.683594 C 4.2327969 18.737594 4.114 18.765641 4 18.806641 L 4 47 C 4 47.552 4.448 48 5 48 L 45 48 C 45.552 48 46 47.552 46 47 L 46 18.806641 C 45.886 18.765641 45.767203 18.738594 45.658203 18.683594 L 25 8.3535156 z M 32.115234 22.689453 C 33.866234 22.689453 35.295203 23.012078 36.283203 23.955078 C 37.169203 24.799078 37.613281 26.088391 37.613281 27.525391 C 37.613281 29.210391 37.144859 30.351141 36.380859 31.244141 C 35.394859 32.409141 33.793719 32.980469 32.386719 32.980469 C 32.115719 32.980469 32.061453 32.980125 31.814453 32.953125 L 31.814453 37 L 29 37 L 29 23 C 29.839 22.801 30.957234 22.689453 32.115234 22.689453 z M 13.626953 23 L 22 23 L 22 24.921875 L 16.755859 34.621094 L 16.755859 34.695312 L 22 34.695312 L 22 37 L 13.625 37 L 13.625 35.4375 L 19.013672 25.367188 L 19.013672 25.318359 L 13.626953 25.318359 L 13.626953 23 z M 24 23 L 27 23 L 27 37 L 24 37 L 24 23 z M 32.560547 24.845703 C 32.190547 24.845703 32.036453 24.894703 31.814453 24.970703 L 31.814453 30.771484 C 32.012453 30.820484 32.018063 30.820313 32.289062 30.820312 C 34.013062 30.820312 34.976562 29.631703 34.976562 27.720703 C 34.976562 26.159703 34.335547 24.845703 32.560547 24.845703 z" />
  </svg>
);
export default ZipIcon;
