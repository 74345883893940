import React from "react";
import DataGridForm from "common/components/DataGrid/DataGridForm";

const WorkCloth = props => (
  <DataGridForm
    {...props}
    tabs={{
      id: "work-clothes",
      documents: true,
      histories: true,
      items: {}
    }}
  />
);

export default WorkCloth;
