import React, { Fragment } from "react";
import PropTypes from "prop-types";
import urlHelper from "common/utils/urlHelper";
import { Avatar } from "@material-ui/core";

class ImgViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  onClick = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({ open: !this.state.open });
  };

  render() {
    const { file_id, name, avatarStyle, DefaultIcon } = this.props;
    const { open } = this.state;
    return (
      <Fragment>
        <Avatar
          alt={name || `file_${file_id}`}
          onClick={open ? undefined : this.onClick}
          style={{ cursor: "pointer", ...avatarStyle }}
          src={file_id ? urlHelper.downloadLink(file_id) : undefined}
        >
          {!file_id && DefaultIcon && <DefaultIcon />}
        </Avatar>
        {open && (
          <div
            onClick={this.onClick}
            style={{
              position: "fixed",
              zIndex: 2100,
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
              overflow: "auto",
              textAlign: "center",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              cursor: "pointer",
              padding: 24,
              paddingTop: 12 + 64
            }}
          >
            <div style={{ color: "#FFFFFF", paddingBottom: 12 }}>
              Letöltés:{" "}
              <a
                href={urlHelper.downloadLink(file_id)}
                style={{ color: "#FFFFFF" }}
              >
                {name}
              </a>
            </div>
            <img
              alt={name}
              style={{ margin: "0 auto", cursor: "pointer", zIndex: 2200 }}
              src={urlHelper.downloadLink(file_id)}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

ImgViewer.propTypes = {
  file_id: PropTypes.number,
  name: PropTypes.string,
  avatarStyle: PropTypes.object,
  DefaultIcon: PropTypes.any
};

export default ImgViewer;
