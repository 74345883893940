import React from "react";
import PropTypes from "prop-types";
import Modal from "common/components/Modals/Modal";
import FilterIcon from "@material-ui/icons/FilterList";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormSelectField from "common/components/DataForm/FormSelectField";
import dataFormStyle from "common/assets/style/dataFormStyle";
import withStyles from "@material-ui/core/styles/withStyles";

class TextFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: typeof props.value === "string" ? props.value : ""
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const fCurrent =
      typeof this.props.value === "string" ? this.props.value : "";
    const fNext = typeof nextProps.value === "string" ? nextProps.value : "";
    if (fCurrent !== fNext) {
      this.setState({ value: fNext });
    }
  }

  /* getFilterValue = () => {
    const {filter} = this.props;
    return filter ? filter.value : "";
  }*/

  render() {
    const { value } = this.state;
    const { name, onChange } = this.props;
    return (
      <input
        name={name}
        id={name}
        autoComplete={name + new Date().getTime()}
        onChange={evt => {
          this.setState({ value: evt.target.value });
          evt.persist();
          setTimeout(() => {
            onChange(evt.target.value);
          }, 500);
        }}
        value={value}
        style={{
          width: "calc(100% - 24px)"
        }}
      />
    );
  }
}

class GridFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      time: new Date().getTime(),
      currentValue: this.props.value ? this.props.value.currentValue : null,
      currentId: this.props.value ? this.props.value.value : null
    };
  }

  handleShow = () => {
    //console.log("on open", this.props.value);
    this.setState({
      open: true,
      currentValue: this.props.value ? this.props.value.currentValue : null,
      currentId: this.props.value ? this.props.value.value : null
    });
  };

  handleClose = () => {
    this.setState({
      open: false
      //time: new Date().getTime()
    });
  };

  handleAccept = () => {
    const { onChange /*, column*/, type } = this.props;
    const { currentValue, currentId } = this.state;

    if (currentValue) {
      if (typeof currentValue === "object") {
        onChange({
          extended: true,
          value: currentId,
          currentValue: currentValue,
          type: type
        });
      }
    } else {
      //Üres érték
      onChange(null);
    }

    //onChange({ [column.id]: this.state.selectedIds });
    //onChange(this.state[column.id]);

    this.setState({
      open: false
      //...state
    });
  };

  onChange = data => {
    const { column /*, onChange*/ } = this.props;
    const { id } = column;
    const { objectField } = column.datasource || {};
    //console.log("onChange", data);
    //onChange({ id: id, value: data[id], type: "in" });
    //onChange(data[id]);
    if (objectField) {
      this.setState({
        currentValue: data[objectField],
        currentId: data[id]
        //time: new Date().getTime()
      });
    } else {
    }
  };

  currentValue = () => {
    const { column } = this.props;
    //const { id } = column;
    const { objectField } = column.datasource;
    //console.log(this.state[objectField]);
    return this.state[objectField];
  };

  render() {
    const {
      classes,
      theme,
      name,
      onChange,
      column,
      columnName,
      //type,
      value
    } = this.props;
    const { open, /*filter,*/ currentValue } = this.state;
    //console.log("GridFilter", this.state);
    return (
      <div>
        <TextFilter name={name} value={value} onChange={onChange} />
        {/*<input
          name={name}
          autoComplete={"off"}
          onChange={evt => onChange(evt.target.value)}
          value={typeof value === "string" ? value : ""}
          style={{
            width: "calc(100% - 24px)"
          }}
        />*/}
        <Tooltip disableFocusListener title="Összetett szűrő">
          <IconButton
            color={value && typeof value === "object" ? "primary" : "default"}
            onClick={this.handleShow}
            style={{ width: 24, height: 24, padding: 0 }}
          >
            <FilterIcon style={{ width: 20, height: 20, marginTop: 2 }} />
          </IconButton>
        </Tooltip>
        {open !== null && (
          <Modal
            open={open}
            title={"Összetett szűrő"}
            onClose={this.handleClose}
            onAccept={this.handleAccept}
            onCancel={this.handleClose}
            Icon={FilterIcon}
          >
            <div style={{ minHeight: "calc(100vh - 300px)" }}>
              <FormSelectField
                key={this.state.time}
                id={column.id}
                name={columnName}
                options={column.options}
                datasource={column.datasource}
                //value={Array.isArray(currentValue) ? null : currentValue}
                column={column}
                isDeleted={false}
                currentValue={Array.isArray(currentValue) ? currentValue : null}
                error={null}
                onChange={this.onChange}
                classes={classes}
                theme={theme}
                item={{}}
                dirty={false}
                isMulti={true}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

GridFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  column: PropTypes.object.isRequired
};

export default withStyles(dataFormStyle, { withTheme: true })(GridFilter);
