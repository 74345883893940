import { NonceProvider } from "react-select";

const sidebarMenuStyle = theme => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0
  },
  listMain: {
    "& div.MuiCollapse-container:last-child": {
      borderBottom: "none!important"
    }
  },
  itemText: {
    display: "flex",
    flexWrap: "wrap",
    flex: "1 1",
    "& > span": {
      //lineHeight: "14px!important"
      //fontSize: "14px!important"
    },
    alignItems: "center"
    //padding: 0
  },
  listSubheader: {
    textTransform: "uppercase"
  },
  itemContainerOpened: {
    //backgroundColor: "#ddd",
    webkitUserSelect: "none",
    mozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    "& > div > span": {
      color: theme.palette.primary.main + "!important"
    },
    "& > a > div > span": {
      color: theme.palette.primary.main + "!important"
    },
    "& > div > svg": {
      fill: theme.palette.primary.main + "!important"
    },
    "& > a > div > svg": {
      fill: theme.palette.primary.main + "!important"
    }
  },
  itemContainerOpenedLeaf: {
    //backgroundColor: theme.palette.primary.main + "!important",
    background:
      (theme.palette.type === "dark"
        ? theme.shapeBackground.dark
        : theme.shapeBackground.light) + "!important",
    "& > div > span": {
      color:
        theme.palette.type === "dark"
          ? "#fff!important"
          : theme.palette.primary.contrastText + "!important"
    },
    "& > a > div > span": {
      color:
        theme.palette.type === "dark"
          ? "#fff!important"
          : theme.palette.primary.contrastText + "!important"
    },
    "& > div > svg": {
      fill:
        theme.palette.type === "dark"
          ? "#fff!important"
          : theme.palette.primary.contrastText + "!important"
    },
    "& > a > div > svg": {
      fill:
        theme.palette.type === "dark"
          ? "#fff!important"
          : theme.palette.primary.contrastText + "!important"
    },
    cursor: "default",
    "&:hover": {
      backgroundColor: theme.palette.primary.main + "!important"
    }
  },
  collapseContainer: {
    width: "100%"
  },
  collapseContainerHeader: {
    width: "100%",
    borderBottom: `solid 1px
      ${
        theme.palette.type === "dark"
    ? theme.palette.grey[700]
    : theme.palette.grey[300]
      }`
  },
  itemLink: {
    display: "flex",
    flexFlow: "row wrap",
    alignContent: "space-between",
    justifyContent: "space-between",
    alignItems: "center",
    textDecoration: "none"
  },
  itemSection: {
    display: "flex",
    flexFlow: "row wrap",
    alignContent: "space-between",
    justifyContent: "space-between",
    alignItems: "center",
    textDecoration: "none",
    paddingRight: 8
  },
  itemContainer: {
    display: "flex",
    flex: "1 1",
    color: "inherit!important",
    /*flexFlow: "row wrap",
    alignContent: "space-between",
    justifyContent: "space-between",*/
    verticalAlign: "middle",
    padding: 0,
    //borderTop: "solid 1px #ddd",
    //marginTop: -8,
    //marginBottom: -10,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 16,
    "&:last-child": {
      //borderTop: "none",
    },
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.grey[700]
          : theme.palette.grey[300]
    },
    "& > div > svg": {
      fill: theme.palette.text.secondary
    },
    "& > a > div > svg": {
      fill: theme.palette.text.secondary
    }
  },
  item: {
    display: "flex",
    flexFlow: "row wrap",
    alignContent: "space-between",
    justifyContent: "space-between",
    padding: "0px!important",
    //paddingLeft: 10,
    //paddingTop: 8,
    //paddingBottom: 8,
    //borderTop: "solid 1px #ddd",
    /*"&:nth-child(1)": {
      borderTop: "none",
    }*/
    "& $item": {
      "& $itemContainer": {
        paddingLeft: 25
      },
      "& $item": {
        "& $itemContainer": {
          paddingLeft: 40
        },
        "& $item": {
          "& $itemContainer": {
            paddingLeft: 55
          },
          "& $item": {
            "& $itemContainer": {
              paddingLeft: 70
            }
          }
        }
      }
    }
  },
  itemIcon: {
    marginRight: theme.spacing(1),
    minWidth: 0
  },
  box: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    //justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    display: "flex",
    cursor: "pointer",
    border: "solid 1px transparent",
    "&:hover": {
      border: `solid 1px ${theme.palette.text.primary}`
    },
    textDecoration: "none",
    color: "inherit"
  }
});

export default sidebarMenuStyle;
