import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import ListIcon from "@material-ui/icons/ViewList";
import CardIcon from "@material-ui/icons/ViewModule";
import { Hidden, Tooltip, TextField, InputAdornment } from "@material-ui/core";

const styles = theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center"
    //width: 400,
  },
  input: {
    marginLeft: -theme.spacing(5),
    //flex: 1,
    minWidth: 300,
    "&::placeholder": {
      //fontSize: "16px!important"
    }
  },
  htmlInput: {
    "&::placeholder": {
      //fontSize: "16px!important"
    }
  },
  leftIconButton: {
    padding: 10,
    marginLeft: "-8px!important",
    marginRight: "0px!important"
  },
  rightIconButton: {
    padding: 10,
    marginLeft: "0px!important",
    marginRight: "-8px!important"
  },
  divider: {
    height: 28,
    margin: 4,
    width: 1,
    color: theme.palette.grey[500]
  }
});

class DatagridFulltextSearch extends React.Component {
  handleInputKeypress = evt => {
    const { onEnter } = this.props;
    let keyCode = evt.which || evt.keyCode;
    if (keyCode === 13) {
      //Enter
      onEnter();
    }
  };

  render() {
    const {
      classes,
      onChange,
      onSearchClick,
      value,
      forceHeaderFilter,
      toggleHeaderFilter
    } = this.props;

    return (
      <TextField
        fullWidth
        //variant="outlined"
        //label="Keresés"
        margin="normal"
        autoFocus
        className={classes.input}
        placeholder="Keresés..."
        inputProps={{ "aria-label": "Keresés", className: classes.htmlInput }}
        onChange={onChange}
        onKeyPress={this.handleInputKeypress}
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip
                title={
                  forceHeaderFilter
                    ? "Oszlop szűrő kikapcsolása"
                    : "Oszlop szűrő bekapcsolása"
                }
              >
                <IconButton
                  color={forceHeaderFilter ? "primary" : "default"}
                  className={classes.leftIconButton}
                  aria-label="search"
                  onClick={toggleHeaderFilter}
                >
                  <MenuIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className={classes.rightIconButton}
                aria-label="search"
                onClick={onSearchClick}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
    /*
    return (
      <Paper className={classes.root}>
        <Tooltip
          title={
            forceHeaderFilter
              ? "Oszlop szűrő kikapcsolása"
              : "Oszlop szűrő bekapcsolása"
          }
        >
          <IconButton
            color={forceHeaderFilter ? "primary" : "default"}
            className={classes.iconButton}
            aria-label="search"
            onClick={toggleHeaderFilter}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
        <InputBase
          autoFocus
          className={classes.input}
          placeholder="Keresés"
          inputProps={{ "aria-label": "Keresés", className: classes.htmlInput }}
          onChange={onChange}
          onKeyPress={this.handleInputKeypress}
          value={value}
        />
        <IconButton
          className={classes.iconButton}
          aria-label="search"
          onClick={onSearchClick}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    );*/
  }
}

DatagridFulltextSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,
  value: PropTypes.string,
  forceHeaderFilter: PropTypes.bool,
  toggleHeaderFilter: PropTypes.func
};

export default withStyles(styles)(DatagridFulltextSearch);
