import { combineReducers } from "redux";
import { restReducers } from "common/utils/restHelper";

const rootReducer = combineReducers({
  //routing: routerReducer,
  ...restReducers([
    { name: "user", object: true }
    /*{ name: "users" },
    { name: "tacho-cards" },
    { name: "tacho-card-cars" },
    { name: "tacho-ques" }*/
  ])
});
export default rootReducer;
