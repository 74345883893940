import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
//import "react-table/react-table.css";
import Grid from "@material-ui/core/Grid";
import dateHelper from "common/utils/dateHelper";
import formatHelper from "common/utils/formatHelper";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PrintIcon from "@material-ui/icons/Print";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button/Button";

const baseHtmlStart = () => {
  let html = "<html><head>";
  html += "<title>Nyomtatás</title><style>";
  html +=
    'body {margin:30px; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; -webkit-font-smoothing: antialiased; ';
  html += "font-size: 10px!important;}";
  html += "table {border-collapse: collapse; width:100%; margin-top:15px;}";
  html += "table, th, td {border: 1px solid black;}";
  html += "td {font-size: 11px!important; padding:3px;}";
  html += ".text_center {text-align:center;}";
  html += "</style>";
  html += "<body>";
  return html;
};

const baseHtmlEnd = () => {
  let html = "</body>";
  html += "</html>";
  return html;
};

const printPageArea = content => {
  var WinPrint = window.open("", "", "width=595,height=842");
  var div = WinPrint.document.createElement("div");
  ReactDOM.render(content, div);
  WinPrint.document.write(baseHtmlStart() + div.innerHTML + baseHtmlEnd());
  WinPrint.document.close();
  WinPrint.focus();
  WinPrint.print();
  WinPrint.close();
};

/**
 * Formos adat megjelenítés és szerkesztés kezelése
 */
class PreviewItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.noHeader === true ? true : false,
      loading: false,
      tabs: [],
      tabIndex: 0
    };
  }

  componentDidMount() {
    const { item } = this.props;
    const section = this.currentSection();
    if (section.fetchData && !item) {
      section.fetchData(item).then(response => {
        let tabs = this.state.tabs.slice();
        tabs.push({ items: response.data, fetched: true });
        this.setState({ tabs: tabs });
      });
    }
  }

  changeTab = tabindex => {
    this.handleChange(null, true, tabindex);
  };

  handleChange = (event, expanded, tabindex) => {
    let tabI = typeof tabindex !== "undefined" ? tabindex : this.state.tabIndex;
    let tab = this.state.tabs[tabI]
      ? this.state.tabs[tabI]
      : { items: [], fetched: false };

    if (tab && tab.fetched === false && expanded) {
      const { item } = this.props;
      const section = this.currentSection(tabI);
      if (section.fetchData) {
        section.fetchData(item).then(response => {
          let tabs = this.state.tabs.slice();

          if (response.data.length === 0) {
            tabs[tabI] = { items: [], fetched: true };
            this.setState({
              tabIndex: tabI,
              tabs: tabs
            });
          } else {
            let tabs = this.state.tabs.slice();
            tabs[tabI] = { items: response.data, fetched: true };
            this.setState({
              tabIndex: tabI,
              tabs: tabs,
              expanded: expanded
            });
          }
        });
      }
    } else {
      this.setState({
        tabIndex: tabI,
        expanded: expanded
      });
    }
  };

  getColumn(row, column) {
    if (row.isHeader && column.id === "name") {
      return row[column.id];
    }
    if (column.value) {
      if (typeof column.value === "function") {
        return column.value(row, this.props.classes);
      } else {
        return column.value;
      }
    }
    if (column.id.indexOf(".") >= 0) {
      const names = column.id.split(".");
      return row[names[0]] && row[names[0]][names[1]]
        ? this.viewValue(column.type, row[names[0]][names[1]])
        : "";
    } else {
      return this.viewValue(column.type, row[column.id]);
    }
  }

  prepareHeader = () => {
    const { classes } = this.props;
    let baseHeader = {
      isHeader: true /*,
      name: (
        <div
          className={
            this.state.expanded
              ? classes.headerIconSelected
              : classes.headerIcon
          }
        >
          {this.props.section.title}
        </div>
      ) */ /*,
      amount: 0,
      qty: 0,
      first_day: 9548111600,
      last_day: 0,
      work_hours: 0,
      amount_pays: 0,
      amount_costs: 0*/
    };

    const tab = this.state.tabs[this.state.tabIndex];
    const items = tab ? tab.items : [];
    //const cols = tab ? tab.columns : [];

    const columns = {};
    const section = this.currentSection();

    //console.log(section);
    if (section.columns) {
      section.columns.forEach(col => {
        columns[col.id] = col;
      });
    }

    const keys = items && items[0] ? Object.keys(items[0]) : [];
    let defaultHeader = {};

    keys.forEach(key => {
      //console.log(columns, key);
      if (key === "name") {
        return;
      }
      const groupFunc =
        columns[key] && columns[key].groupFunc ? columns[key].groupFunc : "sum";
      switch (groupFunc) {
        case "min":
          defaultHeader[key] = 9999999999;
          break;
        case "max":
          defaultHeader[key] = -9999999999;
          break;
        case "sum":
        default:
          defaultHeader[key] = 0;
          break;
      }
    });
    let header = Object.assign(baseHeader, defaultHeader);
    for (let i in items) {
      const item = items[i];
      /*header.amount += item.amount;
      header.qty += parseInt(item.qty);
      header.work_hours += parseFloat(item.work_hours);
      header.work_days += parseInt(item.work_days);
      header.first_day = Math.min(header.first_day, item.first_day);
      header.last_day = Math.max(header.last_day, item.last_day);*/

      keys.forEach(key => {
        const groupFunc =
          columns[key] && columns[key].groupFunc
            ? columns[key].groupFunc
            : "sum";
        switch (groupFunc) {
          case "min":
            header[key] = Math.min(header[key], item[key]);
            break;
          case "max":
            header[key] = Math.max(header[key], item[key]);
            break;
          case "sum":
          default:
            header[key] += parseFloat(item[key]);
            break;
        }
      });

      /*
      if (item.amount_pays) {
        header.amount_pays += item.amount_pays;
      }
      if (item.amount_costs) {
        header.amount_costs += item.amount_costs;
      }*/
    }

    keys.forEach(key => {
      if (isNaN(header[key])) {
        header[key] = null;
      }
    });

    //console.log(defaultHeader, baseHeader, header);

    //Név és ikon
    if (this.props.section.icon) {
      header.name = (
        <div
          className={
            classes.header +
            (this.state.expanded ? " " + classes.headerExpanded : "")
          }
        >
          <this.props.section.icon

          //style={{ marginBottom: -6, marginRight: 6, fill: "#666" }}
          />
          {this.props.section.title}
        </div>
      );
    } else {
      header.name = (
        <div
          className={
            this.state.expanded
              ? classes.headerIconSelected
              : classes.headerIcon
          }
        >
          {this.props.section.title}
        </div>
      );
    }

    if (isNaN(header.qty)) {
      header.qty = null;
    }

    return header;
  };

  viewValue(type, value) {
    let cell = null;
    switch (type) {
      case "unixDate":
        cell = dateHelper.unixToDate(value);
        break;
      case "unixTime":
        cell = dateHelper.unixToTime(value);
        break;
      case "currency":
        cell = formatHelper.currency(value);
        break;
      default:
        cell = value;
        break;
    }
    return cell;
  }

  currentSection = index => {
    return this.props.section && this.props.section.tabs
      ? this.props.section.tabs[index ? index : this.state.tabIndex]
      : {};
  };

  printContent = () => {
    //const { classes /*, section*/ } = this.props;
    const section = this.currentSection();
    const columns = section.columns; //this.props.columns ? this.props.columns : section.columns;
    const tab = this.state.tabs[this.state.tabIndex];
    const items = tab ? tab.items : [];
    //const hasItems = items && items.length > 0 ? true : false;
    const item = this.props.item ? this.props.item : this.prepareHeader();
    const PrintComponent = section.PrintComponent;

    /*const con = (
      <div>
        <h1>Bérlap</h1>
        <h2>Vasútoptika Kft.</h2>
        <h3>{item.name}</h3>
        <div>
          <b>Ledolgozott napok száma:</b> {item.work_days}
        </div>
        <div>
          <b>Ledolgozott órák száma:</b>{" "}
          {Math.round(item.qty * 100) / 100 + " óra"}
        </div>
        <div>
          <b>Bér alap:</b> {formatHelper.currency(item.amount_pays)}
        </div>
        <div>
          <b>Egyéb tételek*:</b> {formatHelper.currency(item.amount_costs)}
        </div>
        <table>
          <thead>
            <tr>
              {columns.map((column, colmnIndex) => {
                return (
                  <td key={colmnIndex}>
                    <b>{column.name ? column.name : column.id}</b>
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {items.map((row, rowIndex) => {
              return (
                <tr key={rowIndex} className={rowIndex % 2 ? "dark" : ""}>
                  {columns.map((column, colmnIndex) => {
                    return (
                      <td key={colmnIndex}>{this.getColumn(row, column)}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        * Egyéb tételek: Jutalmak, előlegek, levonások
        <h2>Fizetendő bér: {formatHelper.currency(item.amount)}</h2>
      </div>
    );*/

    printPageArea(
      PrintComponent(item, items, columns, (r, c) => this.getColumn(r, c))
    );
  };

  render() {
    const { classes /*, section*/ } = this.props;
    const section = this.currentSection();
    const columns = this.props.columns ? this.props.columns : section.columns;
    const { expanded /*, items, fetched */ } = this.state;

    const tab = this.state.tabs[this.state.tabIndex];

    const fetched = tab ? tab.fetched : false;
    const items = tab ? tab.items : [];
    const hasItems = items && items.length > 0 ? true : false;
    const item = this.props.item ? this.props.item : this.prepareHeader();

    const Header = props => (
      <Grid
        item={true}
        xs={12}
        className={props.isSum ? classes.sumContainer : ""}
      >
        <Grid
          container={true}
          //justify="center"
          className={
            (this.state.expanded ? classes.rowContainerExpanded : "") +
            (props.isSum ? " " + classes.rowContainerSum : "")
          }
          spacing={2}
        >
          {columns.map((column, key) => {
            if (column.visible === false) {
              return "";
            }
            return (
              <Grid
                key={key}
                className={
                  classes.cell +
                  (this.state.expanded ? " " + classes.rowExpanded : "") +
                  " " +
                  column.id +
                  /*!item[column.id] || item[column.id] === ""*/ (column.ignoreHeader &&
                  item.isHeader
                    ? " " + classes.emptyCell
                    : "") +
                  (props.isSum ? " " + classes.sumCell : "")
                }
                item={true}
                xs={key > 0 ? 6 : 12}
                md={2}
              >
                {column.ignoreHeader && item.isHeader
                  ? ""
                  : item
                    ? this.getColumn(item, column)
                      : "-"}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );

    return (
      <Fragment>{this.props.showSum ? <Header show={this.props.showSum} isSum={true} /> : ""}
      <ExpansionPanel
        id={this.props.id}
        //disabled={true}
        style={{ width: "100%" }}
        expanded={expanded}
        className={
          //(this.props.noPaper ? classes.noPaper : "") +
          expanded ? classes.expansionPanelExpanded : ""
        }
        onChange={
          section.fetchData && (items.length > 0 || !fetched)
            ? this.handleChange
            : null
        }
      >
        
        <ExpansionPanelSummary
          style={this.props.noHeader === true ? { display: "none" } : {}}
          className={expanded ? classes.expandedPanelSummary : ""}
          expandIcon={
            section.fetchData && (items.length > 0 || !fetched) ? (
              <ExpandMoreIcon />
            ) : null
          }
        >
          <Header />
        </ExpansionPanelSummary>
        {hasItems ? (
          <ExpansionPanelDetails
            className={classes.expansionPanelDetails}
            style={{ display: "block" }}
          >
            {this.props.section.tabs.length > 0
              ? this.props.section.tabs.map((tab, tabindex) => {
                return tab.icon ? (
                  <Tooltip
                      key={tabindex}
                      title={tab.title}
                      disableFocusListener={true}
                    >
                    <IconButton
                      onClick={
                        tab.onIconClick
                          ? () => tab.onIconClick(this.props.id)
                          : () => this.changeTab(tabindex)
                      }
                      className={classes.toolBarBack}
                      color={
                        this.state.tabIndex === tabindex
                            ? "primary"
                            : "default"
                      }
                    >
                      <tab.icon />
                    </IconButton>
                  </Tooltip>
                ) : tab.title ? (
                  <Button
                    onClick={() => this.setState({ tabIndex: tabindex })}
                  >
                    {tab.title}
                  </Button>
                ) : (
                  ""
                );
              })
              : ""}
            {section.enablePrint ? (
              <Tooltip title={"Nyomtatás"} disableFocusListener={true}>
                <IconButton
                  onClick={this.printContent}
                  className={classes.toolBarBack}
                  color={"default"}
                >
                  <PrintIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}
            {items.map((row, index) => {
              return (
                <PreviewItem
                  key={this.state.tabIndex + "_" + index}
                  id={
                    (this.props.id ? this.props.id + "_" : "") +
                    this.state.tabIndex +
                    "_" +
                    index
                  }
                  section={
                    section.child && expanded
                      ? section.child
                      : { tabs: [{ columns: section.columns }] }
                  }
                  columns={section.child ? columns : null}
                  item={row}
                  classes={classes}
                />
              );
            })}
          </ExpansionPanelDetails>
        ) : (
          ""
        )}
      </ExpansionPanel>
      </Fragment>
    );
  }
}

PreviewItem.propTypes = {
  classes: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  columns: PropTypes.array,
  item: PropTypes.object
};
export default PreviewItem;
//export default withStyles(dataPreviewStyle)(PreviewItem);
