import React from "react";
import DataGridForm from "common/components/DataGrid/DataGridForm";
import ServiceWorksheetParts from "views/Service/ServiceWorksheetParts";
import ServiceWorksheetTasks from "views/Service/ServiceWorksheetTasks";
const ServiceWorksheet = props => (
  <DataGridForm
    {...props}
    tabs={{
      id: "service-worksheets",
      documents: true,
      histories: true,
      items: {
        "service-worksheet-parts": {
          itemKey: "service_worksheet_id",
          component: ServiceWorksheetParts,
          filterable: true
        },
        "service-worksheet-tasks": {
          itemKey: "service_worksheet_id",
          component: ServiceWorksheetTasks,
          filterable: true
        }
      }
    }}
  />
);

export default ServiceWorksheet;
