import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";
import FileIcon, { defaultStyles } from "react-file-icon";

const types = strings.documentTypes.map(key => {
  return {
    value: key,
    label: strings.menu[key].title
  };
});

class DocumentTypes extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("document-types");

    this.state = {
      access: access ? access : "",
      definition: {
        attributes: strings.attributes["document-types"],
        id: "document-types",
        url: "document-types",
        columns: [
          { id: "object_id", type: "select", options: types },
          { id: "name" },
          {
            id: "type",
            type: "select",
            options: strings.options["document-types"].type,
            cell: row => {
              if (!row.original.type) {
                return row.value;
              }
              const types = row.original.type.split(",");
              return (
                <div>
                  <FileIcon
                    extension={types[0]}
                    {...defaultStyles[types[0]]}
                    size={20}
                  />
                  {row.value}
                </div>
              );
            }
          },
          { id: "can_generate", type: "checkbox", visible: false },
          { id: "active", type: "checkbox", visible: false }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const {
      user,
      filterable,
      viewMode,
      showPaginationTop,
      showPaginationBottom,
      viewOnRowClick,
      height,
      isDetailView
    } = this.props;
    return (
      <div>
        <DataGrid
          user={user}
          definition={definition}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("document-types", column.id);
          }}
          access={this.state.access}
          filterable={filterable}
          viewMode={viewMode}
          showPaginationTop={showPaginationTop}
          showPaginationBottom={showPaginationBottom}
          height={height}
          viewOnRowClick={viewOnRowClick}
          isDetailView={isDetailView}
          title={item => {
            if (!item) {
              return viewMode
                ? strings.menu["document-types"].title
                : "Tallózás";
            } else if (item.id) {
              return item.name;
            } else {
              return "Új dokumentum típus";
            }
          }}
        />
      </div>
    );
  }
}

DocumentTypes.defaultProps = {
  filterable: true,
  viewMode: false,
  showPaginationBottom: true,
  showPaginationTop: false,
  viewOnRowClick: false
};

DocumentTypes.propTypes = {
  user: PropTypes.object.isRequired,
  filterable: PropTypes.bool,
  viewMode: PropTypes.bool,
  showPaginationTop: PropTypes.bool,
  showPaginationBottom: PropTypes.bool,
  viewOnRowClick: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default DocumentTypes;
