import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";

const types = [
  { value: "menu", label: "Menü" },
  { value: "controller", label: "Kontroller" },
  { value: "attribute", label: "Attribútum" },
  { value: "function", label: "Funkció" }
];

const typesDict = {
  menu: "Menü",
  controller: "Kontroller",
  attribute: "Attribútum",
  function: "Funkció"
};

const menus = Object.keys(strings.menu).map(menu => {
  return { value: menu, label: strings.menu[menu].title };
});

/**
 * Projekt specifikus kódfüggő szabályok
 */
const functions = strings.functions
  ? Object.keys(strings.functions).map(rule => {
      return { value: rule, label: strings.functions[rule] };
    })
  : [];

const controllers = Object.keys(strings.controllers).map(controller => {
  return { value: controller, label: strings.controllers[controller] };
});

const function_rules = [
  { value: "r", label: "Igen" },
  { value: "d", label: "Nem" }
];

const menu_rules = [
  { value: "r", label: "Láthat" },
  { value: "d", label: "Nem láthat" }
];

const controller_rules = [
  { value: "r", label: "Láthat" },
  { value: "rw", label: "Láthat, szerkeszthet" },
  { value: "rwa", label: "Láthat, szerkeszthet, hozzáadhat" },
  { value: "rwax", label: "Láthat, szerkeszthet, hozzáadhat, törölhet" }
];

const attribute_rules = [
  { value: "r", label: "Láthat" },
  { value: "w", label: "Írhat" },
  { value: "d", label: "Nem láthat" }
];

class Rules extends React.Component {
  constructor(props) {
    super(props);
    const access = props.user.accessController("rules");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "rules",
        url: "rules",
        attributes: strings.attributes.rules,
        columns: [
          {
            id: "role_id",
            type: "select",
            hidden: item => {
              return item.user_id ? true : false;
            },
            datasource: {
              controller: "roles",
              objectField: "role",
              valueField: "id",
              search: ["role.name"],
              label: "name",
              sort: { "role.name": "asc" }
            }
          },
          {
            id: "user_id",
            type: "select",
            hidden: item => {
              return item.role_id ? true : false;
            },
            datasource: {
              controller: "users",
              objectField: "user",
              valueField: "id",
              search: ["user.name"],
              label: item => {
                if (!item) {
                  return "";
                }
                if (item.role === 5) {
                  if (item.subcontractor) {
                    let res =
                      item.name +
                      " - " +
                      item.subcontractor.name.substring(0, 10);
                    if (item.subcontractor.name.length > 10) {
                      res += "...";
                    }
                    return res;
                  } else {
                    return item.name + " - hiányzik";
                  }
                } else {
                  return item.name;
                }
              },
              sort: { "user.name": "asc" }
            }
          },
          {
            id: "type",
            type: "select",
            options: types
          },
          {
            id: "category",
            type: "select",
            hidden: item => {
              return item.type === "attribute" ? false : true;
            },
            name: item => {
              if (!item) {
                return strings.attributes.rules.category;
              }
              switch (item.type) {
                case "attribute":
                  return strings.attributes.rules.category + " (Kontroller)";
                default:
                  return strings.attributes.rules.category;
              }
            },
            options: item => {
              if (!item) {
                return [];
              }
              switch (item.type) {
                case "attribute":
                  return controllers;
                default:
                  return [];
              }
            }
          },
          {
            id: "name",
            type: "select",
            name: item => {
              if (!item) {
                return strings.attributes.rules.name;
              }
              switch (item.type) {
                case "attribute":
                  return strings.attributes.rules.name + " (Oszlop)";
                case "controller":
                  return strings.attributes.rules.name + " (Kontroller)";
                case "menu":
                  return strings.attributes.rules.name + " (Menü)";
                case "function":
                  return strings.attributes.rules.name + " (Funkció)";
                default:
                  return strings.attributes.rules.name;
              }
            },
            options: item => {
              if (!item) {
                return [];
              }
              switch (item.type) {
                case "menu":
                  return menus;
                case "controller":
                  return controllers;
                case "function":
                  return functions;
                case "attribute":
                  if (!item.category) {
                    return [];
                  }
                  if (!strings.attributes[item.category]) {
                    return [];
                  }
                  return Object.keys(strings.attributes[item.category]).map(
                    attribute => {
                      return {
                        value: attribute,
                        label: strings.attributes[item.category][attribute]
                      };
                    }
                  );
                default:
                  return [];
              }
            }
          },
          {
            id: "rule",
            type: "select",
            options: item => {
              if (!item) {
                return [];
              }
              switch (item.type) {
                case "menu":
                  return menu_rules;
                case "controller":
                  return controller_rules;
                case "attribute":
                  return attribute_rules;
                case "function":
                  return function_rules;
                default:
                  return [];
              }
            }
          }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const { user } = this.props;
    return (
      <div>
        <DataGrid
          user={user}
          definition={definition}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return this.props.user.accessField("rules", column.id);
          }}
          access={this.state.access}
          inlineEdit={true}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              console.log(item, typesDict);
              return (
                (item.role ? item.role.name : item.user.name) +
                " " +
                typesDict[item.type]
              );
            } else {
              return "Új Jogosultság";
            }
          }}
        />
      </div>
    );
  }
}

Rules.propTypes = {
  //classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default Rules;
