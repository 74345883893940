import React, { useRef } from "react";
import PropTypes from "prop-types";
import ReactToPrint from "react-to-print";
import DataView from "common/components/DataView/DataView";
import { Toolbar, Typography } from "@material-ui/core";

const exludeColsFromDefinition = (def, exluded) => {
  let definition = Object.assign({}, def);
  definition.columns = definition.columns.filter(
    col => exluded.indexOf(col.id) < 0
  );
  return definition;
};
const UserPrintModal = ({ grid, evt }) => {
  const componentRef = useRef();
  const printRef = useRef();
  evt.onAccept = () => {
    printRef.current.handlePrint();
  };
  return (
    <div>
      <ReactToPrint
        ref={printRef}
        trigger={() => <div></div>}
        content={() => componentRef.current}
      />
      <div ref={componentRef} style={{ padding: 32 }}>
        <Toolbar>
          <Typography variant="h2">{grid.state.editItem.name}</Typography>
        </Toolbar>
        <DataView
          fieldsInRow={1}
          definition={exludeColsFromDefinition(grid.props.definition, [
            "name",
            "hourly_price",
            "password1",
            "password2",
            "active",
            "email",
            "job_end_date",
            "task_id",
            "tax_amount",
            "is_owner",
            "avatar_file_id"
          ])}
          access={grid.props.access}
          item={grid.state.editItem}
        />
      </div>
    </div>
  );
};

UserPrintModal.propTypes = {
  grid: PropTypes.any.isRequired,
  evt: PropTypes.object.isRequired
};

export default UserPrintModal;
