const loginPageStyle = theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    height: "100vh"
  },
  paper: {
    marginTop: 30,
    padding: 30
  },
  message: {
    color: theme.palette.text.primary
  },
  textField: {
    //marginLeft: theme.spacing(1),
    //marginRight: theme.spacing(1),
    "& input": {
      /*"&:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 30px transparent inset"
        //"animation-name": "onAutoFillStart"
      }*/
      /*"&:-internal-autofill-selected ": {
        backgroundColor: "#666!important",
        webkitBoxShadow: "0 0 0 30px black inset !important"
        //"animation-name": "onAutoFillStart"
      }*/
      /*"&:-webkit-autofill + label": {
        top: -20, // Just an example
        transform: "scale(0.75)", // Just an example
      }*/
    }
  },
  appName: {
    fontSize: 18,
    //lineHeight: "40px",
    display: "inline-block",
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.primary.main
  },
  headerContainer: {
    marginTop: 30,
    textAlign: "center"
  },
  logo: {
    maxHeight: 64
  }
});

export default loginPageStyle;
