import axios from "axios";
import settings from "variables/settings";

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: settings.api.url
  /*headers: {
    //"X-CSRF-Token": yii2CsrfToken(),
    "Authorization": "Bearer " + localStorage.getItem("auth_key"),
    //'Content-Type': 'application/x-www-form-urlencoded'
  },*/
});

/**
 * Request Wrapper with default success/error actions
 */
const requestHelper = function(options) {
  const onSuccess = function(response) {
    if (window.isdev) {
      console.log("Request Successful:", response);
    }
    return response; //.data;
  };

  const onError = function(error) {
    //console.error("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx

      /*console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);*/

      if (error.response.status === 401) {
        //hanndle logout
      }
    } else {
      // Something else happened while setting up the request
      // triggered the error
      //console.error("Error Message:", error.message);
    }
    //throw error.response || error.message;
    return Promise.reject(error.response || error.message);
  };
  if (options.data && options.data.append) {
    options.data.append("_language", window._lang);
  }

  //db kezelés
  //options.url = "/" + window.db + options.url;
  if (window.isdev) {
    console.log(
      "Request:",
      decodeURI(options.url).replace(new RegExp("%2C", "g"), ",")
    );
  }
  options.url = decodeURI(options.url).replace(new RegExp("%2C", "g"), ",");
  //Token autentikáció
  options.headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
    Accept: "application/json"
    //ContentType: "application/json",
  };
  //options.data.language = window.user.language;
  return client(options)
    .then(onSuccess)
    .catch(onError);
};

export default requestHelper;
