import { Box, Breadcrumbs, Link, Tab, Tabs, Toolbar } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CreateNewIcon from "@material-ui/icons/AddCircle";
import BlockIcon from "@material-ui/icons/Block";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import TrashIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import PageViewIcon from "@material-ui/icons/Pageview";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import RefreshIcon from "@material-ui/icons/Refresh";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import TabViewIcon from "@material-ui/icons/Tab";
import SettingsIcon from "@material-ui/icons/ViewColumn";
import ClearFilterIcon from "common/assets/icons/ClearFilterIcon";
import XlsxIcon from "common/assets/icons/XlsxIcon";
import AppToolbar from "common/components/AppToolbar";
import DataForm from "common/components/DataForm/DataForm";
import FormComboboxField from "common/components/DataForm/FormComboboxField";
import GridActionCell from "common/components/DataGrid/GridActionCell";
import GridCheckboxField from "common/components/DataGrid/GridCheckboxField";
import GridDateTimePickerField from "common/components/DataGrid/GridDateTimePickerField";
import GridFilter from "common/components/DataGrid/GridFilter";
import GridSelectField from "common/components/DataGrid/GridSelectField";
import GridTextField from "common/components/DataGrid/GridTextField";
import DataView from "common/components/DataView/DataView";
import DatePicker from "common/components/DatePicker";
import { globalMessage } from "common/components/GlobalMessaging";
import ImageViewer from "common/components/ImageViewer";
import Modal from "common/components/Modals/Modal";
import ModalConfirm from "common/components/Modals/ModalConfirm";
import dateHelper from "common/utils/dateHelper";
import formatHelper from "common/utils/formatHelper";
import restHelper, { createListItem } from "common/utils/restHelper";
import urlHelper from "common/utils/urlHelper";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import FileIcon, { defaultStyles } from "react-file-icon";
import { Link as RLink } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import settings from "variables/settings";
import strings from "variables/strings.json";
import customButtons from "variables/toolbarButtons";
import GridCustomFilter from "./GridCustomFilter";
import PDFViewer from "./PDFViewer";
import Pagination from "./Pagination";
import TextFilter from "./TextFilter";
/**
 * Szerveroldali adatok megjelenítésére szolgáló grid
 * Főbb funkciók:
 * - Lapozás
 * - Sorrendezés
 * - Szűrés
 * - Formos szerkesztés
 */
class DataGridBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      urlParams: props.urlParams,
      columns: this.columnsById(props.definition.columns), //Adatbázis név alapján elérhető oszlop definíciók
      editItem: props.item || null, //Éppen szerkesztés alatt álló sor. Amennyiben nem null a form jelenik meg a táblázat helyett
      //React table paraméterek
      items: props.items || [], //Táblázat sorai
      loading: true, //Betöltő megjelenítése
      loadingCustom: true, //Egyedi Betöltő megjelenítése
      sorted: props.defaultSort || [], //Sorrendezési definíció
      filtered: [], //Szűrő definíció
      page: 0, //Aktuális oldal (A react table 0-val kezd a szerver oldali api 1-gyel)
      pages: null, //Lapok száma
      total: 0,
      pageSize: props.fullHeight
        ? props.defaultPageSizeFullHeight
        : props.defaultPageSize, //Egy lapon megjelenítendő sorok száma
      expanded: {}, //Kinyitott sorok
      resized: [], //Átméretezett oszlopok
      selected: [],
      selection: [], //kiválasztott oszlopok
      selectionAll: false, //összes ki van választva
      inlineEditItem: null,
      showTrash: false,
      error: null,
      isRedux: false,
      openSettings: false,
      columnVisiblity: {},
      viewMode: props.viewMode,
      forceHeaderFilter: false,
      hideToolbar: false,
      selectedFilterTab: props.filterTabs ? props.filterTabs[0] : "all",
      hideViewSwitcher: false, //A nézet választó elrejtése
      initialized: true, //props.match.params. ? true : false,
      fetched: false, //props.match.params. ? true : false,
      loadings: {}, //Toolbar gombok töltése
      ownFilterOn: true,
      ...this.loadParamsFromStorage() //Storage paraméterek kiolvasása
    };
    this.modal = React.createRef();
    this.table = React.createRef();
    this.top = null;
    this.filtered = []; //Temp szűrő definíció
    this.columns = this.prepareColumns();
  }

  /**
   * A beírt paraméterek kiolvasása a sessionStorage-ből
   */
  loadParamsFromStorage = () => {
    const { definition, storage } = this.props;
    const { id } = definition;
    const params = sessionStorage.getItem(id);
    let result = {};
    if (params) {
      result = JSON.parse(params);
    }

    const columnVisiblity = storage.getItem(id + "_visivlity");
    if (columnVisiblity) {
      result.columnVisiblity = JSON.parse(columnVisiblity);
    }

    const columnResized = storage.getItem(id + "_resized");
    if (columnResized) {
      result.resized = JSON.parse(columnResized);
    }

    //result.columnVisiblity = { name: false, company_phone: true };
    return result;
  };

  /**
   * A beírt paraméterek mentése a sessionStorage-be
   */
  saveParamsToStorage = state => {
    const { definition, storage } = this.props;
    const { id } = definition;
    const params = {
      sorted: state.sorted, //Sorrendezési definíció
      filtered: state.filtered, //Szűrő definíció
      page: state.page, //Aktuális oldal (A react table 0-val kezd a szerver oldali api 1-gyel)
      //pages: state.pages, //Lapok száma
      pageSize: state.pageSize, //Egy lapon megjelenítendő sorok száma
      expanded: state.expanded, //Kinyitott sorok
      //resized: state.resized, //Átméretezett oszlopok
      ownFilterOn: state.ownFilterOn,
      selectedFilterTab: state.selectedFilterTab //Átméretezett oszlopok
    };

    sessionStorage.setItem(id, JSON.stringify(params));
    storage.setItem(id + "_resized", JSON.stringify(state.resized));
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      !this.props.disableRouting
    ) {
      this.onRouteChanged();
    }
  }

  getId = () => {
    const { primaryKey } = this.props;
    return this.state.editItem && this.state.editItem[primaryKey]
      ? this.state.editItem[primaryKey]
      : undefined;
  };

  onRouteChanged = () => {
    const { history, location, match, isDetailView } = this.props;
    const viewParams = urlHelper.getViewParams(history, location, match); //Object.assign({}, this.state.opened, urlHelper.get().opened);

    //const opened = Object.assign({}, this.state.opened, selected);
    //this.
    if (isDetailView || this.props.disableRouting) {
      this.fetchData(this.state);
      return;
    }

    if (!viewParams || isDetailView) {
      if (this.state.editItem) {
        this._onCancel();
      }
      if (this.state.items.length === 0) {
        this.fetchData(this.state);
      }
      return;
    }
    if (viewParams.mode === "edit") {
      if (
        !this.state.editItem ||
        Object.keys(this.state.editItem).length < 2 ||
        viewParams.id != this.getId()
      ) {
        this.setState(
          {
            /*editItem: { id: viewParams.id },*/ viewMode: false,
            initialized: false
          },
          () => {
            this.refreshEditItem(viewParams.id);
          }
        );
      } else {
        if (this.state.viewMode) {
          this.setState({ viewMode: false });
        }
      }
    }
    if (viewParams.mode === "create") {
      this.setState(
        { editItem: { ...this.getItemValuesFromCondition() } },
        () => {
          //this.refreshEditItem();
        }
      );
    }
    if (viewParams.mode === "view") {
      if (
        !this.state.editItem ||
        Object.keys(this.state.editItem).length < 2 ||
        viewParams.id != this.getId()
      ) {
        this.setState(
          {
            /*editItem: { id: viewParams.id },*/ viewMode: true,
            initialized: false
          },
          () => {
            this.refreshEditItem(viewParams.id);
          }
        );
      } else {
        if (!this.state.viewMode) {
          this.setState({ viewMode: true });
        }
      }
    }

    //this.storage.set({ selected: selected, opened: opened });
  };

  /**
   * Az oszlopok szótárának előállítása későbbi használatra.
   *
   * @param {Array} columns Az oszlopok tömbje a definícióból. definition.columns
   * @return {Object} Oszlop definíciós szótár
   */
  columnsById = columns => {
    let result = {};
    for (let i in columns) {
      let column = columns[i];
      //Megcsináljuk a selectnek az opció szótárt
      if (column.options) {
        let optionsDict = {};
        let opts = typeof column.options === "function" ? [] : column.options;

        for (let i in opts) {
          optionsDict[opts[i].value] = opts[i].label;
        }

        column.optionsDict = item => {
          if (!item) {
            return optionsDict;
          }
          if (typeof column.options === "function") {
            let opts = column.options(item);
            optionsDict = {};
            for (let i in opts) {
              optionsDict[opts[i].value] = opts[i].label;
            }
            return optionsDict;
          } else {
            return optionsDict;
          }
          /*return typeof column.options === "function"
            ? column.options(item)
            : column.optionsDict;*/
        };
      }

      result[column.id] = column;
    }
    return result;
  };

  getBreadCrumbs = () => {
    const title = this.title(this.state.editItem);
    let bc = this.props.breadcrumbs ? this.props.breadcrumbs.slice() : [];
    if (bc.length > 0) {
      bc[bc.length - 1].onClick = this.onCancel;
    } else {
      bc.push({
        title:
          (strings.menu[this.props.definition.id] &&
            strings.menu[this.props.definition.id].title) ||
          "Nincs cím " + this.props.definition.id,
        id: this.props.definition.id,
        onClick: this.onCancel
      });
    }
    if (title !== "") {
      bc.push({
        title: title,
        id: this.props.definition.id
        //onClick: this.onCancel
      });
    }

    if (bc.length === 1) {
      bc.unshift({
        title:
          strings.menu[this.props.definition.id].title ||
          "Nincs cím " + this.props.definition.id,
        id: this.props.definition.id,
        onClick: this.onCancel
      });
    }

    /*
    bc.forEach((element, i) => {
      console.log(i);
      if (bc[i - 1]) {
        bc[i - 1].onClick = element.onClick;
        bc[i - 1].actionTitle = element.title;
        delete element.onClick;

    });*/
    //console.log("bc", bc);
    return bc;
  };

  getHeight = () => {
    if (this.props.height) {
      return this.props.height;
    }
    if (this.props.noScroll) {
      return "auto";
    }
    if (this.top === null) {
      return "auto";
    }
    //console.log(window.innerHeight, window.outerHeight);
    const h = this.top + 3 + 32; // + (window.outerHeight - window.innerHeight);
    return "calc(100vh - " + h + "px)";
    //return "calc(var(--vh, 1vh) * 100 - " + h + "px)";
    /*const body = document.body;
    const html = document.documentElement;

    const height = Math.min(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    console.log(this.top, height);

    return height - this.top - 2;*/
  };

  /**
   * A komponens betöltése után elkérjük az adatokat a szervertől
   */
  componentDidMount() {
    //Megkeressük a táblázat tetejét
    if (this.table.current && !this.tableNode) {
      this.tableNode = ReactDOM.findDOMNode(this.table.current);
      if (this.tableNode) {
        const rect = this.tableNode.getBoundingClientRect();
        this.top = rect.top;
      }
    }

    document.addEventListener("keydown", this.escFunction, false);
    if (this.props.startEditRef) {
      this.props.startEditRef(this.onEdit);
    }

    if (this.props.setStateRef) {
      this.props.setStateRef(this.setState);
    }
    this.onRouteChanged();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.urlParams) !==
      JSON.stringify(this.props.urlParams)
    ) {
      let state = Object.assign({}, this.state);
      state.urlParams = nextProps.urlParams;
      this.setState({ urlParams: nextProps.urlParams });
      this.fetchData(state);
    }
  }

  setLoading = (key, loading) => {
    const loadings = Object.assign({}, this.state.loadings);
    if (loadings[key]) {
      if (!loading) {
        delete loadings[key];
      }
    } else {
      if (loading) {
        loadings[key] = true;
      }
    }
    this.setState({ loadings });
  };

  setModalInactive = (id, inactive) => {
    this.setState({
      [`modal_inactive${id}`]: inactive
    });
  };

  closeModal = id => {
    this.setState({
      [`modal${id}`]: false,
      [`modal_inactive${id}`]: false
    });
  };

  title = item => {
    let title = null;
    if (this.state.showTrash && !item) {
      return "Lomtár";
    }
    if (this.props.title) {
      title =
        typeof this.props.title === "function"
          ? this.props.title(item)
          : this.props.title;
    }
    return title;
  };

  /*componentDidUpdate() {
    if (!this.state.editItem && this.scrollTop && this.scrollTop > 0) {
      const table = document
        .getElementsByClassName("ReactTable")[0]
        .getElementsByClassName("rt-tbody")[0];
      table.scrollTo(0, this.scrollTop);
    }
  }*/

  /**
   * Szerkesztés indítása, szerkesztendő sor kiválasztása, amely hatására megjelenik a form
   */
  onEdit = (item, mode = "edit") => {
    const { primaryKey } = this.props;
    if (!this.state.editItem) {
      const table0 = document.getElementsByClassName("ReactTable")[0];

      const table = table0
        ? table0.getElementsByClassName("rt-tbody")[0]
        : null;
      if (table) {
        this.scrollTop = table.scrollTop || 0;
      }
    }
    //console.log("onEdit", item);
    this.setState(
      {
        editItem: item,
        viewMode: mode === "edit" ? false : true
      },
      () => {
        if (!this.props.isDetailView && !this.props.disableRouting) {
          if (item[primaryKey]) {
            this.props.history.push(
              `${this.props.match.url}/${mode}/${item[primaryKey]}`
            );
          } else {
            this.props.history.push(`${this.props.match.url}/create`);
          }
        }
      }
    );

    if (this.props.onEdit) {
      this.props.onEdit(item);
    }
  };

  /**
   * Siekres törlés után hívódik meg
   */
  onDelete = item => {
    const { definition, primaryKey } = this.props;
    this.modal
      .open(
        this.state.showTrash
          ? "Végleges törlés megerősítése"
          : "Törlés megerősítése",
        this.state.showTrash
          ? "Biztosan VÉGLEGESEN törlöd a tételt?"
          : "Biztosan törlöd a tételt?",
        this.title(item),
        strings.buttons.yes,
        strings.buttons.no
      )
      .then(() => {
        this.modal.loading(true);
        restHelper
          .remove(definition.url, item[primaryKey], {
            trash: this.state.showTrash ? 1 : 0
          })
          .then(deletedItem => {
            //const items = restHelper.deleteByKey(this.state.items, "id", deletedItem.id);
            //this.setState({ items: items });
            globalMessage.success("Sikeres Törlés.");
            this.fetchData(this.state, true).then(() => {
              this.modal.hide();
            });
            this.afterDelete(deletedItem);
          })
          .catch(error => {
            this.modal.loading(false);
            this.modal.error(error.error);
          });
      });
  };

  /**
   * Siekres törlés után hívódik meg
   */
  onRestore = item => {
    const { definition } = this.props;
    this.modal
      .open(
        "Visszaállítás megerősítése",
        "Biztosan visszaállítod a tételt?",
        this.title(item),
        strings.buttons.yes,
        strings.buttons.no
      )
      .then(() => {
        this.modal.loading(true);
        restHelper
          .restore(definition.url, item.id)
          .then((/*deletedItem*/) => {
            //const items = restHelper.deleteByKey(this.state.items, "id", deletedItem.id);
            //this.setState({ items: items });
            globalMessage.success("Sikeres visszaállítás.");
            this.fetchData(this.state, true).then(() => {
              this.modal.hide();
            });
          })
          .catch(error => {
            this.modal.loading(false);
            this.modal.error(error.error);
          });
      });
  };

  /**
   * Szerkesztés visszavonása után hívódik meg
   */
  onCancel = () => {
    /*if (!this.state.items || this.state.items.length === 0) {
      this.fetchData(this.state);
    }*/
    if (!this.props.isDetailView && !this.props.disableRouting) {
      this.props.history.push(`${this.props.match.url}`);
      return;
    }
    this._onCancel();
  };

  _onCancel = () => {
    /*if (!this.state.items || this.state.items.length === 0) {
      this.fetchData(this.state);
    }*/
    const editItem = Object.assign({}, this.state.editItem);
    this.setState(
      {
        editItem: null,
        inlineEditItem: null,
        hideToolbar: false
      },
      () => {
        if (!this.state.editItem && this.scrollTop && this.scrollTop > 0) {
          const table = document
            .getElementsByClassName("ReactTable")[0]
            .getElementsByClassName("rt-tbody")[0];
          table.scrollTo(0, this.scrollTop);
        }
      }
    );
    if (this.props.onCancelEdit) {
      this.props.onCancelEdit(editItem);
    }
  };

  /**
   * Sikeres mentés után hívódik meg
   */
  afterSave = (item, isNew) => {
    const { refreshAfterCreate } = this.props;
    let items = [];
    if (this.state.editItem && this.getId() && this.getId() !== item.id) {
      //Klónozás esete
      items = restHelper.addNew(this.state.items, item);
      this.props.onSave && this.props.onSave(item, isNew);
      console.log("push", `${this.props.match.url}/edit/${item.id}`);
      this.props.history.push(`${this.props.match.url}/edit/${item.id}`);
    } else if (isNew) {
      //TODO: Csoportos létrehozás kezelése
      items = Array.isArray(item)
        ? createListItem(item, this.state.items)
        : restHelper.addNew(this.state.items, item);

      //items = restHelper.addNew(this.state.items, item);
      this.props.onSave && this.props.onSave(item, isNew);
    } else {
      items = restHelper.updateByKey(this.state.items, "id", item);
      this.props.onSave && this.props.onSave(item, isNew);
    }
    //console.log("refresh0", this.state, this.state.editItem, isNew, refreshAfterCreate);
    //form szerkesztés után
    if (isNew && refreshAfterCreate) {
      //this.fetchData(this.state);
      this.setState({ editItem: null }, () => {
        this.fetchData(this.state);
      });
    } else if (this.state.editItem) {
      if (Array.isArray(item)) {
        this.setState({ editItem: null, items: items });
      } else {
        const shouldNavigate =
          !this.props.isDetailView && !this.props.disableRouting && isNew;

        this.setState(
          { editItem: item, items: this.state.loading ? [] : items },
          () => {
            //Url átírása létrehozás után szerkesztésre
            if (shouldNavigate) {
              this.props.history.replace(
                `${this.props.match.url}/edit/${item.id}`
              );
            }
          }
        );
      }
    } else {
      this.inlineEditItem = null;
      this.setState({ inlineEditItem: null, items: items });
    }
  };

  /**
   * Sikeres törlés után hívódik meg
   */
  afterDelete = item => {
    this.props.onDelete && this.props.onDelete(item);
  };

  refreshEditItem = id => {
    const { editItem } = this.state;
    const { definition } = this.props;
    if (id || editItem) {
      this.setState({ loadingCustom: true });
      restHelper
        .view(definition.url, id || this.getId())
        .then(response => {
          let items = restHelper.updateByKey(this.state.items, "id", response);
          this.setState({
            editItem: response,
            items: items,
            initialized: true,
            loadingCustom: false
          });
        })
        .catch(error => {
          /*this.setState({
            error: JSON.stringify(error),
            initialized: true,
            loading: false,
            customLoading: false,
            editItem: {}
          });*/
          this.setState({ loadingCustom: false });
          globalMessage.fatalError(error.error);
        });
    }
  };

  setEditItem = editItem => {
    if (editItem) {
      const items = restHelper.updateByKey(this.state.items, "id", editItem);
      this.setState({ editItem, items, initialized: true });
    }
  };

  exportPdf = template => {
    const { editItem } = this.state;
    const { definition } = this.props;
    if (editItem) {
      restHelper
        .exportPdf(definition.url, this.getId(), { template, inline: true })
        .then(response => {
          console.log(response);
          this.setState({ ["modalpdf-export"]: true });

          //let items = [];
          //items = restHelper.updateByKey(this.state.items, "id", response);
          //this.setState({ editItem: response, items: items });
        });
    }
  };

  exportExcel = () => {
    const { state } = this;
    const { definition, customLoader, afterFetch } = this.props;

    if (definition.url) {
      const { page, pageSize, sorted, filtered } = state;

      //Lekérdezési paraméterek eltárolása a storage-ben
      this.saveParamsToStorage(state);

      //Betőltés jelző bekapcsolása
      this.setState({
        loading: customLoader ? false : true,
        loadingCustom: true
      });
      let filter = this.prepareFilter(filtered);

      if (definition.condition) {
        if (Array.isArray(definition.condition)) {
          definition.condition.forEach(element => {
            filter.push([
              element.id,
              element.condition || "exact",
              element.value
            ]);
          });
        } else {
          filter.push([
            definition.condition.id,
            definition.condition.condition || "exact",
            definition.condition.value
          ]);
        }
      }

      //Api számára küldendő paraméterek összállítása
      const params = {
        page: page + 1, //A grid oldalszáma nullától kezdődik
        pagesize: pageSize,
        sort: this.prepareSort(sorted),
        filter: filter,
        trash: state.showTrash ? 1 : 0,
        ...(state.urlParams ? state.urlParams : {})
      };

      //Request küldése a szervernek
      return restHelper
        .index(definition.url + "/excel", params)
        .then(response => {
          this.setState({
            loading: false,
            loadingCustom: false
          });
        })
        .catch(error => {
          //console.log(error.data.error);
          this.setState({
            loading: false,
            loadingCustom: false,
            error: error.data.error
          });
        });
    }
  };

  excelLink = () => {
    const { state } = this;
    const { definition, customLoader, afterFetch } = this.props;

    if (definition.url) {
      const { page, pageSize, sorted, filtered } = state;
      let filter = this.prepareFilter(filtered);

      if (definition.condition) {
        if (Array.isArray(definition.condition)) {
          definition.condition.forEach(element => {
            filter.push([
              element.id,
              element.condition || "exact",
              element.value
            ]);
          });
        } else {
          filter.push([
            definition.condition.id,
            definition.condition.condition || "exact",
            definition.condition.value
          ]);
        }
      }

      //Api számára küldendő paraméterek összállítása
      const params = {
        page: page + 1, //A grid oldalszáma nullától kezdődik
        pagesize: pageSize,
        sort: this.prepareSort(sorted),
        filter: filter,
        trash: state.showTrash ? 1 : 0,
        ...(state.urlParams ? state.urlParams : {})
      };
      return urlHelper.downloadExcelLink(definition.url, params);
    }
  };

  prepareItems = items => {
    let i = Object.assign({}, items);
    i.push({});
    return i;
  };

  /**
   * Kliens oldali szűrés egyedi lekezelése, nincs használatban
   */
  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    let val =
      typeof row[id] === "boolean"
        ? row[id]
          ? "Igen"
          : "Nem"
        : String(row[id]);

    if (filter.id === "role") {
      val = row[id] === 1 ? strings.roles.admin : strings.roles.guest;
    }

    if (filter.id === "name") {
      val =
        row._original["name"] +
        row._original["company_name"] +
        row._original["email"] +
        row._original["phone"];
    }
    return row[id] !== undefined
      ? String(val.toLowerCase()).indexOf(filter.value.toLowerCase()) >= 0
      : true;
  };

  columnName = column => {
    const { definition } = this.props;
    //const access = this.access();
    const { attributes } = definition;
    const columnName =
      column.name ||
      (attributes && attributes[column.id] ? attributes[column.id] : column.id);

    return typeof columnName === "function" ? columnName() : columnName;
  };

  /**
   * Oszlop definíciók előállítása a react table számára
   */
  prepareColumns = showTrash => {
    if (typeof showTrash === "undefined") {
      showTrash = this.state.showTrash;
    }
    const {
      fieldAccessHandler,
      definition,
      showRowNumber,
      actionColumnFirst,
      hideActionColumn,
      hideEditButton,
      primaryKey,
      rowButtons,
      selectable,
      classes
      //viewMode
    } = this.props;
    const { viewMode } = this.state; //TODO: viewMode
    //const access = this.access();
    //const { attributes } = definition;
    let avatar = {};
    if (definition.columns) {
      let columns = definition.columns
        .filter(obj => {
          if (obj.id === definition.avatar) {
            avatar = obj;
            return false;
          }
          if (typeof obj.visible === "function") {
            if (obj.visible(this.state.inlineEditItem) === false) {
              return false;
            }
          } else if (obj.visible === false) {
            if (this.state.columnVisiblity[obj.id] !== true) {
              return false;
            }
          } else {
            if (this.state.columnVisiblity[obj.id] === false) {
              return false;
            }
          }

          if (fieldAccessHandler) {
            obj.access = fieldAccessHandler(obj);
            return obj.access === "r" || obj.access === "w";
          } else {
            return true;
          }
        })
        .map(column => {
          const cname = this.columnName(column);
          return {
            Header:
              cname && cname !== "" ? (
                <Tooltip disableFocusListener title={cname}>
                  <span>{cname}</span>
                </Tooltip>
              ) : (
                cname
              ),
            accessor: column.id,
            filterable: column.filterable, // === false ? false : true,
            sortable: column.sortable, // === false ? false : true,
            minWidth: 50,
            width: column.width,
            Cell:
              column.detailLink && column.cell
                ? this.renderCell(column)
                : column.cell || this.renderCell(column),
            Filter: column.filterCell || this.renderFilter(column),
            Footer: column.footer
              ? typeof column.footer === "function"
                ? //? column.footer(column, this.state.items)
                  column.footer
                : //: this[column.footer + "Footer"](column, this.state.items)
                  this[column.footer + "Footer"](column, this.state.items)
              : null
          };
        });

      if (showRowNumber) {
        columns.unshift({
          Header: "",
          id: "rowNumber",
          maxWidth: 50,
          filterable: false,
          sortable: false,
          Cell: row => {
            return <div>{row.index + 1}</div>;
          }
        });
      }

      if (definition.avatar) {
        let isVisibleAvatar = true;
        if (typeof avatar.visible === "function") {
          if (avatar.visible(this.state.inlineEditItem) === false) {
            isVisibleAvatar = false;
          }
        } else if (avatar.visible === false) {
          if (this.state.columnVisiblity[avatar.id] !== true) {
            isVisibleAvatar = false;
          }
        } else {
          if (this.state.columnVisiblity[avatar.id] === false) {
            isVisibleAvatar = false;
          }
        }

        if (isVisibleAvatar) {
          const avatarField =
            definition.avatar.substr(definition.avatar.length - 3) === "_id"
              ? definition.avatar.substr(0, definition.avatar.length - 3)
              : definition.avatar;
          columns.unshift({
            Header: "",
            id: definition.avatar,
            accessor: definition.avatar,
            maxWidth: 60,
            filterable: false,
            sortable: false,
            Cell: row => (
              <ImageViewer
                file_id={
                  row.original[avatarField]
                    ? row.original[avatarField].id
                    : undefined
                }
                name={
                  row.original[avatarField]
                    ? row.original[avatarField].name
                    : ""
                }
                avatarStyle={{ marginRight: 12 /*, width: 24, height: 24*/ }}
                DefaultIcon={avatar.defaultIcon}
              />
            )
          });
        }
      }
      //console.log("AAA", viewMode, hideActionColumn);
      if (!viewMode && !hideActionColumn) {
        //console.log(showTrash);
        columns[actionColumnFirst ? "unshift" : "push"]({
          Header: "",
          accessor: "actions",
          Cell: cell => (
            <GridActionCell
              refreshList={timeout => {
                if (timeout) {
                  setTimeout(() => {
                    this.fetchData(this.state, true);
                  }, timeout);
                } else {
                  this.fetchData(this.state, true);
                }
              }}
              primaryKey={primaryKey}
              item={cell.original}
              events={{
                onEdit: this.onEdit,
                onDelete: this.onDelete,
                onRestore: this.onRestore
              }}
              buttons={rowButtons}
              access={this.access(cell.original)}
              hideEditButton={hideEditButton}
            />
          ),
          width:
            (showTrash ? 100 : hideEditButton ? 35 : 70) +
            (rowButtons ? rowButtons.length * 35 : 0),
          filterable: false,
          sortable: false
        });
      }

      if (selectable) {
        columns.unshift({
          Header: () => {
            const { selection, selectionAll, total } = this.state; //TODO: viewMode
            const selectedCount = selectionAll
              ? total - selection.length
              : selection.length;
            return (
              <Badge
                badgeContent={selectedCount}
                style={{ display: "inline" }}
                classes={{ badge: classes.selectBadge }}
              >
                <Checkbox
                  color="primary"
                  className={classes.selectCheckbox}
                  checked={
                    selectionAll && selection.length === 0 ? true : false
                  }
                  onChange={this.toggleSelectAll}
                  indeterminate={
                    (selectionAll && selection.length > 0) ||
                    (!selectionAll && selection.length > 0)
                      ? true
                      : false
                  }
                />
              </Badge>
            );
          },
          id: "selection",
          maxWidth: 60,
          filterable: false,
          sortable: false,
          Cell: row => {
            const { selection, selectionAll } = this.state;
            const checked =
              (selectionAll && selection.indexOf(row.original.id) < 0) ||
              (!selectionAll && selection.indexOf(row.original.id) >= 0)
                ? true
                : false;
            return (
              <Checkbox
                color="primary"
                className={classes.selectCheckbox}
                checked={checked}
                onChange={evt => this.toggleSelect(evt, row.original.id)}
              />
            );
          }
        });
      }

      return columns;
    } else {
      return [];
    }
  };

  toggleSelectAll = evt => {
    let selection = this.state.selection.slice();
    let selectionAll = this.state.selectionAll;
    if (selectionAll && selection.length > 0) {
      selection = [];
    } else if (selectionAll) {
      selectionAll = false;
    } else {
      selectionAll = true;
      selection = [];
    }
    //console.log("selectAll", { selection, selectionAll })
    this.setState({ selection, selectionAll });
  };

  toggleSelect = (evt, id) => {
    const { selectionAll } = this.state;
    let selection = this.state.selection.slice();

    const index = selection.indexOf(id);
    if (selectionAll && index >= 0) {
      selection.splice(index, 1);
    } else if (selectionAll) {
      selection.push(id);
    } else if (index >= 0) {
      selection.splice(index, 1);
    } else {
      selection.push(id);
    }
    //console.log(selection);
    this.setState({ selection });
  };

  count = (items, prop) => {
    var total = 0;
    for (let i = 0, _len = items.length; i < _len; i++) {
      //if (items[i].role == 3) {
      total += items[i][prop] ? 1 : 0;
      //}
    }
    return total;
  };

  /*countFooter = (column, items) => (
    <span>
      <strong>{this.count(items, column.id)} db</strong>
    </span>
  );*/
  countFooter = column => info => (
    <span className="footer-text">{this.count(info.data, column.id)} db</span>
  );

  sum = (items, prop) => {
    var total = 0;
    for (let i = 0, _len = items.length; i < _len; i++) {
      //if (items[i].role == 3) {
      total += items[i][prop] ? items[i][prop] : 0;
      //}
    }
    return total;
  };

  /*sumFooter = (column, items) => (
    <span>
      <strong>{this.viewValue(column.type, this.sum(items, column.id))}</strong>
    </span>
  );*/
  sumFooter = column => info => {
    let currency_type = undefined;
    if (column.currencyType) {
      currency_type = "Ft";
      if (typeof column.currencyType === "function") {
        currency_type = column.currencyType({ original: {} });
      } else {
        currency_type = column.currencyType;
      }
    }
    return (
      <span className="footer-text">
        {this.viewValue(
          column.type,
          this.sum(info.data, column.id),
          currency_type
        )}
      </span>
    );
  };

  viewValue(type, value, deviza) {
    let cell = null;
    switch (type) {
      case "unixDate":
        cell = dateHelper.unixToDate(value);
        break;
      case "unixTime":
        cell = dateHelper.unixToTime(value);
        break;
      case "unixDateTime":
        cell = dateHelper.unixToDateTime(value);
        break;
      case "unixRelative":
        cell = dateHelper.unixToRelative(value);
        break;
      case "currency":
        cell = formatHelper.currency(value, "", deviza);
        break;
      case "fileSize":
        cell = formatHelper.fileSize(value);
        break;
      default:
        cell = value;
        break;
    }
    return cell;
  }

  /**
   * Sorrendezés változásának kezelése
   */
  onSortedChange = sorted => {
    let state = Object.assign({}, this.state, { sorted });
    this.fetchData(state);
  };

  /**
   * Aktuális oldal változásának kezelése
   */
  onPageChange = page => {
    let state = Object.assign({}, this.state, { page });
    this.fetchData(state);
  };

  /**
   * Oldal méret változásának kezelése
   */
  onPageSizeChange = (pageSize, page) => {
    let state = Object.assign({}, this.state, { pageSize, page });
    this.fetchData(state);
  };

  /**
   * Szűrő változásának kezelése, késleltetésell
   */
  onFilteredChange = (filtered, disableTime) => {
    //console.log("onFilteredChange", filtered);

    //console.log(state, filtered);
    if (this.filterTimerId) {
      clearTimeout(this.filterTimerId);
    }
    const hasValue = fo => {
      if (fo.value === null || fo.value === undefined) {
        return false;
      }

      if (
        typeof fo.value === "object" &&
        fo.value.extended &&
        (fo.value.value === null || fo.value.value === undefined)
      ) {
        return false;
      }

      return true;
    };
    let ff = [];
    if (filtered && Array.isArray(filtered)) {
      ff = filtered.filter(f => hasValue(f));
      //console.log("ff", ff);
    }

    let state = Object.assign({}, this.state, { filtered: ff, page: 0 });

    if (disableTime === true) {
      this.setState({ filtered: ff, page: 0 }, () => {
        this.fetchData(state);
      });
    } else {
      const context = this;
      this.filterTimerId = setTimeout(() => {
        context.setState({ filtered: ff, page: 0 }, () => {
          context.fetchData(state);
        });
      }, 900);
    }
  };

  /**
   * Kinyitott sorok változásának kezelése
   */
  onExpandedChange = expanded => {
    //Lekérdezési paraméterek eltárolása a storage-ben
    let state = Object.assign({}, this.state, { expanded });
    this.saveParamsToStorage(state);
    this.setState({ expanded });
  };

  /**
   * Oszlop méretezés változásának kezelése
   */
  onResizedChange = resized => {
    //Lekérdezési paraméterek eltárolása a storage-ben
    let state = Object.assign({}, this.state, { resized });
    this.saveParamsToStorage(state);
    this.setState({ resized });
  };

  /**
   * Filter definíció összeállítása a szerveroldali api számára
   */
  prepareFilter = filtered => {
    const columns = Object.assign({}, this.state.columns);
    const filters = {};
    if (this.props.customFilter && this.props.customFilter.filters) {
      this.props.customFilter.filters.forEach(element => {
        filters[element.id] = Object.assign({}, element);
        if (!columns[element.id]) {
          columns[element.id] = Object.assign({}, element);
        }
      });
    }
    let result = [];
    for (let i in filtered) {
      const filter = filtered[i];
      if (!columns[filter.id] && filter.id !== "_fulltext_query") {
        if (!filters[filter.id] && filter.id !== "_fulltext_query") {
          console.log("prepareFilter error", columns, filter.id);
          continue;
        }
      }
      let search = columns[filter.id] ? columns[filter.id].search : null;
      //let type = columns[filter.id] ? columns[filter.id].type : null;
      if (filter.id !== "_fulltext_query") {
        if (
          !search &&
          columns[filter.id].datasource &&
          columns[filter.id].datasource.search
        ) {
          search = columns[filter.id].datasource.search;
        }
      }
      //console.log("FILTER: ", filter.id, filter);
      if (filter && filter.value && filter.value.start) {
        //console.log("Date filter", filter);
        if (filter.value.end) {
          result.push([
            filter.id,
            "between",
            [filter.value.start, filter.value.end]
          ]);
        }
      } else if (filter && filter.value && filter.value.extended) {
        //console.log("Extended filter", filter);
        if (filter.value.value) {
          result.push([filter.id, filter.value.type, filter.value.value]);
        }
      } else if (search) {
        if (Array.isArray(search)) {
          for (let j in search) {
            if (j === "0") {
              if (filter.value !== undefined && filter.value !== null) {
                result.push([search[j], "contains", filter.value]);
              }
            } else {
              if (filter.value !== undefined && filter.value !== null) {
                if (!result[0][3]) {
                  result[0].push([]);
                }
                if (filter.value !== undefined && filter.value !== null) {
                  result[0][3].push([search[j], "contains", filter.value]);
                }
              }
            }
          }
        } else {
          if (filter.value !== undefined && filter.value !== null) {
            result.push([search, "contains", filter.value]);
          }
        }
      } else {
        if (filter.value !== undefined && filter.value !== null) {
          if (filter.id === "_fulltext_query") {
            result.push(this.filterValue({ ...filter }, filter.value));
          } else {
            result.push(this.filterValue(columns[filter.id], filter.value));
          }
        }
      }
    }

    if (this.props.ownFilter && this.state.ownFilterOn) {
      result.push(
        this.filterValue(
          columns[this.props.ownFilter],
          this.props.user.data.id,
          "exact"
        )
      );
    }
    return result;
  };

  filterValue = (column, value, type) => {
    if (!column) {
      return [column.id, "contains", null];
    }
    if (type) {
      return [column.id, type, value];
    }
    switch (column.type) {
      case "checkbox":
        if ("nem".indexOf(value.toLowerCase()) >= 0) {
          return [column.id, "exact", false];
        }
        if ("igen".indexOf(value.toLowerCase()) >= 0) {
          return [column.id, "exact", true];
        }

        return [column.id, "contains", null];
      default:
        return [column.id, "contains", value];
    }
  };

  /**
   * Sorrendezési definíció összeállítása a szerveroldali api számára
   */
  prepareSort = sorted => {
    const { columns } = this.state;
    let result = {};
    for (let i in sorted) {
      const sort = sorted[i];
      let search = columns[sort.id].search;
      let colSort = columns[sort.id].sort;
      if (
        !search &&
        columns[sort.id].datasource &&
        columns[sort.id].datasource.search
      ) {
        search = columns[sort.id].datasource.search;
      }

      if (colSort) {
        result[colSort] = sort.desc ? "desc" : "asc";
      } else if (search) {
        search = Array.isArray(search) ? search[0] : search;
        result[search] = sort.desc ? "desc" : "asc";
      } else {
        result[sort.id] = sort.desc ? "desc" : "asc";
      }
    }
    return result;
  };

  toggleViewSwitcher = hideViewSwitcher => {
    this.setState({ hideViewSwitcher });
  };

  /**
   * Adatok elkérése a szervertől
   */
  fetchData = (state, silent) => {
    const { definition, customLoader, afterFetch } = this.props;

    if (definition.url) {
      const { page, pageSize, sorted, filtered, selectedFilterTab } = state;

      //Lekérdezési paraméterek eltárolása a storage-ben
      this.saveParamsToStorage(state);

      //Betőltés jelző bekapcsolása
      if (!silent) {
        this.setState({
          loading: customLoader ? false : true,
          loadingCustom: true
        });
      } else {
        this.setState({
          loadingCustom: true
        });
      }
      let filter = this.prepareFilter(filtered);

      if (definition.condition) {
        if (Array.isArray(definition.condition)) {
          definition.condition.forEach(element => {
            filter.push([
              element.id,
              element.condition || "exact",
              element.value
            ]);
          });
        } else {
          filter.push([
            definition.condition.id,
            definition.condition.condition || "exact",
            definition.condition.value
          ]);
        }
      }

      //Api számára küldendő paraméterek összállítása
      const params = {
        page: page + 1, //A grid oldalszáma nullától kezdődik
        pagesize: pageSize,
        sort: this.prepareSort(sorted),
        filter: filter,
        trash: state.showTrash ? 1 : 0,
        ...(selectedFilterTab ? { section: selectedFilterTab } : {}),
        ...(state.urlParams ? state.urlParams : {})
      };

      //Request küldése a szervernek
      return restHelper
        .index(definition.indexUrl || definition.url, params)
        .then(response => {
          this.setState(
            {
              items: response.data,
              page: page,
              pages: response.last_page,
              pageSize: pageSize,
              sorted: sorted,
              filtered: filtered,
              total: response.total,
              loading: false,
              loadingCustom: false,
              initialized: true,
              fetched: true
            },
            () => {
              afterFetch && afterFetch(this.state);
            }
          );
        })
        .catch(error => {
          //console.log(error.data.error);
          this.setState({
            loading: false,
            loadingCustom: false,
            error: error.data.error,
            initialized: true,
            fetched: true
          });
        });
    }
  };

  /**
   * Adatok mentése az adatbázisba
   */
  onSave = () => {
    const { definition } = this.props;
    const { isRedux } = this.state;
    const item = this.convertValues(this.inlineEditItem, true);
    const create =
      isRedux && this.props.createR ? this.props.createR : restHelper.create;
    const update =
      isRedux && this.props.updateR ? this.props.updateR : restHelper.update;
    const isNew = item.id ? false : true;
    if (isNew) {
      return create(definition.url, item)
        .then(savedItem => {
          this.setState({ error: {}, inlineEditItem: null });
          this.afterSave(savedItem, true);
        })
        .catch(error => {
          console.log("hiba", this.inlineEditItem, this.state.inlineEditItem);
          this.setState({
            error: error,
            inlineEditItem: Object.assign({}, this.inlineEditItem)
          });
        });
    } else {
      return update(definition.url, item.id, item)
        .then(savedItem => {
          this.setState({ error: {}, inlineEditItem: null });
          this.afterSave(savedItem);
        })
        .catch(error => {
          this.setState({ error: error });
        });
    }
  };

  /**
   * Form mező változások lekezelése
   */
  onChange = values => {
    console.log(values);
    let item = Object.assign({}, this.inlineEditItem);
    for (let id in values) {
      const value = values[id];
      item[id] = value
        ? value === ""
          ? null
          : this.state.columns[id]
          ? this.convertValue(this.state.columns[id].type, value, true)
          : value
        : null;
    }
    this.inlineEditItem = item;
  };

  access = item => {
    return typeof this.props.access === "function"
      ? this.props.access(item)
      : this.props.access;
  };

  cellHandler = (row, column) => {
    const {
      classes,
      definition,
      boolYesText,
      boolNoText,
      user,
      primaryKey
    } = this.props;
    const error =
      this.state.error && this.state.error[column.id]
        ? this.state.error[column.id].join(", ")
        : null;
    const { datasource, optionsDict } = column;
    const access = this.access();
    const { attributes } = definition;
    const name =
      column.name ||
      (attributes && attributes[column.id] ? attributes[column.id] : column.id);

    const isNew =
      this.state.inlineEditItem && this.state.inlinethis.getId() ? false : true;

    const valueField =
      column.id.substr(column.id.length - 3) === "_id"
        ? column.id.substr(0, column.id.length - 3)
        : column.id;
    //Szerkesztés globális letiltása
    //Ha csak olvasási jog van a formra
    if (access === "r") {
      column.access = "r";
    }

    //Ha nincs szerkesztési jog és nem újfelvitel van
    if (access.indexOf("w") < 0 && !isNew) {
      column.access = "r";
    }

    //Ha nincs felviteli jog és újfelvitel van
    if (access.indexOf("a") < 0 && isNew) {
      column.access = "r";
    }

    if (
      this.state.inlineEditItem &&
      this.state.inlinethis.getId() === row.original.id
    ) {
      const item = row.original;

      const fieldValue =
        item[column.id] === null
          ? ""
          : this.convertValue(column.type, item[column.id]);

      switch (column.type) {
        case "combobox":
          return (
            <FormComboboxField
              id={column.id}
              name={name}
              options={
                typeof column.options === "function"
                  ? column.options(item)
                  : column.open
              }
              value={fieldValue}
              error={error}
              onChange={this.onChange}
              onSave={this.onSave}
              onCancel={this.onCancel}
              classes={classes}
              required={column.required}
            />
          );
        case "select":
          return (
            <GridSelectField
              id={column.id}
              name={name}
              options={
                typeof column.options === "function"
                  ? column.options(item)
                  : column.open
              }
              datasource={column.datasource}
              value={fieldValue}
              column={column}
              currentValue={
                column.datasource && column.datasource.objectField
                  ? typeof column.datasource.objectField === "string"
                    ? item[column.datasource.objectField]
                    : column.datasource.objectField
                  : null
              }
              error={error}
              onChange={this.onChange}
              onSave={this.onSave}
              onCancel={this.onCancel}
              classes={classes}
              access={column.access}
              required={column.required}
            />
          );
        case "checkbox":
          return (
            <GridCheckboxField
              id={column.id}
              name={name}
              //options={column.options}
              //datasource={column.datasource}
              value={fieldValue}
              error={error}
              onChange={this.onChange}
              onSave={this.onSave}
              onCancel={this.onCancel}
              classes={classes}
              access={column.access}
              required={column.required}
            />
          );
        case "unixDate":
          return (
            <GridDateTimePickerField
              id={column.id}
              name={name}
              //options={column.options}
              //datasource={column.datasource}
              value={fieldValue}
              error={error}
              onChange={this.onChange}
              onSave={this.onSave}
              onCancel={this.onCancel}
              classes={classes}
              type={column.type}
              access={column.access}
              required={column.required}
            />
          );
        case "password":
        case "text":
        default:
          return (
            <GridTextField
              id={column.id}
              name={name}
              value={fieldValue}
              error={error}
              onChange={this.onChange}
              onSave={this.onSave}
              onCancel={this.onCancel}
              classes={classes}
              format={column.format}
              type={column.type}
              placeholder={column.placeholder}
              access={column.access}
              required={column.required}
            />
          );
      }
    }

    let cell = null;

    switch (column.type) {
      case "unixDate":
        cell = dateHelper.unixToDate(row.value);
        break;
      case "unixTime":
        cell = dateHelper.unixToTime(row.value);
        break;
      case "unixDateTime":
        cell = dateHelper.unixToDateTime(row.value);
        break;
      case "unixRelative":
        cell = dateHelper.unixToRelative(row.value);
        break;
      case "currency":
        cell = formatHelper.currency(
          row.value,
          "",
          column.currencyType &&
            (typeof column.currencyType === "function"
              ? column.currencyType(row)
              : column.currencyType)
        );
        break;
      case "fileSize":
        cell = formatHelper.fileSize(row.value);
        break;
      case "checkbox":
        cell =
          typeof row.value === "undefined"
            ? ""
            : row.value
            ? boolYesText
            : boolNoText;
        break;
      case "file":
        var isPdf =
          row.original[valueField] &&
          row.original[valueField].type === "application/pdf";
        var isInline = isPdf && !column.pdfNotInline ? true : false;
        cell = (
          <Fragment>
            {row.original[valueField] &&
              ["image/png", "image/jpeg", "image/jpg"].indexOf(
                row.original[valueField].type
              ) >= 0 && (
                <ImageViewer
                  file_id={row.original[valueField].id}
                  name={row.original[valueField].name}
                  avatarStyle={{ marginRight: 12 /*, width: 24, height: 24*/ }}
                />
                /*<a
                    style={{ display: "flex", flex: 1 }}
                    href={urlHelper.downloadLink(row.original[valueField].id)}
                  >
                  {row.original[valueField].name}
                </a>*/
              )}

            {row.original[valueField] &&
              row.original &&
              ["image/png", "image/jpeg", "image/jpg"].indexOf(
                row.original[valueField].type
              ) < 0 && (
                <Tooltip title={row.original[valueField].name}>
                  <Link
                    target={isInline ? "_blank" : undefined}
                    className={classes.cellLink}
                    href={urlHelper.downloadLink(
                      row.original[valueField].id,
                      isInline
                    )}
                    style={{ display: "flex" }}
                  >
                    <div
                      style={{
                        flex: 1,
                        marginTop: -1,
                        marginBottom: -1,
                        marginRight: 8,
                        display: "flex",
                        minWidth: 20,
                        minHeight: 20
                      }}
                    >
                      <div
                        style={{
                          width: 20,
                          height: 20
                        }}
                      >
                        <FileIcon
                          extension={row.original[valueField].ext}
                          {...defaultStyles[row.original[valueField].ext]}
                          size={20}
                        />
                      </div>
                      <div
                        style={{
                          flex: 1,
                          marginLeft: 4,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap"
                        }}
                      >
                        {row.original[valueField].name}
                      </div>
                    </div>
                  </Link>
                </Tooltip>
              )}
          </Fragment>
        );
        break;
      case "select":
        /*if(column.label) {
          if(datasource.objectField.indexOf(".") >=0 )
          if (typeof column.label === "function") {
            cell = datasource.label(row.original[datasource.objectField], true);
          } else {
            cell = row.original[datasource.objectField]
              ? row.original[datasource.objectField][datasource.label]
              : "";
          }
        } else*/ if (
          optionsDict
        ) {
          const od = optionsDict(row.original);
          //console.log("OD", od);;
          cell = column.detailLink ? (
            <RLink
              to={`${window.fullPaths[this.props.definition.url]}/view/${
                row.original[primaryKey]
              }`}
              onClick={evt => {
                evt.stopPropagation();
                evt.preventDefault();
                //this.props.history.push(`/${definition.url}/view/${row.original.id}`);
                this.onEdit(row.original, "view");
              }}
              className={classes.cellLink}
            >
              {od[row.value] ? od[row.value] : ""}
            </RLink>
          ) : od[row.value] ? (
            od[row.value]
          ) : (
            ""
          );
        } else if (datasource) {
          if (typeof datasource.label === "function") {
            //cell = datasource.label(row.original[datasource.objectField], true);
            cell =
              this.props.disableRouting ||
              !user ||
              !user.accessMenu("/" + datasource.controller) ? (
                datasource.label(
                  _.get(row.original, datasource.objectField),
                  true
                )
              ) : (
                <RLink
                  to={
                    column.detailLink
                      ? `${window.fullPaths[this.props.definition.url]}/view/${
                          row.original[primaryKey]
                        }`
                      : `${
                          window.fullPaths[datasource.controller]
                        }/view/${_.get(
                          row.original,
                          `${datasource.objectField}.${datasource.valueField}`
                        )}`
                  }
                  className={classes.cellLink}
                  onClick={evt => {
                    if (column.detailLink) {
                      evt.stopPropagation();
                      evt.preventDefault();
                      //this.props.history.push(`/${definition.url}/view/${row.original.id}`);
                      this.onEdit(row.original, "view");
                    } else {
                      evt.stopPropagation();
                    }
                  }}
                >
                  {datasource.label(
                    _.get(row.original, datasource.objectField),
                    true
                  )}
                </RLink>
              );
          } else {
            /*cell = row.original[datasource.objectField]
              ? row.original[datasource.objectField][datasource.label]
              : "";*/
            cell =
              this.props.disableRouting ||
              !user ||
              !user.accessMenu("/" + datasource.controller) ? (
                _.get(
                  row.original,
                  `${datasource.objectField}.${datasource.label}`,
                  ""
                )
              ) : (
                <RLink
                  to={
                    column.detailLink
                      ? `${window.fullPaths[this.props.definition.url]}/view/${
                          row.original[primaryKey]
                        }`
                      : `${
                          window.fullPaths[datasource.controller]
                        }/view/${_.get(
                          row.original,
                          `${datasource.objectField}.${datasource.valueField}`
                        )}`
                  }
                  className={classes.cellLink}
                  onClick={evt => {
                    if (column.detailLink) {
                      evt.stopPropagation();
                      evt.preventDefault();
                      //this.props.history.push(`/${definition.url}/view/${row.original.id}`);
                      this.onEdit(row.original, "view");
                    } else {
                      evt.stopPropagation();
                    }
                  }}
                >
                  {_.get(
                    row.original,
                    `${datasource.objectField}.${datasource.label}`,
                    ""
                  )}
                </RLink>
              );
            /*cell = _.get(
              row.original,
              `${datasource.objectField}.${datasource.label}`,
              ""
            );*/
          }
        }

        break;
      default:
        cell = column.detailLink ? (
          <RLink
            to={`${window.fullPaths[this.props.definition.url]}/view/${
              row.original[primaryKey]
            }`}
            onClick={evt => {
              evt.stopPropagation();
              evt.preventDefault();
              //this.props.history.push(`/${definition.url}/view/${row.original.id}`);
              this.onEdit(row.original, "view");
            }}
            className={classes.cellLink}
          >
            {column.cell ? column.cell(row) : row.value}
          </RLink>
        ) : column.link ? (
          <RLink
            to={
              typeof column.link === "function"
                ? column.link(row.original)
                : column.link
            }
            className={classes.cellLink}
          >
            {column.linkLabel ? column.linkLabel(row.original) : row.value}
          </RLink>
        ) : (
          row.value
        );
        break;
    }

    if (column.colorSpot) {
      return (
        <div style={{ position: "relative", paddingLeft: 16 }}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: 0,
              marginTop: -5,
              width: 10,
              height: 10,
              borderRadius: 5,
              backgroundColor: column.colorSpot(row)
            }}
          />
          {cell}
        </div>
      );
    } else {
      return cell;
    }
  };

  cellFilterHandler = (filter, onChange, column) => {
    //console.log(column.id, filter);
    const { attributes } = this.props.definition;
    const columnName =
      column.name ||
      (attributes && attributes[column.id] ? attributes[column.id] : column.id);

    //const type = column.options ? "text" : column.type;
    const type = column.type;
    const { classes } = this.props;
    const name = "filter_" + column.id;
    //const filter = f;
    switch (type) {
      case "select":
        var filterValues = {};
        for (let i in this.state.filtered) {
          const f = this.state.filtered[i];
          if (f.value) {
            filterValues[f.id] = f.value;
          }
        }
        var opts =
          column.options && typeof column.options === "function"
            ? column.options(filterValues)
            : column.options;
        return opts ? (
          <select
            type="select"
            value={filterValues[column.id] ? filterValues[column.id] : ""}
            onChange={evt => onChange(evt.target.value)}
            //style={{ width: "100%", padding: "4px 7px" }}
            className={classes.headerFilterSelect}
          >
            <option value="" />
            {opts.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </select>
        ) : (
          <GridFilter
            columnName={
              typeof columnName === "function" ? columnName() : columnName
            }
            name={name}
            onChange={onChange}
            value={filter ? filter.value : null}
            column={column}
            type="in"
          />
        );
      case "unixDate":
      case "unixRelative":
      case "unixDateTime":
        var f = Object.assign({}, filter);
        if (f && f.value && typeof f.value.start === "number") {
          f.value = dateHelper.unixToRange(f.value);
        }

        return (
          <DatePicker
            name={name}
            id={name}
            autoComplete={name}
            InputComponent={InputBase}
            numberOfCalendars={2}
            disableMaterailProps
            onChange={val => {
              const convertedVal = dateHelper.rangeToUnix(val);
              onChange(convertedVal);
            }}
            value={f ? f.value : null}
            style={{
              width: "100%"
            }}
          />
        );
      default:
        return <TextFilter name={name} filter={filter} onChange={onChange} />;
      /*return (
          <input
            name={name}
            id={name}
            autoComplete={name + new Date().getTime()}
            onChange={evt => {
              evt.persist();
              setTimeout(() => {
                onChange(evt.target.value)
              }, 500);

            }}
            //value={filter ? filter.value : ""}
            defaultValue={filter ? filter.value : ""}
            style={{
              width: "100%"
            }}
          />
        );*/
    }
  };

  convertValues = (values, deconvert) => {
    //const { fields, columns } = this.props.definition;
    //const cols = fields ? fields : columns;

    let converted = Object.assign({}, values);
    for (let i in this.state.fields) {
      let col = this.state.fields[i];
      converted[col.id] = this.convertValue(
        col.type,
        converted[col.id],
        deconvert
      );
    }
    return converted;
  };

  /**
   * A Egyedi cella objktum vagy a cellában megejelnő érték konvertálása
   * @param {string} convert Konvertálási típus
   * @param {string} type  cella típus
   */
  convertValue = (type, value, deconvert) => {
    let v = value;
    switch (type) {
      case "unixDate":
        v = deconvert
          ? dateHelper.dateToUnix(value)
          : dateHelper.unixToDate(value);
        break;
      case "unixTime":
        v = deconvert
          ? dateHelper.timeToUnix(value)
          : dateHelper.unixToTime(value);
        break;
      default:
        break;
    }
    return v;
  };

  /**
   * A Egyedi cella objktum vagy a cellában megejelnő érték konvertálása
   * @param {string} convert Konvertálási típus
   * @param {string} type  cella típus
   */
  renderCell = column => row => this.cellHandler(row, column);

  /**
   * A Egyedi cella objktum vagy a cellában megejelnő érték konvertálása
   * @param {string} convert Konvertálási típus
   * @param {string} type  cella típus
   */
  renderFilter = column => ({ filter, onChange }) =>
    this.cellFilterHandler(filter, onChange, column);

  escFunction = event => {
    //console.log(event.keyCode);
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      if (this.state.inlineEditItem) {
        this.setState({ inlineEditItem: null });
      }
    }

    if (event.keyCode === 13) {
      //Do whatever when esc is pressed
      if (this.state.inlineEditItem) {
        this.onSave();
      }
    }
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  handleSettingsOnShow = () => {
    console.log("show");
    this.setState({ openSettings: true });
  };

  handleSettingsOnClose = () => {
    console.log("close");
    this.setState({ openSettings: false });
  };

  handleSettingsOnCancel = () => {
    const { storage } = this.props;
    console.log("cancel");
    storage.removeItem(this.props.definition.id + "_visivlity");
    this.columns = this.prepareColumns();
    this.setState({ columnVisiblity: {} }, () => {
      this.columns = this.prepareColumns();
    });
  };

  handleSettingsOnAccept = () => {
    console.log("accept");
    this.columns = this.prepareColumns();
    this.setState({ openSettings: false });
  };

  handleSettingsOnChange = id => evt => {
    console.log("handleSettingsOnChange", id, evt);
    const { storage } = this.props;
    let columnVisiblity = Object.assign({}, this.state.columnVisiblity);
    columnVisiblity[id] = evt.target.checked;
    storage.setItem(
      this.props.definition.id + "_visivlity",
      JSON.stringify(columnVisiblity)
    );

    this.setState({ columnVisiblity });
  };

  handleOwnFilterChange = () => {
    const { ownFilterOn } = this.state;
    console.log("handleOwnFilterChange", !ownFilterOn);

    this.setState({ ownFilterOn: !ownFilterOn }, () => {
      this.fetchData(this.state);
    });
  };

  onChildEdit = () => {
    this.setState({ hideToolbar: true });
  };

  onChildCancelEdit = () => {
    this.setState({ hideToolbar: false });
  };

  onSelectedFilterTabChange = (evt, selectedFilterTab) => {
    this.setState({ selectedFilterTab }, () => {
      this.saveParamsToStorage(this.state);
      this.fetchData(this.state);
    });
  };

  /**
   * Alapértelmezett értékek a definition.condition alapján.
   */
  getItemValuesFromCondition = () => {
    const { definition } = this.props;
    let condition = {};
    //Alapértelmezett értékek a definition.condition alapján
    if (definition.condition) {
      if (Array.isArray(definition.condition)) {
        definition.condition.forEach(element => {
          if (!element.condition || element.condition === "exact") {
            condition[element.id] = element.value;
          }
        });
      } else {
        if (
          !definition.condition.condition ||
          definition.condition.condition === "exact"
        ) {
          condition[definition.condition.id] = definition.condition.value;
        }
      }
    }
    return condition;
  };

  render() {
    const {
      classes,
      theme,
      FormFooter,
      formFieldsInRow,
      fieldAccessHandler,
      definition,
      toolbarButtons,
      filterable,
      sortable,
      showPaginationTop,
      showPaginationBottom,
      inlineEdit,
      FormComponentProps,
      customFilter,
      viewOnRowClick,
      disableToolbar,
      //viewMode,
      isDetailView,
      isForm,
      toggleDetails,
      showViewSwitchButton,
      enablePdfExport,
      pdfTemplates,
      signateureField,
      noScroll,
      primaryKey,
      filterTabs,
      hideSaveButton
    } = this.props;
    const {
      editItem,
      pages,
      page,
      pageSize,
      sorted,
      filtered,
      resized,
      expanded,
      showTrash,
      error,
      total,
      isRedux,
      openSettings,
      viewMode,
      forceHeaderFilter,
      loadings,
      hideToolbar,
      selectedFilterTab
      //columnVisiblity
    } = this.state;
    const access = this.access(editItem);
    //console.log("filtered", filtered);
    const title = this.title(editItem);
    const currentBreadcrumbs = this.getBreadCrumbs();
    const navToolbarItem = (
      <Fragment>
        {editItem ? (
          <Tooltip
            title={
              strings.menu[this.props.definition.id]
                ? `Visszalépés ide: ${strings.menu[this.props.definition.id].title}`
                : "Visszalépés " + this.props.definition.id
            }
            disableFocusListener={true}
          >
            <IconButton onClick={this.onCancel} className={classes.toolBarBack}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}
        <Typography className={classes.title} variant="body1">
          {title}
        </Typography>
      </Fragment>
    );

    let FormComponent = null;
    if (
      (access.indexOf("w") < 0 || viewMode) &&
      !isForm &&
      (!editItem || this.getId() || viewMode)

      /*this.props.ViewComponent &&
      editItem &&
      (!isForm || this.getId())*/
    ) {
      FormComponent = this.props.ViewComponent || DataView;
    } else if (this.props.FormComponent) {
      FormComponent = this.props.FormComponent;
    } else {
      FormComponent = DataForm;
    }

    let items = this.state.items.slice();
    if (inlineEdit && !this.state.showTrash && access.indexOf("a") >= 0) {
      items.push(
        this.state.inlineEditItem && !this.state.inlinethis.getId()
          ? this.state.inlineEditItem
          : {}
      );
    }
    /*const TbodyComponent = props => {
        for (let i = 0; i < props.children[0].length; i++) {
          props.children[0][i] = React.cloneElement(props.children[0][i], { minWidth: props.style.minWidth })
        }

        return <div className="rt-tbody">{props.children}</div>
      }
*/
    const TrGroupComponent = props => {
      return (
        <div
          className="rt-tr-group-own"
          role="rowgroup"
          /* onClick={evt => this.onEdit({
            active: true,
            //...condition
          })}*/
          //style={{ minWidth: props.minWidth }}
        >
          {props.children}
        </div>
      );
    };
    /*const TrProps = (state, rowInfo) => {
      //console.log(rowInfo);
      if (viewOnRowClick && rowInfo && rowInfo.original) {
        //console.log(rowInfo.original)
        return {
          //item: rowInfo.row._original,
          onClick: () =>
            this.onEdit({
              ...rowInfo.original
            }),
          style: {
            cursor: "pointer"
          }
        };
      } else {
        return {};
      }
    };*/
    //színezés előkészület
    const TrProps = (state, rowInfo) => {
      //console.log(rowInfo);
      if (rowInfo && rowInfo.original) {
        //console.log(rowInfo.original)
        const rowStyle = this.props.rowStyle
          ? this.props.rowStyle(rowInfo.row._original, rowInfo.index % 2)
          : {};
        if (viewOnRowClick) {
          return {
            //item: rowInfo.row._original,
            onClick: evt =>
              this.onEdit({
                ...rowInfo.original
              }),
            style: {
              ...rowStyle,
              cursor: "pointer"
            }
          };
        } else {
          return {
            style: rowStyle
          };
        }
      } else {
        return {};
      }
    };

    const InlineEditTrProps = (state, rowInfo) => {
      if (rowInfo && rowInfo.row) {
        const selected =
          this.state.inlineEditItem &&
          rowInfo.row._original.id === this.state.inlinethis.getId();
        const selectedStyle = {
          backgroundColor: "white", //this.props.theme.palette.primary.light,
          //color: this.props.theme.palette.primary.contrastText
          //borderTop: "solid 1px black",
          //borderBottom: "solid 1px black"
          boxShadow: "0 0 0 1px " + theme.palette.primary.dark
        };
        const rowStyle = this.props.rowStyle
          ? this.props.rowStyle(rowInfo.row._original)
          : {};
        return {
          onDoubleClick: () => {
            if (
              !this.state.inlineEditItem ||
              this.state.inlinethis.getId() !== rowInfo.row._original.id
            ) {
              this.inlineEditItem = this.convertValues(rowInfo.row._original);

              if (definition.condition) {
                if (Array.isArray(definition.condition)) {
                  definition.condition.forEach(element => {
                    if (!element.condition || element.condition === "exact") {
                      this.inlineEditItem[element.id] = element.value;
                    }
                  });
                } else {
                  if (
                    !definition.condition.condition ||
                    definition.condition.condition === "exact"
                  ) {
                    this.inlineEditItem[definition.condition.id] =
                      definition.condition.value;
                  }
                }
              }
              this.setState({ inlineEditItem: this.inlineEditItem });
            }
          },
          style: {
            ...(selected ? selectedStyle : {}),
            ...rowStyle
          }
        };
      } else {
        return {};
      }
    };

    const TdProps = (state, rowInfo, column) => {
      if (rowInfo && rowInfo.row) {
        const selected =
          this.state.inlineEditItem &&
          rowInfo.row._original.id === this.state.inlinethis.getId();
        let selectedStyle = {
          overflow: "visible",
          padding: 0,
          marginTop: column.id === "actions" ? 7 : 0,
          paddingLeft: column.id === "actions" ? 5 : 0,
          //marginLeft: -3,
          //marginTop: -1,
          //marginBottom: -1,

          transition: "none"
        };

        //selectedStyle.padding = 0;
        //selectedStyle.transition = "none";
        const error =
          this.state.error && this.state.error[column.id]
            ? this.state.error[column.id].join(", ")
            : null;
        return {
          //onClick: (e, handleOriginal) => {

          // IMPORTANT! React-Table uses onClick internally to trigger
          // events like expanding SubComponents and pivots.
          // By default a custom 'onClick' handler will override this functionality.
          // If you want to fire the original onClick handler, call the
          // 'handleOriginal' function.
          //if (handleOriginal) {
          //handleOriginal();
          //}
          //}
          style: {
            ...(selected ? selectedStyle : { transition: "none" }),
            ...(error && selected ? { borderBottom: "solid 2px red" } : {})
          },
          title: error
        };
      } else {
        return {};
      }
    };

    const inlineEditProps =
      inlineEdit &&
      !this.state.showTrash &&
      (access.indexOf("a") >= 0 || access.indexOf("w") >= 0)
        ? {
            getTrProps: InlineEditTrProps,
            getTdProps: TdProps
          }
        : { getTrProps: TrProps };

    let buttons = [];

    //Alapértelmezett értékek a definition.condition alapján
    let condition = this.getItemValuesFromCondition();

    /*
    //alapértelmezett értékek megadása
    for (let i in definition.columns) {
      let c = definition.columns[i];
      if (c.defaultValue) {
        condition[c.id] = c.defaultValue;
      }
    }*/

    //Egyedi toolbar gombok
    if (toolbarButtons) {
      for (let i in toolbarButtons) {
        let o = Object.assign({}, toolbarButtons[i]);
        if (o.id && loadings[o.id] && !this.state[`modal${o.id}`]) {
          o.tooltip = (
            <span
              style={{ color: "yellow" }}
            >{`Feldolgozás alatt... : ${o.tooltip}`}</span>
          );
          o.Icon = props => (
            <CircularProgress size={18} style={{ padding: 3 }} {...props} />
          );
          o.onClick = () => {};
        }
        if (o.modalComponent) {
          o.onClick = () => {
            this.setState({
              [`modal${o.id}`]: true,
              [`modal_inactive${o.id}`]: false
            });
            //console.log(`Click: ${o.id}`);
          };
        }
        if (o.onlyForm && editItem) {
          buttons.push(o);
        } else if (o.onlyGrid && !editItem) {
          buttons.push(o);
        } else if (!o.onlyGrid && !o.onlyForm) {
          buttons.push(o);
        }
      }
    }
    let bottomButtons = [];
    if (customButtons[`${definition.url}_bottom`]) {
      const bb = customButtons[`${definition.url}_bottom`];
      for (let i in bb) {
        let b = Object.assign({}, bb[i]);
        bottomButtons.push(b);
      }
    }

    if (customButtons[definition.url]) {
      const cb = customButtons[definition.url];
      for (let i in cb) {
        let o = Object.assign({}, cb[i]);
        if (o.id && loadings[o.id] && !this.state[`modal${o.id}`]) {
          o.tooltip = (
            <span
              style={{ color: "yellow" }}
            >{`Feldolgozás alatt... : ${o.tooltip}`}</span>
          );
          o.Icon = props => (
            <CircularProgress size={18} style={{ padding: 3 }} {...props} />
          );
          o.onClick = () => {};
        }
        if (o.modalComponent) {
          o.onClick = () => {
            this.setState({
              [`modal${o.id}`]: true,
              [`modal_inactive${o.id}`]: false
            });
            //console.log(`modal${modalIndex}`);
            //console.log(`Click: ${o.id}`);
          };
        }
        if (o.onlyForm && editItem) {
          buttons.push(o);
        } else if (o.onlyGrid && !editItem) {
          buttons.push(o);
        } else if (!o.onlyGrid && !o.onlyForm) {
          buttons.push(o);
        }
      }
    }
    const buttonColor = settings.actionColor;
    if (editItem && this.getId() && enablePdfExport) {
      buttons.push({
        id: "pdf-export",
        tooltip: "Dokumentum generáló",
        modalComponent: PDFViewer,
        maxWidth: "lg",
        disableOk: true,
        noText: "Bezár",
        modalComponentProps: {
          signateureField: signateureField,
          item: editItem,
          onSave: signature => {
            console.log(signature);
            this.inlineEditItem = Object.assign(
              {},
              { id: this.getId(), signature }
            );
            return this.onSave();
            /*.then(response => {
              callBack && callBack(response);
            });*/
          }
        },
        onClick: () => {
          //this.setState({viewMode: !this.state.viewMode})
          this.setState({ ["modalpdf-export"]: true });
          //this.exportPdf("base");
        },
        Icon: PdfIcon,
        color: buttonColor
      });
    }
    if (!editItem && !this.props.disableExcelExport) {
      buttons.push({
        id: "excel-export",
        tooltip: "Excel export",
        /*onClick: () => {
          this.exportExcel();
        },*/
        Icon: XlsxIcon,
        component: Link,
        href: this.excelLink(),
        color: buttonColor
      });
    }
    if (!isDetailView && showViewSwitchButton) {
      buttons.push({
        tooltip: viewMode ? "Tabos nézetre váltás" : "Oldal nézetre váltás",
        onClick: () => {
          console.log("Click");
          this.setState({ viewMode: !this.state.viewMode });
        },
        Icon: viewMode ? TabViewIcon : PageViewIcon,
        color: buttonColor
      });
    }
    //Settings
    if (!editItem) {
      //console.log("filtered", this.state.filtered);
      if (this.state.filtered.length > 0) {
        buttons.push({
          tooltip: "Szűrő törlése",
          onClick: () => {
            this.setState({ filtered: [] }, () => {
              this.saveParamsToStorage(this.state);
              this.fetchData(this.state);
            });
          },
          Icon: ClearFilterIcon,
          color: buttonColor
        });
      }
      buttons.push({
        tooltip: "Oszlop láthatóság",
        onClick: this.handleSettingsOnShow,
        //this.fetchData(this.state);
        Icon: SettingsIcon,
        color: buttonColor
      });
    }

    //Lista frissítése ikon
    if (!editItem) {
      buttons.push({
        tooltip: "Lista frissíése",
        onClick: () => {
          if (!this.state.loadingCustom) {
            this.fetchData(this.state);
          }
        },
        Icon: this.state.loadingCustom
          ? () => <CircularProgress size={14.5} style={{ padding: 2.5 }} />
          : RefreshIcon,
        color: buttonColor
      });
    }

    if (editItem && editItem.id && this.props.canRefreshView) {
      buttons.push({
        tooltip: "Nézet frissíése",
        onClick: () => {
          if (!this.state.loadingCustom) {
            this.refreshEditItem();
          }
        },
        Icon: this.state.loadingCustom
          ? () => <CircularProgress size={14.5} style={{ padding: 2.5 }} />
          : RefreshIcon,
        color: buttonColor
      });
    }

    if (
      access.indexOf("w") >= 0 &&
      !showTrash &&
      editItem &&
      viewMode &&
      !this.props.disableViewChange &&
      !this.state.hideViewSwitcher
    ) {
      buttons.push({
        tooltip: "Szerkesztés",
        onClick: () => {
          if (isDetailView || this.props.disableRouting) {
            this.setState({ viewMode: false });
          } else {
            this.props.history.push(
              `${this.props.match.url}/edit/${this.getId()}`
            );
          }
        },
        Icon: EditIcon,
        color: buttonColor
      });
    }
    if (
      access.indexOf("w") >= 0 &&
      !showTrash &&
      editItem &&
      this.getId() &&
      !viewMode &&
      !this.props.disableViewChange &&
      !this.state.hideViewSwitcher
    ) {
      buttons.push({
        tooltip: "Nézet",
        onClick: () => {
          if (isDetailView || this.props.disableRouting) {
            this.setState({ viewMode: true });
          } else {
            this.props.history.push(
              `${this.props.match.url}/view/${this.getId()}`
            );
          }
        },
        Icon: ViewIcon,
        color: buttonColor
      });
    }

    //Létrehozási jog esetén gomb hozzáadása
    if (
      access.indexOf("a") >= 0 &&
      !showTrash &&
      (!editItem || (editItem && this.getId()))
    ) {
      buttons.push({
        tooltip: this.title({}),
        onClick: () => {
          this.onEdit({
            active: true,
            ...condition
          });
        },
        Icon: CreateNewIcon,
        color: buttonColor
      });
    }

    if (!editItem) {
      if (showTrash) {
        buttons.push({
          tooltip: "Vissza a listához",
          onClick: () => {
            let st = Object.assign({}, this.state);
            st.showTrash = false;
            this.fetchData(st, true).then(() => {
              this.columns = this.prepareColumns(false);
              this.setState({ showTrash: false });
            });
          },
          Icon: ListIcon,
          color: buttonColor
        });
      } else {
        if (access.indexOf("x") >= 0) {
          buttons.push({
            tooltip: "Lomtár megtekintése",
            onClick: () => {
              let st = Object.assign({}, this.state);
              st.showTrash = true;

              this.fetchData(st, true).then(() => {
                this.columns = this.prepareColumns(true);
                this.setState({ showTrash: true });
              });
            },
            Icon: TrashIcon,
            color: buttonColor
          });
        }
      }
    }
    //console.log("grid", this.props);
    if (!this.state.initialized) {
      return (
        <div
          style={{
            height: 200,
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress color="primary" />
        </div>
      );
    }

    let allButtons = buttons.concat(bottomButtons || []);

    return (
      <div className={classes.gridContainer}>
        {/*!editItem && (
          <Tabs value={0}>
            <Tab label="Nyitottak" />
            <Tab label="Leazártak" />
            <Tab label="Inaktívak" />
          </Tabs>
        )*/}
        {!editItem && filterTabs && (
          <Tabs
            style={{ marginLeft: -16, marginTop: -16 }}
            classes={{
              indicator: classes.topIndicator
            }}
            variant="scrollable"
            value={selectedFilterTab}
            onChange={this.onSelectedFilterTabChange}
          >
            {filterTabs.map((filterTab, filterTabIndex) => {
              return (
                <Tab
                  key={filterTabIndex}
                  value={filterTab}
                  label={
                    filterTab === "all"
                      ? strings.filterTabs.all
                      : strings.filterTabs[this.props.definition.url][filterTab]
                  }
                />
              );
            })}
          </Tabs>
        )}
        {!disableToolbar && !hideToolbar && (
          <div
            className={classes.toolBarContainer}
            style={
              isDetailView && viewMode && !editItem ? { paddingLeft: 0 } : {}
            }
          >
            <Toolbar disableGutters variant="dense" className={classes.toolbar}>
              {!isDetailView && customFilter && !editItem && (
                <GridCustomFilter
                  {...customFilter}
                  onFilteredChange={this.onFilteredChange}
                  columns={definition.columns}
                  filter={filtered}
                  attributes={this.props.definition.attributes}
                  toggleHeaderFilter={() => {
                    this.setState({ forceHeaderFilter: !forceHeaderFilter });
                  }}
                  forceHeaderFilter={forceHeaderFilter}
                />
              )}

              {/*editItem ? parentNavToolbarItem : null*/}
              {editItem ? (
                <Tooltip
                  title={
                    strings.menu[this.props.definition.id]
                      ? `Visszalépés ide: ${strings.menu[this.props.definition.id].title}`
                      : "Visszalépés"
                  }
                  disableFocusListener={true}
                >
                  <IconButton
                    onClick={this.onCancel}
                    className={classes.toolBarBack}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
              {/*!editItem && isDetailView && (
                <Breadcrumbs
                  maxItems={4}
                  aria-label="breadcrumb"
                  //style={{ marginLeft: 16 }}
                >
                  <Tooltip
                    title={
                      currentBreadcrumbs[currentBreadcrumbs.length - 1].title
                        ? `Visszalépés ide: ${currentBreadcrumbs[currentBreadcrumbs.length - 1].title}`
                        : "Visszalépés"
                    }
                    disableFocusListener={true}
                  >
                    <Typography variant="body1">
                      {currentBreadcrumbs[currentBreadcrumbs.length - 1].title}
                    </Typography>
                  </Tooltip>
                </Breadcrumbs>
                  )*/}
              {editItem && (
                <Breadcrumbs
                  maxItems={4}
                  aria-label="breadcrumb"
                  //style={{ marginLeft: 16 }}
                >
                  {currentBreadcrumbs.map((bc, bcIndex) => {
                    return bc.onClick ? (
                      <Tooltip
                        key={bcIndex}
                        title={
                          bc.title
                            ? `Visszalépés ide: ${bc.title}`
                            : "Visszalépés"
                        }
                        disableFocusListener={true}
                      >
                        <Link
                          onClick={bc.onClick}
                          style={{ cursor: "pointer" }}
                        >
                          {bc.title}
                        </Link>
                      </Tooltip>
                    ) : (
                      <Typography key={bcIndex} variant="body1">
                        {bc.title}
                      </Typography>
                    );
                  })}
                </Breadcrumbs>
              )}
              {/*navToolbarItem*/}
              <div className={classes.toolbarMid}></div>
              <AppToolbar
                className={classes.toolBarRight}
                handleOwnFilterChange={
                  this.props.ownFilter ? this.handleOwnFilterChange : undefined
                }
                ownFilterOn={this.state.ownFilterOn}
                buttons={buttons.filter(button => {
                  if (
                    button.access &&
                    !this.props.user.accessFunction(button.access)
                  ) {
                    return false;
                  }
                  if (button.visible && typeof button.visible === "function") {
                    return button.visible(
                      this.state.editItem /*, { grid, form: this }*/
                    );
                  }
                  return true;
                })}
                buttonProps={{ data: editItem, grid: this }}
              />
            </Toolbar>
            {allButtons &&
              allButtons
                .filter(button => {
                  if (!button.modalComponent) {
                    return false;
                  }
                  if (
                    button.access &&
                    !this.props.user.accessFunction(button.access)
                  ) {
                    return false;
                  }
                  return true;
                })
                .map(button => {
                  const buttonIndex = button.id;
                  const ModalComponent = button.modalComponent;
                  //console.log(`Render: ${button.tooltip}, ${buttonIndex}`);
                  let evt = {
                    onAccept: null,
                    close: () => {
                      this.setState({ [`modal${buttonIndex}`]: false });
                    }
                  };
                  const setOnAccept = onAccept => (evt.onAccept = onAccept);
                  return (
                    <Modal
                      key={buttonIndex}
                      title={button.modalTitle || button.tooltip}
                      yesStartIcon={button.yesStartIcon}
                      Icon={button.Icon}
                      maxWidth={button.maxWidth}
                      fullWidth={button.fullWidth}
                      top={button.top}
                      yesText={button.yesText}
                      overlayLoading={button.overlayLoading}
                      disableOk={button.disableOk}
                      noText={button.noText}
                      disableCancel={button.disableCancel}
                      keepMounted={false}
                      loading={loadings[buttonIndex]}
                      open={this.state[`modal${buttonIndex}`] || false}
                      inactiveOk={
                        this.state[`modal_inactive${buttonIndex}`] || false
                      }
                      onAccept={() => {
                        if (evt.onAccept) {
                          evt.onAccept();
                        } else {
                          console.error("evt.onAccept is undefined", evt);
                        }
                      }}
                      onClose={() =>
                        this.setState({
                          [`modal${buttonIndex}`]: false
                          //[`modal_inactive${buttonIndex}`]: false
                        })
                      }
                      onCancel={() =>
                        this.setState({
                          [`modal${buttonIndex}`]: false
                          //[`modal_inactive${buttonIndex}`]: false
                        })
                      }
                    >
                      <ModalComponent
                        grid={this}
                        evt={evt}
                        pdfTemplates={pdfTemplates}
                        loading={loadings[buttonIndex]}
                        {...button.modalComponentProps}
                        setOnAccept={setOnAccept}
                      />
                    </Modal>
                  );
                })}
          </div>
        )}

        {editItem ? (
          <FormComponent
            grid={this}
            bottomButtons={bottomButtons}
            viewMode={viewMode}
            definition={this.props.definition}
            user={this.props.user}
            toggleViewSwitcher={this.toggleViewSwitcher}
            item={editItem}
            title={title}
            onCancel={this.onCancel}
            afterSave={this.afterSave}
            Footer={FormFooter}
            fieldsInRow={formFieldsInRow}
            fieldAccessHandler={fieldAccessHandler}
            access={access}
            isRedux={isRedux}
            createR={this.props.createR}
            updateR={this.props.updateR}
            indexR={this.props.indexR}
            refreshItem={this.refreshEditItem}
            isDetailView={isDetailView}
            parentIsDetailView={isDetailView}
            //toggleDetails={toggleDetails}
            hideToolbar={hideToolbar}
            parentNavToolbarItem={navToolbarItem}
            breadcrumbs={currentBreadcrumbs}
            primaryKey={primaryKey}
            onChildEdit={this.onChildEdit}
            onChildCancelEdit={this.onChildCancelEdit}
            hideSaveButton={hideSaveButton}
            {...FormComponentProps}
          />
        ) : (
          <ReactTable
            //PaginationComponent={DataGridPagination}
            ref={this.table}
            data={items}
            manual
            filterable={filterable && (!customFilter || forceHeaderFilter)}
            sortable={sortable}
            {...inlineEditProps}
            totalRows={total}
            //TbodyComponent={TbodyComponent}
            TrGroupComponent={TrGroupComponent}
            getTrProps={TrProps /*this.getTrProps*/} //Feltételes sor megjelenítés kezelése. Pl.:színezés
            //columns={this.prepareColumns()}
            columns={this.columns}
            /* Kliens oldali szűrés egyedi lekezelése, nincs használatban */
            //defaultFilterMethod={this.filterCaseInsensitive}
            defaultPageSize={
              this.props.fullHeight
                ? this.props.defaultPageSizeFullHeight
                : this.props.defaultPageSize
            }
            //loading={true}
            loading={this.state.loading}
            showPaginationTop={showPaginationTop}
            showPaginationBottom={showPaginationBottom}
            minRows={0}
            PaginationComponent={Pagination}
            //className={`-striped -highlight${
            className={`${
              noScroll || (viewMode && isDetailView) ? " -view" : ""
            }`}
            style={{
              height: items.length === 0 ? 240 : this.getHeight() // This will force the table body to overflow and scroll, since there is not enough room
            }}
            /*
              Az onFetchData használatával a Controlled props és a Callbacks kihagyható lenne,
              de a form megjelenítésekor elveszti a stateket,
              ezért külön le kellett kezelni minden változást és egyenként meghívni az fetchData függvényt
            */
            //onFetchData={this.fetchData}
            // Controlled props
            pages={pages}
            sorted={sorted}
            page={page}
            pageSize={pageSize}
            //filtered={filtered}
            defaultFiltered={filtered}
            expanded={expanded}
            resized={resized}
            // Callbacks
            onSortedChange={this.onSortedChange}
            onPageChange={this.onPageChange}
            onPageSizeChange={this.onPageSizeChange}
            onFilteredChange={this.onFilteredChange}
            onExpandedChange={this.onExpandedChange}
            onResizedChange={this.onResizedChange}
            // Translations
            previousText={strings.grid.previousText}
            nextText={strings.grid.nextText}
            loadingText={strings.grid.loadingText}
            noDataText={
              error ? (
                <p style={{ color: "red" }}>{error}</p>
              ) : this.state.loading ? (
                ""
              ) : this.state.fetched ? (
                <Toolbar disableGutters>
                  <BlockIcon />
                  <Box marginLeft={1}>{strings.grid.noDataText}</Box>
                </Toolbar>
              ) : (
                ""
              )
            }
            pageText={strings.grid.pageText}
            ofText={strings.grid.ofText}
            rowsText={strings.grid.rowsText}
          />
        )}
        <ModalConfirm onRef={ref => (this.modal = ref)} />
        <Modal
          title="Oszlop láthatóság"
          open={openSettings}
          onClose={this.handleSettingsOnClose}
          onAccept={this.handleSettingsOnAccept}
          onCancel={this.handleSettingsOnCancel}
          Icon={SettingsIcon}
          noText="Alapértelmezett"
          maxWidth="md"
        >
          <Grid container spacing={1}>
            {definition.columns
              .filter(obj => {
                if (obj.system) {
                  return false;
                }

                if (fieldAccessHandler) {
                  obj.access = fieldAccessHandler(obj);
                  return obj.access === "r" || obj.access === "w";
                } else {
                  return true;
                }
              })
              .map((column, index) => {
                return (
                  <Grid
                    key={"settings" + index}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color={settings.actionColor}
                          checked={
                            this.state.columnVisiblity[column.id] === false
                              ? false
                              : this.state.columnVisiblity[column.id] === true
                              ? true
                              : column.visible === false
                              ? false
                              : true
                          }
                          onChange={this.handleSettingsOnChange(column.id)}
                        />
                      }
                      label={
                        <small
                          style={{
                            whiteSpace: "normal",
                            lineHeight: "10px"
                          }}
                        >
                          {this.columnName(column)}
                        </small>
                      }
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Modal>
      </div>
    );
  }
}

DataGridBase.defaultProps = {
  noScroll: true,
  filterable: true,
  sortable: true,
  inlineEdit: false,
  showPaginationTop: false,
  showPaginationBottom: true,
  defaultPageSize: 10,
  defaultPageSizeFullHeight: 25,
  fullHeight: true,
  FormComponentProps: {},
  customLoader: true,
  showRowNumber: false,
  actionColumnFirst: false,
  boolYesText: strings.bool.yes,
  boolNoText: strings.bool.no,
  storage: sessionStorage,
  showViewSwitchButton: false,
  primaryKey: "id"
};

DataGridBase.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  definition: PropTypes.object.isRequired,
  FormComponent: PropTypes.any,
  FormComponentProps: PropTypes.any,
  ViewComponent: PropTypes.any,
  startEditRef: PropTypes.func,
  FormFooter: PropTypes.any,
  fieldAccessHandler: PropTypes.func,
  onEdit: PropTypes.func,
  onCancelEdit: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  afterFetch: PropTypes.func,
  access: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  formFieldsInRow: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  toolbarButtons: PropTypes.array,
  filterable: PropTypes.bool,
  sortable: PropTypes.bool,
  showPaginationTop: PropTypes.bool,
  showPaginationBottom: PropTypes.bool,
  inlineEdit: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  urlParams: PropTypes.object,
  createR: PropTypes.func,
  updateR: PropTypes.func,
  indexR: PropTypes.func,
  defaultSort: PropTypes.array,
  fullHeight: PropTypes.bool,
  customLoader: PropTypes.bool,
  rowStyle: PropTypes.func,
  showRowNumber: PropTypes.bool,
  selectable: PropTypes.bool,
  boolYesText: PropTypes.string,
  boolNoText: PropTypes.string,
  primaryKey: PropTypes.string,
  actionColumnFirst: PropTypes.bool, //Előre teszi az akció oszlopot
  storage: PropTypes.any, //storage
  disableToolbar: PropTypes.bool, //disable main toolbar
  viewMode: PropTypes.bool, //Sorklikken ugrunk a nézetre
  viewOnRowClick: PropTypes.bool, //Sorklikken ugrunk a nézetre
  customFilter: PropTypes.object, //Egyedi szűrő form
  isDetailView: PropTypes.bool, //Ha másik nézeten belül helyezkedik el és nem a fő táblázat,
  isForm: PropTypes.bool, //Ha formként akarjuki megjeleníteni
  showViewSwitchButton: PropTypes.bool, //nézetváltó gomb mutatása
  hideActionColumn: PropTypes.bool, //Action oszlop elrejtése
  hideEditButton: PropTypes.bool, //Action oszlop elrejtése
  enablePdfExport: PropTypes.bool, //Pdf export toolbarbutton megjelenítése
  refreshAfterCreate: PropTypes.bool, //Visszamegy a listára és frissíti azt mentés után
  defaultPageSizeFullHeight: PropTypes.number,
  items: PropTypes.array,
  toggleDetails: PropTypes.any,
  pdfTemplates: PropTypes.any,
  signateureField: PropTypes.any,
  noScroll: PropTypes.bool,
  disableViewChange: PropTypes.bool,
  disableRouting: PropTypes.bool,
  item: PropTypes.object,
  filterTabs: PropTypes.array,
  history: PropTypes.object,
  match: PropTypes.object,
  setStateRef: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  user: PropTypes.object,
  ownFilter: PropTypes.string,
  hideSaveButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
};

export default DataGridBase;
