import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Select from "common/components/DataForm/FormSelectField";
import DatePicker from "common/components/DatePicker";
import dateHelper from "common/utils/dateHelper";
import dataFormStyle from "common/assets/style/dataFormStyle";
import ProjectIcon from "@material-ui/icons/Work";
import VehicleRouteMap from "./VehicleRouteMap";
import restHelper from "common/utils/restHelper";
import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  Avatar,
  Typography,
  Tooltip,
  Toolbar,
  IconButton,
  ListItemSecondaryAction,
  Switch,
  FormControlLabel,
  Link
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import ZoomIcon from "@material-ui/icons/ZoomIn";
import OfflineIcon from "@material-ui/icons/GpsOff";
import OldGpsIcon from "@material-ui/icons/GpsNotFixed";
import OnlineIcon from "@material-ui/icons/GpsFixed";

const stylesVs = theme => ({
  toolbar: {
    minHeight: "unset"
  },
  projectIcon: {
    marginRight: theme.spacing(1)
  },
  typo: {
    color: theme.palette.text.secondary
  }
});
const VehicleListSecondaryBase = ({ classes, vehicle }) => {
  return (
    <Fragment>
      {/*vehicle.project && (
        <Toolbar disableGutters variant="dense" className={classes.toolbar}>
          <ProjectIcon fontSize="small" className={classes.projectIcon} />
          <Typography>{vehicle.project.name}</Typography>
        </Toolbar>
      )*/}
      <Tooltip title={dateHelper.unixToDateTime(vehicle.last_signal)}>
        <Typography component="span" className={classes.typo}>
          {dateHelper.unixToRelative(vehicle.last_position_signal)} -{" "}
          {vehicle.geocode_address}
        </Typography>
      </Tooltip>
    </Fragment>
  );
};
const VehicleListSecondary = withStyles(stylesVs)(VehicleListSecondaryBase);
const stylesV = theme => ({
  toolbar: {
    minHeight: "unset"
  },
  projectIcon: {
    marginRight: theme.spacing(1)
  }
});
const VehicleIconBase = ({ classes, vehicle }) => {
  return (
    <ListItemAvatar>
      <Avatar>{vehicle.ign ? <OnlineIcon /> : <OldGpsIcon />}</Avatar>
    </ListItemAvatar>
  );
};
const VehicleIcon = withStyles(stylesV)(VehicleIconBase);

const styles = theme => ({
  refreshIcon: {
    marginLeft: "auto"
  }
});

class VehicleList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicles: []
    };
  }

  componentDidMount() {
    /*const params = {
      filter: [["last_signal", "nte", null], ["tracker_imei", "nte", null]]
    };
    restHelper.index("vehicles", params).then(response => {
      this.setState({ vehicles: response.data });
    });*/
  }

  onClick = vehicle_id => () => {
    this.props.onChange(vehicle_id);
  };

  render() {
    const {
      classes,
      theme,
      vehicles,
      vehicle,
      vehicle_id,
      onRefresh,
      onZoom
    } = this.props;
    //const { vehicles } = this.state;

    return (
      <List>
        <Toolbar disableGutters variant="dense">
          <ListSubheader>Járművek</ListSubheader>
          <Tooltip title="Nézet pozicionálása" disableFocusListener>
            <IconButton onClick={onZoom} className={classes.refreshIcon}>
              <ZoomIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Nézet frissítése" disableFocusListener>
            <IconButton onClick={onRefresh}>
              <RefreshIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Toolbar>
        {vehicles.map((vehicle, vehicleIndex) => {
          //const secondary =
          return (
            <ListItem
              key={vehicleIndex}
              button
              onClick={this.onClick(vehicle.id)}
            >
              <VehicleIcon vehicle={vehicle} />
              <ListItemText
                primary={vehicle.platenumber}
                secondary={<VehicleListSecondary vehicle={vehicle} />}
              />
              {/*<ListItemSecondaryAction>
                <FormControlLabel
                  alue="top"
                  control={<Switch color="primary" />}
                  label="Útvonal"
                  labelPlacement="top"
                />
              </ListItemSecondaryAction>*/}
            </ListItem>
          );
        })}
      </List>
    );
  }
}

VehicleList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(VehicleList);
