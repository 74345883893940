import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducers from "reducers";
import * as serviceWorker from "./serviceWorker";
import AppLoader from "common/components/AppLoader";
import ErrorPage404 from "views/ErrorPage404";
import "text-security/dist/text-security.css";
import "assets/style.css";
window.isdev = window.location.hostname === "localhost" ? true : false;
const store = createStore(reducers, applyMiddleware(thunk));
console.log("development env:", window.isdev);

//TODO: kiszedni
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
const hist = createBrowserHistory(/*{basename: baseFolder}*/);

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route path="/404" component={ErrorPage404} />
        <Route path="/" component={AppLoader} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
