import React from "react";
//import ReactDOM from "react-dom";
import PropTypes from "prop-types";
//import ace from 'brace';
//import AceEditor from 'react-ace';
//import 'brace/mode/json';
//import 'brace/theme/github';
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import { FormControl, FormLabel, FormHelperText } from "@material-ui/core";

//https://github.com/vankop/jsoneditor-react/blob/master/src/Editor.jsx

class FormJsonField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date().getTime()
    };
    this.value = props.value;
  }

  onKeyDown = evt => {
    if (evt.key === "Enter") {
      return this.props.onSave && this.props.onSave();
    } else if (evt.key === "Escape") {
      this.props.onCancel && this.props.onCancel();
      return false;
    }
    return false;
  };

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.value) !== JSON.stringify(nextProps.value)) {
      this.setState({ time: new Date().getTime() });
    }
  }

  onChange = newValue => {
    const { onChange, id } = this.props;
    this.value = newValue;
    onChange({ [id]: newValue });
  };

  render() {
    const {
      id,
      name,
      error,
      value,
      type,
      //access,
      dirty,
      classes,
      helperText,
      height,
      required
    } = this.props;

    let ip = {
      component_type: type,
      autoComplete: "off",
      autoCapitalize: "off",
      autoCorrect: "off",
      spellCheck: "false"
    };

    if (type === "password") {
      //ip.type = "password";
      ip.style = {
        fontFamily: "text-security-disc"
      };
    }
    const containerClassName = error
      ? classes.jsonContainerError
      : dirty
      ? classes.jsonContainerDirty
      : classes.jsonContainer;
    /*const yourSchema = {
      $id: "https://example.com/person.schema.json",
      $schema: "http://json-schema.org/draft-07/schema#",
      title: "Person",
      type: "object",
      properties: {
        "Ügyfelek": {
          type: "object",
          description: "The person's first name."
        },
        lastName: {
          type: "string",
          description: "The person's last name."
        },
        age: {
          description: "Age in years which must be equal to or greater than zero.",
          type: "integer",
          minimum: 0
        }
      }
    }*/
    return (
      <FormControl fullWidth>
        <FormLabel
          style={{
            fontSize: "13px",
            position: "absolute",
            top: 12,
            zIndex: 1000,
            color: "white",
            left: 200
          }}
        >
          {required ? `${name} *` : name}
        </FormLabel>
        <div
          className={containerClassName}
          style={{ height: height || 250, position: "relative" }}
        >
          <Editor
            key={this.state.time}
            value={value ? value : null}
            //onChange={newValue => onChange({ [id]: newValue })}
            onChange={this.onChange}
            id={id}
            mode="tree"
            //navigationBar={false}
            allowedModes={["tree", "code"]}
            //schema={yourSchema}
          />
        </div>
        {(error || helperText) && (
          <FormHelperText error={error ? true : false}>
            {error || FormHelperText}
          </FormHelperText>
        )}
      </FormControl>
    );

    /*return (
      <AceEditor
          value={value ? JSON.stringify(value, null, '\t') : ""}
          onChange={newValue => onChange({ [id]: JSON.parse(newValue) })}
          ace={ace}
          name={id}
          theme="github"
          mode="json"
    theme="github"
          //schema={yourSchema}
      />
  );

    return (
      <TextField
        //inputRef={ref => (this.email = ref)}
        id={id}
        label={name}
        helperText={error || helperText || ""}
        error={error ? true : false}
        //defaultValue={defaultValue}
        //InputLabelProps={inputLabelProps}
        className={dirty && !error ? classes.dirtyTextField : ""}
        fullWidth
        disabled={access !== "w" ? true : false}
        //defaultValue={value ? value : ""}
        value={value ? value : ""}
        onChange={evt => onChange({ [id]: evt.target.value })}
        onKeyDown={this.onKeyDown}
        margin="normal"
        //variant="outlined"
        placeholder={placeholder}
        inputProps={ip}
        multiline={multiline}
        rows={rows}
        // eslint-disable-next-line
        InputProps={InputProps}
      />
    );*/
  }
}

FormJsonField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ]),
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  format: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  access: PropTypes.string,
  helperText: PropTypes.string,
  dirty: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number
};

export default FormJsonField;
