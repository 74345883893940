const Socket = {
  socket: null,
  inited: false,
  connected: false,
  que: [],
  host: null,
  pausedEvents: [],
  registeredEvents: {},

  init: function(ip, port, uid) {
    if (Socket.inited) {
      return true;
    }
    if (ip) {
      Socket.host = "ws://" + ip + ":" + port + (uid ? "/" + uid : ""); // SET THIS TO YOUR SERVER
    }
    //console.log(Socket.host);;
    try {
      Socket.socket = new WebSocket(Socket.host);
      //console.log('WebSocket - status '+Socket.socket.readyState)
      Socket.socket.onopen = function() {
        //console.log('Welcome - status '+this.readyState)
        if (this.readyState === 1) {
          Socket.connected = true;
          Socket.onConnected();
        } else {
          Socket.onError("lol");
        }
      };

      Socket.socket.onmessage = function(msg) {
        //console.log('Received: '+msg.data)
        let data = JSON.parse(msg.data);
        //console.log();
        Socket.onMessage(data);
      };

      Socket.socket.onerror = function(/*event*/) {
        //console.log('Error: ', event)
        Socket.connected = false;
        Socket.onError("Nem sikerült csatlakozni az sms szolgáltatáshoz.");
      };

      Socket.socket.onclose = function() {
        //console.log('Disconnected - status '+this.readyState, this)
        if (this.readyState === 3) {
          Socket.connected = false;
          Socket.onClosed();
        }
      };
      Socket.inited = true;
    } catch (ex) {
      //console.log(ex)
    }
  },

  pause: function(event) {
    if (this.pausedEvents.indexOf(event) < 0) {
      console.log("pause", event);
      this.pausedEvents.push(event);
    }
  },

  start: function(event) {
    let idx = this.pausedEvents.indexOf(event);
    if (idx >= 0) {
      this.pausedEvents.splice(idx, 1);
      console.log("start", event);
    }
  },

  onMessage: function(data) {
    if (data.actions) {
      for (let i in data.actions) {
        const action = data.actions[i];
        if (this.pausedEvents.indexOf(action[0]) < 0) {
          console.log(data);
          Socket.store.dispatch({
            type: action[0],
            data: action[1]
          });
          if (action[0] === "tacho-cards_UPDATE_ACTION_SUCCESS") {
            Socket.store.dispatch({
              type: "tacho-ques_UPDATE_BY_ACTION_SUCCESS",
              data: action[1],
              key: "tacho_card"
            });
          }
        }
      }
    }
  },

  onConnected: function() {
    console.log("onConnected");
    Socket.store.dispatch({
      type: "SET_SOCKET_STATE",
      data: { connected: true }
    });
    if (Socket.que.length > 0) {
      Socket.que.forEach(element => {
        Socket.send(element);
      });
      Socket.que = [];
    }
  },

  onClosed: function() {
    Socket.inited = true;
    console.log("onClosed");
    Socket.store.dispatch({
      type: "SET_SOCKET_STATE",
      data: { connected: false }
    });
  },

  onError: function(error) {
    console.log(error);
    Socket.store.dispatch({
      type: "SET_SOCKET_STATE",
      data: { connected: false }
    });
  },

  send: function(data) {
    if (!Socket.connected) {
      Socket.que.push(data);
      return;
    }

    try {
      let message = JSON.stringify(data);
      Socket.socket.send(message);
      console.log("Sent: " + message);
    } catch (ex) {
      Socket.onError(ex);
    }
  },

  quit: function() {
    if (Socket.socket != null) {
      //console.log('Goodbye!')
      Socket.socket.close();
      Socket.socket = null;
    }
  },

  reconnect: function() {
    Socket.quit();
    Socket.init();
    let events = [];
    Object.keys(Socket.registeredEvents).forEach(name => {
      events.push(Socket.registeredEvents[name]);
    });
    if (events.length > 0) {
      Socket.send({ action: "register_events", events: events });
    }
  },

  registerEvents: events => {
    events.forEach(event => {
      Socket.registeredEvents[event.name] = event;
    });
    Socket.send({ action: "register_events", events: events });
  },

  unregisterEvents: events => {
    events.forEach(event => {
      delete Socket.registeredEvents[event.name];
    });
    Socket.send({ action: "unregister_events", events: events });
  }
};

export default Socket;
