import React from "react";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import LabelTemplate from "common/components//LabelTemplate";
import herma_9532_ultra_ellenallo_cimke from "assets/img/herma_9532_ultra_ellenallo_cimke.jpg"; //24 db

const TemplateA4X24 = ({ items, startFrom }) => {
  return (
    <LabelTemplate
      items={items}
      rows={8}
      columns={3}
      pageWidth={841}
      pageHeight={1189}
      pageMarginVertical={50}
      pageMarginHorizontal={30}
      labelSpaceVertical={0}
      labelSpaceHorizontal={8}
      startFrom={startFrom}
      renderLabel={label => (
        <div
          style={{
            pageBreakBefore: "always",
            textAlign: "center"
          }}
        >
          <QRCode
            renderAs="svg"
            level="H"
            size={256}
            value={label ? `T:${label.id}` : ""}
            fgColor={label ? undefined : "white"}
            //value={`https://app.vasutoptika.hu/check-tool/${item.id}`}
            style={{
              //marginLeft: 0,
              //marginRight: 12,
              marginTop: 20,
              marginBottom: 5,
              width: 76,
              height: 76
            }}
          />
          <div
            style={{
              fontSize: 14,
              textAlign: "center",
              color: label ? undefined : "white"
            }}
          >
            {label ? label.tool_number : "üres"}
          </div>
        </div>
      )}
      //pageBackgroundImage={herma_9532_ultra_ellenallo_cimke}
    />
  );
};

TemplateA4X24.propTypes = {
  items: PropTypes.array.isRequired
};

export default TemplateA4X24;
