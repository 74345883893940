import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import dataFormStyle from "common/assets/style/dataFormStyle";
import ChartistGraph from "react-chartist";

function round(num, decimalPlaces = 0) {
  num = Math.round(num + "e" + decimalPlaces);
  return Number(num + "e" + -decimalPlaces);
}

class VehicleSpeedChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getData = () => {
    const { positions } = this.props;
    console.log("positions", positions);
    let labels = [];
    let data = [];
    let distance = 0;
    positions.forEach(position => {
      const num = position.distance / 1000;
      const d = round(num, 2);
      distance += d;
      labels.push(distance.toFixed(2));
      data.push(position.speed);
    });

    return {
      labels,
      series: [
        {
          name: "Sebesség",
          data,
          className: "color_speed"
        }
      ]
    };
  };

  onEventClick = data => event => {
    console.log("data", data);
  };

  render() {
    const { classes, theme, chartData } = this.props;
    const evt = this.onEventClick;
    var listener = {
      /*"*": function(eventName, data) {
        console.log("event", eventName, data);
      }*/
      draw: function(data) {
        //console.log("draw", data);
        if (data.type === "line") {
          console.log(data);
          //data.element.addEventListener("click", evt(data));
        }
      }
      /*click: (a, b, c) => {
        console.log("click", a, b, c);
      },
      mouseclick: (a, b, c) => {
        console.log("mouseclick", a, b, c);
      },
      mousemove: (a, b, c) => {
        console.log("mousemove", a, b, c);
      }*/
    };
    return (
      <div style={{ height: 180 }}>
        <ChartistGraph
          //listener={listener}
          data={this.getData()}
          options={{
            showPoint: false,
            axisY: {
              labelInterpolationFnc: function(value, index) {
                return value + " km/h";
              }
            },
            axisX: {
              labelInterpolationFnc: function(value, index) {
                //return value + " km";
                return index % 5 === 0 ? value + " km" : null;
              }
            }
          }}
          type="Line"
        />
      </div>
    );
  }
}

VehicleSpeedChart.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  positions: PropTypes.array.isRequired
};

export default withStyles(dataFormStyle, { withTheme: true })(
  VehicleSpeedChart
);
