import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import dateHelper from "common/utils/dateHelper";
import ZoomIcon from "@material-ui/icons/ZoomIn";
import DistanceIcon from "@material-ui/icons/SwapHoriz";
import SpeedIcon from "@material-ui/icons/Speed";
import TimeIcon from "@material-ui/icons/Schedule";
import DriveTimeIcon from "@material-ui/icons/Update";
import StopTimeIcon from "@material-ui/icons/Snooze";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Tooltip,
  Toolbar,
  IconButton,
  Divider
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import OldGpsIcon from "@material-ui/icons/GpsNotFixed";
import OnlineIcon from "@material-ui/icons/GpsFixed";

const stylesVs = theme => ({
  toolbar: {
    minHeight: "unset"
  },
  projectIcon: {
    marginRight: theme.spacing(1)
  },
  typo: {
    color: theme.palette.text.secondary
  }
});
const EtapSecondaryBase = ({ classes, etap }) => {
  return (
    <Fragment>
      {/*vehicle.project && (
        <Toolbar disableGutters variant="dense" className={classes.toolbar}>
          <ProjectIcon fontSize="small" className={classes.projectIcon} />
          <Typography>{vehicle.project.name}</Typography>
        </Toolbar>
      )*/}
      <Typography component="div" variant="body1">
        {etap.address_start}
      </Typography>
      <Typography component="div" variant="body1" className={classes.typo}>
        {etap.address_stop}
      </Typography>
      <Typography
        component="div"
        className={classes.typo}
        style={{ display: "flex", alignConten: "center", marginTop: 8 }}
      >
        <TimeIcon fontSize="small" style={{ marginRight: 4 }} />{" "}
        {dateHelper.unixToDateTimeShort(etap.start_time)} -{" "}
        {dateHelper.unixToDateTimeShort(etap.stop_time)}
        {/*({etap.position_count})*/}
      </Typography>
      <Typography
        component="div"
        className={classes.typo}
        style={{ display: "flex", alignConten: "center" }}
      >
        <DistanceIcon fontSize="small" /> {Math.round(etap.distance / 10) / 100}{" "}
        km{" "}
        <SpeedIcon style={{ marginLeft: 8, marginRight: 4 }} fontSize="small" />{" "}
        {Math.round(etap.max_speed * 100) / 100} km/h
      </Typography>
    </Fragment>
  );
};
const EtapSecondary = withStyles(stylesVs)(EtapSecondaryBase);
const stylesV = theme => ({
  toolbar: {
    minHeight: "unset"
  },
  projectIcon: {
    marginRight: theme.spacing(1)
  }
});

const styles = theme => ({
  refreshIcon: {
    marginLeft: "auto"
  }
});

class VehicleEtaps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicles: []
    };
  }

  componentDidMount() {
    /*const params = {
      filter: [["last_signal", "nte", null], ["tracker_imei", "nte", null]]
    };
    restHelper.index("vehicles", params).then(response => {
      this.setState({ vehicles: response.data });
    });*/
  }

  onClick = etapIndex => () => {
    this.props.onChange(etapIndex);
  };

  getEtapSum = () => {
    const sum = {
      distance: 0,
      stop_seconds: 0,
      drive_seconds: 0,
      max_speed: 0,
      platenumber: ""
    };
    this.props.etaps.forEach(element => {
      sum.distance += element.distance;
      sum.stop_seconds += element.stop_seconds;
      sum.drive_seconds += element.drive_seconds;
      sum.max_speed = Math.max(element.max_speed, sum.max_speed);
      sum.platenumber = element.platenumber;
    });

    return sum;
  };

  render() {
    const { classes, etaps, /*etapIndex*/ onRefresh, onZoom } = this.props;
    //const { vehicles } = this.state;
    const etapSum = this.getEtapSum();
    return (
      <List>
        <Toolbar variant="dense" disableGutters style={{ paddingLeft: 16 }}>
          <div>
            <Typography variant="h5">{etapSum.platenumber}</Typography>
            <Typography variant="button" color="textSecondary">
              Menetlevél
            </Typography>
          </div>
          <Tooltip title="Nézet pozicionálása" disableFocusListener>
            <IconButton onClick={onZoom} className={classes.refreshIcon}>
              <ZoomIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Nézet frissítése" disableFocusListener>
            <IconButton onClick={onRefresh}>
              <RefreshIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Toolbar>

        <ListItem>
          <ListItemText disableTypography>
            <Tooltip title="Menetidő">
              <Typography style={{ display: "flex", alignConten: "center" }}>
                <DriveTimeIcon fontSize="small" style={{ marginRight: 8 }} />{" "}
                {dateHelper.unixToTimeSecs(etapSum.drive_seconds)}
              </Typography>
            </Tooltip>
            <Tooltip title="Állásidő">
              <Typography style={{ display: "flex", alignConten: "center" }}>
                <StopTimeIcon fontSize="small" style={{ marginRight: 8 }} />{" "}
                {dateHelper.unixToTimeSecs(etapSum.stop_seconds)}
              </Typography>
            </Tooltip>
          </ListItemText>
          <ListItemText disableTypography>
            <Tooltip title="Távolság">
              <Typography style={{ display: "flex", alignConten: "center" }}>
                <DistanceIcon fontSize="small" style={{ marginRight: 4 }} />{" "}
                {Math.round(etapSum.distance / 10) / 100} km
              </Typography>
            </Tooltip>
            <Tooltip title="Maximális sebesség">
              <Typography style={{ display: "flex", alignConten: "center" }}>
                <SpeedIcon fontSize="small" style={{ marginRight: 4 }} />{" "}
                {Math.round(etapSum.max_speed * 100) / 100} km/h
              </Typography>
            </Tooltip>
          </ListItemText>
        </ListItem>

        <Divider />
        {etaps.map((etap, etapIndex) => {
          //const secondary =
          return (
            <ListItem
              key={etapIndex}
              button
              onClick={this.onClick(etapIndex)}
              selected={etapIndex === this.props.etapIndex}
            >
              <ListItemText disableTypography>
                <EtapSecondary etap={etap} />
              </ListItemText>
              {/*<ListItemSecondaryAction>
                <FormControlLabel
                  alue="top"
                  control={<Switch color="primary" />}
                  label="Útvonal"
                  labelPlacement="top"
                />
              </ListItemSecondaryAction>*/}
            </ListItem>
          );
        })}
      </List>
    );
  }
}

VehicleEtaps.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  etaps: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(VehicleEtaps);
