import { emphasize } from "@material-ui/core/styles/colorManipulator";

const dataPreviewStyle = theme => ({
  submitButton: {
    marginTop: theme.spacing(2)
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  input: {
    display: "flex",
    //padding: 0
    padding: "10px 14px"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 1000,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  formContainer: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3)
    }
  },
  toolBarContainer: {
    display: "flex"
  },
  toolBarLeft: {
    flex: 1,
    marginTop: 10
  },
  toolBarRight: {
    flex: 1
  },
  footerTypo: {
    color: theme.palette.primary.light,
    marginTop: theme.spacing(1)
  },
  notice: {
    padding: theme.spacing(3),
    display: "flex"
    //color: theme.palette.secondary.dark,
  },
  header: {
    "& svg": {
      marginBottom: -6,
      marginRight: 6
      //fill: "#666"
    }
  },
  headerExpanded: {
    //color: theme.palette.primary.main,

    "& svg": {
      fill: theme.palette.primary.contrastText
    }
  },
  rowExpanded: {
    /*color: theme.palette.primary.main,
    borderBottom: "solid 1px " + theme.palette.primary.main,
    fontWeight: "bold"*/
  },
  rowContainerExpanded: {
    color: theme.palette.primary.contrastText
    //fontWeight: "bold"
    //fontWeight: "bold"
  },
  sumContainer: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)

    //fontWeight: "bold"
  },
  rowContainerSum: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(6),
    borderBottom: "solid 3px " + theme.palette.primary.main
    //fontWeight: "bold"
  },
  emptyCell: {
    [theme.breakpoints.down("md")]: {
      display: "none!important"
    }
  },
  sumCell: {
    color: theme.palette.text.primary,
    fontWeight: "bold"
  },
  dateContainer: {
    marginTop: -8,
    marginBottom: -8,
    //flex:  1,
    display: "flex",
    alignItems: "center",
    justifyContent: "left"
    //flexDirection: "row"
  },
  dateRange: {
    //marginTop: -8,
    //marginBottom: -8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    marginLeft: "auto",
    "& div": {
      ...theme.typography.caption,
      display: "flex",
      //justifyContent: "center",
      flexDirection: "column",
      //textAlign: "center",
      verticalAlign: "middle",
      color: "inherit!important",
      fontWeight: "inherit!important"
    }
  },
  rows: {
    //marginTop: -8,
    //marginBottom: -8,
    display: "flex",
    //alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    //paddingLeft: theme.spacing(2),
    "& div": {
      ...theme.typography.caption,
      display: "flex",
      //justifyContent: "center",
      flexDirection: "column",
      //textAlign: "center",
      verticalAlign: "middle",
      color: "inherit!important",
      fontWeight: "inherit!important"
    }
  },
  dateDays: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row"
  },
  cell: {
    display: "flex",
    //justifyContent: "center"
    alignItems: "center",
    width: "100%"
  },
  expandedPanelSummary: {
    "& > div:nth-child(1)": {
      margin: "12px 0!important"
    },
    "& > div:nth-child(2)": {
      color: theme.palette.primary.contrastText
    }
  },
  expansionPanelDetails: {
    //marginTop: -theme.spacing(2),
    //borderTop: "solid 2px " + theme.palette.primary.main
  },
  expansionPanelExpanded: {
    marginTop: "0!important",
    marginBottom: "0!important",
    "& > div:nth-child(1)": {
      backgroundColor: theme.palette.primary.main
    }
    //backgroundColor: theme.palette.grey[100]
    /*borderTop: "solid 1px " + theme.palette.primary.main,
    borderBottom: "solid 1px " + theme.palette.primary.main,
    boxSizing: "border-box"*/
  }
});

export default dataPreviewStyle;
