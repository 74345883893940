import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "@material-ui/core";

const Spacer = ({ theme, w, h, right, left }) => (
  <div
    style={{
      width: w ? theme.spacing(w) : undefined,
      height: h ? theme.spacing(h) : undefined,
      marginLeft: right ? "auto" : undefined,
      marginRight: left ? "auto" : undefined
    }}
  />
);

Spacer.propTypes = {
  theme: PropTypes.object.isRequired,
  w: PropTypes.number,
  h: PropTypes.number,
  right: PropTypes.bool,
  left: PropTypes.bool
};

export default withTheme(Spacer);
