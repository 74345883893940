import React from "react";
import PropTypes from "prop-types";
import DataForm from "common/components/DataForm/DataForm";
import restHelper from "common/utils/restHelper";
import strings from "variables/strings.json";
import { Typography } from "@material-ui/core";
import SidebarMenu from "common/components/SidebarMenu";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { definition, item, error } = this.state;
    const { user, menuItems } = this.props;
    return (
      <div
        style={{
          minHeight: "calc(100vh - 210px)",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Typography
          variant="h4"
          style={{
            fontWeight: 300,
            textAlign: "center",
            paddingRight: 80,
            felx: 1,
            marginTop: 100
          }}
        >
          Hello
        </Typography>
        <Typography
          variant="h5"
          style={{
            fontWeight: 300,
            textAlign: "center",
            paddingLeft: 80,
            felx: 1,
            marginBottom: 120
          }}
        >
          {user.data.name}!
        </Typography>
        <SidebarMenu
          box
          items={menuItems}
          //favourites={this.getFavourites()}
          //selectedMenu={selectedMenu}
          onClick={(item, slug, event) => {
            console.log(item, slug, event);
            //this.props.history.push(slug)
          }}
        />
      </div>
    );
  }
}

Home.propTypes = {
  user: PropTypes.object.isRequired
};

export default Home;
