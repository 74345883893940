import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";

class Zips extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("zips");

    this.state = {
      access: access ? access : "",
      definition: {
        attributes: strings.attributes.zips,
        id: "zips",
        url: "zips",
        columns: [
          { id: "zip" },
          { id: "name" },
          { id: "county" },
          { id: "county_code" }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const { user } = this.props;
    return (
      <div>
        <DataGrid user={user}
          definition={definition}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("zips", column.id);
          }}
          access={this.state.access}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return item.zip;
            } else {
              return "Új irányítószám";
            }
          }}
        />
      </div>
    );
  }
}

Zips.propTypes = {
  user: PropTypes.object.isRequired,
  
};

export default Zips;
