import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles(theme => ({
  card: {
    position: "relative",
    width: `calc(100% - ${theme.spacing(4)}px)`,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  cardHeader: {
    paddingBottom: 0
  },
  cardContent: {}
}));

const SimpleTableCard = ({ title, children, subheader, action }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        action={action}
        title={title}
        subheader={subheader}
      />
      <CardContent className={classes.cardContent}>{children}</CardContent>
    </Card>
  );
};

SimpleTableCard.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  children: PropTypes.any,
  action: PropTypes.any
};

export default SimpleTableCard;
