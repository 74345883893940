import React, { Fragment } from "react";
import PropTypes from "prop-types";
import restHelper from "common/utils/restHelper";
import SaveIcon from "@material-ui/icons/SaveAlt";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import NewWindowIcon from "@material-ui/icons/Launch";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputBase from "@material-ui/core/InputBase";
import Signature from "common/components/Signature";
import urlHelper from "common/utils/urlHelper";
import settings from "variables/settings";
import { withStyles } from "@material-ui/core/styles";
import { Document, Page, pdfjs } from "react-pdf";
import { Link, Button, Toolbar, Select, MenuItem } from "@material-ui/core";
import ModalConfirm from "common/components/Modals/ModalConfirm";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);
/*window.onresize = function() {
  document.body.height = window.innerHeight;
};
window.onresize(); // called to initially set the height.*/
class PDFViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1,
      loading: false,
      types: [],
      template: null,
      saved: {},
      error: null,
      timeKey: `key-${new Date().getTime()}`
    };

    /*props.evt.onAccept = () => {
      //console.log("ACCEPT");
      props.grid.setLoading("pdf-export", true);
      setTimeout(() => {
        props.grid.setLoading("pdf-export", false);
        props.evt.close();
      }, 2000);
    };*/
    this.modal = null;
  }

  componentDidMount() {
    //this.exportPdf("base");
    this.fetchDocumentTypes();
  }

  fetchDocumentTypes = () => {
    const { editItem } = this.props.grid.state;
    const { definition } = this.props.grid.props;
    if (editItem) {
      //this.props.grid.setLoading("pdf-export", true);
      this.setState({ loading: true });
      restHelper
        .index("document-types", {
          filter: [
            ["document_type.object_id", "exact", definition.url],
            ["document_type.can_generate", "exact", 1],
            ["document_type.type", "exact", "pdf"]
          ]
        })
        .then(response => {
          //this.props.grid.setLoading("pdf-export", false);
          this.setState({
            loading: false,
            types: response.data,
            template: response.data.length > 0 ? response.data[0].id : "default"
          });
        });
    }
  };

  exportPdf = template => {
    const { editItem } = this.props.grid.state;
    const { definition, pdfSaveConfirms } = this.props.grid.props;
    if (editItem) {
      //this.props.grid.setLoading("pdf-export", true);

      if (pdfSaveConfirms && pdfSaveConfirms[template]) {
        this.modal
          .open(
            "Megerősítés",
            pdfSaveConfirms[template],
            null, //this.state.file.file_name,
            "Ok",
            "Mégsem"
          )
          .then(() => {
            this.modal.close();
            this.setState({ loading: true });
            restHelper
              .exportPdf(definition.url, editItem.id, template)
              .then(response => {
                let saved = Object.assign({}, this.state.saved);
                saved[template] = true;
                //this.props.grid.setLoading("pdf-export", false);
                this.setState({ loading: false, saved });
                console.log("itt is vagyok");
              })
              .catch(error => {
                console.log(error);
                this.setState({ error: error });
              });
          });
      } else {
        this.setState({ loading: true });
        restHelper
          .exportPdf(definition.url, editItem.id, template)
          .then(response => {
            let saved = Object.assign({}, this.state.saved);
            saved[template] = true;
            //this.props.grid.setLoading("pdf-export", false);
            this.setState({ loading: false, saved });
          })
          .catch(error => {
            console.log(error);
            this.setState({ error: error });
          });
      }
    }
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  onClick = () => {
    const { template } = this.state;
    this.exportPdf(template);
  };

  onSave = signature => {
    const { onSave } = this.props;
    onSave &&
      onSave(signature).then(() => {
        this.setState({ timeKey: `key-${new Date().getTime()}` });
      });
  };

  render() {
    const {
      numPages,
      template,
      loading,
      types,
      saved,
      timeKey,
      error
    } = this.state;
    const { editItem } = this.props.grid.state;
    const { pdfTemplates, signateureField } = this.props;
    const url = inline =>
      urlHelper.viewPdfLink(
        this.props.grid.props.definition.url,
        this.props.grid.state.editItem.id,
        template,
        inline
      );
    const pages = new Array(numPages);
    for (let index = 0; index < pages.length; index++) {
      pages[index] = true;
    }
    const errorMessage =
      error && error["global"] ? error["global"].join(",") : null;

    return (
      <div style={{ minWidth: 597, minHeight: 800 }}>
        <ModalConfirm onRef={ref => (this.modal = ref)} />
        <div>
          {types.length > 1 ? (
            <Select
              input={<BootstrapInput />}
              value={template}
              onChange={evt => {
                this.setState({ template: evt.target.value, pageNumber: 1 });
              }}
            >
              {types &&
                types.map((type, index) => {
                  return (
                    <MenuItem key={index} value={type.id}>
                      {type.name}
                    </MenuItem>
                  );
                })}
            </Select>
          ) : (
            types.length > 0 && <Typography>{types[0].name}</Typography>
          )}
        </div>
        <Toolbar disableGutters variant="dense">
          <Button
            //disabled={loading}
            size="small"
            component={Link}
            href={url(false)}
            target="_blank"
            color={settings.actionColor}
            style={{
              textDecoration: "none",
              marginLeft: pdfTemplates ? 16 : 0
            }}
            startIcon={<DownloadIcon fontSize="small" />}
          >
            Letöltés
          </Button>
          <Button
            //disabled={loading}
            size="small"
            component={Link}
            href={url(true)}
            target="_blank"
            color={settings.actionColor}
            style={{ textDecoration: "none", marginLeft: 16 }}
            startIcon={<NewWindowIcon fontSize="small" />}
          >
            Új ablak
          </Button>
          {template && template !== "default" && (
            <Button
              disabled={loading || saved[template] || error}
              size="small"
              onClick={this.onClick}
              color={settings.actionColor}
              style={{ marginLeft: 16 }}
              startIcon={
                saved[template] || error ? (
                  error ? (
                    <CloseIcon fontSize="small" style={{ color: "red" }} />
                  ) : (
                    <CheckIcon fontSize="small" style={{ color: "green" }} />
                  )
                ) : loading ? (
                  <CircularProgress size={18} />
                ) : (
                  <SaveIcon fontSize="small" />
                )
              }
            >
              {saved[template] ? "Mentve" : "Mentés"}
            </Button>
          )}
          <IconButton
            //disabled={loading}
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => {
              this.setState({ timeKey: `key-${new Date().getTime()}` });
            }}
            target="_blank"
            color={settings.actionColor}
          >
            <RefreshIcon fontSize="small" />
          </IconButton>
        </Toolbar>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        {template && (
          <Fragment>
            <Document
              key={timeKey}
              file={url(true)}
              onLoadSuccess={this.onDocumentLoadSuccess}
            >
              {pages.map((_p, index) => {
                return (
                  <Page
                    key={`${template}-${index}`}
                    pageNumber={index + 1}
                    className={
                      "pdf-modal-page" + (index > 0 ? " margin-top-2" : "")
                    }
                  />
                );
              })}
            </Document>
            {signateureField && (!editItem || !editItem[signateureField]) && (
              <Signature onSave={this.onSave} />
            )}
          </Fragment>
        )}
        {/*numPages && template && (
          <TablePagination
            component="div"
            labelDisplayedRows={({ from, to, count }) =>
              `${from} / ${count !== -1 ? count : `more than ${to}`}`
            }
            rowsPerPage={1}
            rowsPerPageOptions={[]}
            page={parseInt(pageNumber) - 1}
            count={numPages}
            onChangePage={(evt, page) => {
              this.setState({ pageNumber: page + 1 });
            }}
          />
          )*/}
      </div>
    );
  }
}

PDFViewer.propTypes = {
  grid: PropTypes.any,
  signateureField: PropTypes.string,
  pdfTemplates: PropTypes.any,
  onSave: PropTypes.func
};

export default PDFViewer;
