import React from "react";
import PropTypes from "prop-types";
import { TablePagination } from "@material-ui/core";

const defaultButton = props => <button {...props}>{props.children}</button>;

export default class Pagination extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    totalRows: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    pageSizeOptions: PropTypes.array,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string
  };

  render() {
    //console.log("Pager", this.props);
    const {
      pages,
      page,
      PageButtonComponent,
      pageSizeOptions,
      onPageChange,
      onPageSizeChange,
      pageSize,
      totalRows,
      previousText,
      nextText
    } = this.props;

    return (
      <TablePagination
        rowsPerPageOptions={pageSizeOptions}
        component="div"
        count={totalRows}
        rowsPerPage={pageSize}
        labelRowsPerPage="Sor laponként"
        page={page}
        onChangePage={(evt, page) => onPageChange(page)}
        onChangeRowsPerPage={evt => onPageSizeChange(evt.target.value)}
      />
    );
  }
}
