import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import InfoIcon from "@material-ui/icons/Info";
import Tab from "@material-ui/core/Tab";
import dataFormStyle from "common/assets/style/dataFormStyle";
import DataForm from "common/components/DataForm/DataForm";
import DataView from "common/components/DataView/DataView";
import Documents from "views/Documents";
import Events from "common/views/Events";
import Histories from "common/views/Histories";
import strings from "variables/strings.json";
import { Badge } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";

const NotSavedInfo = props => (
  <Toolbar>
    <InfoIcon fontSize="small" style={{ marginRight: 8, color: "#666" }} />
    <i style={{ lineHeight: "20px" }}>
      Ameddig a(z) {props.title} nincs elmentve, kapcsolt adatok nem hozhatók
      létre
    </i>
    .
  </Toolbar>
);

NotSavedInfo.propTypes = {
  title: PropTypes.string
};

/**
 * Formos adat megjelenítés és szerkesztés kezelése
 */
class DataGridForm extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props);
    this.state = {
      value: this.props.isDetailView ? "" : this.hash(props)
    };
  }

  hash = props => {
    return props.location.hash.replace("#", "");
  };

  componentDidMount() {
    if (this.props.toggleViewSwitcher) {
      if (this.state.value === "") {
        this.props.toggleViewSwitcher(false);
      } else {
        this.props.toggleViewSwitcher(true);
      }
    }
  }

  /*componentDidUpdate(prevProps) {
    console.log("didUpdate");
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }*/

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location) {
      if (!this.props.isDetailView) {
        this.setState({ value: this.hash(nextProps) });
      }
    }
  }

  /*shouldComponentUpdate(nextProps, nextState) {
    if (this.state.value !== nextState.value) {
      return true;
    }
    if (this.props.viewMode !== nextProps.viewMode) {
      return true;
    }
    return false;
  }*/

  onRouteChanged = () => {
    if (!this.props.isDetailView) {
      this.setState({ value: this.hash() });
    }
  };

  handleChange = (event, value) => {
    if (this.props.isDetailView) {
      this.setState({ value });
    }
    if (this.props.toggleViewSwitcher) {
      if (value === "") {
        this.props.toggleViewSwitcher(false);
      } else {
        this.props.toggleViewSwitcher(true);
      }
    }
  };

  /**
   * Eltűntetjük a tabot, ha valakinek nincs hozzá joga
   */
  tabIsvisible = controller => {
    const { user } = this.props;
    return user.accessController(controller);
  };

  render() {
    const {
      classes,
      item,
      user,
      onChildEdit,
      onChildCancelEdit,
      hideToolbar,
      breadcrumbs,
      tabs,
      isDetailView
    } = this.props;
    const { value } = this.state;
    const isNew = item && item.id ? false : true;
    const currentTab = (tabs && tabs.items && tabs.items[value]) || null;
    //console.log("renderDatagridForm", value);
    const DocumentsComponent = tabs.documentsComponent || Documents;
    const EventsComponent = tabs.eventsComponent || Events;
    return (
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={12} md={12}>
          {!hideToolbar && tabs && tabs.items && (
            <Tabs
              value={value}
              onChange={this.handleChange}
              variant="scrollable"
            >
              <Tab
                value=""
                component={isDetailView ? undefined : Link}
                to={"#"}
                label={
                  strings.menu[tabs.id]
                    ? strings.menu[tabs.id].singularTitle
                    : tabs.id
                }
              />
              {tabs.documentsFirst && this.tabIsvisible("documents") && (
                <Tab
                  value="documents"
                  component={isDetailView ? undefined : Link}
                  to="#documents"
                  label={strings.menu.documents.title}
                />
              )}
              {Object.keys(tabs.items).map((tabItemKey, tabIndex) => {
                const tabItem = tabs.items[tabItemKey];
                //console.log("tabItemKey", tabItemKey);
                const title =
                  tabItem.title ||
                  (strings.menu[tabItemKey]
                    ? strings.menu[tabItemKey].title
                    : tabItemKey);
                return (
                  (tabItem.allowed ||
                    this.tabIsvisible(tabItem.controller || tabItemKey)) && (
                    <Tab
                      key={tabItemKey}
                      value={tabItemKey}
                      component={isDetailView ? undefined : Link}
                      to={`#${tabItemKey}`}
                      label={
                        tabItem.badgeContent ? (
                          <Badge
                            badgeContent={item[tabItem.badgeContent] || 0}
                            color="error"
                            classes={{
                              badge: classes.tabBadge,
                              invisible: classes.tabBadgeInvisible
                            }}
                          >
                            <span>{title}</span>
                          </Badge>
                        ) : (
                          title
                        )
                      }
                    />
                  )
                );
              })}
              {tabs.documents && this.tabIsvisible("documents") && (
                <Tab
                  value="documents"
                  component={isDetailView ? undefined : Link}
                  to="#documents"
                  label={strings.menu.documents.title}
                />
              )}
              {tabs.events && this.tabIsvisible("events") && (
                <Tab
                  value="events"
                  component={isDetailView ? undefined : Link}
                  to="#events"
                  label={strings.menu.events.title}
                />
              )}
              {tabs.histories && this.tabIsvisible("histories") && (
                <Tab
                  value="histories"
                  component={isDetailView ? undefined : Link}
                  to="#histories"
                  label={strings.menu.histories.title}
                />
              )}
            </Tabs>
          )}

          {value === "" && !this.props.viewMode && <DataForm {...this.props} />}
          {value === "" && this.props.viewMode && <DataView {...this.props} />}

          {isNew && value !== "" && (
            <NotSavedInfo title={strings.menu[tabs.id].singularTitle} />
          )}
          {!isNew && currentTab && (
            <currentTab.component
              key={currentTab.itemKey}
              classes={classes}
              item={item}
              isDetailView
              breadcrumbs={breadcrumbs}
              onChildEdit={onChildEdit}
              onChildCancelEdit={onChildCancelEdit}
              //hideToolbar={hideToolbar}
              user={user}
              itemKey={currentTab.itemKey}
              filterable={currentTab.filterable || false}
              {...currentTab.componentProps}
              viewMode={this.props.readOnly}
            />
          )}
          {(tabs.documents || tabs.documentsFirst) &&
            value === "documents" &&
            this.tabIsvisible("documents") &&
            !isNew && (
            <DocumentsComponent
              classes={classes}
              isDetailView
              breadcrumbs={breadcrumbs}
              onChildEdit={onChildEdit}
              onChildCancelEdit={onChildCancelEdit}
              item={item}
              user={user}
              object_id={tabs.id}
            />
          )}
          {tabs.events &&
            value === "events" &&
            this.tabIsvisible("events") &&
            !isNew && (
            <EventsComponent
              classes={classes}
              isDetailView
              breadcrumbs={breadcrumbs}
              onChildEdit={onChildEdit}
              onChildCancelEdit={onChildCancelEdit}
              item={item}
              user={user}
              object_id={tabs.id}
            />
          )}
          {tabs.histories &&
            this.tabIsvisible("histories") &&
            value === "histories" &&
            !isNew && (
            <Histories
              classes={classes}
              item={item}
              isDetailView
              breadcrumbs={breadcrumbs}
              onChildEdit={onChildEdit}
              onChildCancelEdit={onChildCancelEdit}
              user={user}
              slug={tabs.id}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

DataGridForm.propTypes = {
  classes: PropTypes.object.isRequired,
  definition: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  user: PropTypes.object,
  isDetailView: PropTypes.bool,
  viewMode: PropTypes.bool,
  hideToolbar: PropTypes.bool,
  tabs: PropTypes.object,
  onChildEdit: PropTypes.func,
  onChildCancelEdit: PropTypes.func,
  breadcrumbs: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  toggleViewSwitcher: PropTypes.func.isRequired
};
export default withRouter(withStyles(dataFormStyle)(DataGridForm));
