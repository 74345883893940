import React from "react";
//import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import classNames from "classnames";
import settings from "variables/settings";

class FormCheckboxField extends React.Component {
  render() {
    const {
      classes,
      id,
      name,
      error,
      value,
      onChange,
      viewMode,
      required,
      access /*, classes*/
    } = this.props;

    const labelStyle = classNames(classes.checkBoxLabel, {
      [classes.checkBoxLabelError]: error ? true : false
    });

    return viewMode ? (
      <FormControl>
        <Checkbox
          //tabIndex={-1}
          style={{ padding: 5 }}
          disabled={access !== "w" ? true : false}
          color={settings.actionColor}
          id={id}
          checked={value ? value : false}
          indeterminate={value === null || value === undefined ? true : false}
          //onClick={() => props.onToggle(props.name)}
          onChange={evt => onChange({ [id]: evt.target.checked })}
          //checkedIcon={<Check className={props.classes.checkedIcon} />}
          //icon={<Check className={props.classes.uncheckedIcon} />}
          //classes={{ checked: props.classes.checked }}
        />
      </FormControl>
    ) : (
      /*<TextField
        //inputRef={ref => (this.email = ref)}
        id={id}
        label={name}
        helperText={error || ""}
        error={error ? true : false}
        //InputLabelProps={inputLabelProps}
        //className={classes.textField}
        fullWidth
        value={value}
        autoComplete="off"
        onChange={evt => onChange(id, evt.target.value)}
        margin="normal"
        //variant="outlined"
      />*/
      <FormControl
        style={{
          marginTop: 8,
          marginBottom: 4
        }}
        fullWidth
      >
        <FormControlLabel
          className={labelStyle}
          control={
            <Checkbox
              //tabIndex={-1}
              disabled={access !== "w" ? true : false}
              color={settings.actionColor}
              id={id}
              checked={value ? value : false}
              indeterminate={
                value === null || value === undefined ? true : false
              }
              //onClick={() => props.onToggle(props.name)}
              onChange={evt => onChange({ [id]: evt.target.checked })}
              //checkedIcon={<Check className={props.classes.checkedIcon} />}
              //icon={<Check className={props.classes.uncheckedIcon} />}
              //classes={{ checked: props.classes.checked }}
            />
          }
          //classes={{ label: props.classes.label }}
          label={required ? `${name} *` : name}
        />
        <FormHelperText error={error ? true : false}>
          {error ? error : ""}
        </FormHelperText>
      </FormControl>
      /*<TextField
        //inputRef={ref => (this.email = ref)}
        id={id}
        label={name}
        helperText={error || ""}
        error={error ? true : false}
        //InputLabelProps={inputLabelProps}
        //className={classes.textField}
        fullWidth
        value={value}
        autoComplete="off"
        onChange={evt => onChange(id, evt.target.value)}
        margin="normal"
        //variant="outlined"
      />*/
    );
  }
}

FormCheckboxField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.bool,
  error: PropTypes.string,
  access: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  viewMode: PropTypes.bool
};

export default FormCheckboxField;
