import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";
import Tool from "./Tool";
import PrintIcon from "@material-ui/icons/Print";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ToolsPrintModal from "./ToolsPrintModal";

class Tools extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("tools");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "tools",
        url: "tools",
        avatar: "photo_file_id",
        attributes: strings.attributes["tools"],
        condition: props.item
          ? {
              id: props.itemKey,
              value: props.item["id"]
            }
          : undefined,
        columns: [
          { id: "tool_number", readOnly: true, detailLink: true },
          { id: "name" },
          {
            id: "type",
            type: "select",
            options: strings.options.tools.type
          },
          { id: "description", visible: false },
          {
            id: "user_id",
            type: "select",
            datasource: {
              controller: "users",
              objectField: "user",
              valueField: "id",
              label: "name",
              sort: { "user.name": "asc" },
              search: ["user.name"]
            },
            sort: { "user.name": "asc" },
            search: ["user.name"]
          },
          { id: "warranty_expires_at", type: "unixDate", visible: false },
          {
            id: "project_id",
            type: "select",
            datasource: {
              controller: "projects",
              objectField: "project",
              valueField: "id",
              label: "name",
              sort: { "project.name": "asc" },
              search: ["project.name"]
            },
            sort: { "project.name": "asc" },
            search: ["project.name"]
          },
          {
            id: "invoice_id",
            type: "select",
            datasource: {
              controller: "invoices",
              objectField: "invoice",
              valueField: "id",
              label: "invoice_number",
              sort: { "invoice.invoice_number": "asc" },
              search: ["invoice.invoice_number"]
            },
            sort: { "invoice.invoice_number": "asc" },
            search: ["invoice.invoice_number"]
          },
          { id: "price", type: "currency" },
          { id: "item_count" },
          { id: "date_of_buy", type: "unixDate" },
          { id: "scrap", type: "checkbox", defaultValue: false },
          {
            id: "photo_file_id",
            type: "file",
            hidden: true,
            defaultIcon: NotInterestedIcon
          }
        ]
      }
    };

    if (props.itemKey) {
      this.hideMasterField(props.itemKey);
    }
  }

  hideMasterField = key => {
    this.state.definition.columns.forEach(column => {
      if (column.id === key) {
        column.system = true;
        column.hidden = true;
        column.visible = false;
      }
    });
  };

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const { user, filterable, isDetailView } = this.props;
    return (
      <div>
        <DataGrid
          user={user}
          filterTabs={isDetailView ? undefined : ["noScrap", "all"]}
          definition={definition}
          FormComponent={Tool}
          ViewComponent={Tool}
          selectable
          FormComponentProps={{ user: user }}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("tools", column.id);
          }}
          filterable={filterable}
          access={this.state.access}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return item.name || item.id;
            } else {
              return `Új ${strings.menu.tools.singularTitle}`;
            }
          }}
          toolbarButtons={[
            {
              id: "print-modal",
              tooltip: "Címke nyomtatás",
              modalComponent: ToolsPrintModal,
              Icon: PrintIcon,
              color: "primary",
              onlyGrid: true,
              yesText: "Nyomtatás",
              maxWidth: "xl",
              buttonProps: ({ grid }) => {
                return {
                  disabled:
                    !grid.state.selectionAll &&
                    grid.state.selection.length === 0
                };
              }
            }
          ]}
        />
      </div>
    );
  }
}

Tools.defaultProps = {
  filterable: true
};

Tools.propTypes = {
  user: PropTypes.object.isRequired,

  item: PropTypes.object,
  itemKey: PropTypes.string,
  filterable: PropTypes.bool
};

export default Tools;
