import WorkClothesIcon from "@material-ui/icons/Accessibility";
import MyHoursIcon from "@material-ui/icons/AccessTime";
//import VehiclePositions from "views/VehiclePositions/VehiclePositions";
//import VehiclePositionsIcon from "@material-ui/icons/Settings";
import InvoiceIcon from "@material-ui/icons/AccountBalance";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import WorksheetIcon from "@material-ui/icons/Assignment";
import DocumentTypeIcon from "@material-ui/icons/AttachFile";
import UserCostIcon from "@material-ui/icons/AttachMoney";
import ToolsIcon from "@material-ui/icons/Build";
import CustomerIcon from "@material-ui/icons/Business";
import DashboardIcon from "@material-ui/icons/Dashboard";
import VehicleIcon from "@material-ui/icons/DirectionsCar";
import TaskIcon from "@material-ui/icons/Dns";
import TaskGroupIcon from "@material-ui/icons/GroupWork";
import RuleIcon from "@material-ui/icons/Https";
import IncomingIcon from "@material-ui/icons/Inbox";
import ReportIcon from "@material-ui/icons/Layers";
import WorkReportIcon from "@material-ui/icons/List";
import CostIcon from "@material-ui/icons/LocalAtm";
import VehicleMapIcon from "@material-ui/icons/Map";
import PaysIcon from "@material-ui/icons/MonetizationOn";
import ReleasesIcon from "@material-ui/icons/NewReleases";
import UsersIcon from "@material-ui/icons/People";
import RoleIcon from "@material-ui/icons/PeopleOutline";
import OutgoingIcon from "@material-ui/icons/Send";
import ServiceVehiclesIcon from "@material-ui/icons/Settings";
import ProjectIcon from "@material-ui/icons/Work";
import ZipIcon from "common/assets/icons/ZipIcon";
import DocumentTypes from "common/views/DocumentTypes";
import Releases from "common/views/Releases";
import Roles from "common/views/Roles";
import Rules from "common/views/Rules";
import strings from "variables/strings";
import Costs from "views/Costs";
import Customers from "views/Customers";
import AdminDashboard from "views/Dashboard/AdminDashboard";
import InvoicesIncoming from "views/Invoices/InvoicesIncoming";
import InvoicesOutgoing from "views/Invoices/InvoicesOutgoing";
import MyHours from "views/MyHours";
import Pays from "views/Pays";
import Projects from "views/Projects/Projects";
import Report from "views/Report";
import ServiceVehicles from "views/Service/ServiceVehicles";
import SsTaxes from "views/SsTaxes";
import TaskGroups from "views/TaskGroups";
import Tasks from "views/Tasks";
import Tools from "views/Tools/Tools";
import UserCosts from "views/UserCosts";
import Users from "views/Users/Users";
import VehicleMap from "views/VehiclePositions/VehicleMap";
import Vehicles from "views/Vehicles";
import Wallet from "views/Wallet";
import WorkClothes from "views/WorkClothes/WorkClothes";
import WorkReport from "views/WorkReport";
import Worksheets from "views/Worksheets/Worksheets";
import Zips from "views/Zips";
/* builder-import start */

/* builder-import end */

const menu = [
  /* builder-menu start */
  /* builder-menu end */
  {
    name: strings.menu["vehicle-positions"].title,
    slug: "/vehicle-positions",
    icon: VehicleMapIcon,
    component: VehicleMap,
    items: []
  },
  /*{
    name: strings.menu["vehicle-positions"].title,
    slug: "/vehicle-positions",
    icon: VehiclePositionsIcon,
    component: VehiclePositions,
    items: []
  },*/
  {
    name: strings.menu["service-vehicles"].title,
    slug: "/service-vehicles",
    icon: ServiceVehiclesIcon,
    component: ServiceVehicles,
    items: []
  },
  {
    name: strings.menu["work-clothes"].title,
    slug: "/work-clothes",
    icon: WorkClothesIcon,
    component: WorkClothes,
    items: []
  },
  {
    name: strings.menu.tools.title,
    slug: "/tools",
    icon: ToolsIcon,
    component: Tools,
    items: []
  },
  {
    name: strings.menu.dashboard.title,
    slug: "/admindashboard",
    icon: DashboardIcon,
    component: AdminDashboard,
    items: []
  },
  {
    name: strings.menu.invoices.title,
    slug: "/invoices",
    icon: InvoiceIcon,
    header: false,
    //component: Invoices,
    items: [
      {
        name: strings.menu.incoming.title,
        slug: "/incoming",
        icon: IncomingIcon,
        component: InvoicesIncoming,
        items: []
      },
      {
        name: strings.menu.outgoing.title,
        slug: "/outgoing",
        icon: OutgoingIcon,
        component: InvoicesOutgoing,
        items: []
      }
    ]
  },
  {
    name: strings.menu.pays.title,
    slug: "/pays",
    icon: PaysIcon,
    component: Pays,
    items: []
  },
  {
    name: strings.menu["my-hours"].title,
    slug: "/my-hours",
    icon: MyHoursIcon,
    component: MyHours,
    items: []
  },
  {
    name: strings.menu.report.title,
    slug: "/report",
    icon: ReportIcon,
    component: Report,
    items: []
  },
  {
    name: strings.menu.worksheets.title,
    slug: "/worksheets",
    icon: WorksheetIcon,
    component: Worksheets,
    items: []
  },
  {
    name: strings.menu["user-costs"].title,
    slug: "/user-costs",
    icon: UserCostIcon,
    component: UserCosts,
    items: []
  },
  {
    name: strings.menu.wallet.title,
    slug: "/wallet",
    icon: WalletIcon,
    component: Wallet,
    items: []
  },
  {
    name: strings.menu["work-report"].title,
    slug: "/work-report",
    icon: WorkReportIcon,
    component: WorkReport,
    items: []
  },
  {
    name: strings.menu.administration.title,
    slug: "/administration",
    icon: ProjectIcon,
    header: true,
    //component: Projects,
    items: [
      {
        name: strings.menu.projects.title,
        slug: "/projects",
        icon: ProjectIcon,
        component: Projects,
        items: []
      },
      {
        name: strings.menu.users.title,
        slug: "/users",
        icon: UsersIcon,
        component: Users,
        items: []
      },
      {
        name: strings.menu.roles.title,
        slug: "/roles",
        icon: RoleIcon,
        component: Roles,
        items: []
      },
      {
        name: strings.menu.rules.title,
        slug: "/rules",
        icon: RuleIcon,
        component: Rules,
        items: []
      },
      {
        name: strings.menu.customers.title,
        slug: "/customers",
        icon: CustomerIcon,
        component: Customers,
        items: []
      },
      {
        name: strings.menu["task-groups"].title,
        slug: "/task-groups",
        icon: TaskGroupIcon,
        component: TaskGroups,
        items: []
      },
      {
        name: strings.menu.tasks.title,
        slug: "/tasks",
        icon: TaskIcon,
        component: Tasks,
        items: []
      },
      {
        name: strings.menu.zips.title,
        slug: "/zips",
        icon: ZipIcon,
        component: Zips,
        items: []
      },
      {
        name: strings.menu.vehicles.title,
        slug: "/vehicles",
        icon: VehicleIcon,
        component: Vehicles,
        items: []
      },
      {
        name: strings.menu.costs.title,
        slug: "/costs",
        icon: CostIcon,
        component: Costs,
        items: []
      },
      {
        name: strings.menu["ss-taxes"].title,
        slug: "/ss-taxes",
        icon: CostIcon,
        component: SsTaxes,
        items: []
      },
      {
        name: strings.menu["document-types"].title,
        slug: "/document-types",
        icon: DocumentTypeIcon,
        component: DocumentTypes,
        items: []
      },
      {
        name: strings.menu.releases.title,
        slug: "/releases",
        icon: ReleasesIcon,
        component: Releases,
        items: []
      }
    ]
  }
];

export default menu;
