import { pink, yellow } from "@material-ui/core/colors";

//Light
const primary = "#366fad";
const secondary = pink[500];
const yellowColor = yellow[700];
const background = primary; //"linear-gradient(to right, #366fad, #333dc3, #20347a)";

//Dark
const primaryDark = "#366fad";
const secondaryDark = pink[500];
const yellowColorDark = yellow[700];
const backgroundDark = primaryDark; //"linear-gradient(to right, #366fad, #333dc3, #20347a)";
const type = localStorage.getItem("themeType") || "light";

const theme = {
  shapeBackground: {
    dark: backgroundDark,
    light: background
  },
  palette: {
    type: type,
    primaryLight: { main: primary },
    secondaryLight: { main: secondary },
    yellowLight: { main: yellowColor },
    primaryDark: { main: primaryDark },
    secondaryDark: { main: secondaryDark },
    yellowDark: { main: yellowColorDark }
  },
  typography: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    fontWeight: 500,
    lineHeight: "1.5em",
    fontSize: 14,
    h6: {
      fontSize: "1.1rem"
    }
    /*h5: {
      ...(type === "dark" ? { color: "#fff" } : {})
    }*/
  },
  //fontSize: 12,
  overrides: {
    MuiOutlinedInput: {
      input: { padding: "14.5px 10px" }
    },
    MuiTab: {
      root: {
        "&$selected": {
          color: secondary
        },
        "&:hover": {
          color: secondary
        }
      }
    },
    MuiTooltip: {
      tooltip: { fontSize: "0.8rem" }
    },
    /*wrapper: {
        color: "red"
      }
    },*/
    MuiInputLabel: {
      outlined: { transform: "translate(10px, 16px) scale(1)" }
    },
    MuiFormControl: {
      marginNormal: { marginTop: 8, marginBottom: 4 }
    }
  },
  props: {
    MuiTextField: {
      variant: "outlined"
    },
    MuiSelect: {
      variant: "outlined"
    }
  }
};

export default theme;
