import React from "react";
import PropTypes from "prop-types";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { DatePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";

class GridDateTimePickerField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value
    };
    this.modal = React.createRef();
  }

  componentDidMount() {}

  onKeyDown = evt => {
    evt.stopPropagation();
    if (evt.key === "Enter") {
      return this.props.onSave();
    } else if (evt.key === "Escape") {
      this.props.onCancel();
      return false;
    }
    return false;
  };

  onChange = date => {
    const d = date.format("YYYY.MM.DD.");
    const { id, onChange } = this.props;
    this.setState({ value: d });
    onChange({ [id]: d });
  };

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          //onKeyDown={this.onKeyDown}
          format="YYYY.MM.DD."
          autoOk={true}
          value={this.state.value}
          onChange={this.onChange}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

GridDateTimePickerField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default GridDateTimePickerField;
