// ##############################
// // // Modal component styles
// #############################
//import { primaryColor } from "common/assets/jss/material-dashboard-pro-react.jsx";

const modalStyle = theme => ({
  modalRoot: {
    overflow: "auto",
    alignItems: "unset",
    justifyContent: "unset"
  },
  progress: {
    padding: 0
    //marginLeft: 51,
  },
  progressCenter: {
    position: "absolute",
    top: "calc(50% - 22px)",
    left: "calc(50% - 22px)"
  },
  progressContainer: {
    //color: theme.palette.text.primary,
    position: "absolute",
    marginLeft: 51,
    overflow: "hidden"
  },
  modal: {
    [theme.breakpoints.up("sm")]: {
      //maxWidth: "500px",
      minWidth: "400px",
      maxHeight: "calc(100vh - 60px)"
      //margin: "1.75rem auto"
    },
    borderRadius: "6px",
    //marginTop: "100px !important",
    //overflow: "visible",
    //maxHeight: "calc(100vh - 120px)",
    maxHeight: "100vh",
    position: "relative",
    height: "fit-content",
    margin: 0
  },
  modalHeader: {
    borderBottom: "18px",
    paddingTop: "18px",
    paddingRight: "24px",
    //paddingBottom: "0",
    paddingLeft: "24px",
    minHeight: "16.43px",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
    //backgroundColor: theme.palette.primary.dark
    background:
      (theme.palette.type === "dark"
        ? theme.shapeBackground.dark
        : theme.shapeBackground.light) + "!important",
    "&.error": {
      background: `${theme.palette.error.main}!important`
    }
  },
  modalTitle: {
    margin: "0",
    lineHeight: "1.42857143",
    color:
      theme.palette.type === "dark"
        ? undefined
        : theme.palette.primary.contrastText,
    textIndent: 30,
    fontWeight: "normal"
  },
  modalCloseButton: {
    color:
      theme.palette.type === "dark"
        ? undefined
        : theme.palette.primary.contrastText,
    marginTop: -6,
    marginBottom: -6,
    WebkitAppearance: "none",
    padding: 6,
    cursor: "pointer",
    background: "0 0",
    border: "0",
    fontSize: "inherit",
    opacity: ".9",
    textShadow: "none",
    fontWeight: "700",
    lineHeight: "1",
    float: "right",
    minWidth: 30,
    marginRight: -12
  },
  modalClose: {
    width: "20px",
    height: "20px"
  },
  titleIcon: {
    //width: "16px",
    //height: "16px",
    color:
      theme.palette.type === "dark"
        ? undefined
        : theme.palette.primary.contrastText,
    position: "absolute",
    marginTop: -1
  },
  modalBody: {
    paddingTop: "24px",
    paddingRight: "24px",
    paddingBottom: "16px",
    paddingLeft: "24px",
    position: "relative"
    //overflow: "visible"
    //overflowY: "scroll"
  },
  modalFooter: {
    //padding: "15px",
    textAlign: "right",
    //paddingTop: "0",
    margin: "0"
  },
  modalFooterCenter: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  instructionNoticeModal: {
    marginBottom: "25px"
  },
  imageNoticeModal: {
    maxWidth: "150px"
  },
  modalSmall: {
    width: "300px"
  },
  modalSmallBody: {
    paddingTop: "0"
  },
  modalSmallFooterFirstButton: {
    margin: "0",
    paddingLeft: "16px",
    paddingRight: "16px",
    width: "auto"
  },
  modalSmallFooterSecondButton: {
    marginBottom: "0",
    marginLeft: "5px"
  },
  modalError: {
    //color: theme.palette.secondary.dark,
    //fontSize: 11
    textAlign: "center"
  },
  modalTop: {
    alignItems: "flex-start",
    marginTop: theme.spacing(4)
  },
  loadingOverlay: {
    backgroundColor:
      theme.palette.type === "dark"
        ? "rgba(0,0,0, 0.3)"
        : "rgba(255,255,255, 0.4)",
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1
  }
});

export default modalStyle;
