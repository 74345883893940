import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  CircularProgress,
  FormControlLabel,
  Typography,
  FormControl,
  FormGroup,
  Checkbox,
  FormLabel,
  Avatar,
  Toolbar
} from "@material-ui/core";
import restHelper from "common/utils/restHelper";
import { withStyles } from "@material-ui/core/styles";
import Spacer from "common/components/Spacer";
import { globalMessage } from "common/components/GlobalMessaging";
import strings from "variables/strings.json";
import dateHelper from "common/utils/dateHelper";
import FormDateTimePickerField from "common/components/DataForm/FormDateTimePickerField";
import Moment from "moment";
import { Link } from "react-router-dom";
import { Check } from "@material-ui/icons";

const styles = theme => ({
  wrapper: {
    position: "relative"
  },
  loaderWrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    zIndex: 100,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor:
      theme.palette.type === "dark"
        ? "rgba(0,0,0,0.5)"
        : "rgba(255,255,255,0.5)"
  },
  successAvatar: {
    backgroundColor: theme.palette.success.main
  },
  errorAvatar: {
    backgroundColor: theme.palette.error.main
  },
  listSubheader: {
    textTransform: "uppercase",
    lineHeight: "inherit",
    paddingLeft: 0,
    paddingRight: 0
  }
});
class WorksheetCloneModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      data: {},
      worksheet_users: true,
      worksheet_tasks: false,
      user_costs: false,
      worksheet_vehicles: false,
      day: Moment()
        .startOf("day")
        .unix(),
      error: null,
      loading: false,
      newWorksheet: null,
      validated: false
    };
    this.modal = null;
  }

  componentDidMount() {
    const { setOnAccept } = this.props;
    setOnAccept(this.onAccept);
  }

  componentDidUpdate() {
    const { setOnAccept } = this.props;
    setOnAccept(this.onAccept);
  }

  closeModal = () => {
    const { grid } = this.props;
    grid.closeModal("worksheet-duplicate");
  };

  onAccept = () => {
    const { grid } = this.props;
    const {
      day,
      worksheet_users,
      user_costs,
      worksheet_tasks,
      worksheet_vehicles
    } = this.state;

    grid.setLoading("worksheet-duplicate", true);
    restHelper
      .action("worksheets", "duplicate", grid.state.editItem.id, {
        day,
        worksheet_users,
        user_costs,
        worksheet_tasks,
        worksheet_vehicles
      })
      .then(newWorksheet => {
        //this.props.grid.setEditItem(res);
        console.log("newWorksheet", newWorksheet);
        grid.setModalInactive("worksheet-duplicate", true);
        grid.setLoading("worksheet-duplicate", false);
        globalMessage.success("Az új munkalapot sikeresen létrejött.");
        this.setState({
          error: null,
          newWorksheet
        });
        //grid.closeModal("worksheet-duplicate");
      })
      .catch(error => {
        grid.setLoading("worksheet-duplicate", false);
        console.log(error);
        this.setState({
          error:
            error.data && error.data.day
              ? error.data.day.join(", ")
              : "Sikertelen művelet.",
          newWorksheet: null
        });
      });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  onChange = ({ day }) => {
    console.log("day", day);
    this.setState({ day: dateHelper.dateToUnix(day) });
  };

  render() {
    const {
      classes,
      theme,
      grid: {
        state: { editItem }
      }
    } = this.props;
    const {
      newWorksheet,
      loading,
      worksheet_users,
      user_costs,
      worksheet_tasks,
      worksheet_vehicles,
      day,
      error
    } = this.state;

    return (
      <Grid container spacing={2} className={classes.wrapper}>
        {loading && (
          <div className={classes.loaderWrapper}>
            <CircularProgress />
          </div>
        )}
        <Grid item xs={12}>
          <Typography>
            <b>
              #{editItem.id} {editItem.project.name} -{" "}
              {dateHelper.unixToDate(editItem.day)}
              <br />
              {editItem.foreman.name}
            </b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Spacer h={2} />
          <FormDateTimePickerField
            id="day"
            name={strings.attributes.worksheets.day}
            //options={column.options}
            //datasource={column.datasource}
            value={Moment.unix(day).format("YYYY-MM-DD")}
            error={error}
            onChange={this.onChange}
            classes={classes}
            theme={theme}
            type={"unixDate"}
            todayHotButton={true}
            item={editItem}
            access="w"
            dirty={false}
            required={true}
          />
          <Spacer h={2} />
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Másolandó adatok</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={worksheet_users}
                    onChange={this.handleChange}
                    name="worksheet_users"
                  />
                }
                label="Jelenléti ív"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={user_costs}
                    onChange={this.handleChange}
                    name="user_costs"
                  />
                }
                label="Tételek"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={worksheet_tasks}
                    onChange={this.handleChange}
                    name="worksheet_tasks"
                  />
                }
                label="Költségek"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={worksheet_vehicles}
                    onChange={this.handleChange}
                    name="worksheet_vehicles"
                  />
                }
                label="Járművek"
              />
            </FormGroup>
          </FormControl>
        </Grid>
        {newWorksheet && (
          <Grid item xs={12}>
            <Toolbar disableGutters>
              <Avatar style={{ backgroundColor: "green" }}>
                <Check />
              </Avatar>
              <Spacer w={2} />
              <Typography>
                A munkalap sikeresen létrejött, amelyet a linkre kattintva
                tekinthet meg.
                <br />
                <Link
                  to={`/worksheets/edit/${newWorksheet.id}`}
                  onClick={this.closeModal}
                >
                  #{newWorksheet.id} {newWorksheet.project.name} -{" "}
                  {dateHelper.unixToDate(newWorksheet.day)}
                </Link>
              </Typography>
            </Toolbar>
          </Grid>
        )}
      </Grid>
    );
  }
}

WorksheetCloneModal.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  grid: PropTypes.object,
  evt: PropTypes.object,
  setOnAccept: PropTypes.func
};

export default withStyles(styles, { withTheme: true })(WorksheetCloneModal);
