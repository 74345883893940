import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import uteStyle from "common/assets/style/uteStyle";
import DataGrid from "common/components/DataGrid/DataGrid";
import Project from "views/Projects/Project";
import strings from "variables/strings.json";

const projectTypes = [
  { value: 1, label: "Projekt" },
  { value: 2, label: "Szerviz" }
];

class Projects extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("projects");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "projects",
        url: "projects",
        attributes: strings.attributes.projects,
        columns: [
          { id: "name", detailLink: true },
          { id: "description" },
          { id: "start_date", type: "unixDate" },
          { id: "due_date", type: "unixDate" },
          {
            id: "customer_id",
            type: "select",
            datasource: {
              controller: "customers",
              objectField: "customer",
              valueField: "id",
              search: ["customer.name"],
              filter: [
                ["customer.active", "exact", true],
                ["customer.is_project_customer", "exact", true]
              ],
              label: item => {
                return item ? item.name : "";
              }
            }
          },
          { id: "custom_costs", type: "checkbox", visible: false },
          {
            id: "type",
            type: "select",
            options: projectTypes
          },
          { id: "active", type: "checkbox", visible: false }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const { user } = this.props;
    return (
      <div>
        <DataGrid
          user={user}
          definition={definition}
          FormComponent={Project}
          ViewComponent={Project}
          FormComponentProps={{ user: user }}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("projects", column.id);
          }}
          access={this.state.access}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return item.name;
            } else {
              return "Új munka";
            }
          }}
        />
      </div>
    );
  }
}

Projects.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(uteStyle, { withTheme: true })(Projects);
