import React from "react";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import LabelTemplate from "common/components//LabelTemplate";
import herma_8339_idojarasallo_cimke from "assets/img/herma_8339_idojarasallo_cimke.jpg"; //70 db

const TemplateA4X70 = ({ items, startFrom }) => {
  return (
    <LabelTemplate
      items={items}
      rows={10}
      columns={7}
      pageWidth={841}
      pageHeight={1189}
      pageMarginVertical={60}
      pageMarginHorizontal={48}
      labelSpaceVertical={10}
      labelSpaceHorizontal={10}
      startFrom={startFrom}
      renderLabel={label => (
        <div
          style={{
            pageBreakBefore: "always",
            textAlign: "center"
          }}
        >
          <QRCode
            renderAs="svg"
            level="H"
            size={256}
            value={label ? `T:${label.id}` : ""}
            fgColor={label ? undefined : "white"}
            //value={`https://app.vasutoptika.hu/check-tool/${item.id}`}
            style={{
              //marginLeft: 0,
              //marginRight: 12,
              marginTop: 20,
              marginBottom: 5,
              width: 40,
              height: 40
            }}
          />
          <div
            style={{
              fontSize: 14,
              textAlign: "center",
              color: label ? undefined : "white"
            }}
          >
            {label ? label.tool_number : "üres"}
          </div>
        </div>
      )}
      //pageBackgroundImage={herma_8339_idojarasallo_cimke}
    />
  );
};

TemplateA4X70.propTypes = {
  items: PropTypes.array.isRequired
};

export default TemplateA4X70;
