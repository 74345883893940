import React from "react";
import PropTypes from "prop-types";
import DataGridForm from "common/components/DataGrid/DataGridForm";
import InvoiceItems from "views/Invoices/InvoiceItems";
import InvoiceTransactions from "views/Invoices/InvoiceTransactions";

const Invoice = props => (
  <DataGridForm
    {...props}
    tabs={{
      id: "invoices",
      documents: true,
      histories: false,
      items: {
        "invoice-items": {
          itemKey: "invoice_id",
          component: InvoiceItems,
          badgeContent: "item_count",
          componentProps: {
            refreshInvoice: props.refreshItem
          }
        },
        "invoice-transactions": {
          itemKey: "invoice_id",
          component: InvoiceTransactions,
          badgeContent: "transaction_count",
          componentProps: {
            refreshInvoice: props.refreshItem
          }
        }
      }
    }}
  />
);

Invoice.propTypes = {
  refreshItem: PropTypes.func.isRequired
};

export default Invoice;
