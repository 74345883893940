import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import uteStyle from "common/assets/style/uteStyle";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";

class TaskGroups extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("task-groups");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "task-groups",
        url: "task-groups",
        attributes: strings.attributes["task-groups"],
        columns: [
          { id: "name" },
          { id: "active", type: "checkbox", visible: false }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const { user } = this.props;
    return (
      <div>
        <DataGrid user={user}
          definition={definition}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("task-groups", column.id);
          }}
          access={this.state.access}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return item.name;
            } else {
              return "Új tétel csoport";
            }
          }}
        />
      </div>
    );
  }
}

TaskGroups.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  
};

export default withStyles(uteStyle, { withTheme: true })(TaskGroups);
