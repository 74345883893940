import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import errorPageStyle from "common/assets/style/errorPageStyle";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

class ErrorPage404 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /*componentDidMount() {
    mnuService.usrMnu().then(res => {
      this.setState({usrMnu: res.data});
    });
  }*/

  render() {
    const { classes } = this.props;

    return (
      <Grid container={true} justify="center" className={classes.container}>
        <Grid item={true} xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h2" component="h2">
              Hiba!
            </Typography>
            <Typography variant="h5" component="h2" className={classes.message}>
              A keresett oldal nem található
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

ErrorPage404.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(errorPageStyle, { withTheme: true })(ErrorPage404);
