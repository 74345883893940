import React from "react";
import PropTypes from "prop-types";

const JsonSyntaxHighlight = props => {
  let json = props.json;
  if (!props.json) {
    return String(props.json);
  }
  if (typeof json != "string") {
    json = JSON.stringify(json, undefined, 2);
  }

  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/\\"/g, '"');
  let pretty = json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function(match) {
      var cls = "color:blue;"; //'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "color:black;"; //'key';
        } else {
          cls = "color:red; white-space: normal;"; //'string';
        }
      } else if (/true|false/.test(match)) {
        cls = "color:green;"; //'boolean';
      } else if (/null/.test(match)) {
        cls = "color:gray;"; //'null';
      }
      return '<span style="' + cls + '">' + match + "</span>";
    }
  );
  pretty = "<code><pre>" + pretty + "</pre></code>";
  return <div dangerouslySetInnerHTML={{ __html: pretty }} />;
};

JsonSyntaxHighlight.propTypes = {
  json: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default JsonSyntaxHighlight;
