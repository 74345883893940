import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";
import Invoice from "views/Invoices/Invoice";
import withTheme from "@material-ui/core/styles/withTheme";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import ModalConfirm from "common/components/Modals/ModalConfirm";
import dateHelper from "common/utils/dateHelper";

const statuses = [
  { value: 1, label: "Rögzített" },
  { value: 2, label: "Lezárt" }
];

const paymentMethods = [
  { value: 1, label: "Átutalás 8 nap" },
  { value: 2, label: "Átutalás 14 nap" },
  { value: 3, label: "Átutalás 30 nap" },
  { value: 4, label: "Készpénz" }
];

class InvoicesIncoming extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("invoices");
    this.now = Math.round(new Date().getTime() / 1000);

    this.state = {
      access: access ? access : "",
      definition: {
        id: "invoices",
        url: "invoices",
        condition: {
          id: "incoming",
          value: true
        },
        attributes: strings.attributes["invoices"],
        columns: [
          { id: "invoice_number", detailLink: true, required: true },
          {
            id: "customer_id",
            type: "select",
            required: true,
            datasource: {
              controller: "customers",
              objectField: "customer",
              valueField: "id",
              label: "name",
              sort: { "customer.name": "asc" },
              search: ["customer.name"],
              filter: [
                ["customer.active", "exact", true],
                ["customer.is_supplier", "exact", true]
              ]
            }
          },
          //{ id: "incoming", type: "checkbox" },
          {
            id: "status",
            type: "select",
            options: strings.options.invoices.status,
            defaultValue: 1,
            required: true
          },
          {
            id: "invoice_type",
            type: "select",
            options: strings.options.invoices.invoice_type,
            defaultValue: 1,
            required: true
          },
          {
            id: "payment_method",
            type: "select",
            options: strings.options.invoices.payment_method,
            required: true,
            defaultValue: 1,
            changeValues: item => {
              switch (item.payment_method) {
                case 1:
                  item.due_date = dateHelper.dateAddDay(item.delivery_date, 8);
                  break;
                case 2:
                  item.due_date = dateHelper.dateAddDay(item.delivery_date, 14);
                  break;
                case 3:
                  item.due_date = dateHelper.dateAddDay(item.delivery_date, 30);
                  break;
                case 4:
                  item.due_date = item.delivery_date;
                  break;
                default:
                  break;
              }
              //item.due_date = item.create_date;
              /*item.description = `Kiválasztott fizetési mód: ${
                item.payment_method
              }`;*/
            }
          },
          {
            id: "create_date",
            type: "unixDate",
            defaultValue: Math.round(new Date().getTime() / 1000),
            required: true
          },
          {
            id: "delivery_date",
            type: "unixDate",
            defaultValue: Math.round(new Date().getTime() / 1000),
            required: true
          },
          {
            id: "due_date",
            type: "unixDate",
            defaultValue:
              Math.round(new Date().getTime() / 1000) + 3600 * 24 * 8,
            required: true
          },
          {
            id: "project_id",
            type: "select",
            datasource: {
              controller: "projects",
              objectField: "project",
              valueField: "id",
              label: "name",
              sort: { "project.name": "asc" },
              search: ["project.name"]
            }
          },
          {
            id: "user_cost_id",
            type: "select",
            datasource: {
              controller: "user-costs",
              objectField: "user_cost",
              valueField: "id",
              label: item => {
                return item && item.cost && item.cost ? (
                  <div>
                    <div>
                      {item.cost.name}
                      {item.description ? ` ${item.description}` : ""}
                    </div>
                    <div style={{ fontSize: "0.8em" }}>
                      {item.user.name} {dateHelper.unixToDate(item.day)}
                    </div>
                  </div>
                ) : (
                  ""
                );
              },
              sort: { day: "desc", "cost.name": "asc" },
              search: ["cost.name", "user_cost.description"]
            },
            cell: cellData =>
              cellData.original.user_cost
                ? cellData.original.user_cost.cost.name +
                  (cellData.original.user_cost.description
                    ? ` ${cellData.original.user_cost.description}`
                    : "")
                : "",
            sort: "cost.name",
            search: ["cost.name", "user_cost.description"]
          },
          { id: "hold_price", type: "currency", footer: "sum" },
          { id: "hold_date", type: "unixDate" },
          {
            id: "price",
            type: "currency",
            footer: "sum",
            filterable: false,
            readOnly: true
          },
          {
            id: "transaction_price",
            type: "currency",
            footer: "sum",
            filterable: false,
            readOnly: true
          },
          {
            id: "remaining_price",
            type: "currency",
            footer: "sum",
            filterable: false,
            readOnly: true
          },
          { id: "description", visible: false }
        ]
      }
    };
    this.modal = React.createRef();
  }

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const {
      user,
      theme,
      filterable,
      viewMode,
      showPaginationTop,
      showPaginationBottom,
      viewOnRowClick,
      isDetailView,
      height,
      editItem
    } = this.props;
    //console.log(theme);
    return (
      <div>
        <ModalConfirm onRef={ref => (this.modal = ref)} />
        <DataGrid
          user={user}
          definition={definition}
          filterable={filterable}
          viewMode={viewMode}
          showPaginationTop={showPaginationTop}
          showPaginationBottom={showPaginationBottom}
          height={height}
          item={editItem}
          isDetailView={isDetailView}
          viewOnRowClick={viewOnRowClick}
          startEditRef={ref => (this.startEdit = ref)}
          ViewComponent={Invoice}
          FormComponent={Invoice}
          FormFooter="CreatedUpdated"
          rowStyle={(item, isOdd) => {
            if (item.remaining_price < 0) {
              const bgColor = isOdd
                ? lighten(theme.palette.error.main, 0.07)
                : theme.palette.error.main;
              return {
                backgroundColor: bgColor,
                color: theme.palette.getContrastText(bgColor)
              };
            }
            const holdPrice =
              item.hold_price && item.hold_date && item.hold_date > this.now
                ? item.hold_price
                : 0;
            const duePrice = item.remaining_price - holdPrice;
            const overDue = duePrice > 0 && item.due_date < this.now;
            if (overDue > 0) {
              return {
                backgroundColor: isOdd
                  ? "rgba(255,205,210, 0.8)"
                  : "rgb(255,205,210)"
              };
            }
          }}
          fieldAccessHandler={column => {
            if (
              ["transaction_price", "remaining_price", "price"].indexOf(
                column.id
              ) >= 0
            ) {
              return "r";
            }
            return user.accessField("invoices", column.id);
          }}
          access={this.state.access}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return (
                item.invoice_number +
                (item.customer && ` - ${item.customer.name}`)
              );
            } else {
              return "Új bejövő számla";
            }
          }}
        />
      </div>
    );
  }
}

InvoicesIncoming.propTypes = {
  theme: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  item: PropTypes.object,
  editItem: PropTypes.object,
  itemKey: PropTypes.string,
  title: PropTypes.string,
  filterable: PropTypes.bool,
  viewMode: PropTypes.bool,
  showPaginationTop: PropTypes.bool,
  showPaginationBottom: PropTypes.bool,
  viewOnRowClick: PropTypes.bool,
  isDetailView: PropTypes.bool,
  showDetails: PropTypes.bool,
  toggleDetails: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default withTheme(InvoicesIncoming);
