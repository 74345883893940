import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Toolbar,
  Typography
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import dataFormStyle from "common/assets/style/dataFormStyle";
import Spacer from "common/components/Spacer";
import dateHelper from "common/utils/dateHelper";
import formatHelper from "common/utils/formatHelper";
import restHelper from "common/utils/restHelper";
import React from "react";

class MyHours extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interval: 1,
      items: [],
      start_date: null,
      end_date: null,
      month: null,
      total: null,
      totalAmount: null
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  handleIntervalChange = interval => {
    this.setState({ interval }, () => {
      this.fetchData();
    });
  };

  fetchData = async () => {
    const { interval } = this.state;
    const data = await restHelper.index("reports/my-hours", { interval });
    this.setState(data);
    //console.log(data);
  };

  render() {
    const { classes, theme } = this.props;
    const { interval, items, month, total, totalAmount } = this.state;
    return (
      <Grid container={true} spacing={4}>
        <Tabs
          value={interval}
          onChange={(evt, value) => this.handleIntervalChange(value)}
        >
          <Tab label="Még Előző hónap" value={3} />
          <Tab label="Előző hónap" value={2} />
          <Tab label="Aktuális hónap" value={1} />
        </Tabs>
        <Grid item={true} xs={12}>
          <Typography variant="h4">{month}</Typography>
          <Toolbar disableGutters style={{ minHeight: 0 }}>
            <Typography variant="h5">{total} óra</Typography>
            <Spacer w={2} />
            <Typography variant="h6" style={{ marginTop: "3px" }}>
              {formatHelper.currency(totalAmount)}
            </Typography>
          </Toolbar>
          <Spacer h={1} />
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Nap</TableCell>
                  <TableCell>Munkaidő</TableCell>
                  <TableCell>Óra</TableCell>
                  <TableCell>Összeg</TableCell>
                  <TableCell>Típus</TableCell>
                  <TableCell>Projekt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(item => {
                  let backgroundColor = undefined;
                  if (item.amount < 0) {
                    backgroundColor = "rgba(255,0,0, 0.1)";
                  }
                  if (item.amount === 0) {
                    backgroundColor = "rgba(0,0,0, 0.1)";
                  }
                  return (
                    <TableRow
                      key={item.id}
                      style={{
                        backgroundColor
                      }}
                    >
                      <TableCell>{dateHelper.unixToDate(item.day)}</TableCell>
                      <TableCell>
                        {dateHelper.unixToTime(item.start_hour)} -{" "}
                        {dateHelper.unixToTime(item.end_hour)}
                      </TableCell>
                      <TableCell>
                        {Math.round(item.work_hours * 100) / 100}
                      </TableCell>
                      <TableCell>
                        {formatHelper.currency(item.amount)}
                      </TableCell>
                      <TableCell>{item.cost_name}</TableCell>
                      <TableCell>{item.project_name}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
}

MyHours.propTypes = {};

export default withStyles(dataFormStyle, { withTheme: true })(MyHours);
