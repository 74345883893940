import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import DatePicker from "common/components/DatePicker";
import FormSelectField from "common/components/DataForm/FormSelectField";
import DataPreview from "common/components/DataPreview/DataPreview";
import restHelper from "common/utils/restHelper";
import formatHelper from "common/utils/formatHelper";
import dateHelper from "common/utils/dateHelper";
import dataFormStyle from "common/assets/style/dataFormStyle";

class Pays extends React.Component {
  constructor(props) {
    super(props);
    const dayOfMonth = new Date().getDate();
    let dates = null;
    const storage = sessionStorage.getItem("pays");
    if (storage) {
      let storageData = JSON.parse(storage);
      if (storageData && storageData.dates) {
        if (typeof storageData.dates === "string") {
          dates = storageData.dates;
        } else {
          dates = dateHelper.unixToRange(storageData.dates);
        }
      }
    }
    if (!dates) {
      dates = dayOfMonth < 10 ? "lastMonth" : "thisMonth";
    }
    this.state = {
      dates: dates,
      time: new Date().getTime(),
      subcontractor: null,
      subcontractor_id: null
    };

    this.state.sections = [
      //Bérek
      {
        title: "Összesen",
        noHeader: true,
        showSum: true,
        //icon: PayIcon,
        filter: this.filter(),
        tabs: [
          {
            //title: "Felhasználónként",
            //icon: UsersIcon,

            fetchData: item => {
              const params = {
                group: "t.user_id",
                groupname: "t.user_name",
                filter: this.filter()
              };
              return restHelper.index("reports/pays-with-costs", params);
            },
            columns: [
              { id: "name" },
              {
                id: "amount",
                type: "currency",
                value: (item, classes) => {
                  if (item.cost_name === "Bér") {
                    return formatHelper.currency(
                      item.amount_pays + item.amount_costs
                    );
                  }
                  return (
                    <div className={classes.cell}>
                      <div className={classes.dateDays}>
                        {formatHelper.currency(
                          item.amount_pays + item.amount_costs
                        )}
                      </div>
                      <div className={classes.dateRange}>
                        <div>{formatHelper.currency(item.amount_pays)}</div>
                        <div>{formatHelper.currency(item.amount_costs)}</div>
                      </div>
                    </div>
                  );
                }
              },
              {
                id: "qty",
                value: item => {
                  return (
                    Math.round(item.qty * 100) / 100 +
                    " óra (" +
                    item.work_days +
                    " nap)"
                  );
                }
              },
              {
                id: "cost_name",
                value: (item, classes) => {
                  if (!item.description) {
                    return item.cost_name;
                  }
                  return (
                    <div className={classes.cell}>
                      <div className={classes.rows}>
                        <div>{item.cost_name}</div>
                        <div>{item.description}</div>
                      </div>
                    </div>
                  );
                }
              },
              { id: "project_name" }
            ],
            child: {
              tabs: [
                {
                  //title: "Felhasználónként",
                  //icon: UsersIcon,
                  enablePrint: true,
                  PrintComponent: (item, items, columns, getColumn) => {
                    const dates = this.parseValue(this.state.dates);
                    return (
                      <div>
                        <h1 className="text_center" style={{ marginBottom: 0 }}>
                          Bérlap
                        </h1>
                        <h4 className="text_center" style={{ marginTop: 0 }}>
                          Vasútoptika Kft.
                        </h4>
                        <h2 className="text_center">{item.name}</h2>
                        {this.state.dates ? (
                          <h3 className="text_center">
                            {dateHelper.unixToDate(dates.start.unix())} -{" "}
                            {dateHelper.unixToDate(dates.end.unix())}
                          </h3>
                        ) : (
                          <h3 className="text_center">
                            {dateHelper.unixToDate(item.first_day)} -{" "}
                            {dateHelper.unixToDate(item.last_day)}
                          </h3>
                        )}
                        <div>
                          <b>Ledolgozott napok száma:</b> {item.work_days}
                        </div>
                        <div>
                          <b>Ledolgozott órák száma:</b>{" "}
                          {Math.round(item.qty * 100) / 100}
                        </div>
                        <div>
                          <b>Bér alap:</b>{" "}
                          {formatHelper.currency(item.amount_pays)}
                        </div>
                        <div>
                          <b>Egyéb tételek*:</b>{" "}
                          {formatHelper.currency(item.amount_costs)}
                        </div>
                        <table>
                          <thead>
                            <tr>
                              {columns.map((column, colmnIndex) => {
                                return (
                                  <td key={colmnIndex}>
                                    <b>
                                      {column.name ? column.name : column.id}
                                    </b>
                                  </td>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {items.map((row, rowIndex) => {
                              return (
                                <tr
                                  key={rowIndex}
                                  className={rowIndex % 2 ? "dark" : ""}
                                >
                                  {columns.map((column, colmnIndex) => {
                                    if (column.id === "qty" && row.start_hour) {
                                      return (
                                        <td
                                          key={colmnIndex}
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          {row.qty > 0
                                            ? Math.round(row.qty * 100) / 100 +
                                              " óra"
                                            : ""}
                                          ,{" "}
                                          {dateHelper.unixToTime(
                                            row.start_hour
                                          )}
                                          -{dateHelper.unixToTime(row.end_hour)}
                                        </td>
                                      );
                                    }
                                    return (
                                      <td key={colmnIndex}>
                                        {getColumn(row, column)}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        * Egyéb tételek: Jutalmak, előlegek, levonások
                        <h2>
                          Fizetendő bér: {formatHelper.currency(item.amount)}
                        </h2>
                      </div>
                    );
                  },
                  /*onIconClick: containerId => {
                    console.log("clicked", containerId);
                    printPageArea(containerId);
                  },*/
                  fetchData: item => {
                    const params = {
                      filter: [["t.user_id", "exact", item.user_id]].concat(
                        this.filter() || []
                      ),
                      group: "t.day",
                      groupname: "t.day"
                    };
                    return restHelper.index("reports/pays-with-costs", params);
                  },
                  columns: [
                    { id: "name", type: "unixDate", name: "Nap" },
                    { id: "amount", type: "currency", name: "Összeg" },
                    {
                      id: "qty",
                      name: "Óra",
                      value: (item, classes) => {
                        /*return item.qty > 0
                          ? Math.round(item.qty * 100) / 100 + " óra"
                          : "";*/

                        return (
                          <div className={classes.cell}>
                            <div className={classes.dateDays}>
                              {item.qty > 0
                                ? Math.round(item.qty * 100) / 100 + " óra"
                                : ""}
                            </div>
                            <div className={classes.dateRange}>
                              <div>
                                {dateHelper.unixToTime(item.start_hour)}
                              </div>
                              <div>{dateHelper.unixToTime(item.end_hour)}</div>
                            </div>
                          </div>
                        );
                      }
                    },
                    {
                      id: "cost_name",
                      name: "Indoklás",
                      value: (item, classes) => {
                        if (!item.description) {
                          return item.cost_name;
                        }
                        return (
                          <div className={classes.cell}>
                            <div className={classes.rows}>
                              <div>{item.cost_name}</div>
                              <div>{item.description}</div>
                            </div>
                          </div>
                        );
                      }
                    },
                    { id: "project_name", name: "Projekt" },
                    { id: "foreman_name", name: "Felelős" }
                  ]
                }
              ]
            }
          }
        ]
      }
    ];
  }

  onChangeSubcontractor = data => {
    this.setState({
      subcontractor: data.subcontractor,
      subcontractor_id: data.subcontractor_id,
      time: new Date().getTime()
    });
  };

  filter = () => {
    const dates = this.parseValue(this.state.dates);
    let filter = [];
    if (dates) {
      filter.push(["t.day", "between", [dates.start.unix(), dates.end.unix()]]);
    }

    if (this.state.subcontractor) {
      filter.push(["t.subcontractor_id", "in", this.state.subcontractor_id]);
    }

    return filter.length > 0 ? filter : null;
  };

  parseValue = value => {
    if (typeof value === "string") {
      return dateHelper.getRange(value);
    } else {
      return value;
    }
  };

  onChange = (value, rangeKey) => {
    const v = rangeKey ? rangeKey : value;
    const vs = rangeKey
      ? rangeKey
      : v
      ? { start: v.start.unix(), end: v.end.unix() }
      : null;

    if (vs) {
      sessionStorage.setItem("pays", JSON.stringify({ dates: vs }));
    } else {
      sessionStorage.removeItem("pays");
    }
    this.setState({
      dates: v,
      time: new Date().getTime()
    });
  };

  render() {
    const { classes, theme } = this.props;
    const { subcontractor } = this.state;
    const column = {
      id: "subcontractor_id",
      type: "select",
      search: "subcontractor.name",
      access: "w",
      name: "Alvállalkozó",
      datasource: {
        controller: "customers",
        objectField: "subcontractor",
        valueField: "id",
        search: ["customer.name"],
        filter: [
          ["customer.active", "exact", true],
          ["customer.subcontractor", "exact", true]
        ],
        sort: { "customer.name": "asc" },
        label: "name"
      }
    };

    return (
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={12} sm={6} md={5} lg={4} xl={3}>
          <div
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 8,
              marginBottom: -16
            }}
          >
            <DatePicker
              key={this.state.time}
              onChange={this.onChange}
              value={this.state.dates}
              selectionType="range"
              numberOfCalendars={2}
              label="Időszak"
              singleDateRange={true}
              firstOfWeek={1}
            />
          </div>
        </Grid>
        <Grid item={true} xs={12} sm={6} md={7} lg={8} xl={9}>
          <div
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 8,
              marginBottom: -16
            }}
          >
            <FormSelectField
              id={column.id}
              name={column.name}
              options={column.options}
              datasource={column.datasource}
              value={subcontractor ? subcontractor.id : null}
              column={column}
              isDeleted={false}
              currentValue={subcontractor}
              error={null}
              onChange={this.onChangeSubcontractor}
              classes={classes}
              theme={theme}
              item={{}}
              dirty={false}
              isMulti={true}
            />
          </div>
        </Grid>
        <Grid item={true} xs={12} md={12}>
          <DataPreview
            key={this.state.time}
            sections={this.state.sections}
            time={this.state.time}
            id={"pays"}
            noContainer={true}
            //buttons={buttons}
            //item={item}
          />
        </Grid>
      </Grid>
    );
  }
}

Pays.propTypes = {};

export default withStyles(dataFormStyle, { withTheme: true })(Pays);
