import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import dataFormStyle from "common/assets/style/dataFormStyle";
import dateHelper from "common/utils/dateHelper";
//import formatHelper from "common/utils/formatHelper";
import DataPreviewOld from "common/components/DataPreview/DataPreviewOld";
import Grid from "@material-ui/core/Grid";
//import strings from "variables/strings.json";
import restHelper from "common/utils/restHelper";
import formatHelper from "common/utils/formatHelper";
const unitsDict = {
  1: "Óra",
  2: "Darab",
  3: "Méter",
  4: "Négyzetméter",
  5: "Köbméter"
};

/**
 * Munkalapok kezelőfelülete
 */
class WorksheetPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      sections: []
    };
  }

  componentDidMount() {
    const { user } = this.props;
    let sections = [];
    const params = {
      filter: [["worksheet_id", "exact", this.props.item.id]],
      pagesize: 1000
    };
    restHelper.index("worksheet-users", params).then(response => {
      sections.push({
        type: "table",
        title: "Jelenléti ív",
        data: response.data,
        columns: [
          { id: "user.name" },
          {
            id: "time",
            value: item => {
              let diff = item.end_hour - item.start_hour;
              let hours = Math.round((diff / 3600) * 100) / 100;

              hours = hours === 0 ? 24 : hours;
              hours = hours < 0 ? 24 + hours : hours;

              return (
                dateHelper.unixToTime(item.start_hour) +
                "-" +
                dateHelper.unixToTime(item.end_hour) +
                " (" +
                hours +
                " óra)"
              );
            }
          },
          {
            id: "hourly_price_billable",
            type: "currency",
            value: item => {
              let diff = item.end_hour - item.start_hour;
              let hours = Math.round((diff / 3600) * 100) / 100;

              hours = hours === 0 ? 24 : hours;
              hours = hours < 0 ? 24 + hours : hours;
              const p = hours * item.hourly_price_billable;
              return p > 0 ? formatHelper.currency(p) : "";
            },
            visible: user.accessField(
              "worksheet-users",
              "hourly_price_billable"
            )
          }
        ] /*,
        fieldAccessHandler: column => {
          return user.accessField("worksheet-users", column.id);
        }*/
      });

      restHelper.index("worksheet-tasks", params).then(response => {
        sections.push({
          type: "table",
          title: "Tételek",
          data: response.data,
          columns: [
            { id: "task_number" },
            { id: "task.name" },
            {
              id: "qty",
              value: item => {
                return item.qty + " " + unitsDict[item.task.unit];
              }
            },
            {
              id: "price",
              type: "currency",
              visible: user.accessField("worksheet-tasks", "price"),
              value: item => {
                const p = item.price * item.qty;
                return p > 0 ? formatHelper.currency(p) : "";
              }
            }
          ] /*,
          fieldAccessHandler: column => {
            return user.accessField("worksheet-tasks", column.id);
          }*/
        });

        restHelper.index("user-costs", params).then(response => {
          sections.push({
            type: "table",
            title: "Költségek",
            data: response.data,
            columns: [
              { id: "cost.name" },
              { id: "amount", type: "currency" }
            ] /*,
            fieldAccessHandler: column => {
              return user.accessField("user-costs", column.id);
            }*/
          });

          restHelper.index("worksheet-vehicles", params).then(response => {
            sections.push({
              type: "table",
              title: "Járművek",
              data: response.data,
              columns: [
                { id: "vehicle.platenumber" },
                {
                  id: "qty",
                  type: "currency",
                  value: item => {
                    return item.qty + " nap";
                  }
                },
                {
                  id: "price",
                  type: "currency",
                  visible: user.accessField("worksheet-vehicles", "price"),
                  value: item => {
                    const p = item.price * item.qty;
                    return p > 0 ? formatHelper.currency(p) : "";
                  }
                }
              ] /*,
              fieldAccessHandler: column => {
                return user.accessField("user-costs", column.id);
              }*/
            });

            this.setState({ sections: sections });
          });
        });
      });
    });
  }

  changeWorksheetStatus = status => {
    let item = Object.assign({}, this.props.item, { status: status });
    restHelper
      .update("worksheets", this.props.item.id, item)
      .then(updatedItem => {
        this.props.afterSave(updatedItem);
      });
  };

  render() {
    const { /*classes,*/ item, user } = this.props;

    /*const { value } = this.state;
    const isNew = item.id ? false : true;*/
    let buttons = [];
    if (
      item.status === 1 &&
      (user.accessFunction("worksheet_send") ||
        user.accessFunction("worksheet_accept"))
    ) {
      buttons.push({
        title: "Bead",
        onClick: () => this.changeWorksheetStatus(2)
      });
    }
    if (item.status === 2 && user.accessFunction("worksheet_accept")) {
      buttons.push({
        title: "Jóváhagy",
        onClick: () => this.changeWorksheetStatus(3)
      });
      buttons.push({
        title: "Elvet",
        onClick: () => this.changeWorksheetStatus(1),
        color: "secondary",
        style: { float: "right" }
      });
    }

    return (
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={12} md={12}>
          <DataPreviewOld
            sections={this.state.sections}
            buttons={buttons}
            item={item}
          />
        </Grid>
      </Grid>
    );
  }
}

WorksheetPreview.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  //definition: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  //onCancel: PropTypes.func.isRequired,
  afterSave: PropTypes.func.isRequired
};

export default withStyles(dataFormStyle)(WorksheetPreview);
