import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Link from "@material-ui/core/Link";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/MoreHoriz";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/RestoreFromTrash";
import Tooltip from "@material-ui/core/Tooltip";
import settings from "variables/settings";
import { Link as RLink } from "react-router-dom";

const GridActionCell = props => {
  const {
    events,
    item,
    access,
    hideEditButton,
    primaryKey,
    buttons,
    refreshList
  } = props;

  if (!item[primaryKey]) {
    return <div style={{ height: 22 }} />;
  }
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {buttons &&
        buttons.map((button, buttonIndex) => {
          return (
            <Tooltip
              key={buttonIndex}
              title={button.title}
              disableFocusListener={true}
            >
              <IconButton
                component={button.to ? RLink : button.href ? Link : undefined}
                color={settings.actionColor}
                to={button.to ? button.to(item) : undefined}
                href={button.href ? button.href(item) : undefined}
                onClick={evt => {
                  evt.stopPropagation();
                  button.onClick && button.onClick(item, refreshList);
                }}
                style={{ padding: 4, marginTop: -9, marginBottom: -5 }}
              >
                <button.icon style={{ width: 20, height: 20 }} />
              </IconButton>
            </Tooltip>
          );
        })}
      {access.indexOf("w") >= 0 && !item.deleted_at ? (
        <Tooltip title="Szerkesztés" disableFocusListener={true}>
          <IconButton
            color={settings.actionColor}
            onClick={evt => {
              evt.stopPropagation();
              return events.onEdit(item);
            }}
            style={{ padding: 4, marginTop: -9, marginBottom: -5 }}
          >
            <EditIcon style={{ width: 20, height: 20 }} />
          </IconButton>
        </Tooltip>
      ) : !hideEditButton ? (
        <Tooltip title="Részletes nézet" disableFocusListener={true}>
          <IconButton
            color={settings.actionColor}
            onClick={evt => {
              evt.stopPropagation();
              return events.onEdit(item, "view");
            }}
            style={{ padding: 4, marginTop: -9, marginBottom: -5 }}
          >
            <ViewIcon style={{ width: 20, height: 20 }} />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
      {item.deleted_at ? (
        <Tooltip title="Visszaállítás" disableFocusListener={true}>
          <IconButton
            color={settings.actionColor}
            onClick={evt => {
              evt.stopPropagation();
              return events.onRestore(item);
            }}
            style={{ padding: 4, marginTop: -9, marginBottom: -5 }}
          >
            <RestoreIcon style={{ width: 20, height: 20 }} />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
      {access.indexOf("x") >= 0 ? (
        <Tooltip
          title={item.deleted_at ? "Végleges törlés" : "Törlés"}
          disableFocusListener={true}
        >
          <IconButton
            color={settings.actionColor}
            onClick={evt => {
              evt.stopPropagation();
              return events.onDelete(item);
            }}
            style={{ padding: 4, marginTop: -9, marginBottom: -5 }}
          >
            <DeleteIcon style={{ width: 20, height: 20 }} />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
    </div>
  );
};

GridActionCell.propTypes = {
  events: PropTypes.object.isRequired, //Eeseménykezelő függvények
  item: PropTypes.object.isRequired, //Adott sor adatai
  access: PropTypes.string.isRequired, //hozzáférés rwax read, write, append, x:delete
  hideEditButton: PropTypes.bool,
  buttons: PropTypes.array,
  primaryKey: PropTypes.string
};

export default GridActionCell;
