import { emphasize } from "@material-ui/core/styles/colorManipulator";

const dataFormStyle = theme => ({
  "@global": {
    "div.jsoneditor": {
      //borderRadius: 4,
      border: "none" //theme.palette.primary.main,
      //border: "solid 3px transparent"
    },
    "div.jsoneditor-menu": {
      backgroundColor: theme.palette.primary.main
    }
  },
  jsonContainerError: {
    borderRadius: 4,
    border: "solid 2px red",
    "&>div": {
      position: "relative",
      height: "100%"
    }
  },
  jsonContainerDirty: {
    borderRadius: 4,
    border: "solid 2px " + theme.palette.yellow.main,
    "&>div": {
      position: "relative",
      height: "100%"
    }
  },
  jsonContainer: {
    borderRadius: 4,
    border: "solid 2px rgba(0, 0, 0, 0.23)",
    "&>div": {
      position: "relative",
      height: "100%"
    }
  },
  error: {
    color: "red",
    textAlign: "center",
    marginTop: 100
  },
  formControl: {
    marginTop: theme.spacing(2) - 1,
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  input: {
    display: "flex",
    //padding: 0
    padding: "14.5px 10px"
  },
  selectInput: {
    display: "flex",
    //padding: 0
    //padding: "10px 14px",
    //height: "36px"
    padding: "14.5px 10px"
  },
  selectInputCloneChanged: {
    //"& fieldset": {
    backgroundColor: "rgba(216,67,21, 0.1)",
    borderRadius: 4
    //}
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    alignContent: "center",
    //marginRight: -136,
    width: "calc(100% - 93px);",
    "& input": {
      color: `${theme.palette.text.primary}!important`
    }
    //overflow: "hidden"
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  selectOption: {
    "&:hover": {
      backgroundColor: theme.palette.primary.light + "!important",
      color: `${theme.palette.getContrastText(
        theme.palette.primary.light
      )}!important`
      //color: "white!important"
    }
  },
  selectOptionContainer: {
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  singleValue: {
    //fontSize: 16,
    display: "flex",
    flex: "0 1",
    //flexBasis: "100%",
    maxWidth: "calc(100% - 10px)",
    //whiteSpace: "nowrap",
    //overflow: "hidden",
    //textOverflow: "ellipsis",
    "& div.description": {
      display: "none"
      /*textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap!important"*/
    },
    "& div.select-value-container": {
      minWidth: 0,
      //width: "calc(100% - 136px)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  singleValueDisabled: {
    //fontSize: 16,
    color: theme.palette.text.disabled,
    display: "flex",
    flex: "0 1",
    //flexBasis: "100%",
    maxWidth: "calc(100% - 10px)",
    //flex: 1,
    //width: "calc(100% - 136px)",
    //whiteSpace: "nowrap",
    //overflow: "hidden",
    //textOverflow: "ellipsis",
    "& div.description": {
      display: "none"
      /*textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap!important"*/
    },
    "& div.select-value-container": {
      minWidth: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  placeholder: {
    position: "absolute",
    left: 2
    //fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 1000,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  formContainer: {
    padding: theme.spacing(3)
  },
  toolBarContainer: {
    display: "flex"
  },
  toolBarLeft: {
    flex: 1,
    marginTop: 10
  },
  toolBarRight: {
    flex: 1
  },

  title: {
    marginTop: theme.spacing(1.8),
    fontSize: 16,
    verticalAlign: "middle"
  },

  footerTypo: {
    color: theme.palette.primary.light
    /*paddingLeft: 5,
    paddingright: 5*/
  },
  notice: {
    padding: theme.spacing(3),
    display: "flex"
    //color: theme.palette.secondary.dark,
  },
  saveButton: {
    marginTop: theme.spacing(1)
  },
  dirtyTextField: {
    "& fieldset": {
      borderColor: theme.palette.yellow.main + "!important"
      //borderColor: "#FFAB00!important"
      //borderStyle: "dotted!important"
    }
    //"& fieldset": { borderColor: theme.palette.yellow.main + "!important" }
  },
  snackbarRoot: {
    backgroundColor: theme.palette.error.main,
    marginBottom: theme.spacing(3)
  },
  checkBoxLabel: {
    borderRadius: 5,
    border: "solid 1px",
    borderColor:
      theme.palette.type === "dark"
        ? "rgba(255, 255, 255, 0.23)"
        : "rgba(0, 0, 0, 0.23)",
    marginLeft: 0,
    marginRight: 0,
    paddingRight: 16,
    paddingTop: 2,
    paddingBottom: 2,
    //height: 50,
    //width: "100%"
    "&:hover": {
      borderColor:
        theme.palette.type === "dark" ? "#fff" : "rgba(0, 0, 0, 0.87)"
    }
  },
  checkBoxLabelError: {
    borderColor: "#f44336",
    "& span": {
      color: "#f44336"
    },
    "&:hover": {
      borderColor: "#f44336"
    }
  },
  tabBadge: {
    transform: "scale(1) translate(100%, -40%)",
    fontWeight: "normal",
    fontSize: 8,
    lineHeight: 8,
    height: 14,
    minWidth: 14,
    right: 5
  },
  tabBadgeInvisible: {
    transform: "scale(0) translate(100%, -40%)",
    fontWeight: "normal",
    fontSize: 8,
    lineHeight: 8,
    height: 14,
    minWidth: 14,
    right: 5
  },
  fileInputRoot: {
    paddingRight: 0
  },
  viewTableBody: {
    "& td": {
      fontSize: "inherit"
    }
  },
  backdrop: {
    display: "flex",
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor:
      theme.palette.type === "dark"
        ? "rgba(0,0,0,0.2)"
        : "rgba(255,255,255,0.35)"
  }
});

export default dataFormStyle;
