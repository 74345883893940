import React from "react";
import DataGridForm from "common/components/DataGrid/DataGridForm";
import ServiceWorksheets from "views/Service/ServiceWorksheets";
import ServiceWorksheetParts from "views/Service/ServiceWorksheetParts";
import ServiceWorksheetTasks from "views/Service/ServiceWorksheetTasks";

const ServiceVehicle = props => (
  <DataGridForm
    {...props}
    tabs={{
      id: "service-vehicles",
      documents: true,
      histories: true,
      items: {
        "service-worksheets": {
          itemKey: "service_vehicle_id",
          component: ServiceWorksheets,
          filterable: true
          //badgeContent: "id"
        },
        "service-worksheet-parts": {
          itemKey: "service_vehicle_id",
          component: ServiceWorksheetParts,
          filterable: true
        },
        "service-worksheet-tasks": {
          itemKey: "service_vehicle_id",
          component: ServiceWorksheetTasks,
          filterable: true
        }
      }
    }}
  />
);

//ServiceVehicle.propTypes = DataGridForm.propTypes;

export default ServiceVehicle;
