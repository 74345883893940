//import jwtDecode from "jwt-decode";
//import access from "variables/access.json";

const userHelper = class {
  constructor(user) {
    this.setUser(user);
  }

  isGuest = () => {
    return this.data.id ? true : false;
  };

  resetUser = () => {
    this.data = {};
    localStorage.removeItem("token");
  };

  accessMenu = slug => {
    return this.data.rules
      ? slug && this.data.rules.menu[slug.substring(1)] === "r"
      : false;
  };

  accessFunction = funcName => {
    return this.data.rules.functions[funcName] === "r";
  };

  accessController = controller => {
    const controllerRules = this.data.rules.controllers[controller];
    if (!controllerRules) {
      return false;
    }
    if (!controllerRules.access) {
      return false;
    }
    return controllerRules.access;
  };

  accessField = (controller, field) => {
    const controllerRules = this.data.rules.controllers[controller];
    if (!controllerRules) {
      return false;
    }
    if (!controllerRules.fields) {
      return false;
    }
    if (!controllerRules.fields[field]) {
      return false;
    }

    return controllerRules.fields[field];
  };

  setUser = user => {
    this.data = Object.assign({}, user ? user : {});
    if (this.data.auth_token) {
      localStorage.setItem("token", user.auth_token);
    } /*else {
      localStorage.removeItem("token");
    }*/
  };

  can = roles => {
    return roles ? roles.indexOf(this.data.role) !== -1 : false;
  };

  validateJsToken = (key, type) => {
    let token = null;
    if (type === "local") {
      token = localStorage.getItem(key);
    } else {
      token = sessionStorage.getItem(key);
    }
    if (token) {
      token = JSON.parse(window.atob(token));
      return token.expires && parseInt(token.expires) >= new Date().getTime();
    }
    return false;
  };

  createJsToken = (key, interval, type) => {
    let _interval = interval ? interval : 3600;
    const token = window.btoa(
      JSON.stringify({
        expires: new Date().getTime() + _interval * 1000
      })
    );
    if (type === "local") {
      localStorage.setItem(key, token);
    } else {
      sessionStorage.setItem(key, token);
    }
  };

  removeJsToken = (key, type) => {
    if (type === "local") {
      type = localStorage.removeItem(key);
    } else {
      type = sessionStorage.removeItem(key);
    }
  };
};

export default userHelper;
