import React from "react";
import PropTypes from "prop-types";
import DashboardProject from "views/Dashboard/DashboardProject";
import Grid from "@material-ui/core/Grid";
import restHelper from "common/utils/restHelper";
import ChartistGraph from "react-chartist";
import { Paper, Typography } from "@material-ui/core";
import EmptyIcon from "@material-ui/icons/RadioButtonUnchecked";

class DashboardProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      sumItem: {},
      fetched: false,
      loading: true
    };
  }

  fetchData = () => {
    if (this.state.fetched) {
      return;
    }

    const params = {
      group: "project_id",
      groupname: "name",
      filter: [["t.project_id", "nte", null], ["t.active", "exact", 1]].concat(
        this.props.filter || []
      )
    };
    const paramsSum = {
      //group: "project_id",
      //groupname: "name",
      filter:
        this.props.filter ||
        [] /*[
        // ["t.project_id", "nte", null],
        //["t.active", "exact", 1]
      ]*/
    };
    restHelper.index("reports/income", params).then(response => {
      restHelper.index("reports/income", paramsSum).then(responseSum => {
        this.setState({
          items: response.data,
          sumItem: responseSum.data[0],
          fetched: true
        });
      });
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  prepareProjects = () => {
    const { items } = this.state;
    let labels = [];
    let series = [];
    let sum = 0.0;
    items.map(item => {
      if (parseFloat(item.amount) > 0) {
        sum += parseFloat(item.amount);
      }
      return 1;
    });

    items.map(item => {
      if (parseFloat(item.amount) > 0) {
        labels.push(
          Math.round((parseFloat(item.amount) * 100) / sum) + "% " + item.name
        );
        series.push(parseFloat(item.amount));
      }
      return 1;
    });

    return {
      labels: labels,
      series: series
    };
  };

  render() {
    const { items, sumItem } = this.state;

    const data = this.prepareProjects();
    //var sum = function(a, b) { return a + b };
    var options = {
      height: "400px",
      donut: true,
      donutWidth: 60,
      //donutSolid: true,
      startAngle: 270,
      showLabel: true
      /*labelInterpolationFnc: function(value, idx) {
        var percentage =
          Math.round((value / data.series.reduce(sum)) * 100) + "%";
        return animals[idx] + " " + percentage;
      }*/
    };
    if (!items || items.length === 0) {
      return (
        <Paper
          style={{
            marginTop: 32,
            marginBottom: 32,
            marginLeft: 16,
            marginRight: 16,
            padding: 32,
            display: "flex",
            alignContent: "center",
            alignItems: "center"
          }}
        >
          <EmptyIcon
            color="disabled"
            style={{
              //marginTop: 2,
              marginRight: 8
            }}
          />
          <Typography color="textSecondary" variant="body2">
            Nincs elég adat a kimutatáshoz.
          </Typography>
        </Paper>
      );
    }
    return (
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={12} md={12}>
          {items.map((project, key) => {
            return (
              <Paper
                key={key}
                style={{
                  marginTop: 32,
                  marginBottom: 32,
                  marginLeft: 16,
                  marginRight: 16,
                  padding: 32
                }}
              >
                <DashboardProject
                  project={project}
                  filter={this.props.filter}
                />
              </Paper>
            );
          })}
          <Paper
            style={{
              marginTop: 32,
              marginBottom: 32,
              marginLeft: 16,
              marginRight: 16,
              padding: 32
            }}
          >
            <DashboardProject
              project={sumItem}
              filter={this.props.filter}
              //key={JSON.stringify(this.props.filter)}
            />
          </Paper>
          <Paper
            style={{
              marginTop: 32,
              marginBottom: 32,
              marginLeft: 16,
              marginRight: 16,
              padding: 32
            }}
          >
            <Typography variant="h6">Haszon eloszlása projektenként</Typography>
            <ChartistGraph
              data={data}
              options={options}
              type="Pie"
              //style={{ /*padding: 32,*/ paddingBottom: 50 }}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

DashboardProjects.propTypes = {
  filter: PropTypes.array
};

export default DashboardProjects;
