import React from "react";
import SendIcon from "@material-ui/icons/Send";
import WorksheetCloneIcon from "@material-ui/icons/FileCopy";
import restHelper from "common/utils/restHelper";
import settings from "variables/settings";
import WorksheetCloneModal from "views/Worksheets/WorksheetCloneModal";

export default {
  "service-worksheets-old": [
    {
      id: "service-worksheets-pdf",
      tooltip: "Munkalap letöltése",
      onClick: (evt, { data, grid }) => {
        //console.log("Click", evt, data, grid);
        //console.log("Click.grid", grid);
        grid.setLoading("service-worksheets-pdf", true);
        restHelper.action("service-worksheets", "pdf", 1).then(res => {
          //console.log("services.service-worksheets-pdf.res", res);
          //grid.setEditItem(res);
          grid.setLoading("service-worksheets-pdf", false);
        });
        //this.setState({viewMode: !this.state.viewMode})
        //this.exportPdf("base");
      },
      onlyForm: true,
      Icon: SendIcon,
      color: settings.actionColor
    }
  ],
  worksheets: [
    {
      id: "worksheet-duplicate",
      tooltip: "Új munkalap készítése a jelenlegi alapján",
      modalComponent: WorksheetCloneModal,
      onlyForm: true,
      Icon: WorksheetCloneIcon,
      buttonProps: ({ data }) => {},
      /*success: ({ data }) => {
        return data.proposal_send_date ? true : false;
      },*/
      color: settings.actionColor,
      overlayLoading: true,
      //yesText: "Ajánlat kiküldése",
      noText: "Bezár"
      //access: ACTIONS.SEND_PROPOSAL
    }
  ]
};
