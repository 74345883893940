import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { TableRow, TableCell, Typography, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import settings from "variables/settings";

const styles = theme => ({
  header: {
    paddingLeft: 0
  },
  label: {
    padding: 0,
    height: 36,
    paddingRight: 8,
    [theme.breakpoints.down("sm")]: {
      width: "40%"
    },
    [theme.breakpoints.up("md")]: {
      //whiteSpace: "nowrap",
      width: "20%"
    }
  },
  container: {
    borderBottom: `solid 1px ${theme.palette.divider}`
    //marginLeft: theme.spacing(4),
    //marginRight: theme.spacing(4)
  },
  value: {
    fontWeight: 500
  },
  valueNoSpace: {
    fontWeight: 500,
    wordBreak: "break-all",
    msWordBreak: "break-all"
  },
  link: {
    color:
      theme.palette.type === "dark"
        ? theme.palette.text.primary
        : settings.actionColor === "primary"
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  }
});

const DataViewField = ({
  classes,
  label,
  value,
  children,
  header,
  size,
  to,
  noBorderBottom
}) => {
  const terms = [" ", ".", "-", "!", "?"];
  const isOneWord =
    value &&
    !children &&
    !terms.map(term => String(value).includes(term)).includes(true);
  return (
    <Fragment>
      {header && (
        <TableRow>
          <TableCell colSpan={2} className={classes.header}>
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              {header}
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {/*<TableRow>
        <TableCell className={classes.label}>
          <Typography variant="body1" color="textSecondary">
            {label}
          </Typography>
        </TableCell>
        <TableCell>{children ? children : value}</TableCell>
      </TableRow>*/}
      <Grid
        container
        className={noBorderBottom ? undefined : classes.container}
      >
        {label && (
          <Grid item xs={6} sm={size === 12 ? 3 : 6} lg={size === 12 ? 2 : 6}>
            <Typography variant="button" color="textSecondary">
              {label}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={label ? 6 : 12}
          sm={label ? (size === 12 ? 9 : 6) : 12}
          lg={label ? (size === 12 ? 10 : 6) : 12}
          className={isOneWord ? classes.valueNoSpace : classes.value}
        >
          {to ? (
            <Link to={to} className={classes.link}>
              {children ? children : value}
            </Link>
          ) : children ? (
            children
          ) : (
            value
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

DataViewField.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  value: PropTypes.any,
  header: PropTypes.string,
  children: PropTypes.any,
  label: PropTypes.string,
  to: PropTypes.string,
  size: PropTypes.number,
  noBorderBottom: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(DataViewField);
