import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
//import TablePagination from '@material-ui/core/TablePagination';
import TableRow from "@material-ui/core/TableRow";
import restHelper from "common/utils/restHelper";
import { CircularProgress } from "@material-ui/core";

const numberFormat = value => (value ? Math.round(value * 100) / 100 : null);

const baseStyles = theme => ({
  fontSize: 10,
  borderCollapse: "collapse",
  border: `1px solid ${theme.palette.grey[300]}`,
  "& th": {
    border: `1px solid ${theme.palette.grey[300]}`,
    textAlign: "center",
    fontSize: "10px"
  },
  "& tr:nth-child(2n+2)": {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[700]
        : theme.palette.grey[100]
  },
  "& tr:hover": {
    backgroundColor: theme.palette.primary.main,
    "& td": {
      color: "white!important"
    }
  },
  "& td": {
    border: `1px solid ${
      theme.palette.type === "dark"
        ? theme.palette.grey[700]
        : theme.palette.grey[300]
    }`,
    textAlign: "center",
    paddingLeft: 2,
    paddingRight: 2,
    "&:nth-child(1)": {
      textAlign: "left",
      whiteSpace: "nowrap"
    }
  }
});

const styles = theme => ({
  wrapper: {
    //maxHeight: 500,
    overflow: "auto",
    position: "relative"
    //width: `calc(100% - ${theme.spacing(4)}px)`,
  },
  loaderWrapper: {
    padding: theme.spacing(5),
    textAlign: "center"
  },
  loaderWrapper2: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    backgroundColor:
      theme.palette.type === "dark"
        ? "rgba(0,0,0,0.5)"
        : "rgba(255,255,255,0.5)"
  },
  table: {
    ...baseStyles(theme)
  },
  tableGrouped: {
    ...baseStyles(theme),
    "& tr:nth-child(1) td": {
      color: theme.palette.primary.main
    },
    "& td:nth-child(2)": {
      color: theme.palette.primary.main
    }
  }
});

class SimpleTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false
    };
  }

  componentDidMount() {
    const { filter, grouped, agr } = this.props;
    this.loadData(filter, grouped, agr);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { filter: oldFilter, grouped: oldGrouped, agr: oldAgr } = this.props;
    const { filter, grouped, agr } = nextProps;
    if (
      JSON.stringify(filter) !== JSON.stringify(oldFilter) ||
      JSON.stringify(grouped) !== JSON.stringify(oldGrouped) ||
      JSON.stringify(agr) !== JSON.stringify(oldAgr)
    ) {
      this.loadData(filter, grouped, agr);
    }
  }

  loadData = (filter, grouped, agr) => {
    const { url } = this.props;
    this.setState({ loading: true });
    const params = {
      filter,
      grouped,
      agr
    };
    restHelper
      .index(url, params)
      .then(response => {
        this.setState({ data: response, loading: false });
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false });
      });
  };

  pivotColumns = rows => {
    if (!rows || rows.length === 0) {
      return [];
    }
    let columns = [
      { id: "name", label: "Név" },
      {
        id: "sum",
        label: "Összesen",
        format: numberFormat
      }
    ];
    Object.keys(rows[0]).forEach(key => {
      if (["id", "name"].indexOf(key) === -1) {
        columns.push({
          id: key,
          label: key.substring(5, 10),
          format: numberFormat
        });
      }
    });
    console.log(columns);
    return columns;
  };

  addSum = rows => {
    if (!rows || rows.length === 0) {
      return [];
    }
    let result = [];
    let sum = { id: "sum", name: "Összesen", sum: 0 };
    rows.forEach(r => {
      const row = Object.assign({}, r);
      row.sum = 0;

      Object.keys(row).forEach(key => {
        if (["id", "name", "sum"].indexOf(key) === -1) {
          if (row[key]) {
            row.sum += row[key];
          }
          if (!sum[key]) {
            sum[key] = 0;
          }
          sum[key] += row[key];
          sum["sum"] += row[key];
        }
      });
      result.push(row);
    });
    result.unshift(sum);

    //console.log(result)
    return result;
  };

  render() {
    const { classes, grouped, height } = this.props;
    const { data, loading } = this.state;
    const rows = grouped ? this.addSum(data) : data;
    const columns = grouped ? this.pivotColumns(data) : this.props.columns;
    /*if (loading) {
      return (
        <div className={classes.loaderWrapper}>
          <CircularProgress />
        </div>
      );
    }*/
    return (
      <div className={classes.wrapper}>
        {loading && (
          <div className={classes.loaderWrapper2}>
            <CircularProgress />
          </div>
        )}
        <Table
          stickyHeader
          size="small"
          padding="none"
          className={grouped ? classes.tableGrouped : classes.table}
        >
          {rows.length > 0 && (
            <TableHead>
              <TableRow>
                {columns.map((column, colIndex) => {
                  return <TableCell key={colIndex}>{column.label}</TableCell>;
                })}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow key={index}>
                  {columns.map((column, colIndex) => {
                    return (
                      <TableCell key={`${index}_${colIndex}`}>
                        {column.format
                          ? column.format(row[column.id])
                          : row[column.id]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array,
  columns: PropTypes.array,
  height: PropTypes.number,
  url: PropTypes.string.isRequired,
  filter: PropTypes.array,
  grouped: PropTypes.bool,
  agr: PropTypes.string
};

export default withStyles(styles)(SimpleTable);
