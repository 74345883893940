import React from "react";
//import ReactDOM from "react-dom";
import PropTypes from "prop-types";
//import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
//import FormHelperText from "@material-ui/core/FormHelperText";
//import FormControl from "@material-ui/core/FormControl";
//import Select from "@material-ui/core/Select";
//import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
//import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import ChangeIcon from "@material-ui/icons/FileCopy";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
//import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
//import { emphasize } from "@material-ui/core/styles/colorManipulator";
import classNames from "classnames";
import restHelper from "common/utils/restHelper";
//import AsyncSelect from "react-select/lib/Async";
import AsyncSelect from "react-select/async";
import withStyles from "@material-ui/core/styles/withStyles";
import dataFormStyle from "common/assets/style/dataFormStyle";
import { Tooltip } from "@material-ui/core";
import settings from "variables/settings";

function NoOptionsMessage(props) {
  return (
    <Typography
      component="div"
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const {
    label,
    error,
    value,
    inputValue,
    cloneChanged,
    onCloneRefreshClick,
    viewMode
  } = props.selectProps;
  return viewMode ? (
    <InputBase
      fullWidth
      {...{
        className: cloneChanged
          ? props.selectProps.classes.selectInputCloneChanged
          : undefined,
        endAdornment: cloneChanged ? (
          <Tooltip title="Megváltozott értékek frissítése">
            <IconButton
              color={settings.actionColor}
              style={{ marginLeft: -16 }}
              onClick={onCloneRefreshClick}
            >
              <ChangeIcon />
            </IconButton>
          </Tooltip>
        ) : (
          undefined
        ),
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.selectInput,
          style: { padding: 0 },
          inputRef: props.innerRef,
          children: props.children,
          //autoComplete: label + new Date().getTime(),
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
      label={label}
      //helperText={error || ""}
      error={error ? true : false}
      autoComplete="off"
    />
  ) : (
    <TextField
      disabled={props.isDisabled}
      fullWidth
      InputProps={{
        className: cloneChanged
          ? props.selectProps.classes.selectInputCloneChanged
          : undefined,
        endAdornment: cloneChanged ? (
          <Tooltip title="Megváltozott értékek frissítése">
            <IconButton
              color={settings.actionColor}
              style={{ marginLeft: -16 }}
              onClick={onCloneRefreshClick}
            >
              <ChangeIcon />
            </IconButton>
          </Tooltip>
        ) : (
          undefined
        ),
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.selectInput,
          inputRef: props.innerRef,
          children: props.children,
          //autoComplete: label + new Date().getTime(),
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
      InputLabelProps={{
        shrink:
          (value && (value.value || value.value === 0)) ||
          (value && Array.isArray(value) && value.length > 0) ||
          (inputValue && inputValue !== "") ||
          inputValue === 0 ||
          props.isFocused
            ? true
            : false
      }}
      //id={id}
      label={label}
      helperText={error || ""}
      error={error ? true : false}
      //value={value ? value.value : ""}
      //autoComplete={label + new Date().getTime()}
      autoComplete="off"
      //id={label}
      //onChange={evt => onChange(id, evt.target.value)}
      margin="normal"
      //variant="outlined"
    />
  );
}

function Option(props) {
  //console.log("render");
  const { onMouseMove, onMouseOver, ...newInnerProps } = props.innerProps;
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        color: props.isSelected
          ? props.selectProps.themeProject.palette.getContrastText(
            props.selectProps.themeProject.palette.primary.main
          )
          : props.selectProps.themeProject.palette.text.primary,
        backgroundColor: props.isSelected
          ? props.selectProps.themeProject.palette.primary.main
          : "none",
        height: "auto"
      }}
      {...newInnerProps}
      className={
        newInnerProps.className + " " + props.selectProps.classes.selectOption
      }
    >
      <div
        //className="select_option_container"
        className={props.selectProps.classes.selectOptionContainer}
      >
        {props.children}
      </div>
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      component="div"
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      component="div"
      className={
        props.isDisabled
          ? props.selectProps.classes.singleValueDisabled
          : props.selectProps.classes.singleValue
      }
      {...props.innerProps}
    >
      <div className="select-value-container">{props.children}</div>
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

class FormSelectField extends React.Component {
  state = {
    defaultOptions: null,
    currentValue: {
      value: null,
      label: null
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.obtainCurrentValue(nextProps);
  }

  componentDidMount() {
    this.obtainCurrentValue(this.props);
  }

  obtainCurrentValue = props => {
    const { currentValue, datasource } = props;
    if (currentValue) {
      let cval = datasource.valueField;
      if (datasource.valueField.indexOf(".") >= 0) {
        const va = datasource.valueField.split(".");
        cval = va[1];
      }

      if (Array.isArray(currentValue)) {
        let currentValues = [];
        currentValue.forEach(element => {
          let currentValuePrepared = null;
          //Ha nem eredeti adatbázis objektum, hanem már preparált currentvalue
          if (element._original) {
            currentValuePrepared = element;
          } else {
            currentValuePrepared = {
              value: element[cval],
              label:
                typeof datasource.label === "function"
                  ? datasource.label(element)
                  : element[datasource.label],
              _original: element
            };
          }
          currentValues.push(currentValuePrepared);
        });

        this.setState({
          currentValue: currentValues
        });
        return;
      }

      let currentValuePrepared = null;
      //Ha nem eredeti adatbázis objektum, hanem már preparált currentvalue
      if (currentValue._original) {
        currentValuePrepared = currentValue;
      } else {
        currentValuePrepared = {
          value: currentValue[cval],
          label:
            typeof datasource.label === "function"
              ? datasource.label(currentValue)
              : currentValue[datasource.label],
          _original: currentValue
        };
      }

      this.setState({
        currentValue: currentValuePrepared
      });
    } else {
      this.currentValue(props);
    }
  };

  currentValue = props => {
    //Előre megadott opcióknál feltöltjük a kezdeti értéket
    if (props.options) {
      const options =
        typeof props.options === "function"
          ? props.options(this.props.item)
          : props.options;

      let currentValue = { value: null, label: null };
      for (let i in options) {
        if (options[i].value === props.value) {
          currentValue = options[i];
        }
      }
      this.setState({
        currentValue: currentValue
      });
      return;
    }

    //Adatforrásnál kilépünk, ha nincs megadva
    if (!props.datasource) {
      this.setState({ currentValue: null });
      return;
    }

    const { controller, label, value } = props.datasource;

    //Adatforrásnál kilépünk, ha nincs érték megadva
    if (!props.value) {
      this.setState({ currentValue: null });
      return;
    }
    //Adatforrásnál elkérjük az aktuális értéket
    restHelper.view(controller, props.value).then(item => {
      this.setState({
        currentValue: {
          value: item[value],
          label: typeof label === "function" ? label(item) : item[label]
        }
      });
    });
  };

  handleInputChange = newValue => {
    const inputValue = newValue; //newValue.replace(/\W/g, "");
    this.setState({ inputValue });
    return inputValue;
  };

  getKey = () => {
    let filter = [];
    const fiXFilter = this.props.datasource.filter;
    if (fiXFilter && typeof fiXFilter === "function") {
      const fi = fiXFilter(this.props.item);
      for (let i in fi) {
        filter.push(fi[i]);
      }
    } else if (fiXFilter) {
      for (let i in fiXFilter) {
        filter.push(fiXFilter[i]);
      }
    }
    return `key-${this.props.id}-${JSON.stringify(filter)}`;
  };

  loadOptions = (inputValue, callback) => {
    const {
      controller,
      label,
      valueField,
      search,
      sort,
      requestParams
    } = this.props.datasource;
    const fiXFilter = this.props.datasource.filter;

    let filter = [];
    if (search) {
      for (let i in search) {
        if (i === "0") {
          filter.push([search[i], "contains", inputValue]);
        } else {
          if (!filter[0][3]) {
            filter[0].push([]);
          }

          filter[0][3].push([search[i], "contains", inputValue]);
        }
      }
    } else if (label && typeof label !== "function") {
      filter.push([label, "contains", inputValue]);
    }

    if (fiXFilter && typeof fiXFilter === "function") {
      const fi = fiXFilter(this.props.item);
      for (let i in fi) {
        filter.push(fi[i]);
      }
    } else if (fiXFilter) {
      for (let i in fiXFilter) {
        filter.push(fiXFilter[i]);
      }
    }

    let object = null;
    let cval = null;
    if (valueField.indexOf(".") >= 0) {
      const va = valueField.split(".");
      object = va[0];
      cval = va[1];
    }

    let params = { filter: filter, pagesize: 200 };
    if (sort) {
      params.sort = sort;
    }
    if (requestParams) {
      params.params = requestParams;
    }
    restHelper.index(controller, params).then(items => {
      const suggestions = items.data.map((item, key) => {
        const sItem = object ? item[object] : item;
        return {
          value: object ? sItem[cval] : item[valueField],
          label: typeof label === "function" ? label(sItem) : sItem[label],
          _original: sItem
        };
      });
      callback(suggestions);
    });
  };

  onChange = (id, currentValue) => {
    this.setState({ currentValue: currentValue });

    if (Array.isArray(currentValue) && this.props.isMulti) {
      let currentValues = [];
      let currentObjects = [];
      currentValue.forEach(element => {
        currentValues.push(element ? element.value : null);
        if (
          this.props.column.datasource &&
          this.props.column.datasource.objectField
        ) {
          currentObjects.push(element ? element._original : null);
        }
      });

      let changedItems = {
        [id]: currentValues.length > 0 ? currentValues : null
      };

      if (currentObjects.length > 0) {
        changedItems[this.props.column.datasource.objectField] = currentObjects;
      }
      this.props.onChange && this.props.onChange(changedItems);
    } else {
      let changedItems = {
        [id]: currentValue ? currentValue.value : null
      };

      if (
        this.props.column.datasource &&
        this.props.column.datasource.objectField
      ) {
        changedItems[this.props.column.datasource.objectField] = currentValue
          ? currentValue._original
          : null;
      }
      this.props.onChange && this.props.onChange(changedItems);
    }
  };

  onKeyDown = evt => {
    if (evt.key === "Enter") {
      return this.props.onSave ? this.props.onSave() : true;
    } else if (evt.key === "Escape") {
      if (this.props.onCancel) this.props.onCancel();
      return false;
    }
    return false;
  };

  render() {
    const {
      id,
      name,
      error,
      //value,
      //onChange,
      item,
      classes,
      options,
      datasource,
      column,
      theme,
      dirty,
      isDeleted,
      onSave,
      viewMode,
      required,
      isClearable,
      isMulti
    } = this.props;

    const dirtyStyle = {
      "& fieldset": {
        borderColor: theme.palette.yellow.main + "!important"
      }
    };
    const selectStyles = {
      input: base => ({
        ...base,
        //color: theme.palette.text.primary,
        "& input": {
          font: "inherit"
        }
      }),
      container: base => ({
        ...base,
        ...(dirty && !error ? dirtyStyle : {})
      }),
      indicatorsContainer: base => ({ ...base, cursor: "pointer" })
    };

    // sorrendezés előre megadott opciók esetén
    let sortedOptions = null;
    if (options) {
      sortedOptions =
        typeof options === "function" ? options(this.props.item) : options;

      if (!column.originalOrder) {
        sortedOptions.sort((a, b) => {
          return String(a.label).localeCompare(b.label);

          /*if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;*/
        });
      }
    }
    return options ? (
      <Select
        inputId={id}
        classes={classes}
        themeProject={theme}
        styles={selectStyles}
        components={components}
        value={this.state.currentValue}
        onKeyDown={this.onKeyDown}
        onChange={val => this.onChange(id, val)}
        options={sortedOptions}
        error={error}
        label={required ? `${name} *` : name}
        isClearable={isClearable === false ? false : true}
        isMulti={isMulti}
        viewMode={viewMode}
        placeholder=""
        isDisabled={column.access !== "w" || isDeleted ? true : false}
      />
    ) : datasource ? (
      <AsyncSelect
        inputId={id}
        key={this.getKey()}
        classes={classes}
        themeProject={theme}
        styles={selectStyles}
        components={components}
        value={this.state.currentValue}
        onKeyDown={this.onKeyDown}
        onChange={val => this.onChange(id, val)}
        isClearable
        isMulti={isMulti}
        error={error}
        viewMode={viewMode}
        onMenuOpen={() => {
          if (!this.state.defaultOptions) {
            this.loadOptions("", opts => {
              this.setState({ defaultOptions: opts });
            });
          }
        }}
        onCloneRefreshClick={evt => {
          //console.log("onCloneRefreshClick");
          onSave(true);
        }}
        cloneChanged={
          column.access === "w" &&
          !isDeleted &&
          item.changed_clones &&
          item.changed_clones[datasource.objectField]
            ? true
            : false
        }
        label={required ? `${name} *` : name}
        loadOptions={this.loadOptions}
        cacheOptions
        defaultOptions={this.state.defaultOptions || undefined}
        //filterOption={() => true}
        onInputChange={this.handleInputChange}
        placeholder=""
        isDisabled={column.access !== "w" || isDeleted ? true : false}
      />
    ) : (
      "No items defined"
    );
  }
}

FormSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  datasource: PropTypes.object,
  item: PropTypes.object,
  currentValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  column: PropTypes.object.isRequired,
  dirty: PropTypes.bool,
  isDeleted: PropTypes.bool,
  isMulti: PropTypes.bool
};

export default withStyles(dataFormStyle, { withTheme: true })(FormSelectField);
