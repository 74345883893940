import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import HistoryView from "./HistoryView";
import strings from "variables/strings.json";
import dateHelper from "common/utils/dateHelper";
class Histories extends React.Component {
  access = "";

  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("histories");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "histories",
        url: "histories",
        attributes: strings.attributes["histories"],
        condition: [
          {
            id: "item_id",
            value: this.props.item["id"]
          },
          {
            id: "slug",
            value: this.props.slug
          }
        ],
        columns: [
          {
            id: "user_id",
            type: "select",
            datasource: {
              controller: "users",
              objectField: "user",
              valueField: "id",
              label: "name",
              sort: { "user.name": "asc" },
              search: ["user.name"]
            }
          },
          {
            id: "created_at",
            type: "unixDateTime"
          },
          {
            id: "data",
            cell: item => {
              if (!item.original.data.map) {
                return "";
              }
              const keys = item.original.data.map(element => element.name);
              return keys.join(", ");
            }
          }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition, access } = this.state;
    const {
      user,
      filterable,
      viewMode,
      showPaginationTop,
      showPaginationBottom,
      viewOnRowClick,
      height,
      isDetailView,
      onChildEdit,
      onChildCancelEdit,
      breadcrumbs
    } = this.props;
    //const { classes } = this.props;

    return (
      <DataGrid
        user={user}
        onEdit={onChildEdit}
        onCancelEdit={onChildCancelEdit}
        breadcrumbs={breadcrumbs}
        definition={definition}
        startEditRef={ref => (this.startEdit = ref)}
        //FormFooter="CreatedUpdated"
        ViewComponent={HistoryView}
        fieldAccessHandler={column => {
          return "r"; //user.accessField("histories", column.id);
        }}
        defaultSort={[{ id: "created_at", desc: true }]}
        access={access}
        filterable={filterable}
        viewMode={viewMode}
        showPaginationTop={showPaginationTop}
        showPaginationBottom={showPaginationBottom}
        height={height}
        viewOnRowClick={viewOnRowClick}
        isDetailView={isDetailView}
        title={item => {
          if (!item) {
            return "";
          } else if (item.id) {
            return `${item.user.name} - ${dateHelper.unixToDateTime(
              item.created_at
            )}`;
          } else {
            return "Új Módosítás";
          }
        }}
        /*toolbarButtons={[
          {
            tooltip: "Letöltés",
            onClick: () => {},
            Icon: DownloadIcon,
            color: "primary"
          }
        ]}*/
      />
    );
  }
}

Histories.defaultProps = {
  filterable: false,
  viewMode: false,
  showPaginationBottom: true,
  showPaginationTop: false,
  viewOnRowClick: false
};

Histories.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  filterable: PropTypes.bool,
  viewMode: PropTypes.bool,
  showPaginationTop: PropTypes.bool,
  showPaginationBottom: PropTypes.bool,
  viewOnRowClick: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

//export default withStyles(uteStyle, { withTheme: true })(ProjectTasks);
export default Histories;
