import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import restHelper from "common/utils/restHelper";
import { createPortal } from "react-dom";
import { MAP } from "react-google-maps/lib/constants";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  Polyline,
  Circle,
  InfoWindow,
  InfoB
} from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import dateHelper from "common/utils/dateHelper";
import { IconButton } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import ListIcon from "@material-ui/icons/List";
import pin from "assets/img/pin.svg";
import logo from "assets/img/logo.svg";
import Popup from "./Popup";
const COLORS = ["red", "green", "blue", "purple", "brown"];
const MIN_STOP_TIME = 180;

const CustomMapControl = (
  { position = window.google.maps.ControlPosition.TOP_LEFT, children, style },
  context
) => {
  const map = context[MAP];

  const controlDiv = document.createElement("div");

  useEffect(() => {
    const controls = map.controls[position];
    const index = controls.length;
    controls.push(controlDiv);
    return () => {
      controls.removeAt(index);
    };
  });

  return createPortal(<div style={style}>{children}</div>, controlDiv);
};

CustomMapControl.contextTypes = {
  [MAP]: PropTypes.object
};

class Map extends React.Component {
  constructor(props) {
    super(props);
    //const { user } = props;
    //const access = user.accessController("users");

    this.state = {
      error: null,
      polyLineInfo: null,
      prevPolyLineInfo: null,
      etapInfo: null,
      showList: false,
      titles: []
    };
    this.cluster = React.createRef();
  }

  parseValue = value => {
    if (typeof value === "string") {
      return dateHelper.getRange(value);
    } else {
      return value;
    }
  };

  componentDidMount() {
    this.props.setRef && this.props.setRef(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.etaps.length !== this.props.etaps.length) {
      this.zoomToEtaps(this.props.etaps);
    } else if (prevProps.etapIndex !== this.props.etapIndex) {
      this.zoomToEtaps(this.props.etaps);
    }

    if (prevProps.vehicles.length !== this.props.vehicles.length) {
      this.zoomToVehicles(this.props.vehicles);
    }
    if (prevProps.vehicle_id !== this.props.vehicle_id) {
      this.zoomToVehicles(this.props.vehicles);
    }
  }

  zoomToEtaps = etaps => {
    const bounds = new window.google.maps.LatLngBounds();
    etaps
      .filter((e, index) =>
        this.props.etapIndex !== null ? index === this.props.etapIndex : true
      )
      .forEach(etap => {
        etap.positions.forEach(item => {
          bounds.extend(new window.google.maps.LatLng(item.lat, item.lng));
        });
      });
    this.map && this.map.fitBounds(bounds);
  };

  zoomToVehicles = vehicles => {
    const bounds = new window.google.maps.LatLngBounds();
    vehicles.forEach(vehicle => {
      bounds.extend(new window.google.maps.LatLng(vehicle.lat, vehicle.lon));
    });
    this.map && this.map.fitBounds(bounds);
  };

  showList = () => {
    this.setState({ showList: !this.state.showList });
  };

  onPolylineMouseOver = (evt, vehicle_id, etapIndex) => {
    var polyLineInfo;
    this.state.items[vehicle_id].etaps[etapIndex].positions.reduce(function(
      carry,
      marker
    ) {
      var d = window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(marker.lat, marker.lng),
        evt.latLng
      );

      if (d < carry || carry == -1) {
        polyLineInfo = marker;
        return d;
      }

      return carry;
    },
    -1);
    this.setState({ polyLineInfo });
    console.log("polyLineInfo", polyLineInfo);
    //your marker is now in the variable polyLineInfo
  };

  onEtapMarkerClick = (evt, vehicle_id, etapIndex, last) => {
    let etapInfo = Object.assign(
      {
        last
      },
      this.state.items[vehicle_id].etaps[etapIndex]
    );

    this.setState({ etapInfo });
  };

  onClusteringBegin = (a, b, c, d) => {
    //console.log("onClusteringBegin", a, b, c, d);
  };

  onClusteringEnd = context => {
    //this.onClusteringEnd2(context);

    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }

    this.timeOut = setTimeout(() => {
      this.onClusteringEnd2(context);
    }, 10);
  };

  onClusteringEnd2 = context => {
    let points = [];
    const clusters = context.getClusters();
    //console.log("onClusteringEnd", clusters);
    if (clusters.length === this.state.titles.length) {
      return;
    }
    //console.log("onClusteringEnd", clusters);
    clusters.forEach(cluster => {
      const center = cluster.getCenter();

      const markers = cluster.getMarkers();
      //console.log("cluster center", center);
      if (markers.length > 1) {
        let title = [];
        markers.forEach(marker => {
          //console.log("marker", marker);
          title.push(marker.vehicle.platenumber);
        });
        points.push({
          title,
          position: {
            lat: center.lat(),
            lng: center.lng()
          },
          offset: { x: 0, y: 0 }
        });
      } else if (markers.length > 0) {
        points.push({
          title: [markers[0].vehicle.platenumber],
          position: {
            lat: center.lat(),
            lng: center.lng()
          },
          offset: { x: 0, y: -32 }
        });
      }
    });
    if (JSON.stringify(this.state.titles) !== JSON.stringify(points)) {
      //console.log("update points", points);
      this.setState({ titles: points });
    }

    //this.titles = points;
  };

  render() {
    const {
      items,
      error,
      polyLineInfo,
      showList,
      etapInfo,
      titles
    } = this.state;
    const { vehicles, etaps, vehicle_id } = this.props;
    //const { user } = this.props;
    const platenumberStyle = {
      borderRadius: 5,
      paddingLeft: 3,
      paddingRight: 3,
      backgroundColor: "blue",
      color: "white"
    };
    return (
      <GoogleMap
        ref={map => (this.map = map)}
        defaultZoom={14}
        defaultCenter={{ lat: 47.471407, lng: 19.06317 }}
        //defaultOptions={{ styles: mapStyles }}
      >
        {this.props.etapIndex === null && (
          <MarkerClusterer
            //onClick={props.onMarkerClustererClick}
            averageCenter
            enableRetinaIcons
            gridSize={60}
          >
            {etaps.map((etap, etapIndex) => (
              <Marker
                key={etapIndex}
                onClick={evt => {
                  this.onEtapMarkerClick(evt, etap.vehicle_id, etapIndex);
                }}
                label={String(etapIndex + 1)}
                //labelStyle={}
                position={{
                  lat: etap.start_lat,
                  lng: etap.start_lng
                }}
                /*onClick={() => {
                //setSelectedPark(park);
              }}*/
                zIndex={etapIndex + 3000}
                icon={{
                  url: pin,
                  size: new window.google.maps.Size(22, 44),
                  // The origin for this image is (0, 0).
                  origin: new window.google.maps.Point(0, -8),
                  // The anchor for this image is the base of the flagpole at (0, 32).
                  anchor: new window.google.maps.Point(10, 42)
                  //scaledSize: new window.google.maps.Size(25, 25)
                }}
              />
            ))}
            {etaps.length > 0 && (
              <Marker
                onClick={evt => {
                  this.onEtapMarkerClick(
                    evt,
                    etaps[etaps.length - 1].vehicle_id,
                    etaps.length - 1
                  );
                }}
                label={String(etaps.length + 1)}
                //labelStyle={}
                position={{
                  lat: etaps[etaps.length - 1].stop_lat,
                  lng: etaps[etaps.length - 1].stop_lng
                }}
                zIndex={etaps.length + 3000}
                icon={{
                  url: pin,
                  size: new window.google.maps.Size(22, 44),
                  // The origin for this image is (0, 0).
                  origin: new window.google.maps.Point(0, -8),
                  // The anchor for this image is the base of the flagpole at (0, 32).
                  anchor: new window.google.maps.Point(10, 42)
                  //scaledSize: new window.google.maps.Size(25, 25)
                }}
              />
            )}
          </MarkerClusterer>
        )}

        {etaps.map((etap, etapIndex) => (
          <Polyline
            key={etapIndex}
            path={etap.positions}
            /*onClick={evt => {
              this.onPolylineMouseOver(evt, etap.vehicle_id, etapIndex);
            }}*/
            options={{
              zIndex:
                etapIndex + (etapIndex === this.props.etapIndex ? 4000 : 1000),

              strokeColor: etapIndex === this.props.etapIndex ? "blue" : "red",
              strokeWeight: etapIndex === this.props.etapIndex ? 6 : 3
            }}
          />
        ))}
        {this.props.etapIndex !== null && etaps.length > 0 && (
          <Fragment>
            <Marker
              label={String(this.props.etapIndex + 1)}
              position={{
                lat: etaps[this.props.etapIndex].start_lat,
                lng: etaps[this.props.etapIndex].start_lng
              }}
              zIndex={this.props.etapIndex + 2000}
            />
            <Marker
              label={String(this.props.etapIndex + 2)}
              position={{
                lat: etaps[this.props.etapIndex].stop_lat,
                lng: etaps[this.props.etapIndex].stop_lng
              }}
              zIndex={this.props.etapIndex + 2001}
            />
          </Fragment>
        )}
        {!vehicle_id && (
          <MarkerClusterer
            ref={this.cluster}
            //onClick={props.onMarkerClustererClick}
            averageCenter
            enableRetinaIcons
            onClusteringBegin={this.onClusteringBegin}
            onClusteringEnd={this.onClusteringEnd}
            gridSize={60}
            title="Krumpli-12"
          >
            {vehicles.map((v, a) => (
              <Fragment key={`vehicle-m-${v.id}`}>
                <Marker
                  onClick={() => this.props.onSelectVehicle(v)}
                  position={{
                    lat: v.lat,
                    lng: v.lon
                  }}
                  zIndex={a + 2000}
                  options={{
                    vehicle: v
                  }}
                />
              </Fragment>
            ))}
          </MarkerClusterer>
        )}
        {!vehicle_id &&
          titles.map((t, b) => (
            <Popup
              key={b}
              anchorPosition={{
                lat: t.position.lat,
                lng: t.position.lng
              }}
              markerPixelOffset={t.offset}
              content={t.title.join(", ")}
            />
          ))}
      </GoogleMap>
    );
  }
}

Map.propTypes = {
  vehicle_id: PropTypes.number,
  etapIndex: PropTypes.number,
  dates: PropTypes.any
};

const MapWrapped = withScriptjs(withGoogleMap(Map));
const VehicleRouteMap = props => (
  <MapWrapped
    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`}
    loadingElement={<div style={{ height: "100%" }} />}
    containerElement={<div style={{ height: "100%" }} />}
    mapElement={<div style={{ height: "100%" }} />}
    {...props}
  />
);

VehicleRouteMap.propTypes = {
  vehicle_id: PropTypes.number,
  etapIndex: PropTypes.number,
  vehicles: PropTypes.array,
  dates: PropTypes.any
};

export default VehicleRouteMap;
