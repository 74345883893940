import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactToPrint from "react-to-print";
import TemplateA4X24 from "./TemplateA4X24";
import TemplateA4X50 from "./TemplateA4X50";
import TemplateA4X70 from "./TemplateA4X70";
import TemplateFeed from "./TemplateFeed";
import SplitButton from "common/components/SplitButton";
import restHelper from "common/utils/restHelper";
import { Toolbar, Typography } from "@material-ui/core";
import FormTextField from "common/components/DataForm/FormTextField";
import Spacer from "common/components/Spacer";

const TEMPLATE_A4X24 = 0;
const TEMPLATE_A4X50 = 1;
const TEMPLATE_A4X70 = 2;
const TEMPLATE_FEED = 3;

const ToolsPrintModal = ({ grid, evt }) => {
  const componentRef = useRef();
  const printRef = useRef();
  evt.onAccept = () => {
    printRef.current.handlePrint();
  };

  const [template, setTemplate] = useState(TEMPLATE_A4X50);
  const [items, setItems] = useState([]);
  const [startFrom, setStartFrom] = useState(1);

  useEffect(() => {
    // Update the document title using the browser API
    restHelper
      .action("tools", "print-list", null, {
        selectionAll: grid.state.selectionAll,
        selection: grid.state.selection
      })
      .then(response => {
        //console.log("print-list", response);
        setItems(response);
      });
  }, [grid.state.selection, grid.state.selectionAll]);

  const changeStartFrom = value => {
    setStartFrom(value["startFrom"] ? parseInt(value["startFrom"]) : 1);
  };

  return (
    <div style={{ minHeight: 200 }}>
      <Toolbar disableGutters>
        <SplitButton
          selectedIndex={template}
          setSelectedIndex={setTemplate}
          options={[
            { label: "A4 24 darabos" },
            { label: "A4 50 darabos" },
            { label: "A4 70 darabos" },
            { label: "Cimke szalag" }
          ]}
          //style={{ marginBottom: 16 }}
        />
        <Spacer w={2} />
        {template !== TEMPLATE_FEED && (
          <div style={{ width: 120 }}>
            <FormTextField
              classes={{}}
              id="startFrom"
              name="Kezdő matrica"
              column={{}}
              type="integer"
              value={startFrom}
              onChange={changeStartFrom}
              access="w"
            />
          </div>
        )}
      </Toolbar>
      {!grid.state.selectionAll && grid.state.selection.length === 0 && (
        <Typography>Nincs szerszám kiválasztva</Typography>
      )}
      <ReactToPrint
        ref={printRef}
        trigger={() => <div></div>}
        content={() => componentRef.current}
      />
      <div ref={componentRef}>
        {template === TEMPLATE_FEED && (
          <TemplateFeed items={items} startFrom={startFrom} />
        )}
        {template === TEMPLATE_A4X24 && (
          <TemplateA4X24 items={items} startFrom={startFrom} />
        )}
        {template === TEMPLATE_A4X50 && (
          <TemplateA4X50 items={items} startFrom={startFrom} />
        )}
        {template === TEMPLATE_A4X70 && (
          <TemplateA4X70 items={items} startFrom={startFrom} />
        )}
      </div>
    </div>
  );
};

ToolsPrintModal.propTypes = {
  grid: PropTypes.any.isRequired,
  evt: PropTypes.object.isRequired
};

export default ToolsPrintModal;
