import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid";
import PreviewFooter from "common/components/DataPreview/PreviewFooter";
import PreviewItem from "common/components/DataPreview/PreviewItem";
import dataPreviewStyle from "common/assets/style/dataPreviewStyle";
import dateHelper from "common/utils/dateHelper";
import formatHelper from "common/utils/formatHelper";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";

/**
 * Hierarchikus adatelemző
 */
class DataPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      expanded: null
    };
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  renderSection = section => {
    if (!section.data || section.data.length === 0) {
      return (
        <Grid item={true} xs={12}>
          {"Nincsenek " + section.title.toLowerCase() + "."}
        </Grid>
      );
    }
    switch (section.type) {
      case "title":
        return "";
      case "form":
        return this.renderFormSection(section);
      case "table":
        return this.renderTableSection(section);
      default:
        return "";
    }
  };

  renderFormSection = section => {
    return section.columns.map((column, key) => {
      return (
        <Grid key={key} item={true} xs={12} md={6}>
          {section.data[column.id]}
        </Grid>
      );
    });
  };

  getColumn(row, column) {
    if (column.value) {
      if (typeof column.value === "function") {
        return column.value(row);
      } else {
        return column.value;
      }
    }
    if (column.id.indexOf(".") >= 0) {
      const names = column.id.split(".");
      return row[names[0]] && row[names[0]][names[1]]
        ? this.viewValue(column.type, row[names[0]][names[1]])
        : "";
    } else {
      return this.viewValue(column.type, row[column.id]);
    }
  }

  renderTableSection = section => {
    //const { classes } = this.props;
    const { expanded } = this.state;

    return section.data.map((row, key) => {
      return (
        <ExpansionPanel
          //disabled={true}
          style={{ width: "100%" }}
          key={key}
          expanded={expanded === "panel" + key}
          //onChange={this.handleChange("panel" + key)}
        >
          <ExpansionPanelSummary>
            <Grid key={key} item={true} xs={12}>
              <Grid
                container={true}
                //justify="center"
                //className={classes.container}
                spacing={2}
              >
                {section.columns.map((column, key) => {
                  if (column.visible === false) {
                    return "";
                  }
                  return (
                    <Grid key={key} item={true} xs={12} md={2}>
                      {this.getColumn(row, column)}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
        </ExpansionPanel>
      );
    });
  };

  viewValue(type, value) {
    let cell = null;
    switch (type) {
      case "unixDate":
        cell = dateHelper.unixToDate(value);
        break;
      case "unixDateTime":
        cell = dateHelper.unixToDateTime(value);
        break;
      case "unixTime":
        cell = dateHelper.unixToTime(value);
        break;
      case "currency":
        cell = formatHelper.currency(value);
        break;
      default:
        cell = value;
        break;
    }
    return cell;
  }

  render() {
    const { sections, classes, buttons, item } = this.props;

    return (
      <div
        className={this.props.noContainer ? "" : classes.formContainer}
        /*className={
          editIndex === null ? classes.fullContainer : classes.container
        }*/
      >
        <Grid
          container={true}
          //justify="center"
          //className={classes.container}
          spacing={2}
        >
          {sections.map((section, key) => {
            //return <PreviewSection key={key} section={section} />;
            return (
              <PreviewItem
                key={key}
                id={(this.props.id ? this.props.id + "_" : "") + key}
                section={section}
                classes={classes}
                noHeader={section.noHeader}
                showSum={section.showSum}
                //noPaper={this.props.noPaper}
              />
            );
            /*return (
              <Grid key={key} item={true} xs={12}>
                <Typography variant="h6">{section.title}</Typography>
                <Grid
                  container={true}
                  //justify="center"
                  //className={classes.container}
                  spacing={1}
                >
                  {this.renderSection(section)}
                </Grid>
              </Grid>
            );*/
          })}
        </Grid>

        {item ? (
          <PreviewFooter item={item} typoClassName={classes.footerTypo} />
        ) : (
          ""
        )}
        {buttons
          ? buttons.map((button, key) => {
              return (
                <Button
                  key={key}
                  onClick={button.onClick}
                  className={classes.submitButton}
                  color={button.color ? button.color : "primary"}
                  variant="contained"
                  style={button.style ? button.style : null}
                >
                  {button.title}
              </Button>
              );
            })
          : ""}
      </div>
    );
  }
}

DataPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  buttons: PropTypes.array,
  item: PropTypes.object
};
//export default DataForm;
export default withStyles(dataPreviewStyle)(DataPreview);
