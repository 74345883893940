import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Select from "common/components/DataForm/FormSelectField";
import DatePicker from "common/components/DatePicker";
import dateHelper from "common/utils/dateHelper";
import dataFormStyle from "common/assets/style/dataFormStyle";
import VehicleRouteMap from "./VehicleRouteMap";
import VehicleList from "./VehicleList";
import VehicleEtaps from "./VehicleEtaps";
import restHelper from "common/utils/restHelper";
import { Toolbar, IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import VehicleSpeedChart from "./VehicleSpeedChart";

class VehicleMap extends React.Component {
  constructor(props) {
    super(props);

    let dates = null;
    const storage = sessionStorage.getItem("vehicle-positions-map");
    if (storage) {
      let storageData = JSON.parse(storage);
      if (storageData && storageData.dates) {
        if (typeof storageData.dates === "string") {
          dates = storageData.dates;
        } else {
          dates = dateHelper.unixToRange(storageData.dates);
        }
      }
    }
    if (!dates) {
      dates = "today";
    }
    this.state = {
      vehicle_id: null,
      vehicles: [],
      etaps: [],
      dates: dates,
      top: null,
      loading: false
    };
    this.containerNode = null;
    this.container = React.createRef();
    this.map = null;
  }

  componentDidMount() {
    if (this.container.current && !this.containerNode) {
      //eslint-disable-next-line react/no-find-dom-node
      this.containerNode = ReactDOM.findDOMNode(this.container.current);
      if (this.containerNode) {
        const rect = this.containerNode.getBoundingClientRect();
        this.setState({ top: rect.top });
      }
    }
    this.fetchVehicles();
  }

  fetchVehicles = () => {
    const params = {
      filter: [["last_signal", "nte", null], ["tracker_imei", "nte", null]],
      pagesize: 100
    };
    this.setState({ loading: true });
    restHelper.index("vehicles", params).then(response => {
      this.setState({ vehicles: response.data, loading: false });
    });
  };

  getHeight = (delta = 0) => {
    if (this.state.top === null) {
      return "auto";
    }
    const h = this.state.top + 60;
    return "calc(100vh - " + (h + delta) + "px)";
  };

  onChange = (value, rangeKey) => {
    const v = rangeKey ? rangeKey : value;

    let vs = null;
    if (rangeKey) {
      vs = rangeKey;
    } else if (v) {
      vs = { start: v.start.unix(), end: v.end.unix() };
    }

    if (vs) {
      sessionStorage.setItem(
        "vehicle-positions-map",
        JSON.stringify({ dates: vs })
      );
    } else {
      sessionStorage.removeItem("vehicle-positions-map");
    }
    this.setState(
      {
        dates: v,
        time: new Date().getTime()
      },
      () => {
        this.fetchPositions();
      }
    );
  };

  onVehicleChanged = vehicle_id => {
    this.setState(
      {
        vehicle_id: vehicle_id,
        etapIndex: null,
        time: new Date().getTime()
      },
      () => {
        this.fetchPositions();
      }
    );
  };

  onSelectVehicle = vehicle => {
    this.onVehicleChanged(vehicle.id);
  };

  onEtapChanged = etapIndex => {
    this.setState({
      etapIndex: etapIndex === this.state.etapIndex ? null : etapIndex,
      time: new Date().getTime()
    });
  };

  parseValue = value => {
    if (typeof value === "string") {
      return dateHelper.getRange(value);
    } else {
      return value;
    }
  };

  closeEtap = () => {
    this.setState({
      vehicle_id: null,
      etaps: [],
      time: new Date().getTime(),
      etapIndex: null
    });
  };

  fetchPositions = () => {
    const { dates, vehicle_id } = this.state;
    const range = this.parseValue(dates);
    this.setState({ loading: true });
    restHelper
      .index(`vehicle-positions/waybill/${vehicle_id}`, {
        start_time: range.start.unix(),
        end_time: range.end.unix() + 3600 * 24 - 1
      })
      .then(etaps => {
        this.setState({ etaps, loading: false });
      })
      .catch(error => {
        this.setState({ error: error.error, loading: false });
      });
  };

  onZoom = () => {
    if (this.map) {
      if (!this.state.vehicle_id) {
        this.map.zoomToVehicles(this.state.vehicles);
      } else {
        this.map.zoomToEtaps(this.state.etaps);
      }
    }
  };

  render() {
    const { classes, theme } = this.props;
    const {
      vehicle_id,
      dates,
      vehicles,
      etaps,
      etapIndex,
      loading,
      time
    } = this.state;
    const column = {
      id: "vehicle_id",
      type: "select",
      access: "w",
      name: "Járművek",
      datasource: {
        controller: "vehicles",
        objectField: "vehicle",
        valueField: "id",
        search: ["vehicle.platenumber"],
        sort: { platenumber: "asc" },
        filter: [["last_signal", "nte", null], ["tracker_imei", "nte", null]],
        label: item => {
          return item ? item.platenumber : "";
        }
      }
    };
    const positions =
      etaps && etaps[etapIndex] && etaps[etapIndex].positions
        ? etaps[etapIndex].positions
        : [];
    return (
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={12} sm={6} md={5} lg={4} xl={3}>
          {vehicle_id && (
            <Toolbar disableGutters variant="dense">
              <IconButton onClick={this.closeEtap} style={{ marginTop: -10 }}>
                <ChevronLeftIcon fontSize="large" />
              </IconButton>
              <div style={{ flex: 1 }}>
                <DatePicker
                  fullWidth
                  key={this.state.time}
                  onChange={this.onChange}
                  value={this.state.dates}
                  selectionType="range"
                  numberOfCalendars={2}
                  label="Időszak"
                  singleDateRange={true}
                  firstOfWeek={1}
                />
              </div>
            </Toolbar>
          )}
          <div
            style={{
              maxHeight: this.getHeight(78),
              overflow: "auto",
              position: "relative"
            }}
          >
            {!vehicle_id && (
              <VehicleList
                vehicles={vehicles}
                onChange={this.onVehicleChanged}
                vehicle_id={vehicle_id}
                onRefresh={this.fetchVehicles}
                onZoom={this.onZoom}
              />
            )}
            {vehicle_id && (
              <VehicleEtaps
                etaps={etaps}
                onRefresh={this.fetchPositions}
                onChange={this.onEtapChanged}
                onZoom={this.onZoom}
                etapIndex={etapIndex}
              />
            )}
            {loading && (
              <div className={classes.backdrop}>
                <CircularProgress color="primary" />
              </div>
            )}
          </div>
        </Grid>
        <Grid item={true} xs={12} sm={6} md={7} lg={8} xl={9}>
          <Grid container={true} spacing={4}>
            <Grid item={true} xs={12} md={12}>
              <div
                ref={this.container}
                style={{
                  width: "100%",
                  height: this.getHeight(positions.length > 0 ? 200 : 0)
                }}
              >
                <VehicleRouteMap
                  //key={time}
                  setRef={ref => (this.map = ref)}
                  vehicle_id={vehicle_id}
                  vehicles={vehicles}
                  etaps={etaps}
                  etapIndex={etapIndex}
                  dates={dates}
                  onSelectVehicle={this.onSelectVehicle}
                />
              </div>
            </Grid>
            {positions.length > 0 && (
              <Grid item={true} xs={12} md={12}>
                <div
                  ref={this.container}
                  style={{ width: "100%", height: 180 }}
                >
                  <VehicleSpeedChart positions={positions} />
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

VehicleMap.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(dataFormStyle, { withTheme: true })(VehicleMap);
