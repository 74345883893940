import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";

class ProjectCosts extends React.Component {
  access = "";

  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("project-costs");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "project-costs",
        url: "project-costs",
        attributes: strings.attributes["project-costs"],
        condition: {
          id: "project_id",
          value: this.props.item["id"]
        },
        columns: [
          {
            id: "cost_id",
            type: "select",
            datasource: {
              controller: "costs",
              objectField: "cost",
              valueField: "id",
              search: ["cost.name"],
              sort: { "cost.name": "asc" },
              filter: [
                ["show_at_worksheet", "exact", 1],
                ["min_role", "gte", user.data.role]
              ],
              label: item => {
                return item ? item.name : "";
              }
            }
          },
          { id: "active", type: "checkbox" }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition, access } = this.state;
    const {
      user,
      onChildEdit,
      onChildCancelEdit,
      breadcrumbs,
      isDetailView
    } = this.props;
    //const { classes } = this.props;

    return (
      <DataGrid
        user={user}
        isDetailView={isDetailView}
        onEdit={onChildEdit}
        onCancelEdit={onChildCancelEdit}
        breadcrumbs={breadcrumbs}
        definition={definition}
        startEditRef={ref => (this.startEdit = ref)}
        FormFooter="CreatedUpdated"
        fieldAccessHandler={column => {
          return user.accessField("project-costs", column.id);
        }}
        access={access}
        filterable={false}
        title={item => {
          if (!item) {
            return "Tallózás";
          } else if (item.id) {
            return item.cost.name;
          } else {
            return "Új költségtípus";
          }
        }}
        /*toolbarButtons={[
          {
            tooltip: "Letöltés",
            onClick: () => {},
            Icon: DownloadIcon,
            color: "primary"
          }
        ]}*/
      />
    );
  }
}

ProjectCosts.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired
};

//export default withStyles(uteStyle, { withTheme: true })(ProjectTasks);
export default ProjectCosts;
