import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";

class Events extends React.Component {
  access = "";

  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("events");

    let evts = {};
    strings.options.events[props.object_id].forEach(element => {
      evts[element.value] = element.label;
    });

    this.state = {
      evts,
      access: access ? access : "",
      definition: {
        id: "events",
        url: "events",
        attributes: strings.attributes["events"],
        condition: [
          {
            id: "item_id",
            value: this.props.item["id"]
          },
          {
            id: "object_id",
            value: this.props.object_id
          }
        ],
        columns: [
          {
            id: "code",
            type: "select",
            options: strings.options.events[this.props.object_id],
            detailLink: true
          },
          //{ id: "name" },
          { id: "description" },
          {
            id: "created_at",
            type: "unixDateTime",
            hidden: item => !item.id
          },
          {
            id: "user_id",
            type: "select",
            datasource: {
              controller: "users",
              objectField: "user",
              valueField: "id",
              label: "name",
              sort: { "user.name": "asc" },
              search: ["user.name"]
            },
            sort: "user.name",
            search: ["user.name"],
            hidden: item => !item.id
          },
          {
            id: "file_id",
            type: "file"
          }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition, access, evts } = this.state;
    const {
      user,
      filterable,
      viewMode,
      showPaginationTop,
      showPaginationBottom,
      viewOnRowClick,
      height,
      isDetailView,
      onChildEdit,
      onChildCancelEdit,
      breadcrumbs
    } = this.props;
    //const { classes } = this.props;

    return (
      <DataGrid
        user={user}
        onEdit={onChildEdit}
        onCancelEdit={onChildCancelEdit}
        breadcrumbs={breadcrumbs}
        definition={definition}
        defaultSort={[{ id: "created_at", desc: true }]}
        startEditRef={ref => (this.startEdit = ref)}
        //FormFooter="CreatedUpdated"
        fieldAccessHandler={column => {
          return user.accessField("events", column.id);
        }}
        access={access}
        filterable={filterable}
        viewMode={viewMode}
        showPaginationTop={showPaginationTop}
        showPaginationBottom={showPaginationBottom}
        height={height}
        viewOnRowClick={viewOnRowClick}
        isDetailView={isDetailView}
        title={item => {
          if (!item) {
            return "";
          } else if (item.id) {
            return evts[item.code] || item.code;
          } else {
            return "Új esemény";
          }
        }}
        /*toolbarButtons={[
          {
            tooltip: "Letöltés",
            onClick: () => {},
            Icon: DownloadIcon,
            color: "primary"
          }
        ]}*/
      />
    );
  }
}

Events.defaultProps = {
  filterable: false,
  viewMode: false,
  showPaginationBottom: true,
  showPaginationTop: false,
  viewOnRowClick: false
};

Events.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  item: PropTypes.object,
  object_id: PropTypes.string,
  filterable: PropTypes.bool,
  viewMode: PropTypes.bool,
  showPaginationTop: PropTypes.bool,
  showPaginationBottom: PropTypes.bool,
  viewOnRowClick: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

//export default withStyles(uteStyle, { withTheme: true })(ProjectTasks);
export default Events;
