import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";

const types = [
  { value: 1, label: "Készpénz" },
  { value: 2, label: "Utalás" },
  { value: 3, label: "Számla" }
];

class InvoiceTransactions extends React.Component {
  constructor(props) {
    super(props);
    const { user /*, item */ } = props;
    const access = user.accessController("invoice-transactions");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "invoice-transactions",
        url: "invoice-transactions",
        condition: {
          id: "invoice_id",
          value: this.props.item["id"]
        },
        attributes: strings.attributes["invoice-transactions"],
        columns: [
          { id: "type", type: "select", options: types, defaultValue: 1 },
          {
            id: "delivery_date",
            type: "unixDate",
            defaultValue: Math.round(new Date().getTime() / 1000)
          },
          {
            id: "invoice_pair_id",
            type: "select",
            datasource: {
              controller: "invoices",
              objectField: "invoice_pair",
              valueField: "id",
              label: "invoice_number",
              sort: { "invoice.invoice_number": "asc" },
              search: ["invoice.invoice_number"]
            },
            hidden: item => {
              if (item.type === 3) {
                return false;
              }
              return true;
            }
          },
          {
            id: "price",
            type: "currency",
            footer: "sum",
            defaultValue:
              props.item.remaining_price > 0 ? props.item.remaining_price : null
          }
        ]
      }
    };
  }

  /**
   * Ha frissülnek a számla adatok és változik a fentmaradó összeg,
   * akkor frissítjük az ár alapértelmezett értékét.
   * @param {Object} nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.item.remaining_price !== this.props.item.remaining_price) {
      const definition = Object.assign({}, this.state.definition);
      definition.columns[3].defaultValue =
        nextProps.item.remaining_price > 0
          ? nextProps.item.remaining_price
          : null;
      this.setState({ definition });
    }
  }

  componentDidMount() {}

  onSave = (item, isNew) => {
    const { refreshInvoice } = this.props;
    refreshInvoice();
  };

  onDelete = item => {
    const { refreshInvoice } = this.props;
    refreshInvoice();
  };

  render() {
    const { definition } = this.state;
    const {
      user,
      filterable,
      viewMode,
      showPaginationTop,
      showPaginationBottom,
      viewOnRowClick,
      isDetailView,
      height,
      editItem
    } = this.props;
    return (
      <div>
        <DataGrid
          user={user}
          definition={definition}
          filterable={filterable}
          viewMode={viewMode}
          showPaginationTop={showPaginationTop}
          showPaginationBottom={showPaginationBottom}
          height={height}
          item={editItem}
          isDetailView={isDetailView}
          viewOnRowClick={viewOnRowClick}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("invoice-transactions", column.id);
          }}
          access={this.state.access}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return item.name;
            } else {
              return "Új Teljesítés";
            }
          }}
          onSave={this.onSave}
          onDelete={this.onDelete}
        />
      </div>
    );
  }
}

InvoiceTransactions.propTypes = {
  user: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  refreshInvoice: PropTypes.func.isRequired,
  editItem: PropTypes.object,
  itemKey: PropTypes.string,
  title: PropTypes.string,
  filterable: PropTypes.bool,
  viewMode: PropTypes.bool,
  showPaginationTop: PropTypes.bool,
  showPaginationBottom: PropTypes.bool,
  viewOnRowClick: PropTypes.bool,
  isDetailView: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default InvoiceTransactions;
