import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  page: {
    boxSizing: "border-box",
    border: `solid 1px ${theme.palette.text.hint}`
  },
  label: {
    display: "inline-block",
    pageBreakBefore: "always",
    textAlign: "center",
    border: `dashed 1px ${theme.palette.text.hint}`,
    borderRadius: 8,
    boxSizing: "border-box",
    "@media print": {
      border: "none!important"
    }
  }
});

class LabelTemplate extends Component {
  getPages = () => {
    const { rows, columns, startFrom } = this.props;
    let items = this.props.items.slice();
    const perPage = rows * columns;

    if (items.length > 0 && startFrom > 1) {
      for (let j = 1; j < startFrom; j++) {
        items.unshift(null);
      }
    }

    let pages = [];

    let page = 0;
    let i = 0;
    items.forEach(item => {
      if (!pages[page]) {
        pages.push([]);
      }
      pages[page].push(item);

      if (i >= perPage - 1) {
        page++;
        i = 0;
      } else {
        i++;
      }
    });

    return pages;
  };

  getPageStyle = () => {
    const {
      pageWidth,
      pageHeight,
      pageMarginVertical,
      pageMarginHorizontal,
      pageBackgroundImage,
      labelSpaceVertical,
      labelSpaceHorizontal
    } = this.props;

    let pageStyle = {
      width: pageWidth,
      height: pageHeight,
      paddingTop: pageMarginVertical - labelSpaceVertical / 2,
      paddingBottom: pageMarginVertical - labelSpaceVertical / 2,
      paddingLeft: pageMarginHorizontal - labelSpaceHorizontal / 2,
      paddingRight: pageMarginHorizontal - labelSpaceHorizontal / 2
    };
    if (pageBackgroundImage) {
      pageStyle.background = `url(${pageBackgroundImage})`;
      pageStyle.backgroundRepeat = "no-repeat";
      pageStyle.backgroundSize = "100% 100%";
    }
    return pageStyle;
  };

  getLabelStyle = () => {
    const {
      rows,
      columns,
      labelSpaceVertical,
      labelSpaceHorizontal
    } = this.props;

    return {
      width: `calc(${100 / columns}% - ${labelSpaceHorizontal}px)`,
      height: `calc(${100 / rows}% - ${labelSpaceVertical}px)`,
      marginRight: labelSpaceHorizontal / 2,
      marginBottom: labelSpaceVertical / 2,
      marginLeft: labelSpaceHorizontal / 2,
      marginTop: labelSpaceVertical / 2
    };
  };

  render() {
    const { renderLabel, classes } = this.props;

    return this.getPages().map((page, pageIndex) => {
      return (
        <div
          key={pageIndex}
          style={this.getPageStyle()}
          className={classes.page}
        >
          {page.map((label, labelIndex) => (
            <div
              key={`${pageIndex}_${labelIndex}`}
              style={this.getLabelStyle()}
              className={classes.label}
            >
              {renderLabel(
                label
              ) /*label ? (
                renderLabel(label)
              ) : (
                <div style={{ breakBefore: "page", textAlign: "center" }}>
                  <div style={{ width: 60, height: 60 }}></div>
                </div>
              )*/}
            </div>
          ))}
        </div>
      );
    });
  }
}

LabelTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
  pageWidth: PropTypes.number.isRequired,
  pageHeight: PropTypes.number.isRequired,
  pageMarginVertical: PropTypes.number.isRequired,
  pageMarginHorizontal: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
  columns: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  labelSpaceVertical: PropTypes.number.isRequired,
  labelSpaceHorizontal: PropTypes.number.isRequired,
  renderLabel: PropTypes.func.isRequired,
  pageBackgroundImage: PropTypes.any,
  startFrom: PropTypes.number
};

export default withStyles(styles)(LabelTemplate);
