import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import dateHelper from "common/utils/dateHelper";
const PreviewFooter = props => {
  if (!props.item.created_by) {
    return "";
  }
  return (
    <div>
      <Typography className={props.typoClassName} variant="caption">
        Rögzítette: {props.item.created_by_name},{" "}
        {dateHelper.unixToDateTimeMid(props.item.created_at)},{" "}
        {props.item.updated_by_name}, Módosította:{" "}
        {dateHelper.unixToDateTimeMid(props.item.updated_at)}
      </Typography>{" "}
    </div>
  );
};

PreviewFooter.propTypes = {
  item: PropTypes.object.isRequired, //Adott sor adatai
  typoClassName: PropTypes.string //Szöveg stílusa
};

export default PreviewFooter;
