import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import uteStyle from "common/assets/style/uteStyle";
import dateHelper from "common/utils/dateHelper";
//import strings from "variables/strings.json";
import releases from "variables/releases.json";
import Typography from "@material-ui/core/Typography";

class Releases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    localStorage.setItem("ver", releases[releases.length - 1].ver);
  }

  render() {
    let r = releases
      .slice()
      .sort((a, b) => (a.date > b.date ? -1 : b.date > a.date ? 1 : 0));

    return (
      <div style={{ padding: 15 }}>
        <table>
          <tbody>
            {r.map((item, key) => {
              const lines = Array.isArray(item.text)
                ? item.text
                : item.text.split("|");
              return (
                <tr key={key}>
                  <td>
                    <div>
                      <Typography variant="h6">
                        {dateHelper.unixToDate(item.date)} - {item.ver}
                      </Typography>
                    </div>
                    <div>
                      <ul>
                        {lines.map((listItem, listKey) => {
                          return <li key={listKey}>{listItem}</li>;
                        })}
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

Releases.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object
};

export default withStyles(uteStyle, { withTheme: true })(Releases);
