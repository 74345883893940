import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import appToolbarStyle from "common/assets/style/appToolbarStyle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Box, Button, Checkbox, FormControlLabel } from "@material-ui/core";
import Spacer from "./Spacer";
import { Check } from "@material-ui/icons";

class AppToolbar extends React.Component {
  /*constructor(props) {
    super(props);
    this.state = {};
  }*/

  render() {
    const {
      classes,
      buttons,
      buttonProps,
      handleOwnFilterChange,
      ownFilterOn
    } = this.props;
    if (buttons.length === 0) {
      return "";
    }
    //console.log("buttonProps", buttonProps);
    return (
      <Fragment>
        {buttonProps && buttonProps.data === null && handleOwnFilterChange && (
          <FormControlLabel
            control={
              <Checkbox
                checked={ownFilterOn}
                onChange={handleOwnFilterChange}
                name="ownFilter"
                color="primary"
              />
            }
            label="Sajátok"
          />
        )}
        {buttons.map((button, key) => {
          const btProps =
            typeof button.buttonProps === "function"
              ? button.buttonProps(buttonProps) || { variant: button.variant }
              : button.buttonProps || { variant: button.variant };
          //console.log("btProps", btProps);
          if (["outlined", "contained", "text"].indexOf(btProps.variant) >= 0) {
            return (
              <Fragment key={key}>
                <Spacer w={1} />
                <Button
                  variant={button.variant}
                  startIcon={<button.Icon />}
                  color={button.color ? button.color : "inherit"}
                  className={classes.button}
                  aria-label={button.tooltip}
                  onClick={
                    button.onClick
                      ? evt => button.onClick(evt, buttonProps, button.value)
                      : undefined
                  }
                  component={button.component}
                  href={button.href}
                  to={button.to}
                  {...btProps}
                >
                  {button.tooltip}
                </Button>
              </Fragment>
            );
          }
          return (
            <Tooltip key={key} title={button.tooltip} disableFocusListener>
              <Box style={{ width: 43.5, height: 43.5 }}>
                <IconButton
                  color={button.color ? button.color : "inherit"}
                  className={classes.button}
                  aria-label={button.tooltip}
                  onClick={
                    button.onClick
                      ? evt => button.onClick(evt, buttonProps, button.value)
                      : undefined
                  }
                  component={button.component}
                  href={button.href}
                  to={button.to}
                  {...btProps}
                >
                  <button.Icon />
                  {button.success && button.success(buttonProps) && (
                    <Check
                      style={{
                        color: "green",
                        position: "absolute",
                        top: 0,
                        right: 0
                      }}
                    />
                  )}
                </IconButton>
              </Box>
            </Tooltip>
          );
        })}
      </Fragment>
    );
  }
}

AppToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonProps: PropTypes.object, //egyedi adat továbbítása az onclick eseményekre 2. paraméterként
  buttons: PropTypes.array.isRequired,
  handleOwnFilterChange: PropTypes.func,
  ownFilterOn: PropTypes.bool
};

export default withStyles(appToolbarStyle, { withTheme: true })(AppToolbar);
