import React from "react";
import PropTypes from "prop-types";

class TextFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.filter && props.filter.value ? props.filter.value : ""
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const fCurrent =
      this.props.filter && this.props.filter.value
        ? this.props.filter.value
        : "";
    const fNext =
      nextProps.filter && nextProps.filter.value ? nextProps.filter.value : "";
    if (fCurrent !== fNext) {
      this.setState({ value: fNext });
    }
  }

  /* getFilterValue = () => {
    const {filter} = this.props;
    return filter ? filter.value : "";
  }*/

  render() {
    const { value } = this.state;
    const { name, onChange } = this.props;
    return (
      <input
        name={name}
        id={name}
        autoComplete={name + new Date().getTime()}
        onChange={evt => {
          this.setState({ value: evt.target.value });
          evt.persist();
          setTimeout(() => {
            onChange(evt.target.value);
          }, 500);
        }}
        value={value}
        style={{
          width: "100%"
        }}
      />
    );
  }
}

TextFilter.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  filter: PropTypes.object
};

export default TextFilter;
