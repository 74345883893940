const storageHelper = (type, key, context, only) => {
  return {
    set: (state, callback) => {
      context.setState(state, () => {
        let onlyState = {};
        if (only) {
          Object.keys(context.state).map(key => {
            if (only.indexOf(key) >= 0) {
              onlyState[key] = context.state[key];
            }
            return;
          });
        } else {
          onlyState = context.state;
        }
        type === "local"
          ? localStorage.setItem(key, JSON.stringify(onlyState))
          : sessionStorage.setItem(key, JSON.stringify(onlyState));
        callback && callback();
      });
    },
    setStorage: state => {
      const st =
        type === "local"
          ? JSON.parse(localStorage.getItem(key))
          : JSON.parse(sessionStorage.getItem(key));
      const merged = Object.assign({}, st, state);
      type === "local"
        ? localStorage.setItem(key, JSON.stringify(merged))
        : sessionStorage.setItem(key, JSON.stringify(merged));
    },
    get: () =>
      type === "local"
        ? JSON.parse(localStorage.getItem(key))
        : JSON.parse(sessionStorage.getItem(key)),
    clearBrowser: () => {
      type === "local"
        ? localStorage.removeItem(key)
        : sessionStorage.removeItem(key);
    }
  };
};
export default storageHelper;
