import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import uteStyle from "common/assets/style/uteStyle";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";

class Customers extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("customers");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "customers",
        url: "customers",
        attributes: strings.attributes.customers,
        columns: [
          { id: "name" },
          {
            id: "zip_id",
            type: "select",
            datasource: {
              controller: "zips",
              objectField: "zip",
              valueField: "id",
              search: ["zip.name", "zip.zip"],
              label: item => {
                return item ? item.zip + ", " + item.name : "";
              }
            }
          },
          { id: "address" },
          //{ id: "tax_number" },
          //{ id: "bank_account_number" },
          { id: "subcontractor", type: "checkbox", visible: false },
          { id: "is_project_customer", type: "checkbox" },
          { id: "is_buyer", type: "checkbox" },
          { id: "is_supplier", type: "checkbox" },
          { id: "active", type: "checkbox", visible: false }
          //{ id: "photo", name: "Fénykép" },
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const { user } = this.props;
    return (
      <div>
        <DataGrid user={user}
          definition={definition}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("customers", column.id);
          }}
          access={this.state.access}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return item.name;
            } else {
              return "Új ügyfél";
            }
          }}
        />
      </div>
    );
  }
}

Customers.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(uteStyle, { withTheme: true })(Customers);
