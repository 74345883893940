import React from "react";
import PropTypes from "prop-types";
import DataForm from "common/components/DataForm/DataForm";
import restHelper from "common/utils/restHelper";
import strings from "variables/strings.json";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("users");

    this.state = {
      item: {},
      error: null,
      access: access ? access : "",
      definition: {
        id: "users",
        url: "users",
        attributes: strings.attributes.users,
        columns: [
          { id: "name" },
          { id: "email" },
          { id: "password1", type: "password", visible: false },
          { id: "password2", type: "password", visible: false },
          {
            id: "zip_id",
            type: "select",
            datasource: {
              controller: "zips",
              objectField: "zip",
              valueField: "id",
              search: ["zip.name", "zip.zip"],
              label: item => {
                return item ? item.zip + ", " + item.name : "";
              }
            }
          },
          { id: "address", name: "Cím" },
          { id: "birth_date", type: "unixDate", visible: false },
          { id: "birth_place", visible: false },
          { id: "mothers_name", visible: false },
          { id: "tax_number", visible: false },
          { id: "phone", visible: false },
          { id: "company_phone", visible: false },
          { id: "identity_card", visible: false },
          { id: "avatar_file_id", type: "file" }
        ]
      }
    };
  }

  componentDidMount() {
    restHelper
      .view("users", this.props.user.data.id)
      .then(item => {
        this.setState({ item: item });
      })
      .catch(error => {
        this.setState({ error: error.error });
      });
  }

  afterSave = item => {
    console.log("Ez eldurran?", item, this.props);
    this.setState({ item }, () => {
      this.props.refreshUser(item);
    });
  };

  render() {
    const { definition, item, error } = this.state;
    const { user } = this.props;
    return (
      <div>
        <DataForm
          definition={definition}
          startEditRef={ref => (this.startEdit = ref)}
          Footer="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("users", column.id);
          }}
          access={this.state.access}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return item.name;
            } else {
              return "Új felhasználó";
            }
          }}
          item={item}
          afterSave={this.afterSave}
          error={error}
        />
      </div>
    );
  }
}

Profile.propTypes = {
  user: PropTypes.object.isRequired
};

export default Profile;
