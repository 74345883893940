import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import restHelper from "common/utils/restHelper";
import strings from "variables/strings.json";
import withStyles from "@material-ui/core/styles/withStyles";
import dataFormStyle from "common/assets/style/dataFormStyle";
import CloseIcon from "@material-ui/icons/DoneAll";
import FormSelectField from "common/components/DataForm/FormSelectField";

class Wallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wallet_user_id: null,
      changed_at: new Date().getTime(),
      users: [],
      access: "r",
      definition: {
        attributes: strings.attributes["user-costs"],
        id: "wallet",
        url: "user-costs/wallet",
        /*condition: user.can([3])
          ? {
              id: "user_id",
              value: user.user.jti
            }
          : null,*/
        columns: [
          {
            id: "user_id",
            type: "select",
            datasource: {
              controller: "users",
              objectField: "user",
              valueField: "id",
              search: ["user.name"],
              //filter: [["unit", "=", 1]],
              sort: { "user.name": "asc" },
              label: item => {
                return item ? item.name : "";
              }
            }
          },
          {
            id: "day",
            type: "unixDate",
            defaultValue: new Date().getTime() / 1000
          },
          {
            id: "project_id",
            type: "select",
            datasource: {
              controller: "projects",
              objectField: "project",
              valueField: "id",
              search: ["project.name"],
              filter: [["project.active", "exact", true]],
              label: "name"
            }
          },
          {
            id: "cost_id",
            type: "select",
            datasource: {
              controller: "costs",
              objectField: "cost",
              valueField: "id",
              search: ["cost.name"],
              //filter: [["unit", "=", 1]],
              label: item => {
                return item ? item.name : "";
              }
            }
          },
          { id: "amount", type: "currency", footer: "sum" },
          { id: "description" },
          {
            id: "person_id",
            type: "select",
            search: ["person.name"],
            datasource: {
              controller: "users",
              objectField: "person",
              valueField: "id",
              search: ["user.name"],
              sort: { "user.name": "asc" },
              //filter: [["unit", "=", 1]],
              label: item => {
                return item ? item.name : "";
              }
            },
            hidden: item => {
              if (item.cost && item.cost.resource === 2) {
                return false;
              }
              return true;
            },
            label: item => {
              if (item.cost && item.cost.incoming === true) {
                return "Kitől";
              }
              return "Kinek";
            }
          },
          {
            id: "vehicle_id",
            type: "select",
            datasource: {
              controller: "vehicles",
              objectField: "vehicle",
              valueField: "id",
              search: ["platenumber"],
              sort: { platenumber: "asc" },
              //filter: [["unit", "=", 1]],
              label: item => {
                return item ? item.platenumber : "";
              }
            },
            /*visible: item => {
              return false;
            },*/
            hidden: item => {
              if (item.cost && item.cost.resource === 1) {
                return false;
              }
              return true;
            }
          },
          {
            id: "counter",
            hidden: item => {
              if (item.cost && item.cost.counter_required) {
                return false;
              }
              return true;
            }
          },
          {
            id: "worksheet_id",
            //type: "te",
            cell: item =>
              item.original.worksheet_id ? "#" + item.original.worksheet_id : ""
          },
          {
            id: "accepted",
            type: "checkbox"
            //cell: item => (item.accepted ? "Igen" : "")
          }
        ],
        fields: [
          { id: "user_id" },
          { id: "day" },
          { id: "project_id" },
          { id: "cost_id" },
          { id: "amount" },
          { id: "description" },
          { id: "person_id" },
          { id: "vehicle_id" },
          { id: "counter" },
          { id: "accepted" }
        ]
      }
    };
  }

  componentDidMount() {
    const params = {
      pagesize: 200,
      sort: { "user.name": "asc" }
    };
    restHelper.index("users", params).then(response => {
      const items = response.data.map(item => {
        return { label: item.name, value: item.id };
      });
      this.setState({ users: items });
    });
  }

  onUserChange2 = option => {
    if (option) {
      this.setState({ wallet_user_id: option.value });
    } else {
      this.setState({ wallet_user_id: null });
    }
  };

  closeWallet = () => {
    let params = {
      wallet_user_id: this.state.wallet_user_id
    };
    restHelper.create("user-costs/close-wallet", params).then(response => {
      this.setState({ changed_at: new Date().getTime() });
    });
  };

  onUserChange = data => {
    console.log(data);
    this.setState({
      currentValue: data.user,
      wallet_user_id: data.user_id,
      time: new Date().getTime()
    });
  };

  render() {
    const { definition } = this.state;
    const { classes, theme, user } = this.props;
    const buttons = [
      {
        Icon: CloseIcon,
        tooltip: "Időszak lezárása",
        onClick: this.closeWallet,
        color: "primary"
      }
    ];

    const column = {
      id: "user_id",
      type: "select",
      search: "user.name",
      access: "w",
      name: "Felhasználó",
      datasource: {
        controller: "users",
        objectField: "user",
        valueField: "id",
        search: ["user.name"],
        filter: [["user.active", "exact", true]],
        sort: { "user.name": "asc" },
        label: "name"
      }
    };

    return (
      <div>
        {user.accessFunction("wallet_access_all") ? (
          /*<Select
            inputId={"wallet_user_id"}
            //classes={classes}
            //styles={selectStyles}
            //components={components}
            //value={this.state.currentValue}
            //onKeyDown={this.onKeyDown}
            onChange={this.onUserChange}
            options={this.state.users}
            //error={error}
            //label={name}
            isClearable
            placeholder="Válassz felhasználót"
            //isDisabled={column.access !== "w" || isDeleted ? true : false}
          />*/
          <FormSelectField
            id={"user_id"}
            name={column.name}
            options={column.options}
            datasource={column.datasource}
            value={this.state.currentValue ? this.state.currentValue.id : null}
            column={column}
            isDeleted={false}
            currentValue={this.state.currentValue}
            error={null}
            onChange={this.onUserChange}
            classes={classes}
            theme={theme}
            item={{}}
            dirty={false}
            isMulti={false}
          />
        ) : (
          ""
        )}
        <DataGrid
          user={user}
          urlParams={{
            wallet_user_id: this.state.wallet_user_id,
            changed_at: this.state.changed_at
          }}
          definition={definition}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          filterable={false}
          sortable={false}
          showPaginationTop={false}
          showPaginationBottom={false}
          defaultPageSize={1000}
          disableExcelExport
          toolbarButtons={
            user.accessFunction("wallet_close") && this.state.wallet_user_id
              ? buttons
              : null
          }
          fieldAccessHandler={(column, item) => {
            //return user.accessField("user-costs", column.id);
            const access = user.accessField("user-costs", column.id);

            if (access) {
              if (
                column.id === "user_id" &&
                (access === "r" || access === "w")
              ) {
                return "r";
              }
              return item && (item.worksheet_id || item.accepted)
                ? "r"
                : access;
            }
            return access;
          }}
          access={item => {
            if (item && (item.worksheet_id || item.accepted)) {
              return "r";
            }
            return this.state.access;
          }}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return (
                (item.cost ? item.cost.name : "Ismeretlen költség") +
                (item.worksheet_id ? " #" + item.worksheet_id : "")
              );
            } else {
              return "Új költség";
            }
          }}
        />
      </div>
    );
  }
}

Wallet.propTypes = {
  user: PropTypes.object.isRequired
};

export default withStyles(dataFormStyle, { withTheme: true })(Wallet);
