import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import uteStyle from "common/assets/style/uteStyle";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";

const units = [
  { value: 1, label: "Óra" },
  { value: 2, label: "Darab" },
  { value: 3, label: "Méter" },
  { value: 4, label: "Négyzetméter" },
  { value: 5, label: "Köbméter" },
  { value: 6, label: "Készlet" },
  { value: 7, label: "Üzemóra" },
  { value: 8, label: "Kilométer" },
  { value: 9, label: "Tonna" },
  { value: 10, label: "Nap" }
];

const taskResources = [
  { value: 1, label: "Jármű" }
  //{ value: 2, label: "Felhasználó" }
];

class Tasks extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("tasks");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "tasks",
        url: "tasks",
        attributes: strings.attributes.tasks,
        columns: [
          { id: "name" },
          {
            id: "unit",
            type: "select",
            options: units
          },
          {
            id: "task_group_id",
            type: "select",
            //visible: false,
            datasource: {
              controller: "task-groups",
              objectField: "task_group",
              valueField: "id",
              search: ["task_group.name"],
              sort: { "task_group.name": "asc" },
              //filter: [["unit", "=", 1]],
              label: "name"
            }
          },
          { id: "resource", type: "select", options: taskResources },
          { id: "description" },
          { id: "internal", type: "checkbox", visible: false },
          { id: "active", type: "checkbox", visible: false }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const { user } = this.props;
    return (
      <div>
        <DataGrid user={user}
          definition={definition}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("tasks", column.id);
          }}
          access={this.state.access}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return item.name;
            } else {
              return "Új feladat";
            }
          }}
        />
      </div>
    );
  }
}

Tasks.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  
};

export default withStyles(uteStyle, { withTheme: true })(Tasks);
