import DataGrid from "common/components/DataGrid/DataGrid";
import PropTypes from "prop-types";
import React from "react";
import strings from "variables/strings.json";

const vehicleTypes = [
  { value: 1, label: "Személygépkocsi" },
  { value: 2, label: "Tehergépkocsi" },
  { value: 3, label: "Munkagép" }
];

const fuelTypes = [
  { value: 1, label: "Benzin" },
  { value: 2, label: "Diesel" }
];

class Vehicles extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("vehicles");

    this.state = {
      access: access ? access : "",
      definition: {
        attributes: strings.attributes.vehicles,
        id: "vehicles",
        url: "vehicles",
        columns: [
          { id: "platenumber" },
          { id: "maker" },
          { id: "model" },
          { id: "type", type: "select", options: vehicleTypes },
          { id: "owner" },
          { id: "mot_expires", type: "unixDate" },
          {
            id: "project_id",
            type: "select",
            datasource: {
              controller: "projects",
              objectField: "project",
              valueField: "id",
              search: ["project.name"],
              filter: [["project.active", "exact", true]],
              label: "name"
            }
          },
          { id: "vin", visible: false },
          { id: "engine_no", visible: false },
          {
            id: "fuel_type",
            type: "select",
            options: fuelTypes,
            visible: false
          },
          { id: "ccm", visible: false },
          { id: "kw", visible: false },
          { id: "year_of_make", visible: false },
          { id: "seating_capacity", visible: false },
          { id: "own_weight", visible: false },
          { id: "gross_weight", visible: false },
          { id: "insurance_expires", type: "unixDate", visible: false },
          { id: "insurance_payed_till", type: "unixDate", visible: false },
          { id: "insurer", visible: false },
          { id: "insurance_policy_no", visible: false },
          { id: "daily_price", type: "currency", visible: false },
          { id: "next_oil_at_km", visible: false },
          { id: "tracker_imei", visible: false },
          { id: "tracker_phone", visible: false },
          { id: "online", type: "checkbox" },
          { id: "last_signal", type: "unixRelative" },
          { id: "active", type: "checkbox", visible: false },
          { id: "sell_date", type: "unixDate", visible: false },
          {
            id: "tools",
            type: "text",
            size: 12,
            multiline: true,
            rows: 5,
            visible: false
          }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const { user } = this.props;
    return (
      <div>
        <DataGrid
          user={user}
          filterTabs={["active", "inactive"]}
          definition={definition}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("vehicles", column.id);
          }}
          access={this.state.access}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return item.platenumber;
            } else {
              return "Új jármű";
            }
          }}
        />
      </div>
    );
  }
}

Vehicles.propTypes = {
  user: PropTypes.object.isRequired
};

export default Vehicles;
