import React from "react";
//import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import DatePicker from "common/components/DatePicker";
import dateHelper from "common/utils/dateHelper";
class FormDateRangePickerField extends React.Component {
  /*componentDidMount() {
    this.color = this.input.style.color;
    if (!this.props.value) {
      this.input.style.color = "transparent";
    }
  }*/

  constructor(props) {
    super(props);
    this.state = {
      //dates: dates,
      time: new Date().getTime()
    };
  }

  convertDate = (value, type) => {
    let m = new Date(value);

    //Dátum iso string yyyy-mm-dd
    const dateStr =
      m.getFullYear() +
      "-" +
      ("0" + (m.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + m.getDate()).slice(-2);

    //Time iso string HH:mm
    const timeStr =
      ("0" + m.getHours()).slice(-2) + ":" + ("0" + m.getMinutes()).slice(-2);

    //Typusnak megfelelően a date string összeállítása
    let strValue = "";
    let fieldType = "datetime";
    switch (type) {
      case "unixDate":
        strValue = dateStr;
        fieldType = "date";
        break;
      case "unixTime":
        strValue = timeStr;
        fieldType = "time";
        break;
      case "unixDateTime":
      default:
        strValue = dateStr + " " + timeStr;
        break;
    }

    return { strValue, fieldType };
  };

  onChange = (value, rangeKey) => {
    const { /*id,*/ fields } = this.props;
    console.log("onChange", value, rangeKey);
    const v = rangeKey ? rangeKey : value;
    const vs = rangeKey
      ? rangeKey
      : v
      ? { start: v.start.unix(), end: v.end.unix() }
      : null;

    if (vs) {
      sessionStorage.setItem("pays", JSON.stringify({ dates: vs }));
    } else {
      sessionStorage.removeItem("pays");
    }
    this.setState({
      dates: v,
      time: new Date().getTime()
    });
    //console.log(vs, v, { [fields.start]: vs.start, [fields.end]: vs.end });
    if (typeof vs === "string") {
      this.props.onChange({
        [fields.start]: value
          ? dateHelper.unixToDate(value.start.unix())
          : null,
        [fields.end]: value ? dateHelper.unixToDate(value.end.unix()) : null
      });
    } else {
      this.props.onChange({
        [fields.start]: vs ? dateHelper.unixToDate(vs.start) : null,
        [fields.end]: vs ? dateHelper.unixToDate(vs.end) : null
      });
    }
  };

  render() {
    const {
      // id,
      // name,
      error,
      value,
      // type,
      //  onChange,
      access,
      //  classes,
      //  dirty
      initialYear,
      initialMonth,
      required
    } = this.props;

    const v1 = value
      ? {
          start: dateHelper.dateToUnix(value.start),
          end: dateHelper.dateToUnix(value.end)
        }
      : null;

    const v = dateHelper.unixToRange(v1);
    //console.log(value, v1, v);
    /*const { strValue, fieldType } = this.convertDate(value, type);

    if (!value || value === "") {
      if (this.input) {
        this.input.style.color = "transparent";
      }
    }*/
    return (
      <DatePicker
        key={this.state.time}
        onChange={this.onChange}
        value={v}
        selectionType="range"
        numberOfCalendars={2}
        label="Időszak"
        singleDateRange={true}
        firstOfWeek={1}
        helperText={error || ""}
        disabled={access !== "w" ? true : false}
        initialYear={initialYear}
        initialMonth={initialMonth}
      />
    );
    /* return (
      <TextField
        inputRef={ref => (this.input = ref)}
        id={id}
        label={name}
        helperText={error || ""}
        error={error ? true : false}
        disabled={access !== "w" ? true : false}
        //format="YYYY.MM.DD."
        //InputLabelProps={{ shrink: true }}
        //className={classes.textField}
        onFocus={() => (this.input.style.color = this.color)}
        onBlur={() => {
          if (!value || value === "") {
            this.input.style.color = "transparent";
          }
        }}
        fullWidth
        type={fieldType}
        //defaultValue={value ? strValue : ""}
        value={value ? strValue : ""}
        autoComplete="off"
        onChange={evt => onChange({ [id]: evt.target.value })}
        margin="normal"
        //variant="outlined"
        className={dirty && !error ? classes.dirtyTextField : ""}
      />
    );*/
  }
}

FormDateRangePickerField.propTypes = {
  classes: PropTypes.object.isRequired,
  //theme: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.object,
  fields: PropTypes.object,
  type: PropTypes.string.isRequired,
  error: PropTypes.string,
  access: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  dirty: PropTypes.bool,
  initialYear: PropTypes.number,
  initialMonth: PropTypes.number
};

export default FormDateRangePickerField;
