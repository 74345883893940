import { IconButton, Link, Tooltip } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import QtyIcon from "@material-ui/icons/Adjust";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import PivotIcon from "@material-ui/icons/CalendarToday";
import ListIcon from "@material-ui/icons/List";
import XlsxIcon from "common/assets/icons/XlsxIcon";
import FormSelectField from "common/components/DataForm/FormSelectField";
import DatePicker from "common/components/DatePicker";
//import JsonSyntaxHighlight from "common/components/JsonSyntaxHighlight";
import SimpleTable from "common/components/SimpleTable/SimpleTable";
import SimpleTableCard from "common/components/SimpleTable/SimpleTableCard";
import dateHelper from "common/utils/dateHelper";
import formatHelper from "common/utils/formatHelper";
import urlHelper from "common/utils/urlHelper";
import React, { Fragment } from "react";

const styles = theme => ({
  formGroup: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
});

const Action = ({ onClick, onClickAgr, grouped, agr, url, filter }) => {
  return (
    <Fragment>
      {grouped && onClickAgr && (
        <Tooltip
          title={
            agr === "amount" ? "Váltás mennyiségekre" : "Váltás összegekre"
          }
        >
          <IconButton onClick={onClickAgr} color="primary">
            {agr === "amount" ? <QtyIcon /> : <MoneyIcon />}
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={grouped ? "Váltás listára" : "Váltás napi összesítésre"}>
        <IconButton onClick={onClick} color="primary">
          {grouped ? <ListIcon /> : <PivotIcon />}
        </IconButton>
      </Tooltip>
      <Tooltip title="Excel exportálás">
        <IconButton
          component={Link}
          href={`${urlHelper.downloadExcelLink(url, { filter })}&grouped=${
            grouped ? "true" : "false"
          }&agr=${agr}`}
          color="primary"
        >
          <XlsxIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
};

const ReportPanel = ({
  name,
  title,
  filter,
  visible,
  toggleGrouped,
  grouped,
  toggleAgr,
  agr,
  columns,
  url
}) =>
  visible(name) && (
    <Grid item={true} xs={12} md={12}>
      <SimpleTableCard
        title={title}
        action={
          <Action
            onClick={toggleGrouped(name)}
            grouped={grouped(name)}
            onClickAgr={toggleAgr && toggleAgr(name)}
            agr={agr(name)}
            url={url}
            filter={filter()}
          />
        }
      >
        <SimpleTable
          columns={columns}
          url={url}
          filter={filter()}
          grouped={grouped(name)}
          agr={agr(name)}
        />
      </SimpleTableCard>
    </Grid>
  );

class WorkReport extends React.Component {
  constructor(props) {
    super(props);
    const dayOfMonth = new Date().getDate();
    let dates = null;
    const storage = localStorage.getItem("workreport");
    let storageData = {
      project: null,
      project_id: null,
      vis_workedHours: true
    };
    if (storage) {
      storageData = JSON.parse(storage);
      if (storageData && storageData.dates) {
        if (typeof storageData.dates !== "string") {
          storageData.dates = dateHelper.stringToRange(storageData.dates);
        }
      }
    }
    if (!storageData.dates) {
      storageData.dates = dayOfMonth < 10 ? "lastMonth" : "thisMonth";
    }
    this.state = {
      ...storageData
    };
  }

  componentDidMount() {
    //this.loadData();
  }

  storeState = state => {
    this.setState(state);
    const context = this;
    setTimeout(() => {
      localStorage.setItem("workreport", JSON.stringify(context.state));
    }, 500);
  };

  onChangeproject = data => {
    this.storeState({
      project: data.project,
      project_id: data.project_id
    });
  };

  filter = () => {
    const dates = this.parseValue(this.state.dates);
    let filter = [];
    if (dates) {
      filter.push([
        "worksheet.day",
        "between",
        [dates.start.unix(), dates.end.unix()]
      ]);
    }

    if (this.state.project) {
      filter.push(["worksheet.project_id", "in", this.state.project_id]);
    }

    return filter.length > 0 ? filter : null;
  };

  parseValue = value => {
    if (typeof value === "string") {
      return dateHelper.getRange(value);
    } else {
      return value;
    }
  };

  onChange = (value, rangeKey) => {
    const v = rangeKey ? rangeKey : value;
    const vs = rangeKey
      ? rangeKey
      : v
      ? { start: v.start.unix(), end: v.end.unix() }
      : null;

    if (vs) {
      sessionStorage.setItem("workreport", JSON.stringify({ dates: vs }));
    } else {
      sessionStorage.removeItem("workreport");
    }
    this.storeState({
      dates: v
    });
  };

  toggleGrouped = name => () => {
    this.storeState({ [name]: !this.state[name] });
  };

  grouped = name => {
    return this.state[name] ? true : false;
  };

  toggleVisible = name => () => {
    this.storeState({ [`vis_${name}`]: !this.state[`vis_${name}`] });
  };

  visible = name => {
    return this.state[`vis_${name}`] ? true : false;
  };

  toggleAgr = name => () => {
    this.storeState({
      [`agr_${name}`]: this.state[`agr_${name}`] === "amount" ? "qty" : "amount"
    });
  };

  agr = name => {
    return this.state[`agr_${name}`] === "amount" ? "amount" : "qty";
  };

  render() {
    const { classes, theme } = this.props;
    const { project } = this.state;

    const column = {
      id: "project_id",
      type: "select",
      search: "project.name",
      access: "w",
      name: "Projekt",
      datasource: {
        controller: "projects",
        objectField: "project",
        valueField: "id",
        search: ["customer.name"],
        filter: [["project.active", "exact", true]],
        sort: { "project.name": "asc" },
        label: "name"
      }
    };

    return (
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={12} sm={6} md={5} lg={4} xl={3}>
          <div
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 8,
              marginBottom: -16
            }}
          >
            <DatePicker
              key={this.state.time}
              onChange={this.onChange}
              value={this.state.dates}
              selectionType="range"
              numberOfCalendars={2}
              label="Időszak"
              singleDateRange={true}
              firstOfWeek={1}
            />
          </div>
        </Grid>
        <Grid item={true} xs={12} sm={6} md={7} lg={8} xl={9}>
          <div
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 8,
              marginBottom: -16
            }}
          >
            <FormSelectField
              id={column.id}
              name={column.name}
              options={column.options}
              datasource={column.datasource}
              value={project ? project.id : null}
              column={column}
              isDeleted={false}
              currentValue={project}
              error={null}
              onChange={this.onChangeproject}
              theme={theme}
              item={{}}
              dirty={false}
              isMulti={true}
            />
          </div>
        </Grid>
        <Grid item={true} xs={12} md={12}>
          <FormGroup row className={classes.formGroup}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.visible("workedHours")}
                  onChange={this.toggleVisible("workedHours")}
                  color="primary"
                />
              }
              label="Ledolgozott munka"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.visible("billableTasks")}
                  onChange={this.toggleVisible("billableTasks")}
                  color="primary"
                />
              }
              label="Számlázható tételek"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.visible("costs")}
                  onChange={this.toggleVisible("costs")}
                  color="primary"
                />
              }
              label="Költségek"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.visible("billableVehicles")}
                  onChange={this.toggleVisible("billableVehicles")}
                  color="primary"
                />
              }
              label="Járművek bérbeadása"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.visible("billableHours")}
                  onChange={this.toggleVisible("billableHours")}
                  color="primary"
                />
              }
              label="Számlázható munka"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.visible("vehicleCosts")}
                  onChange={this.toggleVisible("vehicleCosts")}
                  color="primary"
                />
              }
              label="Jármű költségek"
            />
          </FormGroup>
        </Grid>
        <ReportPanel
          name="workedHours"
          title="Ledolgozott munka"
          url="work-reports/worked-hours"
          columns={[
            { id: "day", label: "Nap", format: dateHelper.unixToDate },
            { id: "name", label: "Név" },
            {
              id: "qty",
              label: "Óra",
              format: formatHelper.numberFormat
            },
            {
              id: "amount",
              label: "Összeg",
              format: formatHelper.currency
            },
            {
              id: "type",
              label: "Típus"
            },
            {
              id: "foreman_name",
              label: "Munkavezető"
            },
            {
              id: "project_name",
              label: "Projekt"
            }
          ]}
          filter={this.filter}
          visible={this.visible}
          toggleGrouped={this.toggleGrouped}
          grouped={this.grouped}
          toggleAgr={this.toggleAgr}
          agr={this.agr}
        />
        <ReportPanel
          name="billableTasks"
          title="Számlázható tételek"
          url="work-reports/billable-tasks"
          columns={[
            { id: "day", label: "Nap", format: dateHelper.unixToDate },
            { id: "task_name", label: "Név" },
            {
              id: "qty",
              label: "Mennyiség",
              format: formatHelper.numberFormat
            },
            {
              id: "unit_name",
              label: "Menny. Egys."
            },
            {
              id: "amount",
              label: "Összeg",
              format: formatHelper.currency
            },
            {
              id: "task_number",
              label: "Feladat az."
            },
            {
              id: "user_name",
              label: "Munkavezető"
            },
            {
              id: "project_name",
              label: "Projekt"
            }
          ]}
          filter={this.filter}
          visible={this.visible}
          toggleGrouped={this.toggleGrouped}
          grouped={this.grouped}
          toggleAgr={this.toggleAgr}
          agr={this.agr}
        />
        <ReportPanel
          name="costs"
          title="Költségek"
          url="work-reports/costs"
          columns={[
            { id: "day", label: "Nap", format: dateHelper.unixToDate },
            { id: "name", label: "Név" },
            {
              id: "amount",
              label: "Összeg",
              format: formatHelper.currency
            },
            {
              id: "user_name",
              label: "Munkavezető"
            },
            {
              id: "description",
              label: "Megjegyzés"
            },
            {
              id: "platenumber",
              label: "Rendszám"
            },
            {
              id: "project_name",
              label: "Projekt"
            }
            /*{
              id: "details",
              label: "Részletek",
              format: value => {
                const arr = value.split("|");
                return arr.map((row, index) => {
                  return <div key={index}>{row}</div>;
                });
              }
            }*/
          ]}
          filter={this.filter}
          visible={this.visible}
          toggleGrouped={this.toggleGrouped}
          grouped={this.grouped}
          //toggleAgr={this.toggleAgr}
          agr={this.agr}
        />
        <ReportPanel
          name="billableVehicles"
          title="Járművek bérbeadása"
          url="work-reports/billable-vehicles"
          columns={[
            { id: "day", label: "Nap", format: dateHelper.unixToDate },
            { id: "name", label: "Rendszám" },
            {
              id: "qty",
              label: "Napok száma",
              format: formatHelper.numberFormat
            },
            {
              id: "amount",
              label: "Összeg",
              format: formatHelper.currency
            },
            {
              id: "foreman_name",
              label: "Munkavezető"
            },
            {
              id: "project_name",
              label: "Projekt"
            }
          ]}
          filter={this.filter}
          visible={this.visible}
          toggleGrouped={this.toggleGrouped}
          grouped={this.grouped}
          toggleAgr={this.toggleAgr}
          agr={this.agr}
        />
        <ReportPanel
          name="billableHours"
          title="Számlázható munka"
          url="work-reports/billable-hours"
          columns={[
            { id: "day", label: "Nap", format: dateHelper.unixToDate },
            { id: "name", label: "Név" },
            {
              id: "qty",
              label: "Óra",
              format: formatHelper.numberFormat
            },
            {
              id: "amount",
              label: "Összeg",
              format: formatHelper.currency
            },
            {
              id: "user_name",
              label: "Felhasználó"
            },
            {
              id: "foreman_name",
              label: "Munkavezető"
            },
            {
              id: "project_name",
              label: "Projekt"
            }
          ]}
          filter={this.filter}
          visible={this.visible}
          toggleGrouped={this.toggleGrouped}
          grouped={this.grouped}
          toggleAgr={this.toggleAgr}
          agr={this.agr}
        />
        <ReportPanel
          name="vehicleCosts"
          title="Jármű költségek"
          url="work-reports/vehicle-costs"
          columns={[
            { id: "day", label: "Nap", format: dateHelper.unixToDate },
            { id: "name", label: "Rendszám" },
            {
              id: "qty",
              label: "Napok száma",
              format: formatHelper.numberFormat
            },
            {
              id: "amount",
              label: "Összeg",
              format: formatHelper.currency
            },
            {
              id: "foreman_name",
              label: "Munkavezető"
            },
            {
              id: "project_name",
              label: "Projekt"
            }
          ]}
          filter={this.filter}
          visible={this.visible}
          toggleGrouped={this.toggleGrouped}
          grouped={this.grouped}
          toggleAgr={this.toggleAgr}
          agr={this.agr}
        />
      </Grid>
    );
  }
}

WorkReport.propTypes = {};

export default withStyles(styles)(WorkReport);
