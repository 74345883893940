import settings from "variables/settings";

const getUrlParams = params => {
  var str = "";
  for (var key in params) {
    if (str !== "") {
      str += "&";
    }
    if (params[key]) {
      if (typeof params[key] === "object") {
        str += key + "=" + encodeURIComponent(JSON.stringify(params[key]));
      } else {
        str += key + "=" + encodeURIComponent(params[key]);
      }
    }
  }
  return str;
};

const urlHelper = {
  get: path => {
    let slugs = [];

    if (path) {
      slugs = path.substr(1).split("/");
    } else {
      slugs = window.location.pathname.substr(1).split("/");
    }

    let key = "";
    let opened = {};
    for (let i in slugs) {
      key += "/" + slugs[i];
      opened[key] = true;
    }
    return {
      opened: opened,
      path: key
    };
  },
  publicDownloadLink: (id, token, inline) =>
    `${settings.api.url}/downloads/${id}/${token}${inline ? "&inline=1" : ""}`,
  downloadLink: (id, inline, params) => {
    const urlParams = getUrlParams(params);
    return (
      settings.api.url +
      "/files/download/" +
      id +
      "?token=" +
      localStorage.getItem("token") +
      (inline ? "&inline=1" : "") +
      (urlParams ? "&" + urlParams : "")
    );
  },
  viewPdfLink: (controller, id, template, inline) =>
    `${
      settings.api.url
    }/${controller}/pdf/${id}/${template}?token=${localStorage.getItem(
      "token"
    )}${inline ? "&inline=1" : ""}`,
  downloadExcelLink: (controller, params) => {
    const urlParams = getUrlParams(params);
    return `${
      settings.api.url
    }/${controller}/excel?token=${localStorage.getItem("token")}${
      urlParams ? "&" + urlParams : ""
    }`;
  },
  downloadExcelLinkNew: (controller, params) => {
    const urlParams = getUrlParams(params);
    return `${
      settings.api.url
    }/${controller}?excel=1&token=${localStorage.getItem("token")}${
      urlParams ? "&" + urlParams : ""
    }`;
  },
  getViewParams: (history, location, match) => {
    let path = location.pathname.replace(match.url, "");
    if (path[0] === "/") {
      path = path.substring(1);
    }
    const params = path.split("/");
    let result = null;
    if (!params || params.length === 0) {
      return result;
    }

    if (["create", "edit", "view"].indexOf(params[0]) < 0) {
      return result;
    }

    if (params.length === 1) {
      if (params[0] !== "create") {
        return result;
      }
      result = {
        mode: params[0]
      };
    } else if (params.length > 1) {
      if (isNaN(params[1])) {
        return result;
      }
      result = {
        mode: params[0],
        id: parseInt(params[1])
      };
    }
    return result;
  }
};

export default urlHelper;
