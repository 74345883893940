import React from "react";
//import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import formatHelper from "common/utils/formatHelper";
import InputBase from "@material-ui/core/InputBase";
function limit(val, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = "0" + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = "00";

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

function timestamp(val) {
  let month = limit(val.substring(0, 2), "23");
  let year = limit(val.substring(2, 4), "59");

  return month + (year.length ? ":" + year : "");
}

/*function currency(val) {
  val += "";
  let x = val.split(".");
  let x1 = x[0];
  let x2 = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + " " + "$2");
  }
  return x1 + x2 + " Ft";
}*/

const formattedTypes = {
  unixTime: true,
  currency: true
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  //a formatnak megfelelően hozzáadjuk az egyedi tulajdonságokat
  let customProps = {};
  switch (props.component_type) {
    case "unixTime":
      customProps.format = timestamp;
      customProps.placeholder = "00:00";
      break;
    case "currency":
      //customProps.suffix = "Ft";
      customProps.format = formatHelper.currency;
      //customProps.thousandSeparator = true;
      customProps.placeholder = " Ft";
      break;

    default:
      //customProps.format = props.format;
      break;
  }

  return (
    <NumberFormat
      {...other}
      //getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      {...customProps}
      //thousandSeparator
      //prefix="$"
      //format="##:##"
      //format={formatter(props.format)}
      //placeholder={props.placeholder}
      //mask={["0", "0", "0", "0"]}
    />
  );
}

NumberFormatCustom.propTypes = {
  //inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

class GridTextField extends React.Component {
  onKeyDown = evt => {
    if (evt.key === "Enter") {
      return this.props.onSave();
    } else if (evt.key === "Escape") {
      this.props.onCancel();
      return false;
    }
    return false;
  };

  render() {
    const {
      id,
      name,
      // error,
      value,
      onChange,
      type,
      placeholder,
      classes,
      access
    } = this.props;

    if (formattedTypes[type]) {
      //InputProps.inputComponent = NumberFormatCustom;
      return (
        <div>
          <NumberFormatCustom
            //inputRef={ref => (this.email = ref)}
            id={id}
            label={name}
            //helperText={error || ""}
            //error={error ? true : false}
            //defaultValue={defaultValue}
            //InputLabelProps={inputLabelProps}
            disabled={access !== "w" ? true : false}
            className={classes.baseInput}
            //fullWidth
            defaultValue={value ? value : ""}
            autoComplete="off"
            onChange={evt => onChange({ [id]: evt.target.value })}
            //onKeyDown={this.onKeyDown}
            placeholder={placeholder}
            component_type={type}
            /*style={{
              paddingTop: 7,
              paddingBottom: 6,
              paddingRight: 5,
              paddingLeft: 5,
              fontSize: "inherit",
              margin: 0,
              font: "inherit",
              //WebkitFontSmoothing: "antialiased",
              border: "none",
              width: "100%",
              WebkitTapHighlightColor: "transparent",
              display: "block",
              minWidth: 0,
              boxSizing: "content-box",
              background: "none",
              "&:focus": {
                outline: 0
              }
            }}*/
            // eslint-disable-next-line
          //InputProps={InputProps}
          />
          {/*error || ""*/}
        </div>
      );
    }

    let ip = {
      component_type: type
    };

    if (type === "password") {
      ip.type = "password";
    }
    //console.log(access);
    return (
      <div>
        <InputBase
          //inputRef={ref => (this.email = ref)}
          id={id}
          label={name}
          //helperText={error || ""}
          //error={error ? true : false}
          //defaultValue={defaultValue}
          //InputLabelProps={inputLabelProps}
          //className={classes.textField}
          disabled={access !== "w" ? true : false}
          fullWidth
          defaultValue={value ? value : ""}
          autoComplete="off"
          onChange={evt => onChange({ [id]: evt.target.value })}
          //onKeyDown={this.onKeyDown}
          placeholder={placeholder}
          inputProps={ip}
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            fontSize: "inherit",
            marginTop: 1,
            font: "inherit"
            //WebkitFontSmoothing: "antialiased"
          }}
          // eslint-disable-next-line
        //InputProps={InputProps}
        />
        {/*error || ""*/}
      </div>
    );
  }
}

GridTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  format: PropTypes.string,
  type: PropTypes.string,
  access: PropTypes.string,
  placeholder: PropTypes.string
};

export default GridTextField;
