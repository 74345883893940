import React, { Fragment } from "react";
//import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import NumberFormat from "react-number-format";
import UploadButton from "common/components/UploadButton";
import DownloadButton from "common/components/DownloadButton";
import settings from "variables/settings";
import { Avatar } from "@material-ui/core";
import urlHelper from "common/utils/urlHelper";
import ImageViewer from "common/components/ImageViewer";

function isImage(type) {
  const validTypes = ["image/jpeg", "image/jpg", "image/png"];
  return validTypes.indexOf(type) >= 0;
}

function limit(val, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = "0" + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = "00";

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

function timestamp(val) {
  let month = limit(val.substring(0, 2), "23");
  let year = limit(val.substring(2, 4), "59");

  return month + (year.length ? ":" + year : "");
}

const formattedTypes = {
  unixTime: true,
  currency: true
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  //a formatnak megfelelően hozzáadjuk az egyedi tulajdonságokat
  let customProps = {};
  switch (props.component_type) {
    case "unixTime":
      customProps.format = timestamp;
      customProps.placeholder = "00:00";
      break;
    case "currency":
      customProps.suffix = " Ft";
      //customProps.format = formatHelper.currency;
      customProps.thousandSeparator = " ";
      customProps.decimalScale = 4;
      customProps.placeholder = " Ft";
      break;

    default:
      //customProps.format = props.format;
      break;
  }

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      {...customProps}
      //thousandSeparator
      //prefix="$"
      //format="##:##"
      //format={formatter(props.format)}
      //placeholder={props.placeholder}
      //mask={["0", "0", "0", "0"]}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

class FormFileField extends React.Component {
  onKeyDown = evt => {
    if (evt.key === "Enter") {
      return this.props.onSave && this.props.onSave();
    } else if (evt.key === "Escape") {
      this.props.onCancel && this.props.onCancel();
      return false;
    }
    return false;
  };

  valueField = () => {
    const { id } = this.props;
    return id.substr(id.length - 3) === "_id"
      ? id.substr(0, id.length - 3)
      : id;
  };

  onUpload = (fileData, file) => {
    const { id, onChange } = this.props;
    const valueField = this.valueField();
    onChange({ [id]: fileData.id, [valueField]: fileData });
  };

  onRemove = (fileData, file) => {
    const { id, onChange } = this.props;
    const valueField = this.valueField();
    onChange({ [id]: null, [valueField]: null });
  };

  render() {
    const {
      id,
      name,
      error,
      type,
      placeholder,
      access,
      dirty,
      classes,
      helperText,
      multiline,
      rows,
      item,
      isNew,
      required,
      column,
      viewMode
    } = this.props;
    const valueField = this.valueField();
    const disabled = access !== "w" ? true : false;
    var isPdf =
      item && item[valueField] && item[valueField].type === "application/pdf";
    var isInline = isPdf && !column.pdfNotInline ? true : false;
    //console.log("RENDER", valueField, item);
    let InputProps = {
      classes: { root: classes.fileInputRoot },
      startAdornment:
        item[valueField] && isImage(item[valueField].type) ? (
          <ImageViewer
            file_id={item[valueField].id}
            name={item[valueField].name}
            avatarStyle={{ marginLeft: -12, marginRight: 12 }}
          />
        ) : (
          undefined
        ),
      endAdornment: isNew ? (
        <UploadButton
          name={id}
          onUpload={this.onUpload}
          onRemove={this.onRemove}
          file={item[valueField]}
          disableDelete={!isNew}
          //disabled={disabled}
        />
      ) : (
        <Fragment>
          {item[id] && item[id] !== "" && (
            <DownloadButton
              id={item[valueField] ? item[valueField].id : null}
              color={settings.actionColor}
              iconProps={viewMode ? { fontSize: "small" } : {}}
              buttonProps={viewMode ? { style: { padding: 4 } } : {}}
              isInline={isInline}
            />
          )}
          <UploadButton
            name={id}
            onUpload={this.onUpload}
            onRemove={this.onRemove}
            file={item[valueField]}
            iconProps={viewMode ? { fontSize: "small" } : {}}
            buttonProps={
              viewMode ? { style: { padding: 4 }, disabled } : { disabled }
            }
            //disabled={disabled}
            disableDelete={disabled && !isNew}
          />
        </Fragment>
      )
    };
    if (formattedTypes[type]) {
      InputProps.inputComponent = NumberFormatCustom;
    }
    let ip = {
      component_type: type,
      autoComplete: "off",
      autoCapitalize: "off",
      autoCorrect: "off",
      spellCheck: "false",
      disabled: true
    };

    if (type === "password") {
      //ip.type = "password";
      ip.style = {
        fontFamily: "text-security-disc"
      };
    }

    return viewMode ? (
      <InputBase
        //inputRef={ref => (this.email = ref)}
        id={id}
        label={required ? `${name} *` : name}
        //helperText={error || helperText || ""}
        error={error ? true : false}
        //defaultValue={defaultValue}
        //InputLabelProps={inputLabelProps}
        className={dirty && !error ? classes.dirtyTextField : ""}
        fullWidth
        disabled={disabled}
        //defaultValue={value ? value : ""}
        //value={value ? value : ""}
        value={item && item[valueField] ? item[valueField].name : ""}
        //onChange={evt => onChange({ [id]: evt.target.value })}
        onKeyDown={this.onKeyDown}
        //margin="normal"
        ////variant="outlined"
        placeholder={placeholder}
        inputProps={ip}
        multiline={multiline}
        rows={rows}
        // eslint-disable-next-line
        {...InputProps}
      />
    ) : (
      <TextField
        //inputRef={ref => (this.email = ref)}
        id={id}
        label={required ? `${name} *` : name}
        helperText={error || helperText || ""}
        error={error ? true : false}
        //defaultValue={defaultValue}
        //InputLabelProps={inputLabelProps}
        className={dirty && !error ? classes.dirtyTextField : ""}
        fullWidth
        disabled={disabled}
        //defaultValue={value ? value : ""}
        //value={value ? value : ""}
        value={item && item[valueField] ? item[valueField].name : ""}
        //onChange={evt => onChange({ [id]: evt.target.value })}
        onKeyDown={this.onKeyDown}
        margin="normal"
        //variant="outlined"
        placeholder={placeholder}
        inputProps={ip}
        multiline={multiline}
        rows={rows}
        // eslint-disable-next-line
        InputProps={InputProps}
      />
    );
  }
}

FormFileField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  format: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  access: PropTypes.string,
  helperText: PropTypes.string,
  dirty: PropTypes.bool,
  multiline: PropTypes.bool,
  viewMode: PropTypes.bool,
  rows: PropTypes.number
};

export default FormFileField;
