import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import restHelper from "common/utils/restHelper";
import AsyncSelect from "react-select/async";

class GridSelectField extends React.Component {
  state = {
    currentValue: {
      value: null,
      label: null
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.obtainCurrentValue(nextProps);
  }

  componentDidMount() {
    this.obtainCurrentValue(this.props);
  }

  obtainCurrentValue = props => {
    const { currentValue, datasource } = props;
    if (currentValue) {
      //let object = null;
      let cval = datasource.valueField;
      if (datasource.valueField.indexOf(".") >= 0) {
        const va = datasource.valueField.split(".");
        //object = va[0];
        cval = va[1];
      }

      let currentValuePrepared = null;
      //Ha nem eredeti adatbázis objektum, hanem már preparált currentvalue
      if (currentValue._original) {
        currentValuePrepared = currentValue;
      } else {
        currentValuePrepared = {
          value: currentValue[cval],
          label:
            typeof datasource.label === "function"
              ? datasource.label(currentValue)
              : currentValue[datasource.label],
          _original: currentValue
        };
      }

      this.setState({
        currentValue: currentValuePrepared
      });
    } else {
      this.currentValue(props);
    }
  };

  currentValue = props => {
    //Előre megadott opcióknál feltöltjük a kezdeti értéket
    if (props.options) {
      let currentValue = { value: null, label: null };
      for (let i in props.options) {
        if (props.options[i].value === props.value) {
          currentValue = props.options[i];
        }
      }
      this.setState({
        currentValue: currentValue
      });
      return;
    }

    //Adatforrásnál kilépünk, ha nincs megadva
    if (!props.datasource) {
      this.setState({ currentValue: null });
      return;
    }

    const { controller, label, value } = props.datasource;

    //Adatforrásnál kilépünk, ha nincs érték megadva
    if (!props.value) {
      this.setState({ currentValue: null });
      return;
    }
    //Adatforrásnál elkérjük az aktuális értéket
    restHelper.view(controller, props.value).then(item => {
      this.setState({
        currentValue: {
          value: item[value],
          label: typeof label === "function" ? label(item) : item[label]
        }
      });
    });
  };

  handleInputChange = newValue => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({ inputValue });
    return inputValue;
  };

  loadOptions = (inputValue, callback) => {
    const {
      controller,
      label,
      valueField,
      search,
      sort
    } = this.props.datasource;
    const fiXFilter = this.props.datasource.filter;

    let filter = [];
    if (search) {
      for (let i in search) {
        if (i === "0") {
          filter.push([search[i], "contains", inputValue]);
        } else {
          if (!filter[0][3]) {
            filter[0].push([]);
          }

          filter[0][3].push([search[i], "contains", inputValue]);
        }
      }
    } else if (label && typeof label !== "function") {
      filter.push([label, "contains", inputValue]);
    }

    if (fiXFilter) {
      for (let i in fiXFilter) {
        filter.push(fiXFilter[i]);
      }
    }

    let object = null;
    let cval = null;
    if (valueField.indexOf(".") >= 0) {
      const va = valueField.split(".");
      object = va[0];
      cval = va[1];
    }

    let params = { filter: filter, pagesize: 200 };
    if (sort) {
      params.sort = sort;
    }
    restHelper.index(controller, params).then(items => {
      const suggestions = items.data.map((item, key) => {
        const sItem = object ? item[object] : item;
        return {
          value: object ? sItem[cval] : item[valueField],
          label: typeof label === "function" ? label(sItem) : sItem[label],
          _original: sItem
        };
      });
      callback(suggestions);
    });
  };

  onChange = (id, currentValue) => {
    this.setState({ currentValue: currentValue });
    let changedItems = {
      [id]: currentValue ? currentValue.value : null
    };

    if (
      this.props.column.datasource &&
      this.props.column.datasource.objectField
    ) {
      changedItems[this.props.column.datasource.objectField] = currentValue
        ? currentValue._original
        : null;
    }
    this.props.onChange(changedItems);
  };

  onKeyDown = evt => {
    if (evt.key === "Enter") {
      return this.props.onSave ? this.props.onSave() : true;
    } else if (evt.key === "Escape") {
      if (this.props.onCancel) this.props.onCancel();
      return false;
    }
    return false;
  };

  render() {
    const {
      id,
      name,
      error,
      //value,
      //onChange,
      classes,
      options,
      datasource,
      column
    } = this.props;

    const selectStyles = {
      //position: "relative",
      container: base => ({
        ...base,
        marginTop: 2,
        paddingLeft: 8,
        paddingRight: 8,
        marginBottom: 0
        //zIndex: 9999998
      }),
      input: base => ({
        ...base,
        //color: theme.palette.text.primary,
        "& input": {
          font: "inherit"
        }
      }),
      indicatorsContainer: base => ({
        ...base,
        //color: theme.palette.text.primary,
        //display: "none",
        marginTop: -4,
        marginBottom: -4
      }),
      control: base => ({
        ...base,
        borderRadius: 0,
        border: "none",
        borderWidth: 0,
        boxShadow: "none",
        minHeight: 31,
        //marginTop: 1,
        paddingLeft: 0,
        //marginBottom: 1,
        "&:hover": {
          border: "none",
          borderWidth: 0,
          boxShadow: "none"
        }
        //backgroundColor: "red"
        //position: "absolute",
      }),
      menu: base => ({
        ...base
        //marginTop:  "auto",
        //height: "auto",
        //position: "absolute",
        //top: "calc(100% - 100px)",
        //bottom: 0,
        //width: "auto",
        //top: 10,
        //left: 10,
        //bottom: 0,
        //zIndex: 9999
        //height: 400
        //backgroundColor: "white"
      }),
      valueContainer: base => ({
        ...base,
        padding: "0px 0px",
        marginTop: 0,
        //marginBottom: -1,
        marginLeft: -5
        //height: 400
      }),
      singleValue: base => ({
        ...base,
        paddingBottom: 2
        //height: 400
      }),
      menuList: base => ({
        ...base,
        zIndex: 9999999
        /*position: "absolute",
        backgroundColor: "white",
        right: 0,
        left: 0,
        bottom: "46px !important",
        zIndex: 9999999,
        boxShadow: "0px 0px 3px 1px rgba(0,0,0,0.75)"*/
      }),
      option: base => ({
        ...base,
        //position: "absolute"
        color: "black!important"
        //height: 400
      })
    };
    return options ? (
      <Select
        classes={classes}
        styles={selectStyles}
        //components={components}
        value={this.state.currentValue}
        //onKeyDown={this.onKeyDown}
        onChange={val => this.onChange(id, val)}
        options={options}
        error={error}
        label={name}
        isClearable
        placeholder=""
        isDisabled={column.access !== "w" ? true : false}
      />
    ) : datasource ? (
      <AsyncSelect
        key={id}
        classes={classes}
        styles={selectStyles}
        //components={components}
        value={this.state.currentValue}
        //onKeyDown={this.onKeyDown}
        onChange={val => this.onChange(id, val)}
        isClearable
        error={error}
        label={name}
        loadOptions={this.loadOptions}
        cacheOptions
        defaultOptions
        onInputChange={this.handleInputChange}
        placeholder=""
        isDisabled={column.access !== "w" ? true : false}
      />
    ) : (
      "No items defined"
    );
  }
}

GridSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  options: PropTypes.array,
  datasource: PropTypes.object,
  currentValue: PropTypes.object,
  column: PropTypes.object.isRequired
};

export default GridSelectField;
