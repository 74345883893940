import React, { Component, Fragment } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import SignaturePad from "react-signature-canvas";
import Link from "@material-ui/core/Link";
import Modal from "common/components/Modals/Modal";
import { Toolbar, Checkbox, FormControlLabel, Button } from "@material-ui/core";

const styles = theme => ({
  canvas: {
    width: " calc(100% - 2px)",
    border: "solid 1px #ccc",
    marginTop: theme.spacing(2)
  }
});

class Signature extends Component {
  state = {
    signatureIsEmpty: true,
    openASZF: false,
    accepted: false
  };

  sigPad = {};

  scrollToEnd = () => {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 200);
  };

  getSignature = () => {
    try {
      return this.sigPad.getTrimmedCanvas().toDataURL("image/png");
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  onDrawEnd = () => {
    this.setState({ signatureIsEmpty: this.sigPad.isEmpty() });
  };

  clearSignature = () => {
    if (this.sigPad) {
      this.sigPad.clear();
      this.setState({ signatureIsEmpty: true });
    }
  };

  openASZF = () => {
    this.setState({ openASZF: true });
  };

  closeASZF = () => {
    this.setState({ openASZF: false });
  };

  onChangeAccepted = () => {
    this.setState({ accepted: !this.state.accepted });
  };

  onSaveClick = () => {
    const { onSave } = this.props;
    onSave && onSave(this.getSignature());
  };

  render() {
    const { classes, reset, loading } = this.props;

    const { accepted, signatureIsEmpty, openASZF } = this.state;

    if (this.props.loading) {
      return (
        <div className="text-center mt-2">
          <CircularProgress />
        </div>
      );
    }

    return (
      <Fragment>
        <Modal
          maxWidth="md"
          disableIcon
          open={openASZF}
          disableCancel
          title="Általános szerződési feltételek"
          onCancel={this.closeASZF}
          onClose={this.closeASZF}
          onAccept={this.closeASZF}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut
          mattis turpis, nec aliquam tortor. Nullam condimentum lectus in
          convallis tempus. Ut bibendum, eros at rhoncus sollicitudin, lacus mi
          faucibus lacus, in facilisis quam lorem sit amet nunc. Proin id tempor
          lacus. Etiam at augue vulputate velit imperdiet aliquet. Maecenas
          porttitor leo eu tempus consequat. Nunc semper sem in metus venenatis
          tincidunt. Suspendisse luctus gravida quam ut imperdiet. Sed vulputate
          sit amet nibh quis pellentesque. Duis facilisis posuere pellentesque.
          Cras in eros a risus rutrum fringilla id vitae metus. Morbi fermentum
          nisl ornare, blandit eros sit amet, venenatis erat. In hac habitasse
          platea dictumst. Nunc luctus, odio nec malesuada consectetur, velit
          tortor commodo odio, nec dignissim lacus augue ultrices est. Sed
          vehicula mi ac vulputate vestibulum. Vivamus id tortor ac lorem
          scelerisque interdum. Etiam nisi ex, auctor ut imperdiet at, accumsan
          quis quam. Suspendisse nec malesuada quam. Curabitur eu nunc dolor.
          Sed semper nunc in mauris imperdiet rhoncus. Nullam in scelerisque
          eros. Nulla facilisi. Donec egestas odio suscipit risus porttitor
          pulvinar ut vitae metus. Etiam auctor faucibus lorem id auctor. Etiam
          et turpis id metus venenatis feugiat nec a dolor. Lorem ipsum dolor
          sit amet, consectetur adipiscing elit. Vestibulum imperdiet est et
          consectetur cursus. Ut congue metus eget leo porttitor faucibus.
          Integer in leo molestie enim iaculis efficitur non nec mauris. Orci
          varius natoque penatibus et magnis dis parturient montes, nascetur
          ridiculus mus. In hac habitasse platea dictumst. Aliquam faucibus arcu
          est, nec cursus est dapibus ac. In eget metus lectus. Phasellus
          euismod tincidunt lectus, a maximus est sagittis vel. Duis vel
          ultricies nisi, ut aliquet erat. Ut sagittis nunc non leo porttitor
          ornare. Sed a semper dui. Morbi congue scelerisque lorem vitae
          malesuada. Aenean consectetur tellus sem. Pellentesque porttitor
          turpis quis tellus sodales vestibulum. Vestibulum efficitur arcu
          magna, non tincidunt arcu volutpat sed. Quisque urna lectus, volutpat
          eu fringilla vitae, aliquam id urna. Vivamus tincidunt, nisl quis
          aliquam finibus, ipsum diam pharetra justo, vel mattis sapien tellus
          nec sapien. Sed ac lectus tincidunt, tempor libero quis, ornare metus.
          Maecenas at elit turpis. Integer congue dapibus bibendum. Donec
          efficitur mattis interdum. Donec mi nibh, lacinia et feugiat vel,
          consequat vitae odio. Phasellus pretium nunc in est tempor, at
          condimentum nulla venenatis. Donec hendrerit eros tincidunt elit
          accumsan commodo. Morbi ut condimentum dui, a vehicula dui. Duis
          laoreet efficitur est sit amet convallis. Morbi hendrerit nisi et
          feugiat lobortis. Vestibulum mattis tortor in dui commodo, vel dictum
          felis ultrices. Aliquam dignissim enim eget enim luctus fermentum.
          Vestibulum sed nisi eget ex eleifend laoreet. Sed in lacinia nisl.
          Nulla scelerisque hendrerit augue non semper. Nulla venenatis turpis
          quis nunc venenatis elementum. Praesent tortor mauris, dignissim sit
          amet turpis sed, tincidunt faucibus lacus.
        </Modal>
        <SignaturePad
          //clearOnResize={false}
          onEnd={this.onDrawEnd}
          canvasProps={{ className: classes.canvas }}
          ref={ref => {
            this.sigPad = ref;
          }}
        />
        <Toolbar>
          <Button
            variant="contained"
            disabled={signatureIsEmpty}
            onClick={this.clearSignature}
          >
            Töröl
          </Button>
          <FormControlLabel
            style={{ marginLeft: "auto" }}
            control={
              <Checkbox
                checked={accepted}
                onChange={this.onChangeAccepted}
                //value="checkedB"
                color="primary"
              />
            }
            label={
              <span>
                Elfogadom az{" "}
                <Link
                  onClick={evt => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    this.openASZF();
                  }}
                  style={{
                    color: "#32408f",
                    textDecoration: "underline"
                  }}
                >
                  ÁSZF
                </Link>
                -t , igazolom a munka elvégzését.
              </span>
            }
          />
          <Button
            onClick={this.onSaveClick}
            variant="contained"
            color="primary"
            disabled={!accepted || signatureIsEmpty}
          >
            Mentés
          </Button>
        </Toolbar>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Signature);
