import { withTheme } from "@material-ui/core";
import { darken, lighten } from "@material-ui/core/styles";
//import { emphasize } from "@material-ui/core/styles/colorManipulator";
import settings from "variables/settings";

const dataGridStyle = theme => ({
  "@global": {
    "div.pdf-modal-page": {
      border: "solid 1px #ccc"
    },
    "div.margin-top-1": {
      marginTop: theme.spacing(1)
    },
    "div.margin-top-2": {
      marginTop: theme.spacing(2)
    },
    ".ReactTable": {
      border: "none"
    },
    ".ReactTable .rt-tr-group-own": {
      flex: "0 0 auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      minHeight: 36
    },
    ".ReactTable .rt-tbody": {
      overflowY: "scroll",
      overflowX: "hidden",
      /*[theme.breakpoints.down("md")]: {
        overflowX: "hidden"
      },*/

      webkitOverflowScrolling: "touch"
    },
    ".ReactTable .rt-tbody .rt-td": {
      alignSelf: "center"
    },
    ".ReactTable .rt-tbody .rt-tr": {
      borderBottom: `solid 1px ${theme.palette.grey[300]}`
    },
    ".ReactTable .rt-thead .rt-tr": {
      borderBottom: `solid 1px ${theme.palette.grey[300]}`,
      textAlign: "left",
      "&:nth-child(1)": {
        borderBottom: "none"
      }
    },
    ".ReactTable .rt-table": {
      [theme.breakpoints.up("md")]: {
        //paddingLeft: theme.spacing(3),
        //paddingRight: theme.spacing(3)
      }
      /*[theme.breakpoints.down("md")]: {
        overflowX: "hidden"
      },*/
    },
    ".ReactTable .rt-thead.-filters select": {
      background: theme.palette.background.paper,
      color: theme.palette.text.primary,
      border: `1px solid ${
        theme.palette.type === "dark" ? "#fff" : "rgba(0,0,0,0.1)"
      }`,
      "& > option": {
        color: theme.palette.text.primary
      }
    },
    ".ReactTable .rt-thead.-filters input": {
      //border: "none",
      borderRadius: theme.shape.borderRadius,
      background: "none",
      border: `1px solid ${
        theme.palette.type === "dark" ? "#fff" : "rgba(0,0,0,0.1)"
      }`,
      color: theme.palette.text.primary
    },
    ".ReactTable .rt-thead .rt-resizable-header-content": {
      ...theme.typography.subtitle2
    },
    ".ReactTable .rt-tfoot": {
      boxShadow: "none",
      fontWeight: 500,
      color: theme.palette.primary.main,
      marginTop: -1,
      borderTop: `solid 1px ${theme.palette.primary.main}`
    },
    ".ReactTable .rt-thead.-header": {
      overflowY: "scroll",
      overflowX: "hidden",
      webkitOverflowScrolling: "touch",
      boxShadow: "none"
    },
    ".ReactTable .rt-thead.-filters": {
      overflowY: "scroll",
      overflowX: "hidden",
      webkitOverflowScrolling: "touch",
      borderBottom: "none"
    },
    ".ReactTable.-view": {
      border: "none"
      //border: `solid 1px${theme.palette.grey[30]}`
    },
    ".ReactTable.-view .rt-tr-group-own": {
      flex: "0 0 auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      minHeight: 36
    },
    ".ReactTable.-view .rt-tbody": {
      overflowY: "auto",
      overflowX: "hidden",
      /*[theme.breakpoints.down("md")]: {
        overflowX: "hidden"
      },*/

      webkitOverflowScrolling: "touch"
    },
    ".ReactTable.-view .rt-thead.-header": {
      overflowY: "auto",
      overflowX: "hidden",
      webkitOverflowScrolling: "touch",
      boxShadow: "none"
    },
    ".ReactTable.-view .rt-thead.-filters": {
      overflowY: "auto",
      overflowX: "hidden",
      webkitOverflowScrolling: "touch"
    },
    ".ReactTable .-loading > div": {
      color: theme.palette.type === "dark" ? "#fff" : "rgba(0,0,0,0.6)"
    },
    ".ReactTable .-loading": {
      background:
        theme.palette.type === "dark"
          ? "rgba(0,0,0,0.5)"
          : "rgba(255,255,255,0.8)"
    },
    ".ReactTable.-view .rt-noData": {
      background: "transparent",
      color: theme.palette.text.primary,
      paddingTop: 0
      //display: "none"
    },
    ".ReactTable .rt-thead .rt-th": {
      borderRight: "none"
    },
    ".ReactTable.-view .rt-thead .rt-th.-sort-asc": {
      boxShadow: `inset 0 3px 0 0 ${
        theme.palette.type === "dark"
          ? "rgba(255,255,255,0.2)"
          : "rgba(0,0,0,0.2)"
      }`
    },
    ".ReactTable.-view .rt-thead .rt-th.-sort-desc": {
      boxShadow: `inset 0 -3px 0 0 ${
        theme.palette.type === "dark"
          ? "rgba(255,255,255,0.2)"
          : "rgba(0,0,0,0.2)"
      }`
    },
    ".ReactTable .rt-tbody .rt-tr:not(.-padRow):hover": {
      backgroundColor:
        (theme.palette.type === "dark"
          ? theme.palette.grey[700] //darken(theme.palette.primary.main, 0.7)
          : theme.palette.grey[300]) + "!important" //lighten(theme.palette.primary.main, 0.9)
      /*color: "white",
      "& .MuiIconButton-colorPrimary": {
        color: "white"
      }*/
    }
  },
  toolBarContainer: {
    display: "flex",
    paddingLeft: theme.spacing(3)
  },
  toolBarContainerView: {
    display: "flex"
  },
  gridContainer: {
    //marginLeft: theme.spacing(1)
    //marginRight: theme.spacing(1)
    //height: "100%",
    //position: "relative"
    // paddingLeft: theme.spacing(3)
    //paddingTop: theme.spacing(2)
  },
  toolbar: {
    flex: 1
    /*"& button:nth-child(1)": {
      marginLeft: -32
    }*/
  },
  toolBarLeft: {
    flex: 1
    //marginLeft: 25
  },
  toolBarRight: {
    flex: 1
  },
  toolBarBack: {
    //marginLeft: theme.spacing(3) * -1
  },
  footerTypo: {
    color: theme.palette.primary.light,
    paddingLeft: 5,
    paddingright: 5
  },
  title: {
    //marginTop: theme.spacing(1.8),
    //fontSize: 16,
    vertivalAlign: "middle"
  },
  toolbarMid: {
    display: "flex",
    flexGrow: 1
  },
  baseInput: {
    paddingTop: 7,
    paddingBottom: 6,
    paddingRight: 5,
    paddingLeft: 5,
    fontSize: "inherit",
    margin: 0,
    font: "inherit",
    //WebkitFontSmoothing: "antialiased",
    border: "none",
    width: "100%",
    WebkitTapHighlightColor: "transparent",
    display: "block",
    minWidth: 0,
    boxSizing: "content-box",
    background: "none",
    "&:focus": {
      outline: 0
    }
  },
  cellLink: {
    color:
      theme.palette.type === "dark"
        ? theme.palette.text.primary
        : settings.actionColor === "primary"
          ? theme.palette.primary.main
          : theme.palette.secondary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  topIndicator: {
    top: 0
  },
  headerFilterSelect: {
    width: "100%",
    padding: "4px 7px!important",
    "& option": {
      fontSize: "1.1em"
    }
  },
  selectCheckbox: {
    padding: 4,
    margin: -4
  },
  selectBadge: {
    top: 4,
    right: -10
  }
});

export default dataGridStyle;
