import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import userMenuStyle from "common/assets/style/userMenuStyle";
import UserIcon from "@material-ui/icons/Person";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import LogoutIcon from "@material-ui/icons/PowerSettingsNew";
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BadgeAvatar from "./BadgeAvatar";
import Menu from "@material-ui/core/Menu";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { CircularProgress, Divider, Typography } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import settings from "variables/settings";
import customButtons from "variables/toolbarButtons";

class UserMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      loading: {}
    };
  }

  handleOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClick = clickHandler => evt => {
    this.handleClose();
    clickHandler(evt);
  };

  setLoading = (key, isLoading) => {
    let loading = Object.assign({}, this.state.loading);
    if (isLoading) {
      loading[key] = true;
    } else if (loading[key]) {
      delete loading[key];
    } else {
      return;
    }
    this.setState({ loading });
  };

  render() {
    const { classes, user, logOut, showProfile, showSettings } = this.props;
    const { anchorEl, loading } = this.state;
    //console.log("user", user);
    let buttons = [];
    if (customButtons["_user_menu_buttons"]) {
      const bb = customButtons["_user_menu_buttons"];
      for (let i in bb) {
        let b = Object.assign({}, bb[i]);
        if (user.accessFunction(b.id)) {
          buttons.push(b);
        }
      }
    }

    return (
      <div className={classes.menuContainer}>
        <BadgeAvatar user={user.data} />
        {/*<Typography variant="button" className={classes.menuName}>
          {user.name}
    </Typography>*/}
        <Hidden mdUp>
          <IconButton onClick={this.handleOpen} className={classes.menuButton}>
            {anchorEl ? (
              <ExpandLess className={classes.menuOpenIncon} />
            ) : (
              <ExpandMore className={classes.menuOpenIncon} />
            )}
          </IconButton>
        </Hidden>
        <Hidden smDown>
          <Button
            size="small"
            onClick={this.handleOpen}
            className={classes.menuButton}
          >
            {user.data.name}
            {anchorEl ? (
              <ExpandLess className={classes.menuOpenIncon} />
            ) : (
              <ExpandMore className={classes.menuOpenIncon} />
            )}
          </Button>
        </Hidden>
        <Popover
          id="profile-menu"
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <List>
            {settings.profileSlug && (
              <ListItem
                className={classes.item}
                onClick={this.handleClick(showProfile)}
              >
                <ListItemIcon className={classes.itemIcon}>
                  <UserIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Profilom"}
                  //disableTypography={true}
                  //className={classes.itemText}
                />
              </ListItem>
            )}
            {settings.settingsSlug && (
              <ListItem
                className={classes.item}
                onClick={this.handleClick(showSettings)}
              >
                <ListItemIcon className={classes.itemIcon}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Beállítások"}
                  //disableTypography={true}
                  //className={classes.itemText}
                />
              </ListItem>
            )}
            <ListItem
              className={classes.item}
              onClick={this.handleClick(logOut)}
            >
              <ListItemIcon className={classes.itemIcon}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Kilépés"}
                //disableTypography={true}
                //className={classes.itemText}
              />
            </ListItem>
            {buttons.length > 0 && <Divider />}
            {buttons.map((button, index) => {
              return (
                <ListItem
                  key={index}
                  className={classes.item}
                  onClick={evt => {
                    button.onClick(evt, this.setLoading);
                  }}
                  disabled={loading[button.id]}
                >
                  <ListItemIcon
                    className={classes.itemIcon}
                    color={button.color}
                  >
                    {loading[button.id] ? (
                      <CircularProgress size={20} />
                    ) : (
                      <button.Icon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={button.tooltip}
                    //disableTypography={true}
                    //className={classes.itemText}
                  />
                </ListItem>
              );
            })}
          </List>
        </Popover>
        {/* <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          //keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={showProfile}>Profilom</MenuItem>
          <MenuItem onClick={logOut}>Kilépés</MenuItem>
       </Menu>*/}
      </div>
      /*<div
        className={
          classes.container +
          (window.location.pathname === "/profile"
            ? " " + classes.containerSelected
            : "")
        }
        onClick={showProfile}
      >
        <UserIcon className={classes.icon} />
        <div className={classes.itemText}>{user.name}</div>
        <Tooltip title="Kilépés">
          <IconButton
            className={classes.button}
            aria-label="Logout"
            onClick={logOut}
          >
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </div>*/
    );
  }
}

UserMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.any.isRequired,
  logOut: PropTypes.func.isRequired,
  showProfile: PropTypes.func,
  showSettings: PropTypes.func
};

export default withStyles(userMenuStyle, { withTheme: true })(UserMenu);
