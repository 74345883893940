import React, { Fragment } from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import { Link, withRouter } from "react-router-dom";
// material-ui components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
//import ListItemText from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
//import StarIcon from "@material-ui/icons/StarBorder";
//import DocxIcon from "common/assets/icons/DocxIcon.jsx";
import FolderIcon from "@material-ui/icons/Folder";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import { withStyles } from "@material-ui/core/styles";
import sidebarMenuStyle from "common/assets/style/sidebarMenuStyle";
import urlHelper from "common/utils/urlHelper";
import ListSubheader from "@material-ui/core/ListSubheader";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import storageHelper from "common/utils/storageHelper";
import { Grid, Toolbar, Avatar, Divider } from "@material-ui/core";
class SidebarMenu extends React.Component {
  constructor(props) {
    super(props);

    const opened = urlHelper.get().opened;
    this.storage = storageHelper("session", "menuOpened", this, ["opened"]);
    this.state = {
      //usrMnu: [],
      opened: opened,
      selected: opened,
      ...this.storage.get() //storage értékek feltöltése
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged = () => {
    const selected = urlHelper.get(this.props.history.location.pathname).opened; //Object.assign({}, this.state.opened, urlHelper.get().opened);
    const opened = Object.assign({}, this.state.opened, selected);
    this.storage.set({ selected: selected, opened: opened });
  };

  /*
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
*/
  openCollapse = (evt, id) => {
    evt.stopPropagation();
    evt.preventDefault();
    var opened = Object.assign({}, this.state.opened);
    opened[id] = !opened[id];
    this.storage.set({ opened: opened });
  };

  onClick = (item, slug, event) => {
    event.stopPropagation();
    if (this.props.onClick) {
      this.props.onClick(item, slug);
    }
  };

  getBoxItems = (items, path = "") => {
    const { classes /*, color*/ } = this.props;
    if (!items || items.length === 0) {
      return null;
    }

    const Box = props => (
      <Grid item xs={12} sm={6} md={4} lg={3} {...props}>
        <Link className={classes.box} to={props.to}>
          {props.children}
        </Link>
      </Grid>
    );

    return items.map((item, key) => {
      const slug = path + item.slug;

      return item.items && item.items.length > 0 ? (
        item.header ? (
          <Fragment key={key}>
            <Grid item xs={12} style={{ paddingLeft: 16 }}>
              <Toolbar>
                <Typography variant="h5">{item.name}</Typography>
              </Toolbar>
            </Grid>
            {this.getBoxItems(item.items, slug)}
            <Grid item xs={12} />
          </Fragment>
        ) : (
          <Fragment key={key}>
            <Grid item xs={12} style={{ paddingLeft: 16 }}>
              <Toolbar>
                <Avatar>{item.icon ? <item.icon /> : <FolderIcon />}</Avatar>
                <Typography style={{ marginLeft: 8 }} variant="h5">
                  {item.name}
                </Typography>
              </Toolbar>
            </Grid>
            {this.getBoxItems(item.items, slug)}
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Fragment>
        )
      ) : (
        <Box
          key={key}
          onClick={event => {
            this.onClick(item, slug, event);
          }}
          to={slug}
        >
          <Avatar>
            {item.icon ? (
              <item.icon />
            ) : this.state.opened[slug] || this.state.selected[slug] ? (
              <FolderOpenIcon />
            ) : (
              <FolderIcon />
            )}
          </Avatar>
          <Typography style={{ marginLeft: 8 }} variant="h6">
            {item.name}
            <br />
            {item.desc && (
              <Typography variant="body1" color="textSecondary">
                {item.desc || `Leírás a következő menüpontról: ${item.name}`}
              </Typography>
            )}
          </Typography>
        </Box>
      );
    });
  };

  getMnuItems = (items, path = "") => {
    const { classes /*, color*/ } = this.props;
    if (!items || items.length === 0) {
      return null;
    }
    return items.map((item, key) => {
      const slug = path + item.slug;

      return item.items && item.items.length > 0 ? (
        item.header ? (
          <Fragment key={key}>
            <ListSubheader
              className={classes.listSubheader}
              //className={classes.item}
              style={{
                paddingRight: 0,
                cursor: "pointer"
                //textTransform: "uppercase"
              }}
            >
              {/*<Link to={to} className={classes.itemLink} >*/}
              <div
                className={classes.itemSection}
                onClick={event => this.openCollapse(event, slug)}
              >
                {item.name}
                {this.state.opened[slug] ? <ExpandLess /> : <ExpandMore />}
              </div>
              {/*</Link>*/}
            </ListSubheader>

            <Collapse
              in={this.state.opened[slug]}
              unmountOnExit
              className={
                item.noBottomBorder
                  ? classes.collapseContainer
                  : classes.collapseContainerHeader
              }
            >
              <List className={classes.list}>
                {this.getMnuItems(item.items, slug)}
              </List>
            </Collapse>
          </Fragment>
        ) : (
          <ListItem
            key={key}
            className={classes.item}
            style={{
              paddingRight: 0,
              cursor: "pointer"
              //textTransform: "uppercase"
            }}
          >
            {/*<Link to={to} className={classes.itemLink} >*/}
            <div
              className={
                classes.itemContainer +
                " " +
                classes.itemLink +
                (this.state.selected[slug]
                  ? " " + classes.itemContainerOpened
                  : "")
              }
              onClick={event => this.openCollapse(event, slug)}
            >
              <ListItemIcon className={classes.itemIcon}>
                {item.icon ? (
                  <item.icon />
                ) : this.state.opened[slug] || this.state.selected[slug] ? (
                  <FolderOpenIcon />
                ) : (
                  <FolderIcon />
                )}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                /*secondary={}*/
                //disableTypography={true}
                className={classes.itemText}
              />
              {this.state.opened[slug] ? <ExpandLess /> : <ExpandMore />}
            </div>
            {/*</Link>*/}
            <Collapse
              in={this.state.opened[slug]}
              unmountOnExit
              className={classes.collapseContainer}
            >
              <List className={classes.list}>
                {this.getMnuItems(item.items, slug)}
              </List>
            </Collapse>
          </ListItem>
        )
      ) : (
        <ListItem key={key} className={classes.item}>
          <Link
            to={slug}
            /*className={classes.itemLink}*/ className={
              classes.itemLink +
              " " +
              classes.itemContainer +
              (this.state.selected[slug]
                ? " " +
                  classes.itemContainerOpened +
                  " " +
                  classes.itemContainerOpenedLeaf
                : "")
            }
            onClick={event => {
              this.onClick(item, slug, event);
            }}
          >
            <ListItemIcon className={classes.itemIcon}>
              <item.icon />
            </ListItemIcon>
            <ListItemText
              primary={item.name}
              //disableTypography={true}
              className={classes.itemText}
            />
          </Link>
        </ListItem>
      );
    });
  };

  render() {
    const { classes, /* color, */ items, favourites, box } = this.props;
    const showFavourites = favourites && favourites.length > 0;
    return box ? (
      <Grid container spacing={2}>
        {showFavourites &&
          this.getBoxItems([
            {
              name: "Kedvencek",
              header: true,
              noBottomBorder: true,
              items: favourites,
              slug: "/favourites",
              fix: true
            }
          ])}
        {/*items && !items[0].header && (
          <Grid item xs={12} style={{ paddingLeft: 16 }}>
            <Toolbar>
              <Typography variant="h5">Menü</Typography>
            </Toolbar>
          </Grid>
        )*/}
        {this.getBoxItems(items)}
      </Grid>
    ) : (
      <List className={classes.listMain}>
        {showFavourites &&
          this.getMnuItems([
            {
              name: "Kedvencek",
              header: true,
              noBottomBorder: true,
              items: favourites,
              slug: "/favourites",
              fix: true
            }
          ])}
        {items && items[0] && !items[0].header && (
          <ListSubheader className={classes.listSubheader}>
            <div className={classes.itemSection}>Menü</div>
          </ListSubheader>
        )}
        {this.getMnuItems(items)}
      </List>
    );
  }
}

SidebarMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  opened: PropTypes.array,
  miniActive: PropTypes.bool,
  //location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose"
  ]),
  onClick: PropTypes.func
};

export default withRouter(
  withStyles(sidebarMenuStyle, { withTheme: true })(SidebarMenu)
);
