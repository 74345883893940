import React, { Fragment } from "react";
//import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import { DatePicker, DateTimePicker, Picker } from "@material-ui/pickers";
import { LocalizationProvider } from "@material-ui/pickers";
import MomentUtils from "@material-ui/pickers/adapter/moment";
import { IconButton, InputAdornment, Typography } from "@material-ui/core";
import { Today, Visibility, VisibilityOff } from "@material-ui/icons";
import moment from "moment";

class FormDateTimePickerField extends React.Component {
  componentDidMount() {
    /*this.color = this.input.style.color;
    if (!this.props.value) {
      this.input.style.color = "transparent";
    }*/
  }

  convertDate = (value, type) => {
    let m = new Date(value);

    //Dátum iso string yyyy-mm-dd
    const dateStr =
      m.getFullYear() +
      "-" +
      ("0" + (m.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + m.getDate()).slice(-2);

    //Time iso string HH:mm
    const timeStr =
      ("0" + m.getHours()).slice(-2) + ":" + ("0" + m.getMinutes()).slice(-2);

    //Typusnak megfelelően a date string összeállítása
    let strValue = "";
    let fieldType = "datetime";
    switch (type) {
      case "unixDate":
        strValue = dateStr;
        fieldType = "date";
        break;
      case "unixTime":
        strValue = timeStr;
        fieldType = "time";
        break;
      case "unixDateTime":
      default:
        strValue = dateStr + " " + timeStr;
        fieldType = "datetime";
        break;
    }

    return { strValue, fieldType };
  };

  render() {
    const {
      id,
      name,
      error,
      value,
      type,
      onChange,
      access,
      classes,
      viewMode,
      onBlur,
      onFocus,
      dirty,
      required,
      todayHotButton
    } = this.props;
    const { strValue, fieldType } = this.convertDate(value, type);
    //console.log(name, strValue, fieldType);
    if (!value || value === "") {
      if (this.input) {
        //this.input.style.color = "transparent";
      }
    }
    //console.log(value, strValue);
    const PickerComp = type === "unixDateTime" ? DateTimePicker : DatePicker;
    return viewMode ? (
      <InputBase
        inputRef={ref => (this.input = ref)}
        id={id}
        label={required ? `${name} *` : name}
        //helperText={error || ""}
        error={error ? true : false}
        disabled={access !== "w" ? true : false}
        //format="YYYY.MM.DD."
        //InputLabelProps={{ shrink: true }}
        //className={classes.textField}
        onFocus={evt => {
          this.input.style.color = this.color;
          onFocus && onFocus(evt);
        }}
        onBlur={evt => {
          if (!value || value === "") {
            //this.input.style.color = "transparent";
          }
          onBlur && onBlur(evt);
        }}
        fullWidth
        type={fieldType}
        //defaultValue={value ? strValue : ""}
        value={value ? strValue : ""}
        autoComplete="off"
        onChange={evt => onChange({ [id]: evt.target.value })}
        //margin="normal"
        ////variant="outlined"
        className={dirty && !error ? classes.dirtyTextField : ""}
      />
    ) : (
      <LocalizationProvider dateAdapter={MomentUtils}>
        <PickerComp
          id={id}
          label={required ? `${name} *` : name}
          format={
            type === "unixDateTime" ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"
          }
          disabled={access !== "w" ? true : false}
          //showToolbar
          autoOk
          clearable
          value={value ? value : null}
          onChange={date =>
            onChange({
              [id]: date
                ? date.format(
                  type === "unixDateTime"
                    ? "YYYY-MM-DD HH:mm:ss"
                    : "YYYY-MM-DD"
                )
                : null
            })
          }
          renderInput={props => {
            let InputProps = Object.assign({}, props.InputProps);
            if (todayHotButton) {
              InputProps.endAdornment = (
                <Fragment>
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => {
                        onChange({
                          [id]: moment().format(
                            type === "unixDateTime"
                              ? "YYYY-MM-DD HH:mm:ss"
                              : "YYYY-MM-DD"
                          )
                        });
                      }}
                    >
                      <Typography variant="h6">Ma</Typography>
                    </IconButton>
                  </InputAdornment>
                  {props.InputProps.endAdornment}
                </Fragment>
              );
            }

            return (
              <TextField
                {...props}
                //variant="outlined"
                fullWidth
                margin="normal"
                className={dirty && !error ? classes.dirtyTextField : ""}
                helperText={error || ""}
                error={error ? true : false}
                InputProps={InputProps}
              />
            );
          }}
        />
      </LocalizationProvider>
    );
  }
}

FormDateTimePickerField.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string.isRequired,
  error: PropTypes.string,
  access: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  dirty: PropTypes.bool,
  viewMode: PropTypes.bool,
  todayHotButton: PropTypes.bool,
  required: PropTypes.bool
};

export default FormDateTimePickerField;
