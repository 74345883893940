import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";
const years = [2019,2020,2021];
const months = ["Január", "Február", "Március", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Október", "November", "December"];
const yearMonths = [];
const yearMonthsDict = {};

years.forEach(year => {
  let monthIndex = 1;
  months.forEach(month => {
    yearMonths.push({
      value: year * 100 + monthIndex,
      label:`${year} ${month}`
    });
    yearMonthsDict[year * 100 + monthIndex] = `${year} ${month}`;
    monthIndex++;
  });
});

const defaultYearMonth = () => {
  let m = new Date();
  let month = m.getMonth();
  if(month === 0) {
    return (m.getFullYear() -1 ) * 100 + 12;
  } else {
    return m.getFullYear() * 100 + month;
  }
  
}

class SsTaxes extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("ss-taxes");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "ss-taxes",
        url: "ss-taxes",
        attributes: strings.attributes["ss-taxes"],
        columns: [
          {
            id: "user_id",
            type: "select",
            datasource: {
              controller: "users",
              objectField: "user",
              valueField: "id",
              search: ["user.name"],
              //filter: [["unit", "=", 1]],
              sort: { "user.name": "asc" },
              label: item => {
                return item ? item.name : "";
              }
            }
          },
          { 
            id: "year_month",
            type: "select",
            options: yearMonths,
            defaultValue: defaultYearMonth()
          },
          { id: "amount", type: "currency" },
          { id: "work_days" },
          { id: "daily_amount", type: "currency" }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const { user } = this.props;
    return (
      <div>
        <DataGrid user={user}
          definition={definition}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("ss-taxes", column.id);
          }}
          access={this.state.access}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return `${item.user.name} - ${yearMonthsDict[item.year_month]}`;
            } else {
              return "Új járulék";
            }
          }}
        />
      </div>
    );
  }
}

SsTaxes.propTypes = {
  user: PropTypes.object.isRequired,
  
};

export default SsTaxes;
