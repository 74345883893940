import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ClearFilterIcon = props => (
  <SvgIcon
    {...props}
    //style={{ width: 18, height: 18 }}
    //viewBox="0 0 496.159 496.159"
  >
    <path
      d="M20.6,4.2h-17C3.3,4.2,3,4.3,2.9,4.6C2.8,4.9,2.8,5.2,3,5.4l6.2,8.8c0,0,0,0,0,0c0.2,0.3,0.3,0.7,0.3,1.1v7.1
		c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.4,0.2,0.6,0.2c0.1,0,0.2,0,0.3-0.1l3.5-1.3c0.3-0.1,0.5-0.4,0.5-0.7v-5.8c0-0.4,0.1-0.7,0.3-1.1
		c0,0,0,0,0,0l6.2-8.8c0.2-0.2,0.2-0.6,0.1-0.8C21.2,4.3,20.9,4.2,20.6,4.2z"
    />
    <path
      style={{ fill: "#D61E1E" }}
      d="M12,24c-3.2,0-6.2-1.2-8.5-3.5C1.2,18.2,0,15.2,0,12c0-2.9,1-5.6,2.9-7.8l0,0l0,0C3,4.1,3,4,3.1,4
			c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2l0,0c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0.1-0.1
			l0,0c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1C4,3,4.1,3,4.1,3C6.3,1,9.1,0,12,0c3.2,0,6.2,1.2,8.5,3.5C22.8,5.8,24,8.8,24,12
			c0,2.9-1,5.7-3,7.9c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1l0,0c0,0,0,0-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2
			l0,0c-0.1,0.1-0.1,0.1-0.2,0.2c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1C20,21,19.9,21,19.9,21l0,0l0,0
			C17.7,23,14.9,24,12,24z M4.7,6.6C3.5,8.2,2.9,10.1,2.9,12c0,5,4.1,9.1,9.1,9.1c1.9,0,3.8-0.6,5.4-1.8L4.7,6.6z M19.3,17.4
			c1.1-1.6,1.8-3.4,1.8-5.4c0-5-4.1-9.1-9.1-9.1c-1.9,0-3.8,0.6-5.4,1.8L19.3,17.4z"
    />
  </SvgIcon>
);
export default ClearFilterIcon;
