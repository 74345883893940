import { createMuiTheme } from "@material-ui/core/styles";

export const createTheme = (theme = {}) => {
  const themeType = localStorage.getItem("themeType") || "light";
  theme.palette.type = themeType;
  const color = themeType === "dark" ? "#fff" : undefined;
  if (!theme.overrides) {
    theme.overrides = {};
  }
  if (theme.shapeBackground) {
    theme.overrides.MuiAppBar = {
      colorPrimary: {
        ...(themeType === "dark" ? { color: "#fff" } : {}),
        background:
          themeType === "dark"
            ? theme.shapeBackground.dark
            : theme.shapeBackground.light
      }
    };
  }
  theme.palette.primary =
    themeType === "dark"
      ? theme.palette.primaryDark
      : theme.palette.primaryLight;
  theme.palette.secondary =
    themeType === "dark"
      ? theme.palette.secondaryDark
      : theme.palette.secondaryLight;
  theme.palette.yellow =
    themeType === "dark" ? theme.palette.yellowDark : theme.palette.yellowLight;

  theme.typography.h1
    ? (theme.typography.h1.color = color)
    : (theme.typography.h1 = { color: color });
  theme.typography.h2
    ? (theme.typography.h2.color = color)
    : (theme.typography.h2 = { color: color });
  theme.typography.h3
    ? (theme.typography.h3.color = color)
    : (theme.typography.h3 = { color: color });
  theme.typography.h4
    ? (theme.typography.h4.color = color)
    : (theme.typography.h4 = { color: color });
  theme.typography.h5
    ? (theme.typography.h5.color = color)
    : (theme.typography.h5 = { color: color });
  theme.typography.h6
    ? (theme.typography.h6.color = color)
    : (theme.typography.h6 = { color: color });

  return createMuiTheme(theme);
};
