import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import restHelper from "common/utils/restHelper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import UsersIcon from "@material-ui/icons/People";
import InvoiceIcon from "@material-ui/icons/Send";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import VehicleIcon from "@material-ui/icons/DirectionsCar";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import ChartIcon from "@material-ui/icons/ShowChart";
import dateHelper from "common/utils/dateHelper";
import formatHelper from "common/utils/formatHelper";

import ChartistGraph from "react-chartist";

class DashboardProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: {},
      fetched: false,
      loading: true,
      deviceSize: this.deviceSize(),
      tabIndex: 0
    };
  }

  fetchData = () => {
    if (this.state.fetched) {
      return;
    }
    const { project } = this.props;
    const params = {
      filter: (project.project_id
        ? [["t.project_id", "exact", project.project_id]]
        : []
      ).concat(this.props.filter || []),
      group: "t.day",
      groupname: "t.day"
    };

    restHelper.index("reports/income", params).then(response => {
      this.setState({
        items: this.prepareChartData(response.data),
        fetched: true
      });
    });
  };

  deviceSize() {
    const width = window.innerWidth;
    let cState = "md";

    /*switch (state) {
      case "xs":
        return width < 600;
      case "sm":
        return width < 960;
      case "md":
        return width < 1280;
      case "lg":
        return width < 1980;

      default:
        break;
    }*/

    if (width >= 1980) {
      cState = "xl";
    } else if (width >= 1280) {
      cState = "lg";
    } else if (width >= 960) {
      cState = "md";
    } else if (width >= 600) {
      cState = "sm";
    } else {
      cState = "xs";
    }
    return cState;
  }

  onResize = event => {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = null;
    }
    this.resizeTimeout = setTimeout(() => {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = null;
      const newSize = this.deviceSize();
      if (this.state.deviceSize !== newSize) {
        this.setState({ deviceSize: newSize });
      }
    }, 300);
  };

  componentDidMount() {
    this.fetchData();
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  changeTab = tabIndex => {
    this.setState({ tabIndex: tabIndex });
  };

  prepareChartData = items => {
    let labels = [];
    let amounts = [];
    let amount_costs = [];
    let amount_hours = [];
    let amount_pays = [];
    let amount_taxes = [];
    let amount_tasks = [];
    let amount_vehicles = [];
    let amount_vehicles_income = [];
    let amount_invoices_outgoing = [];
    let amount_invoices_outgoing_transaction = [];
    let work_hours = [];
    let worker_users = [];
    let worker_vehicles = [];
    let project_health = [];
    const aDay = 24 * 3600;
    let lastday = items.length > 0 ? parseInt(items[0].day) : 0;
    let emptyCount = 0;
    items.map(item => {
      let day = parseInt(item.day);
      while (lastday + aDay < day && emptyCount < 10) {
        labels.push(dateHelper.unixToDate(lastday + aDay));
        amounts.push(0);
        work_hours.push(0);
        worker_users.push(0);
        worker_vehicles.push(0);
        project_health.push(0);

        amount_costs.push(0);
        amount_hours.push(0);
        amount_pays.push(0);
        amount_taxes.push(0);
        amount_tasks.push(0);
        amount_vehicles.push(0);
        amount_vehicles_income.push(0);
        amount_invoices_outgoing.push(0);
        amount_invoices_outgoing_transaction.push(0);

        lastday += aDay;
        emptyCount++;
      }
      labels.push(dateHelper.unixToDate(item.day));
      amounts.push(parseInt(item.amount));
      work_hours.push(parseInt(item.work_hours));
      worker_users.push(parseInt(item.worker_users));
      worker_vehicles.push(parseInt(item.worker_vehicles));

      amount_costs.push(parseFloat(item.amount_costs));
      amount_hours.push(parseFloat(item.amount_hours));
      amount_pays.push(parseFloat(item.amount_pays));
      amount_taxes.push(parseFloat(item.amount_taxes));
      amount_tasks.push(parseFloat(item.amount_tasks));
      amount_vehicles.push(parseFloat(item.amount_vehicles));
      amount_vehicles_income.push(parseFloat(item.amount_vehicles_income));
      amount_invoices_outgoing.push(parseFloat(item.amount_invoices_outgoing));
      amount_invoices_outgoing_transaction.push(
        parseFloat(item.amount_invoices_outgoing_transaction)
      );

      project_health.push(
        parseInt(parseFloat(item.amount) / parseFloat(item.work_hours))
      );
      lastday = parseInt(item.day);
      emptyCount = 0;
      return 1;
    });

    return {
      income: {
        labels: labels.slice(),
        series: [
          {
            name: "Elszámolható tételek",
            data: amount_tasks,
            className: "color_task"
          },
          {
            name: "Órabéres munka",
            data: amount_hours,
            className: "color_hours"
          },
          {
            name: "Jármű napidíjak",
            data: amount_vehicles,
            className: "color_vehicles"
          },
          {
            name: "Jármű bevételek",
            data: amount_vehicles_income,
            className: "color_vehicles_income"
          },
          {
            name: "Költségek",
            data: amount_costs,
            className: "color_costs"
          },
          {
            name: "Bérek",
            data: amount_pays,
            className: "color_pays"
          },
          {
            name: "Jutalék",
            data: amount_taxes,
            className: "color_taxes"
          },
          {
            name: "Haszon",
            data: amounts,
            className: "color_amount"
          }
        ]
      },
      work_hours: {
        labels: labels.slice(),
        series: [work_hours]
      },
      worker_users: {
        labels: labels.slice(),
        series: [worker_users]
      },
      worker_vehicles: {
        labels: labels.slice(),
        series: [worker_vehicles]
      },
      project_health: {
        labels: labels.slice(),
        series: [project_health]
      },
      invoices: {
        labels: labels.slice(),
        series: [
          {
            name: "Számlázva",
            data: amount_invoices_outgoing,
            className: "color_invoices_outgoing"
          },
          {
            name: "Teljesítve",
            data: amount_invoices_outgoing_transaction,
            className: "color_invoices_outgoing_transction"
          }
        ]
      }
    };
  };

  tab = (tabIndex, title, Icon) => {
    return (
      <Tooltip title={title} disableFocusListener={true}>
        <IconButton
          onClick={() => this.changeTab(tabIndex)}
          //className={classes.toolBarBack}
          color={tabIndex === this.state.tabIndex ? "primary" : "default"}
        >
          <Icon />
        </IconButton>
      </Tooltip>
    );
  };

  render() {
    const { project } = this.props;
    const { items, deviceSize, tabIndex } = this.state;
    //const width = window.innerWidth;
    let lastWorkerUsers = 0;

    if (items.worker_users && items.worker_users.series[0].length > 0) {
      lastWorkerUsers =
        items.worker_users.series[0][items.worker_users.series[0].length - 1];
    }
    let lastWorkerVehicles = 0;

    if (items.worker_vehicles && items.worker_vehicles.series[0].length > 0) {
      lastWorkerVehicles =
        items.worker_vehicles.series[0][
          items.worker_vehicles.series[0].length - 1
        ];
    }
    const count = items.income ? items.income.labels.length : 1;
    var options = {
      //high: 10,
      //low: -10,
      height: "350px",
      showPoint: false,
      axisX: {
        labelInterpolationFnc: function(value, index) {
          switch (deviceSize) {
            case "xs":
              return index % Math.ceil(count / 10) === 0 ? value : null;
            case "sm":
              return index % Math.ceil(count / 15) === 0 ? value : null;
            case "md":
              return index % Math.ceil(count / 20) === 0 ? value : null;
            case "lg":
              return index % Math.ceil(count / 25) === 0 ? value : null;
            case "xl":
              return index % Math.ceil(count / 30) === 0 ? value : null;
            default:
              return index % Math.ceil(count / 30) ? value : null;
          }
        }
      }
    };

    let style = {
      borderRadius: 5,
      position: "absolute",
      //display: "inline-block",
      width: 10,
      height: 10,
      marginLeft: -16,
      marginTop: 5
      //backgroundColor: "red"
    };

    const Row = props => {
      return (
        <tr>
          <td>
            <Typography
              variant="button"
              style={{ whiteSpace: "nowrap", marginRight: 12 }}
            >
              {props.label}:
            </Typography>
          </td>
          <td>
            {props.color && props.showColor ? (
              <span
                style={{
                  ...style,
                  ...{ backgroundColor: props.color }
                }}
              />
            ) : (
              ""
            )}
            <Typography variant="body2">{props.value}</Typography>
          </td>
        </tr>
      );
    };

    const Chart = props => {
      let opts = Object.assign({}, options, props.options ? props.options : {});
      opts.showPoint = props.data && props.data.labels.length === 1;
      if (!props.data) {
        return "";
      }
      return (
        <div style={{ height: opts.height, paddingBottom: 16 }}>
          <ChartistGraph
            data={props.data}
            options={opts}
            type={props.type}
            style={{ /*padding: 32,*/ paddingBottom: 50 }}
          />
        </div>
      );
    };
    const score =
      project && !isNaN(parseFloat(project.work_hours))
        ? Math.round(
            parseFloat(project.amount) / parseFloat(project.work_hours) / 100
          ) / 10
        : 0;

    //console.log(project.name, project);
    return (
      <Grid container={true} spacing={4} style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            marginTop: -32,
            right: 30
          }}
        >
          <BookmarkIcon
            style={{
              width: 58,
              height: 58,
              fill:
                score <= 0 ? "#d70206" : score <= 2.5 ? "#d17905" : "#59922b"
            }}
          />
          <span
            style={{
              position: "absolute",
              marginTop: 16,
              right: 0,
              color: "white",
              width: 58,
              textAlign: "center",
              //fontWeight: "bold",
              fontSize: 14,
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
            }}
          >
            {score || ""}
          </span>
        </div>

        <Grid
          item={true}
          xs={12}
          md={6}
          lg={4}
          xl={3} /*style={{ padding: 32 }}*/
        >
          <Typography variant="h6" style={{ marginBottom: 12 }}>
            {project.name}
          </Typography>

          <table>
            <tbody>
              <Row label="Munkavezető" value={project.foreman_name} />
              <Row
                label="Időtartam"
                value={
                  dateHelper.unixToDate(project.first_day) +
                  " - " +
                  dateHelper.unixToDate(project.last_day)
                }
              />
              <Row
                label="Eltelt / Hasznos napok"
                value={
                  Math.round(
                    (project.last_day - project.first_day) / (3600 * 24) + 1
                  ) +
                  " / " +
                  project.work_days +
                  " nap"
                }
              />
              <Row
                label="Utolsó munkalap"
                value={dateHelper.unixToRelative(project.last_day)}
              />
              <Row
                label="Ledolgozott óra"
                value={Math.round(project.work_hours) + " óra"}
              />
              <Row
                label="Haszon / munkaóra"
                color="#d70206"
                showColor={this.state.tabIndex === 2}
                value={
                  formatHelper.currency(
                    parseInt(
                      parseFloat(project.amount) /
                        parseFloat(project.work_hours)
                    ),
                    "0 Ft"
                  ) + " / óra"
                }
              />
              <Row
                label="Utolsó / összlétszám"
                color="#d70206"
                showColor={this.state.tabIndex === 1}
                value={lastWorkerUsers + " / " + project.worker_users + " fő"}
              />
              <Row
                label="Utolsó / össz jármű"
                color="#d70206"
                showColor={this.state.tabIndex === 3}
                value={
                  lastWorkerVehicles + " / " + project.worker_vehicles + " db"
                }
              />
              <Row
                label="Elszámolható tételek"
                color="#827717"
                showColor={this.state.tabIndex === 0}
                value={formatHelper.currency(project.amount_tasks, "0 Ft")}
              />
              <Row
                label="Órabéres munka"
                color="#00838f"
                showColor={this.state.tabIndex === 0}
                value={formatHelper.currency(project.amount_hours, "0 Ft")}
              />
              <Row
                label="Jármű napidíjak"
                color="#d17905"
                showColor={this.state.tabIndex === 0}
                value={formatHelper.currency(project.amount_vehicles, "0 Ft")}
              />
              <Row
                label="Jármű bevételek"
                color="#003c8f"
                showColor={this.state.tabIndex === 0}
                value={formatHelper.currency(
                  project.amount_vehicles_income,
                  "0 Ft"
                )}
              />
              <Row
                label="Költségek"
                color="#d70206"
                showColor={this.state.tabIndex === 0}
                value={formatHelper.currency(project.amount_costs, "0 Ft")}
              />
              <Row
                label="Bérek"
                color="#6a1b9a"
                showColor={this.state.tabIndex === 0}
                value={formatHelper.currency(project.amount_pays, "0 Ft")}
              />
              <Row
                label="Járulékok"
                color="#f502ac"
                showColor={this.state.tabIndex === 0}
                //aa={console.log(project)}
                value={formatHelper.currency(project.ss_tax, "0 Ft")}
              />
              <Row
                label="Haszon"
                color="#005005"
                showColor={this.state.tabIndex === 0}
                value={
                  formatHelper.currency(project.amount, "0 Ft") +
                  (parseFloat(project.amount_tasks) +
                    parseFloat(project.amount_hours) >
                  0
                    ? " (" +
                      Math.round(
                        (100 * parseFloat(project.amount)) /
                          (parseFloat(project.amount_tasks) +
                            parseFloat(project.amount_hours))
                      ) +
                      "%)"
                    : "")
                }
              />
              <Row
                label="Számlázva"
                color="#00838f"
                showColor={this.state.tabIndex === 4}
                value={formatHelper.currency(
                  project.amount_invoices_outgoing,
                  "0 Ft"
                )}
              />
              <Row
                label="Teljesítve"
                color="#005005"
                showColor={this.state.tabIndex === 4}
                value={formatHelper.currency(
                  project.amount_invoices_outgoing_transaction,
                  "0 Ft"
                )}
              />
            </tbody>
          </table>
        </Grid>
        <Grid
          item={true}
          xs={12}
          md={6}
          lg={8}
          xl={9} /*style={{ padding: 32 }}*/
        >
          <div style={{ marginLeft: 34 }}>
            {this.tab(0, "Napi haszon", WalletIcon)}
            {this.tab(1, "Napi létszám", UsersIcon)}
            {this.tab(2, "Napi haszon/munkaóra", ChartIcon)}
            {this.tab(3, "Járművek", VehicleIcon)}
            {this.tab(4, "Számlák", InvoiceIcon)}
          </div>
          {tabIndex === 0 && (
            <Chart
              type="Line"
              data={items.income}
              options={{
                axisY: {
                  labelInterpolationFnc: function(value, index) {
                    return formatHelper.currency(value, "0 Ft");
                  }
                }
              }}
            />
          )}
          {tabIndex === 1 && (
            <Chart
              type="Line"
              data={items.worker_users}
              options={{
                axisY: {
                  onlyInteger: true,
                  labelInterpolationFnc: function(value, index) {
                    return value + " Fő";
                  }
                }
              }}
            />
          )}
          {tabIndex === 2 && (
            <Chart
              type="Line"
              data={items.project_health}
              options={{
                axisY: {
                  onlyInteger: true,
                  labelInterpolationFnc: function(value, index) {
                    return value + " Ft/ó";
                  }
                }
              }}
            />
          )}
          {tabIndex === 3 && (
            <Chart
              type="Line"
              data={items.worker_vehicles}
              options={{
                axisY: {
                  onlyInteger: true,
                  labelInterpolationFnc: function(value, index) {
                    return value + " db";
                  }
                }
              }}
            />
          )}
          {tabIndex === 4 && (
            <Chart
              type="Line"
              data={items.invoices}
              options={{
                axisY: {
                  onlyInteger: true,
                  labelInterpolationFnc: function(value, index) {
                    return value + " Ft";
                  }
                }
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

DashboardProject.propTypes = {
  project: PropTypes.object.isRequired,
  filter: PropTypes.array
};

export default DashboardProject;
