import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";
import ServiceWorksheet from "views/Service/ServiceWorksheet";

class ServiceWorksheets extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("service-worksheets");

    this.state = {
      access: access ? access : "",
      showDetails: false,
      definition: {
        id: "service-worksheets",
        url: "service-worksheets",
        attributes: strings.attributes["service-worksheets"],
        condition: props.item
          ? {
              id: props.itemKey,
              value: props.item["id"]
            }
          : undefined,
        columns: [
          {
            id: "worksheet_number",
            readOnly: true,
            //required: true,
            detailLink: true
          },
          {
            id: "address",
            required: true,
            defaultValue: () => this.props.item.address
          },
          {
            id: "hourly_price",
            type: "currency",
            required: true,
            defaultValue: () => this.props.item.hourly_price
          },
          { id: "counter", required: true },
          {
            id: "user_id",
            type: "select",
            datasource: {
              controller: "users",
              objectField: "user",
              valueField: "id",
              label: "name",
              sort: { "user.name": "asc" },
              search: ["user.name"]
            },
            sort: { "user.name": "asc" },
            search: ["user.name"]
          },
          { id: "day", type: "unixDate", required: true },
          {
            id: "status",
            type: "select",
            options: strings.options["service-worksheets"].status,
            required: true,
            defaultValue: 1
          },
          {
            id: "project_id",
            type: "select",
            datasource: {
              controller: "projects",
              objectField: "project",
              valueField: "id",
              label: "name",
              sort: { "project.name": "asc" },
              search: ["project.name"]
            },
            sort: { "project.name": "asc" },
            search: ["project.name"]
          },
          {
            id: "service_vehicle_id",
            type: "select",
            datasource: {
              controller: "service-vehicles",
              objectField: "service_vehicle",
              valueField: "id",
              label: "platenumber",
              sort: { "service_vehicle.platenumber": "asc" },
              search: ["service_vehicle.platenumber"]
            },
            sort: { "service_vehicle.platenumber": "asc" },
            search: ["service_vehicle.platenumber"],
            required: true
          },
          { id: "active", type: "checkbox", defaultValue: 1 },
          { id: "description", visible: false, multiline: true, rows: 5 },
          { id: "public_note", visible: false, multiline: true, rows: 5 },
          { id: "private_note", visible: false, multiline: true, rows: 5 }
        ]
      }
    };

    if (props.itemKey) {
      this.hideMasterField(props.itemKey);
    }
  }

  hideMasterField = key => {
    this.state.definition.columns.forEach(column => {
      if (column.id === key) {
        column.system = true;
        column.hidden = true;
        column.visible = false;
      }
    });
  };

  componentDidMount() {}

  toggleDetails = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  render() {
    const { definition, showDetails } = this.state;
    const {
      user,
      filterable,
      viewMode,
      showPaginationTop,
      showPaginationBottom,
      viewOnRowClick,
      isDetailView,
      height,
      //item,
      editItem,

      title,
      onChildEdit,
      onChildCancelEdit,
      breadcrumbs
    } = this.props;

    return (
      <div>
        <DataGrid
          user={user}
          onEdit={onChildEdit}
          onCancelEdit={onChildCancelEdit}
          breadcrumbs={breadcrumbs}
          definition={definition}
          FormComponent={ServiceWorksheet}
          ViewComponent={ServiceWorksheet}
          FormComponentProps={{
            user,
            showDetails,
            toggleDetails: this.toggleDetails,
            title
          }}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("service-worksheets", column.id);
          }}
          filterable={filterable}
          viewMode={viewMode}
          enablePdfExport /*#pdfTemplates#*/
          showPaginationTop={showPaginationTop}
          showPaginationBottom={showPaginationBottom}
          height={height}
          item={editItem}
          isDetailView={isDetailView}
          viewOnRowClick={viewOnRowClick}
          access={this.state.access}
          title={item => {
            if (!item) {
              if (title) {
                return title;
              }
              return viewMode ? strings.menu["service-worksheets"].title : "";
            } else if (item.id) {
              return (
                (isDetailView
                  ? strings.menu["service-worksheets"].title + ": "
                  : "") + (item.worksheet_number || item.id)
              );
            } else {
              return `Új ${strings.menu["service-worksheets"].singularTitle}`;
            }
          }}
        />
      </div>
    );
  }
}

ServiceWorksheets.defaultProps = {
  filterable: true,
  viewMode: false,
  showPaginationBottom: true,
  showPaginationTop: false,
  viewOnRowClick: false
};

ServiceWorksheets.propTypes = {
  user: PropTypes.object.isRequired,

  item: PropTypes.object,
  editItem: PropTypes.object,
  itemKey: PropTypes.string,
  title: PropTypes.string,
  filterable: PropTypes.bool,
  viewMode: PropTypes.bool,

  showPaginationTop: PropTypes.bool,
  showPaginationBottom: PropTypes.bool,
  viewOnRowClick: PropTypes.bool,
  isDetailView: PropTypes.bool,
  showDetails: PropTypes.bool,
  toggleDetails: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChildEdit: PropTypes.func,
  onChildCancelEdit: PropTypes.func,
  breadcrumbs: PropTypes.array
};

export default ServiceWorksheets;
