import requestHelper from "common/utils/requestHelper";

export const ACTION_START = "_ACTION_START";
export const ACTION_SUCCESS = "_ACTION_SUCCESS";
export const ACTION_FAILED = "_ACTION_FAILED";
export const ACTION_USER = "USER";
export const ACTION_LOGOUT = "ACTION_LOGOUT";

export const updateListItem = (item, list) => {
  let newList = list.slice();
  for (let i in newList) {
    if (newList[i].id === item.id) {
      newList[i] = Object.assign({}, newList[i], item);
      return newList;
    }
  }
  return newList;
};

export const updateAllListItem = (items, list) => {
  let map = {};
  items.forEach(element => {
    map[element.id] = element;
  });
  let newList = list.slice();
  for (let i in newList) {
    if (map[newList[i].id]) {
      newList[i] = Object.assign({}, newList[i], map[newList[i].id]);
    }
  }
  return newList;
};

export const updateByListItem = (item, key, list) => {
  let newList = list.slice();
  for (let i in newList) {
    if (newList[i][key].id === item.id) {
      newList[i] = Object.assign({}, newList[i], { [key]: item });
    }
  }
  return newList;
};

export const removeListItem = (item, list) => {
  let newList = list.slice();
  for (let i in newList) {
    if (newList[i].id === item.id) {
      newList.splice(i, 1);
      return newList;
    }
  }
  return newList;
};

export const createListItem = (item, list) => {
  let newList = list.slice();
  if (Array.isArray(item)) {
    newList.push.apply(newList, item);
  } else {
    newList.unshift(Object.assign({}, item));
  }
  return newList;
};

/**
 * Töröl egy elemet a megadott mező egyezése esetén
 *
 * @param {Array} items Módosítani kívánt lista
 * @param {String} key A kulcs, ami mentén az azonosságot vizsgáljuk
 * @param {any} value Az érték, amivel a kulcsmezőnek egyeznie kell
 * @return {Array} A módosított lista
 */
const deleteByKey = (items, key, value) => {
  let itemsNew = items.slice();
  for (let i in itemsNew) {
    let item = itemsNew[i];
    if (item[key] === value) {
      itemsNew.splice(i, 1);
      break;
    }
  }

  return itemsNew;
};

/**
 * Frissít egy elemet a listában egy megadott mező egyezése esetén
 *
 * @param {Array} items Módosítani kívánt lista
 * @param {String} key A kulcs, ami mentén az azonosságot vizsgáljuk
 * @param {Object} updatedItem A frissített elem, amit a listában a régi helyére kell tenni
 * @return {Array} A módosított lista
 */
const updateByKey = (items, key, updatedItem) => {
  let itemsNew = items.slice();
  for (let i in itemsNew) {
    if (itemsNew[i][key] === updatedItem[key]) {
      itemsNew[i] = updatedItem;
      break;
    }
  }

  return itemsNew;
};

/**
 * Hozzáad egy új elemet a listához
 *
 * @param {Array} items Módosítani kívánt lista
 * @param {Object} newItem  Új beszúrandó elem
 * @return {Array} A módosított lista
 */
const addNew = (items, newItem) => {
  let itemsNew = items.slice();
  itemsNew.push(newItem);
  return itemsNew;
  /*
  let itemsNew = [];
  itemsNew.push(newItem);
  for (let i in items) {
    itemsNew.push(items[i]);
  }

  return itemsNew;*/
};

export const restReducers = (controllers = [], actions = []) => {
  let reducers = {};
  for (let i in controllers) {
    let controller = controllers[i];
    let defaultState = {
      loading: false,
      initialized: false,
      data: controller.object ? {} : [],
      time: Date.now(),
      error: null,
      pagination: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        pageCount: 0
      },
      sort: "",
      filter: {}
    };

    const ACTION_TYPE_BASE = controller.name;
    reducers[controller.name] = (state = defaultState, action) => {
      switch (action.type) {
        case ACTION_TYPE_BASE + "_GET" + ACTION_START:
          state.loading = true;
          return state; //{...state, loading: true };
        case ACTION_TYPE_BASE + "_GET" + ACTION_SUCCESS:
          return Object.assign({}, state, {
            data: action.data,
            pagination: action.pagination,
            sort: action.sort,
            filter: action.filter,
            error: null,
            time: Date.now(),
            loading: false
          });
        case ACTION_TYPE_BASE + "_GET" + ACTION_FAILED:
          return Object.assign({}, state, {
            error: action.error,
            time: Date.now(),
            loading: false
          });
        case ACTION_TYPE_BASE + "_UPDATE" + ACTION_START:
          state.loading = true;
          return state;
        case ACTION_TYPE_BASE + "_UPDATE" + ACTION_SUCCESS:
          return Object.assign({}, state, {
            data: updateListItem(action.data, state.data),
            loading: false,
            error: null
          });
        case ACTION_TYPE_BASE + "_UPDATE" + ACTION_FAILED:
          return Object.assign({}, state, {
            error: action.error,
            loading: false
          });
        case ACTION_TYPE_BASE + "_UPDATE_BY" + ACTION_SUCCESS:
          return Object.assign({}, state, {
            data: updateByListItem(action.data, action.key, state.data),
            loading: false,
            error: null
          });
        case ACTION_TYPE_BASE + "_CREATE" + ACTION_START:
          state.loading = true;
          return state;
        case ACTION_TYPE_BASE + "_CREATE" + ACTION_SUCCESS:
          return Object.assign({}, state, {
            data: createListItem(action.data, state.data),
            loading: false,
            error: null
          });
        case ACTION_TYPE_BASE + "_CREATE" + ACTION_FAILED:
          return Object.assign({}, state, {
            error: action.error,
            loading: false
          });
        case ACTION_TYPE_BASE + "_REMOVE" + ACTION_START:
          state.loading = true;
          return state;
        case ACTION_TYPE_BASE + "_REMOVE" + ACTION_SUCCESS:
          return Object.assign({}, state, {
            data: removeListItem(action.data, state.data),
            pagination: {
              ...state.pagination,
              totalCount: state.pagination.totalCount - 1
            },
            loading: false,
            error: null
          });
        case ACTION_TYPE_BASE + "_REMOVE" + ACTION_FAILED:
          return Object.assign({}, state, {
            error: action.error,
            loading: false
          });
        default:
          return state;
      }
    };
  }

  let defaultState = {
    loading: false,
    initialized: false,
    data: {},
    time: Date.now(),
    error: null,
    pagination: {
      page: 0, //Aktuális oldal (A react table 0-val kezd a szerver oldali api 1-gyel)
      pages: null, //Lapok száma
      pageSize: 10,
      total: 0,
      from: 0,
      to: 0
      /*pageSize: 20,
      currentPage: 1,
      totalCount: 0,
      pageCount: 0*/
    },
    sort: "",
    filter: {}
  };

  reducers["user"] = (state = defaultState, action) => {
    switch (action.type) {
      case ACTION_USER + ACTION_START:
        state.loading = true;
        return state;
      case ACTION_USER + ACTION_SUCCESS:
        return Object.assign({}, state, {
          data:
            action.data === null ? {} : Object.assign(state.data, action.data),
          loading: false,
          error: null,
          initialized: true
        });
      case ACTION_USER + ACTION_FAILED:
        return Object.assign({}, state, {
          loading: false,
          error: { status: action.data.status, error: action.data.data },
          initialized: true
        });
      case ACTION_LOGOUT:
        return Object.assign({}, state, {
          loading: false,
          data: {},
          initialized: true
        });
      default:
        return state;
    }
  };

  reducers["socket"] = (state = { connected: false }, action) => {
    switch (action.type) {
      case "SET_SOCKET_STATE":
        return Object.assign({}, state, action.data);
      default:
        return state;
    }
  };

  for (let j in actions) {
    reducers[actions[j].action] = actions[j].reducer;
  }

  return reducers;
};

/**
 * Egy Objektumot (Kulcs érték pár) alakítá át url query-vé
 *
 * @param {Object} params Átalakítandó paraméterek
 * @return {String} params Átalakítandó paraméterek
 */
const getUrlParams = params => {
  var str = "";
  for (var key in params) {
    if (str !== "") {
      str += "&";
    }
    if (params[key]) {
      if (typeof params[key] === "object") {
        str += key + "=" + encodeURIComponent(JSON.stringify(params[key]));
      } else {
        str += key + "=" + encodeURIComponent(params[key]);
      }
    }
  }
  return str;
};

/* REDUX FUNCTIONS START */

const indexR = (controller, params, reducer) => dispatch => {
  const urlParams = getUrlParams(params);
  const ACTION_TYPE = (reducer || controller) + "_GET";
  dispatch({
    type: ACTION_TYPE + ACTION_START
  });
  return requestHelper({
    url: "/" + controller + (urlParams ? "?" + urlParams : ""),
    method: "GET"
  })
    .then(res => {
      dispatch({
        type: ACTION_TYPE + ACTION_SUCCESS,
        data: res.data.data,
        pagination: {
          page: parseInt(res.data.current_page) - 1, //Aktuális oldal (A react table 0-val kezd a szerver oldali api 1-gyel)
          pages: parseInt(res.data.last_page), //Lapok száma
          pageSize: parseInt(res.data.per_page),
          total: parseInt(res.data.total),
          from: parseInt(res.data.from),
          to: parseInt(res.data.to)
        }
        /*filter: filter,
        sort: sort*/
      });
      return res.data;
    })
    .catch(res => {
      throw res;
    });
};

const updateR = (controller, id, item, reducer, popupCallback) => dispatch => {
  const ACTION_TYPE = (reducer || controller) + "_UPDATE";
  dispatch({
    type: ACTION_TYPE + ACTION_START
  });
  return requestHelper({
    url: "/" + controller + "/" + id,
    method: "PUT",
    data: item
  })
    .then(res => {
      dispatch({
        type: ACTION_TYPE + ACTION_SUCCESS,
        data: res.data
      });
      const message = res.headers["popup-message"]
        ? decodeURIComponent(res.headers["popup-message"]).replace(/\+/g, " ")
        : null;
      if (message) {
        popupCallback && popupCallback(message);
      }
      return res.data;
    })
    .catch(res => {
      dispatch({
        type: ACTION_TYPE + ACTION_FAILED,
        error: res.data
      });
      throw res.data;
    });
};

const createR = (controller, item, reducer, popupCallback) => dispatch => {
  const ACTION_TYPE = (reducer || controller) + "_CREATE";
  dispatch({
    type: ACTION_TYPE + ACTION_START
  });
  return requestHelper({
    url: "/" + controller,
    method: "POST",
    data: item
  })
    .then(res => {
      dispatch({
        type: ACTION_TYPE + ACTION_SUCCESS,
        data: res.data
      });
      const message = res.headers["popup-message"]
        ? decodeURIComponent(res.headers["popup-message"]).replace(/\+/g, " ")
        : null;
      if (message) {
        popupCallback && popupCallback(message);
      }
      return res.data;
    })
    .catch(res => {
      dispatch({
        type: ACTION_TYPE + ACTION_FAILED,
        error: res.data
      });
      throw res.data;
    });
};

const removeR = (
  controller,
  id,
  params,
  reducer,
  popupCallback
) => dispatch => {
  const urlParams = getUrlParams(params);
  const ACTION_TYPE = (reducer || controller) + "_DELETE";
  dispatch({
    type: ACTION_TYPE + ACTION_START
  });
  return requestHelper({
    url: "/" + controller + "/" + id + (urlParams ? "?" + urlParams : ""),
    method: "DELETE"
  })
    .then(res => {
      dispatch({
        type: ACTION_TYPE + ACTION_SUCCESS,
        data: res.data
      });
      const message = res.headers["popup-message"]
        ? decodeURIComponent(res.headers["popup-message"]).replace(/\+/g, " ")
        : null;
      if (message) {
        popupCallback && popupCallback(message);
      }
      return res.data;
    })
    .catch(res => {
      dispatch({
        type: ACTION_TYPE + ACTION_FAILED,
        error: res.data
      });
      throw res.data;
    });
};

/* REDUX FUNCTIONS END */

const index = (controller, params) => {
  const urlParams = getUrlParams(params);
  return requestHelper({
    url: "/" + controller + (urlParams ? "?" + urlParams : ""),
    method: "GET"
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res;
    });
};

const view = (controller, id) => {
  return requestHelper({
    url: "/" + controller + "/" + id,
    method: "GET"
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const viewPdfLink = (controller, id, template) => {
  return `/${controller}/pdf/${id}/${template}/${localStorage.getItem(
    "token"
  )}`;
};

const exportPdf = (controller, id, template) => {
  return requestHelper({
    url: `/${controller}/pdf/${id}/${template}`,
    method: "POST"
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const restore = (controller, id) => {
  return requestHelper({
    url: "/" + controller + "/restore/" + id,
    method: "GET"
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const update = (controller, id, item, params, popupCallback) => {
  const urlParams = getUrlParams(params);
  return requestHelper({
    url: "/" + controller + "/" + id + (urlParams ? "?" + urlParams : ""),
    method: "PUT",
    data: item
  })
    .then(res => {
      const message = res.headers["popup-message"]
        ? decodeURIComponent(res.headers["popup-message"]).replace(/\+/g, " ")
        : null;
      if (message) {
        popupCallback && popupCallback(message);
      }

      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const create = (controller, item, params, popupCallback) => {
  const urlParams = getUrlParams(params);
  return requestHelper({
    url: "/" + controller + (urlParams ? "?" + urlParams : ""),
    method: "POST",
    data: item
  })
    .then(res => {
      const message = res.headers["popup-message"]
        ? decodeURIComponent(res.headers["popup-message"]).replace(/\+/g, " ")
        : null;
      if (message) {
        popupCallback && popupCallback(message);
      }
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const remove = (controller, id, params, popupCallback) => {
  const urlParams = getUrlParams(params);

  return requestHelper({
    url: "/" + controller + "/" + id + (urlParams ? "?" + urlParams : ""),
    method: "DELETE"
  })
    .then(res => {
      const message = res.headers["popup-message"]
        ? decodeURIComponent(res.headers["popup-message"]).replace(/\+/g, " ")
        : null;
      if (message) {
        popupCallback && popupCallback(message);
      }
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const saveSettings = settings => {
  return create("users/save-settings", { settings });
};

const upload = file => {
  /*
  const formData = new FormData();
  formData.append("file", file);
  dispatch({
    type: FILE_UPLOAD_LOAD
  });
  return axios
    .post("/v1/files", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response =>
      dispatch({
        type: FILE_UPLOAD_SUCCESS,
        id: get(response, "data.data.id", false),
        url: get(response, "data.data.url", false),
        data: get(response, "data.data", null),
        file
      })
    )
    .catch(error => {
      return dispatch({
        type: FILE_UPLOAD_FAILED,
        message: get(error, "response.data.message", null),
        formErrors: get(error, "response.data.form_errors", {})
      });
    });
  */

  const formData = new FormData();
  formData.append("file", file);
  return requestHelper({
    url: "/files",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res.data;
    });
};

const login = (controller, email, password) => dispatch => {
  /*dispatch({
    type: ACTION_USER + ACTION_START
  });*/
  return requestHelper({
    url: "/" + controller + "/login",
    method: "POST",
    data: { email: email, password: password }
  })
    .then(res => {
      dispatch({
        type: ACTION_USER + ACTION_SUCCESS,
        data: res.data
      });
      return res.data;
    })
    .catch(res => {
      /* dispatch({
        type: ACTION_USER + ACTION_FAILED,
        data: res
      });*/
      throw res;
    });
};

const refreshUser = data => dispatch => {
  dispatch({
    type: ACTION_USER + ACTION_SUCCESS,
    data
  });
};

const logout = () => dispatch => {
  dispatch({
    type: ACTION_USER + ACTION_SUCCESS,
    data: null
  });
};

const send = (controller, id) => {
  return requestHelper({
    url: "/" + controller + "/send/" + id,
    method: "POST"
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res;
    });
};

const action = (controller, action, id, data, method) => {
  return requestHelper({
    url: `/${controller}/${action}${id ? `/${id}` : ""}`,
    method: method || "POST",
    data
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res;
    });
};

const auth = () => dispatch => {
  dispatch({
    type: ACTION_USER + ACTION_START
  });

  return requestHelper({
    url: "/users/auth",
    method: "GET"
  })
    .then(res => {
      dispatch({
        type: ACTION_USER + ACTION_SUCCESS,
        data: res.data
      });
      return res.data;
    })
    .catch(res => {
      dispatch({
        type: ACTION_USER + ACTION_FAILED,
        data: res
      });
      throw res;
    });
};
/* REDUX FUNCTIONS END */

const issues = (repo, token, state) => {
  return requestHelper({
    url:
      "https://gitlab.com/molnarbence/" +
      repo +
      "/issues.atom?feed_token=" +
      token +
      "&state=" +
      state,
    method: "GET"
  })
    .then(res => {
      return res.data;
    })
    .catch(res => {
      throw res;
    });
};

const getErrorMessage = error => {
  let err = "A manóba. :(";
  if (error.data && error.data.error) {
    err = error.data.error;
  } else if (error.statusText) {
    err = error.statusText;
  }
  return err;
};

const restHelper = {
  index,
  view,
  update,
  create,
  remove,
  indexR,
  updateR,
  createR,
  removeR,
  login,
  logout,
  send,
  auth,
  deleteByKey,
  updateByKey,
  addNew,
  restore,
  issues,
  refreshUser,
  upload,
  exportPdf,
  action,
  saveSettings,
  getErrorMessage
};

export default restHelper;
