import React from "react";
import { OverlayView } from "react-google-maps";
import { withStyles, Typography } from "@material-ui/core";
const getPixelPositionOffset = pixelOffset => (width, height) => ({
  x: -(width / 2) + pixelOffset.x,
  y: -(height / 2) + pixelOffset.y
});

const styles = theme => ({
  /* The location pointed to by the popup tip. */
  popupTipAnchor: {
    height: 0,
    position: "absolute",
    /* The max width of the info window. */
    width: 200
  },

  /* Draw the tip. */
  popupBubbleAnchor: {
    position: "absolute",
    width: "100%",
    bottom: 16,
    left: 0,
    "&:after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      /* Center the tip horizontally. */
      transform: "translate(-50%, 0)",
      /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
      width: 0,
      height: 0,
      /* The tip is 8px high, and 12px wide. */
      borderLeft: "6px solid transparent",
      borderRight: "6px solid transparent",
      borderTop: "solid 8px white"
    }
  },
  /* The popup bubble itself. */
  popupBubbleContent: {
    position: "absolute",
    top: 0,
    left: 0,
    transform: "translate(-50%, -100%)",
    /* Style the info window. */
    backgroundColor: "white",
    padding: 5,
    borderRadius: 5,
    overflowY: "auto",
    maxHeight: 60,
    boxShadow: "0px 2px 10px 1px rgba(0, 0, 0, 0.5)"
  }
});

const Popup = props => {
  return (
    <OverlayView
      position={props.anchorPosition}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset(props.markerPixelOffset)}
    >
      <div className={props.classes.popupTipAnchor}>
        <div className={props.classes.popupBubbleAnchor}>
          <div className={props.classes.popupBubbleContent}>
            <Typography variant="subtitle1" color="textPrimary">
              {props.content}
            </Typography>
          </div>
        </div>
      </div>
    </OverlayView>
  );
};

export default withStyles(styles)(Popup);
