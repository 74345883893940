import React from "react";
import PropTypes from "prop-types";
import DashboardProjects from "views/Dashboard/DashboardProjects";
import Grid from "@material-ui/core/Grid";
import DatePicker from "common/components/DatePicker";
import dateHelper from "common/utils/dateHelper";

class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);

    let dates = null;
    const storage = sessionStorage.getItem("report");
    if (storage) {
      let storageData = JSON.parse(storage);
      if (storageData && storageData.dates) {
        if (typeof storageData.dates === "string") {
          dates = storageData.dates;
        } else {
          dates = dateHelper.unixToRange(storageData.dates);
        }
      }
    }

    this.state = {
      dates: dates,
      time: new Date().getTime()
    };
  }

  filter = field => {
    const dates = this.parseValue(this.state.dates);
    return dates
      ? [[field, "between", [dates.start.unix(), dates.end.unix()]]]
      : null;
  };

  parseValue = value => {
    if (typeof value === "string") {
      return dateHelper.getRange(value);
    } else {
      return value;
    }
  };

  onChange = (value, rangeKey) => {
    //console.log("onChange", value);
    const v = rangeKey ? rangeKey : value;
    const vs = rangeKey
      ? rangeKey
      : v
        ? { start: v.start.unix(), end: v.end.unix() }
        : null;
    if (vs) {
      sessionStorage.setItem("report", JSON.stringify({ dates: vs }));
    } else {
      sessionStorage.removeItem("report");
    }
    this.setState({
      dates: v,
      time: new Date().getTime()
    });
  };

  render() {
    return (
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={12} sm={6} md={5} lg={4} xl={3}>
          <div
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 8,
              marginBottom: -16
            }}
          >
            <DatePicker
              key={this.state.time}
              onChange={this.onChange}
              value={this.state.dates}
              selectionType="range"
              numberOfCalendars={2}
              label="Időszak"
              singleDateRange={true}
              firstOfWeek={1}
            />
          </div>
        </Grid>
        <Grid item={true} xs={12} md={12}>
          <DashboardProjects
            key={this.state.time}
            filter={this.filter("t.day") || []}
          />
        </Grid>
      </Grid>
    );
  }
}

AdminDashboard.propTypes = {
  
};

export default AdminDashboard;
