import React from "react";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";

const TemplateFeed = ({ items }) => {
  return items.map((item, index) => {
    return (
      <div
        key={index}
        style={{
          pageBreakBefore: "always",
          textAlign: "center"
        }}
      >
        <QRCode
          renderAs="svg"
          level="H"
          size={256}
          value={`T:${item.id}`}
          //value={`https://app.vasutoptika.hu/check-tool/${item.id}`}
          style={{
            marginLeft: 0,
            marginRight: 12,
            marginTop: 20,
            marginBottom: 5,
            width: 76,
            height: 76
          }}
        />
        <div style={{ fontSize: 14, textAlign: "center", marginRight: 12 }}>
          {item.tool_number}
        </div>
      </div>
    );
  });
};

TemplateFeed.propTypes = {
  items: PropTypes.array.isRequired
};

export default TemplateFeed;
