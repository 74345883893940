import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";

class Roles extends React.Component {
  constructor(props) {
    super(props);
    const access = props.user.accessController("roles");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "roles",
        url: "roles",
        attributes: strings.attributes.roles,
        columns: [
          { id: "name" },
          { id: "active", type: "checkbox", visible: false }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const { user } = this.props;
    return (
      <div>
        <DataGrid
          user={user}
          definition={definition}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return this.props.user.accessField("roles", column.id);
          }}
          access={this.state.access}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return item.name;
            } else {
              return "Új szerepkör";
            }
          }}
        />
      </div>
    );
  }
}

Roles.propTypes = {
  //classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default Roles;
