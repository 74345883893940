import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import strings from "variables/strings.json";

class HistoryView extends React.Component {
  render() {
    const { item } = this.props;
    const columns = [
      { id: "name", title: strings.attributes["histories"]["field"] },
      { id: "old", title: strings.attributes["histories"]["old"] },
      { id: "new", title: strings.attributes["histories"]["new"] }
    ];
    const rows = item.data || [];
    return (
      <div>
        <Paper>
          <Table size="small" aria-label="table">
            <TableHead>
              <TableRow>
                {columns.map((column, columnIndex) => (
                  <TableCell key={columnIndex}>
                    {column.title || column.id}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((column, columnIndex) => (
                    <TableCell key={columnIndex}>{row[column.id]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

HistoryView.propTypes = {
  definition: PropTypes.object.isRequired,
  access: PropTypes.string.isRequired,
  fieldsInRow: PropTypes.number,
  item: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  afterSave: PropTypes.func,
  Footer: PropTypes.any,
  fieldAccessHandler: PropTypes.func,
  error: PropTypes.string,
  isRedux: PropTypes.bool
};

export default HistoryView;
