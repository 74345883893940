import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";

const unitsDict = {
  1: "Óra",
  2: "Darab",
  3: "Méter",
  4: "Négyzetméter",
  5: "Köbméter",
  6: "Készlet",
  7: "Üzemóra",
  8: "Kilométer",
  9: "Tonna",
  10: "Nap"
};

class ProjectTasks extends React.Component {
  access = "";

  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("project-tasks");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "project-tasks",
        url: "project-tasks",
        attributes: strings.attributes["project-tasks"],
        condition: {
          id: "project_id",
          value: this.props.item["id"]
        },
        columns: [
          {
            id: "task_id",
            type: "select",
            datasource: {
              controller: "tasks",
              objectField: "task",
              valueField: "id",
              search: ["task.name", "task_group.name"],
              //filter: [["unit", "=", 1]],
              requestParams: {
                exclptasks: { project_id: this.props.item.id }
              },
              label: item => {
                return item
                  ? item.name +
                      " (" +
                      unitsDict[item.unit] +
                      ")" +
                      (item.task_group ? ", " + item.task_group.name : "")
                  : "";
              }
            }
          },
          { id: "price", type: "currency" },
          {
            id: "vehicle_id",
            type: "select",
            datasource: {
              controller: "vehicles",
              objectField: "vehicle",
              valueField: "id",
              search: ["platenumber"],
              //filter: [["unit", "=", 1]],
              label: item => {
                return item ? item.platenumber : "";
              }
            },
            /*visible: item => {
              return false;
            },*/
            hidden: item => {
              if (item.task && item.task.resource === 1) {
                return false;
              }
              return true;
            }
          },
          {
            id: "parent_id",
            type: "select",
            datasource: {
              controller: "project-tasks",
              objectField: "parent",
              valueField: "id",
              search: ["task.name"],
              filter: [
                ["task.unit", "gt", 1],
                ["task.active", "exact", true],
                ["project_task.active", "exact", true],
                ["project_id", "exact", this.props.item["id"]]
              ],
              label: item => {
                return item && item.task
                  ? item.task.name + " (" + unitsDict[item.task.unit] + ")"
                  : item && item.name
                    ? item.name + " (" + unitsDict[item.unit] + ")"
                    : "";
              }
            }
          },
          { id: "active", type: "checkbox", visible: false }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition, access } = this.state;
    const {
      user,
      onChildEdit,
      onChildCancelEdit,
      breadcrumbs,
      isDetailView
    } = this.props;
    //const { classes } = this.props;

    return (
      <DataGrid
        isDetailView={isDetailView}
        user={user}
        onEdit={onChildEdit}
        onCancelEdit={onChildCancelEdit}
        breadcrumbs={breadcrumbs}
        definition={definition}
        startEditRef={ref => (this.startEdit = ref)}
        FormFooter="CreatedUpdated"
        fieldAccessHandler={column => {
          return user.accessField("project-tasks", column.id);
        }}
        access={access}
        filterable={false}
        title={item => {
          if (!item) {
            return "Tallózás";
          } else if (item.id) {
            return item.task.name;
          } else {
            return "Új ár";
          }
        }}
        /*toolbarButtons={[
          {
            tooltip: "Letöltés",
            onClick: () => {},
            Icon: DownloadIcon,
            color: "primary"
          }
        ]}*/
      />
    );
  }
}

ProjectTasks.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired
};

//export default withStyles(uteStyle, { withTheme: true })(ProjectTasks);
export default ProjectTasks;
