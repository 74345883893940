import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";
class Documents extends React.Component {
  access = "";

  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("documents");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "documents",
        url: "documents",
        attributes: strings.attributes["documents"],
        condition: [
          {
            id: "item_id",
            value: this.props.item["id"]
          },
          {
            id: "object_id",
            value: this.props.object_id
          }
        ],
        columns: [
          {
            id: "document_type_id",
            type: "select",
            datasource: {
              controller: "document-types",
              objectField: "document_type",
              valueField: "id",
              search: ["document_type.name"],
              filter: [["object_id", "exact", this.props.object_id]],
              /*requestParams: {
                exclptasks: { project_id: this.props.project.id }
              },*/
              label: "name"
            }
          },
          //{ id: "name" },
          { id: "description" },
          {
            id: "file_id",
            type: "file"
          },
          {
            id: "is_default",
            type: "checkbox"
          },
          {
            id: "created_at",
            hidden: true,
            type: "unixDateTime"
          }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition, access } = this.state;
    const {
      user,
      filterable,
      viewMode,
      showPaginationTop,
      showPaginationBottom,
      viewOnRowClick,
      height,
      isDetailView,
      onChildEdit,
      onChildCancelEdit,
      breadcrumbs
    } = this.props;
    //const { classes } = this.props;

    return (
      <DataGrid
        user={user}
        onEdit={onChildEdit}
        onCancelEdit={onChildCancelEdit}
        breadcrumbs={breadcrumbs}
        definition={definition}
        defaultSort={[{ id: "created_at", desc: true }]}
        startEditRef={ref => (this.startEdit = ref)}
        FormFooter="CreatedUpdated"
        fieldAccessHandler={column => {
          return user.accessField("documents", column.id);
        }}
        access={access}
        filterable={filterable}
        viewMode={viewMode}
        showPaginationTop={showPaginationTop}
        showPaginationBottom={showPaginationBottom}
        height={height}
        viewOnRowClick={viewOnRowClick}
        isDetailView={isDetailView}
        title={item => {
          if (!item) {
            return viewMode ? strings.menu.documents.title : "Tallózás";
          } else if (item.id) {
            return item.file.name;
          } else {
            return "Új dokumentum";
          }
        }}
        /*toolbarButtons={[
          {
            tooltip: "Letöltés",
            onClick: () => {},
            Icon: DownloadIcon,
            color: "primary"
          }
        ]}*/
      />
    );
  }
}

Documents.defaultProps = {
  filterable: false,
  viewMode: false,
  showPaginationBottom: true,
  showPaginationTop: false,
  viewOnRowClick: false
};

Documents.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  object_id: PropTypes.string.isRequired,
  filterable: PropTypes.bool,
  viewMode: PropTypes.bool,
  showPaginationTop: PropTypes.bool,
  showPaginationBottom: PropTypes.bool,
  viewOnRowClick: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

//export default withStyles(uteStyle, { withTheme: true })(ProjectTasks);
export default Documents;
