import React from "react";
import PropTypes from "prop-types";
import Modal from "common/components/Modals/Modal";
import FilterIcon from "@material-ui/icons/FilterList";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormSelectField from "common/components/DataForm/FormSelectField";
import dataFormStyle from "common/assets/style/dataFormStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid } from "@material-ui/core";
import GridCustomSelectFilter from "common/components/DataGrid/GridCustomSelectFilter";
import dateHelper from "common/utils/dateHelper";
import DatePicker from "common/components/DatePicker";
import FormTextField from "common/components/DataForm/FormTextField";
import FormDateRangePickerField from "common/components/DataForm/FormDateRangePickerField";
import InputBase from "@material-ui/core/InputBase";
import DatagridFulltextSearch from "./DatagridFulltextSearch";
import strings from "variables/strings.json";
import DataViewField from "common/components/DataView/DataViewField";

class GridCustomFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: this.getfilter(this.props.filter)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const filter = this.getfilter(nextProps.filter);
    if (JSON.stringify(filter) != JSON.stringify(this.state.filter)) {
      //console.log("Külső filer állítás", filter);
      this.setState({ filter });
    }
  }

  getfilter = filters => {
    let filter = {};
    if (filters) {
      filters.forEach(f => {
        filter[f.id] = f.value;
      });
    }
    return filter;
  };

  onChange = (value, disableTime) => {
    const { onFilteredChange } = this.props;
    //console.log(this.state.filter, value);

    const filter = Object.assign({}, this.state.filter, value);
    //console.log("onFilterChange.filter", filter);
    this.setState({ filter }, () => {
      let filterArr = Object.keys(filter)
        .map(key => {
          return {
            id: key,
            value: filter[key]
          };
        })
        .filter(elem => elem.value !== "");
      //console.log("onFilterChange.filterArr", filterArr);
      onFilteredChange(filterArr, disableTime);
    });
  };

  cellFilterHandler = column => {
    //console.log(column.id, filter);
    const {
      classes,
      attributes,
      forceHeaderFilter,
      toggleHeaderFilter
    } = this.props;
    const { filter } = this.state;
    const columnName =
      column.label ||
      (attributes[column.id] ? attributes[column.id] : column.id);

    //const type = column.options ? "text" : column.type;
    const type = column.type;
    //const { classes } = this.props;
    //const name = "filter_" + column.id;
    //const name = column.id;
    const name = "filter_" + column.id;
    //const filter = f;
    if (column.id === "main_filter") {
      return (
        <DatagridFulltextSearch
          forceHeaderFilter={forceHeaderFilter}
          toggleHeaderFilter={toggleHeaderFilter}
          onChange={evt => this.onChange({ [column.id]: evt.target.value })}
          onSearchClick={evt => this.onChange({}, true)}
          onEnter={evt => this.onChange({}, true)}
          value={filter[column.id] ? filter[column.id] : ""}
        />
      );
    }
    switch (type) {
      case "select":
        /*var filterValues = {};
        for (let i in this.state.filter) {
          const f = this.state.filter[i];
          if (f.value) {
            filterValues[f.id] = f.value;
          }
        }*/
        var opts =
          column.options && typeof column.options === "function"
            ? column.options(filter)
            : column.options;
        if (!opts) {
          return (
            <GridCustomSelectFilter
              columnName={
                typeof columnName === "function" ? columnName() : columnName
              }
              name={name}
              onChange={val => {
                this.setState({
                  filter: Object.assign({}, filter, { [column.id]: val })
                });
                this.onChange({ [column.id]: val });
              }}
              value={filter[column.id] ? filter[column.id] : null}
              column={column}
              type="in"
            />
          );
        }
        return (
          <FormSelectField
            classes={classes}
            name={name}
            datasource={column.datasource}
            label={columnName}
            item={filter}
            column={column}
            id={name}
            options={opts}
            disabled={false}
            access="w"
            autoComplete={name + new Date().getTime()}
            onChange={this.onChange}
            value={filter[column.id] ? filter[column.id] : ""}
            currentValue={
              column.datasource && column.datasource.objectField
                ? typeof column.datasource.objectField === "string"
                  ? filter[column.datasource.objectField]
                  : column.datasource.objectField
                : null
            }
            style={{
              width: "100%"
            }}
          />
        );
      case "unixDate":
      case "unixRelative":
        var f = Object.assign({}, filter[column.id]);
        if (f && f.start && typeof f.start === "number") {
          f = dateHelper.unixToRange(f);
        }
        return (
          <FormDateRangePickerField
            classes={classes}
            name={columnName}
            label={columnName}
            id={name}
            disabled={false}
            access="w"
            type={column.type}
            autoComplete={name + new Date().getTime()}
            fields={{
              start: "start",
              end: "end"
            }}
            onChange={range => {
              const convertedVal = dateHelper.rangeToUnix(range);
              console.log(range, convertedVal);

              this.onChange({
                [column.id]: convertedVal.start ? convertedVal : null
              });
            }}
            value={f.start ? f : null}
            fullWidth
          />
        );
      default:
        return (
          <FormTextField
            classes={classes}
            name={columnName}
            label={columnName}
            id={column.id}
            disabled={false}
            access="w"
            autoComplete={name + new Date().getTime()}
            onChange={this.onChange}
            value={filter[column.id] ? filter[column.id] : ""}
            style={{
              width: "100%"
            }}
          />
        );
    }
  };

  render() {
    const {
      classes,
      theme,
      name,
      onChange,
      columns,
      filters,
      columnName,
      //type,
      value,
      forceHeaderFilter,
      toggleHeaderFilter
      //filter
    } = this.props;
    const { open, filter, currentValue } = this.state;
    const mappedColumns = {};
    columns.map(col => {
      mappedColumns[col.id] = Object.assign({}, col);
    });
    //console.log("GridFilter", filter);
    return (
      <div style={{ paddingBottom: 16, paddingLeft: 24, paddingRight: 24 }}>
        <Grid container spacing={2}>
          {filters.map((f, index) => {
            const column = Object.assign({}, mappedColumns[f.id], f);
            return (
              <Grid key={index} item style={{ width: "100%" }}>
                {this.cellFilterHandler(column)}
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

GridCustomFilter.defaultProps = {
  filter: [],
  columnOrder: []
};

GridCustomFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  name: PropTypes.string,
  onFilteredChange: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  filter: PropTypes.array,
  filters: PropTypes.array,
  forceHeaderFilter: PropTypes.bool,
  toggleHeaderFilter: PropTypes.func
};

export default withStyles(dataFormStyle, { withTheme: true })(GridCustomFilter);
