const drawerWidth = 240;

const appStyle = theme => ({
  "@global": {
    html: {
      fontSize: 13
      //color: theme.palette.type === "dark" ? "#fff" : undefined
      /*[theme.breakpoints.up("sm")]: {
        fontSize: 18
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 20
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 24
      }*/
    },
    ".DateRangePicker__Month": {
      color: theme.palette.type === "dark" ? "#FFF" : undefined
    },
    ".DateRangePicker__WeekdayHeading abbr[title]": {
      color: theme.palette.type === "dark" ? "#FFF" : undefined
    },
    ".DateRangePicker__Date--weekend": {
      backgroundColor: theme.palette.type === "dark" ? "#777" : "#fff"
    },
    ".DateRangePicker__MonthHeaderSelect": {
      backgroundColor: theme.palette.type === "dark" ? "#444" : "#fff",
      color: theme.palette.type === "dark" ? "#FFF" : undefined
    },
    ".DateRangePicker__MonthHeaderLabel": {
      color: theme.palette.type === "dark" ? "#FFF" : undefined
    },
    ".DateRangePicker__CalendarSelection": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.dark
    },
    ".DateRangePicker__CalendarHighlight": {
      borderColor: theme.palette.primary.dark
    },
    ".ct-label": {
      //fontSize: 15
      color: theme.palette.type === "dark" ? "white" : undefined
    }
    /*body: {
      ...theme.typography.body2
    }*/
  },
  root: {
    flexGrow: 1
  },
  appFrame: {
    //height: 430,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%"
    //minHeight: "100vh"
  },
  appBar: {
    position: "absolute",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
    /*[theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3)
    }*/
  },
  pageToolbar: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  appBarShift: {
    width: "calc(100%)",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
    /*[theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth,
    }*/
  },
  drawer: {
    width: drawerWidth,
    flex: 1,
    flexShrink: 0,
    //minHeight: "100vh",
    //overflowY: "scroll"
    height: "100%"
  },
  logo: {
    //maxWidth: "80%",
    height: 30,
    //margin: "0 auto",
    //marginLeft: 20,
    display: "inline-block"
    //marginTop: 5
    //marginRight: -15,
  },
  appName: {
    //maxWidth: "80%",
    //height: 40,
    verticalAlign: "middle",
    margin: "0 auto",
    marginLeft: 34,
    fontSize: 18,
    //lineHeight: "40px",
    display: "inline-block",
    marginTop: -36,
    //marginBottom: 18,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    //marginRight: -15,
    "&:visited": {
      color: theme.palette.primary.main
    },
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  appVerBottom: {
    marginTop: "auto",
    //display: "flex",
    flex: 1
  },
  appVer: {
    //position: "absolute",
    display: "flex",
    alignItems: "end",
    flex: 1,
    //bottom: 0,
    left: theme.spacing(1),
    right: theme.spacing(1),
    marginTop: 12,
    marginBottom: theme.spacing(1),
    //marginBottm: -20,
    fontSize: 10,
    textAlign: "center"
  },
  appVerNum: {
    borderRadius: 3,
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[300],
    marginLeft: 5,
    paddingLeft: 5,
    paddingRight: 5
  },
  appVerNew: {
    borderRadius: 3,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    marginLeft: 5,
    paddingLeft: 5,
    paddingRight: 5,
    textDecoration: "none"
  },
  logoLink: {
    //margin: "0 auto"
    margin: 0,
    marginRight: "auto"
  },
  appTitle: {
    color: "white"
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20
  },
  hide: {
    display: "none"
  },
  drawerPaper: {
    position: "relative",
    //width: drawerWidth,
    minHeight: "100vh",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none"
      //width: 3
    }
  },
  drawerResizable: {
    /*position: "relative",
    minHeight: "100vh",*/
    width: drawerWidth,
    "& > span > div": {
      //right: "0px!important",
      width: 5,
      bottom: "0px!important",
      height: "100vh!important",
      //marginRight: -10,
      zIndex: "100000!important",
      "&:hover": {
        backgroundColor: theme.palette.text.secondary
      }
    }
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  isFavIcon: {
    color: theme.palette.yellow.main
  },
  drawerPaperMobile: {
    position: "absolute",
    right: 0,
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.default,
    //padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -drawerWidth
    }
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: 0
  },
  headerIcon: {
    //fill: theme.palette.primary.contrastText,
    //fill: theme.palette.primary.main,
    ...theme.typography.h5,
    //color: theme.palette.grey[700],
    //marginBottom: -5,
    marginRight: theme.spacing(1)
  },
  headerTitle: {
    textDecoration: "none",
    color: `${
      theme.palette.type === "dark"
        ? "#fff"
        : theme.palette.primary.contrastText
    }!important`,
    //marginRight: -15,
    "&:visited": {
      color: `${
        theme.palette.type === "dark"
          ? "#fff"
          : theme.palette.primary.contrastText
      }!important`
    },
    "&:hover": {
      color: `${
        theme.palette.type === "dark"
          ? "#fff"
          : theme.palette.primary.contrastText
      }!important`
    }
  },
  subHeaderTitle: {},
  snackbarRoot: {
    backgroundColor: theme.palette.error.main,
    //color: theme.palette.error.main,
    marginTop: theme.spacing(3)
  }
});

export default appStyle;
