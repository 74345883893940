import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";
import WorkCloth from "views/WorkClothes/WorkCloth";

class WorkClothes extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("work-clothes");

    this.state = {
      access: access ? access : "",
      showDetails: false,
      definition: {
        id: "work-clothes",
        url: "work-clothes",
        attributes: strings.attributes["work-clothes"],
        condition: props.item
          ? {
            id: props.itemKey,
            value: props.item["id"]
          }
          : undefined,
        columns: [
          { id: "name", required: true, detailLink: true },
          { id: "description", visible: false },
          {
            id: "user_id",
            type: "select",
            datasource: {
              controller: "users",
              objectField: "user",
              valueField: "id",
              label: "name",
              sort: { "user.name": "asc" },
              search: ["user.name"]
            },
            sort: { "user.name": "asc" },
            search: ["user.name"]
          },
          { id: "transfer_at", type: "unixDate" },
          {
            id: "project_id",
            type: "select",
            datasource: {
              controller: "projects",
              objectField: "project",
              valueField: "id",
              label: "name",
              sort: { "project.name": "asc" },
              search: ["project.name"]
            },
            sort: { "project.name": "asc" },
            search: ["project.name"]
          },
          {
            id: "invoice_id",
            type: "select",
            datasource: {
              controller: "invoices",
              objectField: "invoice",
              valueField: "id",
              label: "invoice_number",
              sort: { "invoice.invoice_number": "asc" },
              search: ["invoice.invoice_number"]
            },
            sort: { "invoice.invoice_number": "asc" },
            search: ["invoice.invoice_number"]
          },
          { id: "price", type: "currency" },
          { id: "item_count" }
        ]
      }
    };

    if (props.itemKey) {
      this.hideMasterField(props.itemKey);
    }
  }

  hideMasterField = key => {
    this.state.definition.columns.forEach(column => {
      if (column.id === key) {
        column.system = true;
        column.hidden = true;
        column.visible = false;
      }
    });
  };

  componentDidMount() {}

  toggleDetails = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  render() {
    const { definition, showDetails } = this.state;
    const {
      user,
      filterable,
      viewMode,
      showPaginationTop,
      showPaginationBottom,
      viewOnRowClick,
      isDetailView,
      height,
      //item,
      editItem,

      title
    } = this.props;
    return (
      <div>
        <DataGrid
          user={user}
          definition={definition}
          FormComponent={WorkCloth}
          ViewComponent={WorkCloth}
          //FormComponentProps={{}}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("work-clothes", column.id);
          }}
          filterable={filterable}
          viewMode={viewMode}
          showPaginationTop={showPaginationTop}
          showPaginationBottom={showPaginationBottom}
          height={height}
          item={editItem}
          isDetailView={isDetailView}
          viewOnRowClick={viewOnRowClick}
          access={this.state.access}
          title={item => {
            if (!item) {
              if (title) {
                return title;
              }
              return viewMode ? strings.menu["work-clothes"].title : "Tallózás";
            } else if (item.id) {
              return (
                (isDetailView
                  ? strings.menu["work-clothes"].title + ": "
                  : "") + (item.name || item.id)
              );
            } else {
              return `Új ${strings.menu["work-clothes"].singularTitle}`;
            }
          }}
        />
      </div>
    );
  }
}

WorkClothes.defaultProps = {
  filterable: true,
  viewMode: false,
  showPaginationBottom: true,
  showPaginationTop: false,
  viewOnRowClick: false
};

WorkClothes.propTypes = {
  user: PropTypes.object.isRequired,
  item: PropTypes.object,
  editItem: PropTypes.object,
  itemKey: PropTypes.string,
  title: PropTypes.string,
  filterable: PropTypes.bool,
  viewMode: PropTypes.bool,

  showPaginationTop: PropTypes.bool,
  showPaginationBottom: PropTypes.bool,
  viewOnRowClick: PropTypes.bool,
  isDetailView: PropTypes.bool,
  showDetails: PropTypes.bool,
  toggleDetails: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default WorkClothes;
