import DataGrid from "common/components/DataGrid/DataGrid";
import formatHelper from "common/utils/formatHelper";
import PropTypes from "prop-types";
import React from "react";
import strings from "variables/strings.json";

class WorksheetUsers extends React.Component {
  access = "";

  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("worksheet-users");

    this.state = {
      title: "Dolgozók",
      access: access ? access : "",
      definition: {
        id: "worksheet-users",
        url: "worksheet-users",
        attributes: strings.attributes["worksheet-users"],
        condition: {
          id: "worksheet_id",
          value: this.props.item["id"]
        },
        columns: [
          {
            id: "user_id",
            type: "select",
            datasource: {
              controller: "users",
              objectField: "user",
              valueField: "id",
              search: ["user.name", "subcontractor.name"],
              filter: [
                ["user.active", "exact", true],
                ["function", "exact", "user_list_worker"],
                ["user.hourly_price", "gt", 0]
              ],
              label: item => {
                if (!item) {
                  return "";
                }

                if (item.subcontractor) {
                  let res =
                    item.name +
                    " - " +
                    item.subcontractor.name.substring(0, 10);
                  if (item.subcontractor.name.length > 10) {
                    res += "...";
                  }
                  return res;
                } else {
                  return item.name;
                }
              },
              sort: { "user.name": "asc" }
            },
            footer: "count"
          },
          { id: "start_hour", type: "unixTime" },
          { id: "end_hour", type: "unixTime" },
          {
            id: "hourly_price",
            type: "currency",
            footer: (column, items) => (
              <span>
                <strong>{formatHelper.currency(this.sumPrice(items))}</strong>
              </span>
            )
          },
          {
            id: "task_id",
            type: "select",
            datasource: {
              controller: "project-tasks",
              objectField: "task",
              valueField: "task.id",
              search: ["task.name"],
              filter: [
                ["task.unit", "exact", 1],
                ["task.active", "exact", true],
                ["project_task.active", "exact", true],
                ["project_id", "exact", this.props.item["project_id"]]
              ],
              label: item => {
                return item ? item.name : "";
              }
            }
          },
          {
            id: "hourly_price_billable",
            type: "currency",
            footer: items => (
              <span>
                <strong>
                  {formatHelper.currency(this.sumBillablePrice(items.data))}
                </strong>
              </span>
            )
          },
          { id: "start_hour_billable", type: "unixTime" },
          { id: "end_hour_billable", type: "unixTime" },
          { id: "hide_on_reports", type: "checkbox" },
          {
            id: "absence_type",
            type: "select",
            type: "select",
            options: strings.options["worksheet-users"].absence_type
          }
          //{ id: "deposit", type: "currency", footer: "sum" }
          //{ id: "clothing_exchange", type: "currency" }
        ]
      }
    };
  }

  sumPrice = items => {
    var total = 0;
    for (let i = 0, _len = items.length; i < _len; i++) {
      //if (items[i].role == 3) {
      const start_hour = items[i]["start_hour"] ? items[i]["start_hour"] : null;
      const end_hour = items[i]["end_hour"] ? items[i]["end_hour"] : null;
      const hourly_price = items[i]["hourly_price"]
        ? items[i]["hourly_price"]
        : null;
      if (start_hour && end_hour && hourly_price) {
        let diff = end_hour - start_hour;
        //let hours = Math.round((diff / 3600) * 100) / 100;
        let hours = diff / 3600;

        hours = hours === 0 ? 24 : hours;
        hours = hours < 0 ? 24 + hours : hours;
        total += Math.round(hours * hourly_price);
      }
      //}
    }
    return total;
  };

  sumBillablePrice = items => {
    var total = 0;
    for (let i = 0, _len = items.length; i < _len; i++) {
      //if (items[i].role == 3) {
      const start_hour = items[i]["start_hour_billable"]
        ? items[i]["start_hour_billable"]
        : null;
      const end_hour = items[i]["end_hour_billable"]
        ? items[i]["end_hour_billable"]
        : null;
      const hourly_price = items[i]["hourly_price_billable"]
        ? items[i]["hourly_price_billable"]
        : null;
      if (start_hour && end_hour && hourly_price) {
        let diff = end_hour - start_hour;
        //let hours = Math.round((diff / 3600) * 100) / 100;
        let hours = diff / 3600;

        hours = hours === 0 ? 24 : hours;
        hours = hours < 0 ? 24 + hours : hours;
        total += Math.round(hours * hourly_price);
      }
      //}
    }
    return total;
  };

  componentDidMount() {}

  render() {
    const { definition, access /*, title*/ } = this.state;
    const {
      /*classes,*/ user,
      item,
      onChildEdit,
      onChildCancelEdit,
      breadcrumbs,
      isDetailView
    } = this.props;

    return (
      <DataGrid
        user={user}
        isDetailView={isDetailView}
        onEdit={onChildEdit}
        onCancelEdit={onChildCancelEdit}
        breadcrumbs={breadcrumbs}
        definition={definition}
        startEditRef={ref => (this.startEdit = ref)}
        FormFooter="CreatedUpdated"
        fieldAccessHandler={column => {
          const access = user.accessField("worksheet-users", column.id);
          if (access) {
            if (item && item.status > 1) {
              return "r";
            }
          }
          return access;
        }}
        access={row => {
          if (item && item.status > 1) {
            return "r";
          }
          return access;
        }}
        filterable={false}
        showPaginationBottom={false}
        defaultPageSize={500}
        defaultPageSizeFullHeight={500}
        title={row => {
          if (!row) {
            return "Tallózás";
          } else if (row.id) {
            return row.user.name;
          } else {
            return `Új ${strings.menu["worksheet-users"].singularTitle}`;
          }
        }}
      />
    );
  }
}

WorksheetUsers.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired
};

//export default withStyles(uteStyle, { withTheme: true })(ProjectTasks);
export default WorksheetUsers;
