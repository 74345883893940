import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import IconButton from "@material-ui/core/IconButton";
import classNames from "classnames";
import urlHelper from "common/utils/urlHelper";

const downloadButtonStyle = theme => ({
  colorPrimary: {
    color: theme.palette.primary.main + "!important"
  },
  colorSecondary: {
    color: theme.palette.secondary.main + "!important"
  },
  colorDefault: {
    color: theme.palette.text.secondary + "!important"
  }
});

class DownloadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Letöltés url előállítása
   */
  getUrl = () => {
    const { id, isInline } = this.props;
    return urlHelper.downloadLink(id, isInline);
  };

  render() {
    const {
      classes,
      tooltip,
      tooltipView,
      Icon,
      ViewIcon,
      buttonProps,
      iconProps,
      isInline,
      color
    } = this.props;

    //Az "a" komponens miatt kell a külön szín trükk, mert sajna mindig link színű volt az ikon (kék)
    const buttonClassName = classNames({
      [classes.colorPrimary]: color === "primary",
      [classes.colorSecondary]: color === "secondary",
      [classes.colorDefault]: color === "default"
    });

    return (
      <Tooltip disableFocusListener title={isInline ? tooltipView : tooltip}>
        <IconButton
          color={"primary"}
          href={this.getUrl()}
          target="_blank"
          component="a"
          className={buttonClassName}
          {...buttonProps}
        >
          {isInline ? <ViewIcon {...iconProps} /> : <Icon {...iconProps} />}
        </IconButton>
      </Tooltip>
    );
  }
}

DownloadButton.defaultProps = {
  tooltip: "Letöltés",
  tooltipView: "Megtekintés",
  color: "default",
  Icon: DownloadIcon,
  ViewIcon: ViewIcon,
  buttonProps: {},
  iconProps: {}
};

DownloadButton.propTypes = {
  classes: PropTypes.object.isRequired,
  fileId: PropTypes.string, //A fájlvégpontnak beküldendő file_id
  tooltip: PropTypes.string, //A tooltip szövege, alapértelmezetten Letöltés
  tooltipView: PropTypes.string, //A view tooltip szövege, alapértelmezetten Letöltés
  color: PropTypes.oneOf(["primary", "secondary", "default"]), //Az ikon színe
  Icon: PropTypes.object, //Icon komponens
  ViewIcon: PropTypes.object, //View Icon komponens
  buttonProps: PropTypes.object, //Button property-k
  iconProps: PropTypes.object, //Icon property-k
  isInline: PropTypes.bool //Inline letöltés-e
};

export default withStyles(downloadButtonStyle)(DownloadButton);
