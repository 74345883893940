import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import formatHelper from "common/utils/formatHelper";
import strings from "variables/strings.json";

class WorksheetVehicles extends React.Component {
  access = "";

  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("worksheet-vehicles");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "worksheet-vehicles",
        url: "worksheet-vehicles",
        attributes: strings.attributes["worksheet-vehicles"],
        condition: {
          id: "worksheet_id",
          value: this.props.item["id"]
        },
        columns: [
          { id: "task_number" },
          {
            id: "vehicle_id",
            type: "select",
            datasource: {
              controller: "vehicles",
              objectField: "vehicle",
              valueField: "id",
              search: ["vehicle.platenumber"],
              sort: { platenumber: "asc" },
              filter: [
                ["daily_price", "gt", 0]
                /*["task.active", "exact", true],
                ["project_task.active", "exact", true],
                ["project_id", "exact", this.props.item["project_id"]]*/
              ],
              label: item => {
                return item ? item.platenumber : "";
              }
            }
          },
          {
            id: "price",
            type: "currency",
            footer: items => (
              <span>
                <strong>
                  {formatHelper.currency(this.sumPrice(items.data))}
                </strong>
              </span>
            )
          },
          { id: "qty" }
        ]
      }
    };
  }

  componentDidMount() {}

  sumPrice = items => {
    var total = 0;
    for (let i = 0, _len = items.length; i < _len; i++) {
      //if (items[i].role == 3) {
      const qty = items[i]["qty"] ? items[i]["qty"] : 0;
      const price = items[i]["price"] ? items[i]["price"] : 0;
      total += qty * price;
      //}
    }
    return total;
  };

  render() {
    const { definition, access } = this.state;
    const {
      /*classes,*/ user,
      item,
      onChildEdit,
      onChildCancelEdit,
      breadcrumbs,
      isDetailView
    } = this.props;

    return (
      <DataGrid
        user={user}
        isDetailView={isDetailView}
        onEdit={onChildEdit}
        onCancelEdit={onChildCancelEdit}
        breadcrumbs={breadcrumbs}
        definition={definition}
        startEditRef={ref => (this.startEdit = ref)}
        FormFooter="CreatedUpdated"
        fieldAccessHandler={column => {
          const access = user.accessField("worksheet-vehicles", column.id);
          if (access) {
            if (item && item.status > 1) {
              return "r";
            }
          }
          return access;
        }}
        access={row => {
          if (item && item.status > 1) {
            return "r";
          }
          return access;
        }}
        filterable={false}
        showPaginationBottom={false}
        defaultPageSize={500}
        defaultPageSizeFullHeight={500}
        title={row => {
          if (!row) {
            return "Tallózás";
          } else if (row.id) {
            return row.vehicle.platenumber;
          } else {
            return `Új ${strings.menu["worksheet-vehicles"].singularTitle}`;
          }
        }}
      />
    );
  }
}

WorksheetVehicles.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired
};

//export default withStyles(uteStyle, { withTheme: true })(ProjectTasks);
export default WorksheetVehicles;
