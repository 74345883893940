import React from "react";
import DataGridForm from "common/components/DataGrid/DataGridForm";

const ServiceWorksheetPart = props => (
  <DataGridForm
    {...props}
    tabs={{
      id: "service-worksheet-parts",
      documents: true,
      histories: true
    }}
  />
);

export default ServiceWorksheetPart;
