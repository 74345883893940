import React, { Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import FormFooter from "common/components/DataForm/FormFooter";
import dataFormStyle from "common/assets/style/dataFormStyle";
import DataViewField from "./DataViewField";
import dateHelper from "common/utils/dateHelper";
import {
  Link,
  Table,
  TableBody,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  Toolbar,
  Button
} from "@material-ui/core";
import formatHelper from "common/utils/formatHelper";
import strings from "variables/strings";
import { get } from "lodash";
import FileIcon, { defaultStyles } from "react-file-icon";
import urlHelper from "common/utils/urlHelper";
import Spacer from "common/components/Spacer";

/**
 * Formos adat megjelenítés és szerkesztés kezelése
 */
class DataView extends React.Component {
  constructor(props) {
    super(props);

    let fieldMap = {};
    for (let i in props.definition.columns) {
      const key = props.definition.columns[i].id;
      fieldMap[key] = props.definition.columns[i];
    }

    this.state = {
      loading: false,
      error: {},
      fields: this.prepareFields(props.definition, fieldMap),
      fieldMap: fieldMap,
      item: props.item
    };

    this.modal = React.createRef();
  }

  prepareFields(definition, fieldMap) {
    if (!definition.columns) {
      return definition.fields;
    }

    if (!definition.fields) {
      return definition.columns;
    }
    let fields = [];
    for (let i in definition.fields) {
      const key = definition.fields[i].id;
      if (fieldMap[key]) {
        fields.push(Object.assign(fieldMap[key], definition.fields[i]));
      } else {
        fields.push(definition.fields[i]);
      }
    }

    return fields;
  }

  /**
   * Frissítjük az értékeket
   * @param {Object} nextProps Új tulajdonságok
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    /*let originalItem = nextProps.item.id
      ? Object.assign({}, this.convertValues(nextProps.item))
      : {};*/

    this.setState({
      item: nextProps.item
      //originalItem: originalItem
    });
  }

  /**
   * A definícióban megadott editor tulajdonság függvényében
   * az adott mező nézetének elkészítése (text, combobox, select, ...)
   */
  getField = (column, key, item, error, size, headerTitle) => {
    const { primaryKey } = this.props;

    const { attributes } = this.props.definition;
    let colProp = column.name;
    if (colProp) {
      colProp = typeof colProp === "function" ? colProp(item) : colProp;
    }
    let name =
      colProp ||
      (attributes && attributes[column.id] ? attributes[column.id] : column.id);
    name = name.replace(headerTitle + " ", "");

    switch (column.type) {
      case "combobox":
        return (
          <DataViewField size={size} label={name} value={item[column.id]} />
        );
      case "select":
        var v =
          column.datasource && column.datasource.objectField
            ? typeof column.datasource.objectField === "string"
              ? item[column.datasource.objectField]
              : column.datasource.objectField
            : null;
        var value = null;
        if (v) {
          value =
            typeof column.datasource.label === "function"
              ? column.datasource.label(v)
              : v[column.datasource.label];
        } else if (column.options) {
          const c = column.options.find(
            elem => elem.value === get(item, column.id)
          );
          value = c ? c.label : null;
        }
        //console.log("window.fullPaths", window.fullPaths);
        return (
          <DataViewField
            key={key}
            size={size}
            label={name}
            value={value}
            error={error}
            to={
              column.datasource
                ? `${window.fullPaths[column.datasource.controller]}/view/${get(
                    item,
                    `${column.datasource.objectField}.${column.datasource.valueField}`
                  )}`
                : undefined
            }
          />
        );
      case "checkbox":
        return (
          <DataViewField
            key={key}
            size={size}
            label={name}
            value={item[column.id] ? "Igen" : "Nem"}
            error={error}
          />
        );
      case "unixDate":
        var v = dateHelper.unixToDate(item[column.id]);
        if (column.id.indexOf(".") >= 0) {
          v = parseInt(get(item, column.id.split(".")));
          v = dateHelper.unixToDate(v);
        }
        return (
          <DataViewField
            key={key}
            size={size}
            label={name}
            value={v}
            error={error}
          />
        );
      case "unixDateTime":
        var v = dateHelper.unixToDateTime(item[column.id]);
        if (column.id.indexOf(".") >= 0) {
          v = parseInt(get(item, column.id.split(".")));
          v = dateHelper.unixToDateTime(v);
        }
        return (
          <DataViewField
            key={key}
            size={size}
            label={name}
            value={v}
            error={error}
          />
        );
      case "unixDateRange":
        /*var value = {
          start: item[column.fields.start],
          end: item[column.fields.end]
        };*/
        return (
          <DataViewField
            key={key}
            size={size}
            label={name}
            value={item[column.id]}
            error={error}
          />
        );
      case "file":
        var f = column.id.replace("_id", "");
        var file = item[f];
        var isPdf = file && file.type === "application/pdf";
        var isInline = isPdf && !column.pdfNotInline ? true : false;
        return (
          <DataViewField
            key={key}
            size={size}
            label={name}
            value={file ? file.name : null}
            error={error}
          >
            {file && (
              <Link
                //className={classes.cellLink}
                href={urlHelper.downloadLink(file.id, isInline)}
                target={isInline ? "_blank" : undefined}
                style={{ display: "flex" }}
              >
                <div
                  style={{
                    marginTop: -1,
                    marginBottom: -1,
                    marginRight: 8,
                    display: "inline-block",
                    minWidth: 20,
                    minHeight: 20,
                    flex: 0
                  }}
                >
                  <FileIcon
                    extension={file.ext}
                    {...defaultStyles[file.ext]}
                    size={20}
                  />
                </div>
                {file.name}
              </Link>
            )}
          </DataViewField>
        );
      case "json":
        return (
          <DataViewField
            key={key}
            size={size}
            label={name}
            value={item[column.id] ? JSON.stringify(item[column.id]) : ""}
          />
        );
      case "table":
      case "table2":
        return (
          <DataViewField
            key={key}
            size={size}
            label={column.header ? undefined : name}
            noBorderBottom
            //value={item[column.id] /* ? JSON.stringify(item[column.id]) : ""*/}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {column.columns.map((tableCol, tableColIndex) => {
                    return (
                      <TableCell
                        key={tableColIndex}
                        style={{ padding: 0, paddingTop: 8, paddingBottom: 8 }}
                      >
                        {tableCol.title}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {item[column.id] &&
                  item[column.id].map((row, rowIndex) => {
                    return (
                      <TableRow key={rowIndex}>
                        {column.columns.map((tableCol, tableColIndex) => {
                          console.log(tableCol);
                          return (
                            <TableCell
                              style={{
                                padding: 0,
                                paddingTop: 8,
                                paddingBottom: 8
                              }}
                              key={tableColIndex}
                            >
                              {tableCol.type === "currency"
                                ? formatHelper.currency(row[tableCol.id])
                                : row[tableCol.id]}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </DataViewField>
        );
      case "password":
      case "text":
      default:
        var vp = get(item, column.id.split("."));
        var v = column.type === "currency" ? formatHelper.currency(vp) : vp;

        return (
          <DataViewField
            key={key}
            size={size}
            label={name}
            value={v}
            error={error}
          />
        );
    }
  };

  editButtonIsVisible = item => {
    const { access, primaryKey } = this.props;
    const isNew = this.state.item[primaryKey] ? false : true;
    if (access.indexOf("w") >= 0 && !isNew) {
      return true;
    }
    if (access.indexOf("a") >= 0 && isNew) {
      return true;
    }
    return false;
  };

  render() {
    const {
      Footer,
      classes,
      theme,
      fieldAccessHandler,
      definition,
      fieldsInRow,
      bottomButtons,
      grid
    } = this.props;
    const { item, error, fields, globalErrors, showGlobalErrors } = this.state;
    const buttons = bottomButtons
      ? bottomButtons.filter(button => {
        if (button.access && !this.props.user.accessFunction(button.access)) {
          return false;
        }
        if (!button.showOnView) {
          return false;
        }
        if (button.visible && typeof button.visible === "function") {
          return button.visible(item /*, { grid, form: this }*/);
        }
        return true;
      })
      : null;

    let FooterComp = null;
    if (Footer && typeof Footer === "string") {
      switch (Footer) {
        case "CreatedUpdated":
          FooterComp = FormFooter;
          break;
        default:
          break;
      }
    } else if (Footer) {
      FooterComp = Footer;
    }

    if (this.props.error) {
      return (
        <div className={classes.formContainer}>
          <div className={classes.error}>{this.props.error}</div>
        </div>
      );
    }

    const filteredFields = fields.filter(obj => {
      if (typeof obj.hidden === "function") {
        if (obj.hidden(item) === true) {
          return false;
        }
      } else if (obj.hidden === true) {
        return false;
      }
      if (fieldAccessHandler) {
        obj.access = fieldAccessHandler(obj, item);
        return obj.access === "r" || obj.access === "w";
      } else {
        return true;
      }
    });

    let leftCol = [];
    let rightCol = [];

    for (let i in filteredFields) {
      if (i < filteredFields.length / 2) {
        leftCol.push(filteredFields[i]);
      } else {
        rightCol.push(filteredFields[i]);
      }
    }
    let tempHeader = null;
    let tempHeaderTitle = null;
    return (
      <div className={classes.formContainer}>
        <Grid container={true} spacing={2} alignItems="stretch">
          {fields
            .filter(obj => {
              if (typeof obj.hidden === "function") {
                if (obj.hidden(item) === true) {
                  return false;
                }
              } else if (obj.hidden === true) {
                return false;
              }
              if (fieldAccessHandler) {
                obj.access = fieldAccessHandler(obj, item);
                return obj.access === "r" || obj.access === "w";
              } else {
                return true;
              }
            })
            .map((column, key) => {
              const err =
                error && error[column.id] && Array.isArray(error[column.id])
                  ? error[column.id].join(", ")
                  : null;
              const needHeader = column.header && tempHeader !== column.header;
              const headerTitle =
                (column.header &&
                  strings.headers &&
                  strings.headers[definition.url] &&
                  strings.headers[definition.url][column.id]) ||
                "Missing header title";
              tempHeader = column.header;
              if (needHeader) {
                tempHeaderTitle = headerTitle;
              }
              return (
                <Fragment key={key}>
                  {needHeader && (
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: 400, marginTop: key > 0 ? 32 : 0 }}
                      >
                        {headerTitle}
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    style={{
                      //borderBottom: `solid 1px ${theme.palette.divider}`,
                      display: "flex"
                    }}
                    item={true}
                    xs={12}
                    sm={
                      column.size
                        ? column.size
                        : fieldsInRow
                        ? 12 / fieldsInRow
                        : 6
                    }
                    lg={
                      column.size
                        ? column.size
                        : fieldsInRow
                        ? 12 / fieldsInRow
                        : 4
                    }
                  >
                    {this.getField(
                      column,
                      key,
                      item,
                      err,
                      column.size,
                      tempHeaderTitle
                    )}
                  </Grid>
                </Fragment>
              );
            })}
        </Grid>
        {/*<Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Table>
                  <TableBody className={classes.viewTableBody}>
                    {leftCol.map((column, key) => {
                      const err =
                        error &&
                        error[column.id] &&
                        Array.isArray(error[column.id])
                          ? error[column.id].join(", ")
                          : null;
                      const needHeader =
                        column.header && tempHeader !== column.header;

                      tempHeader = column.header;

                      return this.getField(column, key, item, err, needHeader);
                    })}
                  </TableBody>
                </Table>
              </Grid>

              <Grid item xs={12} md={6}>
                <Table>
                  <TableBody className={classes.viewTableBody}>
                    {rightCol.map((column, key) => {
                      const err =
                        error &&
                        error[column.id] &&
                        Array.isArray(error[column.id])
                          ? error[column.id].join(", ")
                          : null;
                      const needHeader =
                        column.header && tempHeader !== column.header;

                      tempHeader = column.header;
                      return this.getField(column, key, item, err, needHeader);
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Grid>
                  </Grid>*/}
        {FooterComp && (
          <FooterComp item={item} typoClassName={classes.footerTypo} />
        )}
        {/*this.editButtonIsVisible() && */ buttons && buttons.length > 0 && (
          <Toolbar disableGutters>
            {buttons.map((button, buttonIndex) => {
              const btProps =
                typeof button.buttonProps === "function"
                  ? button.buttonProps(this) || {
                    variant: button.variant
                  }
                  : button.buttonProps || { variant: button.variant };
              return (
                <Fragment key={buttonIndex}>
                  <Spacer w={1} />
                  <Button
                    variant={button.variant}
                    startIcon={<button.Icon />}
                    color={button.color ? button.color : "inherit"}
                    //className={classes.button}
                    aria-label={button.tooltip}
                    onClick={
                      button.onClick
                        ? evt =>
                            button.onClick(
                              evt,
                              { data: item, form: this, grid: grid },
                              button.value
                            )
                        : button.modalComponent
                        ? () =>
                            grid.setState({
                              [`modal${button.id}`]: true,
                              [`modal_inactive${button.id}`]: false
                            })
                        : undefined
                    }
                    component={button.component}
                    href={button.href}
                    to={button.to}
                    {...btProps}
                  >
                    {button.tooltip}
                  </Button>
                </Fragment>
              );
            })}
          </Toolbar>
        )}
      </div>
    );
  }
}

DataView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  definition: PropTypes.object.isRequired,
  access: PropTypes.string.isRequired,
  fieldsInRow: PropTypes.number,
  item: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  afterSave: PropTypes.func,
  Footer: PropTypes.any,
  fieldAccessHandler: PropTypes.func,
  error: PropTypes.string,
  isRedux: PropTypes.bool,
  showToolbar: PropTypes.bool
};

export default withStyles(dataFormStyle, { withTheme: true })(DataView);
