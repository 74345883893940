import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import DataPreview from "common/components/DataPreview/DataPreview";
import restHelper from "common/utils/restHelper";
import PayIcon from "@material-ui/icons/Money";
import DailyIcon from "@material-ui/icons/CalendarToday";
import HourlyWorkIcon from "@material-ui/icons/AccessTime";
import UsersIcon from "@material-ui/icons/People";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ProjectIcon from "@material-ui/icons/Work";
import TaskIcon from "@material-ui/icons/Dns";
import VehicleIcon from "@material-ui/icons/DirectionsCar";
import CostIcon from "@material-ui/icons/LocalAtm";
import UserCostIcon from "@material-ui/icons/AttachMoney";
import DatePicker from "common/components/DatePicker";
import dateHelper from "common/utils/dateHelper";

const unitsDict = {
  1: "Óra",
  2: "Darab",
  3: "Méter",
  4: "Négyzetméter",
  5: "Köbméter",
  6: "Készlet",
  7: "Üzemóra",
  8: "Kilométer",
  9: "Tonna",
  10: "Nap"
};

class Report extends React.Component {
  constructor(props) {
    super(props);

    //const dayOfMonth = new Date().getDate();
    let dates = null;
    const storage = sessionStorage.getItem("report");
    if (storage) {
      let storageData = JSON.parse(storage);
      if (storageData && storageData.dates) {
        if (typeof storageData.dates === "string") {
          dates = storageData.dates;
        } else {
          dates = dateHelper.unixToRange(storageData.dates);
        }
      }
    }
    /*if (!dates) {
      dates = "thisMonth";
    }*/
    this.state = {
      dates: dates,
      time: new Date().getTime(),
      sections: [
        {
          title: "Elszámolható tételek",
          icon: TaskIcon,
          tabs: [
            {
              title: "Tétel típusonként",
              icon: TaskIcon,
              fetchData: item => {
                const params = {
                  group: "worksheet_task.task_id",
                  groupname: "task.name",
                  filter: this.filter("worksheet.day") || []
                };
                return restHelper.index("reports/billable-tasks", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                {
                  id: "qty",
                  ignoreHeader: true,
                  value: item => {
                    return item.qty + " " + unitsDict[item.unit];
                  }
                }
              ],
              child: {
                tabs: [
                  {
                    fetchData: item => {
                      const params = {
                        filter: [
                          [
                            "worksheet_task.task_id",
                            "exact",
                            item.task_id
                          ].concat(this.filter("worksheet.day") || [])
                        ],
                        groupname: "worksheet.day"
                      };
                      return restHelper.index("reports/billable-tasks", params);
                    },
                    columns: [
                      { id: "name", type: "unixDate" },
                      { id: "amount", type: "currency" },
                      {
                        id: "qty",
                        ignoreHeader: true,
                        value: item => {
                          return item.qty + " " + unitsDict[item.unit];
                        }
                      },
                      { id: "project_name" },
                      { id: "user_name" },
                      { id: "task_number" }
                    ]
                  }
                ]
              }
            },
            {
              title: "Felhasználónként",
              icon: UsersIcon,
              fetchData: item => {
                const params = {
                  group: "foreman.id",
                  groupname: "foreman.name",
                  filter: this.filter("worksheet.day") || []
                };
                return restHelper.index("reports/billable-tasks", params);
              },
              columns: [{ id: "name" }, { id: "amount", type: "currency" }],
              child: {
                tabs: [
                  {
                    fetchData: item => {
                      const params = {
                        filter: [["foreman.id", "exact", item.id]].concat(
                          this.filter("worksheet.day") || []
                        ),
                        groupname: "worksheet.day"
                      };
                      return restHelper.index("reports/billable-tasks", params);
                    },
                    columns: [
                      { id: "name", type: "unixDate" },
                      { id: "amount", type: "currency" },
                      {
                        id: "qty",
                        ignoreHeader: true,
                        value: item => {
                          return item.qty + " " + unitsDict[item.unit];
                        }
                      },
                      { id: "project_name" },
                      { id: "user_name" },
                      { id: "task_number" }
                    ]
                  }
                ]
              }
            },
            {
              title: "Projektenként",
              icon: ProjectIcon,
              fetchData: item => {
                const params = {
                  group: "worksheet.project_id",
                  groupname: "project.name",
                  filter: this.filter("worksheet.day") || []
                };
                return restHelper.index("reports/billable-tasks", params);
              },
              columns: [{ id: "name" }, { id: "amount", type: "currency" }],
              child: {
                tabs: [
                  {
                    fetchData: item => {
                      const params = {
                        filter: [
                          ["worksheet.project_id", "exact", item.project_id]
                        ].concat(this.filter("worksheet.day") || []),
                        group: "worksheet_task.task_id",
                        groupname: "task.name"
                      };
                      return restHelper.index("reports/billable-tasks", params);
                    },
                    columns: [
                      { id: "name" },
                      { id: "amount", type: "currency" },
                      //{ id: "day", type: "unixDate" },
                      {
                        id: "qty",
                        ignoreHeader: true,
                        value: item => {
                          return item.qty + " " + unitsDict[item.unit];
                        }
                      }
                      //{ id: "project_name" },
                      //{ id: "user_name" }
                    ]
                  }
                ]
              }
            },
            {
              title: "Naponként",
              icon: DailyIcon,
              fetchData: item => {
                const params = {
                  group: "worksheet.day",
                  groupname: "worksheet.day",
                  filter: this.filter("worksheet.day") || []
                };
                return restHelper.index("reports/billable-tasks", params);
              },
              columns: [
                { id: "name", type: "unixDate" },
                { id: "amount", type: "currency" }
              ]
            }
          ]
        },
        {
          title: "Órabéres munka",
          icon: HourlyWorkIcon,
          tabs: [
            {
              fetchData: item => {
                const params = {
                  filter: this.filter("worksheet.day") || []
                };
                return restHelper.index("reports/billable-hours", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                {
                  id: "qty",
                  value: item => {
                    return Math.round(item.qty * 100) / 100 + "  óra";
                  }
                }
              ]
            }
          ]
        },
        {
          title: "Jármű napidíjak",
          icon: VehicleIcon,
          tabs: [
            {
              title: "Járművek szerint",
              icon: VehicleIcon,
              fetchData: item => {
                const params = {
                  group: "worksheet_vehicle.vehicle_id",
                  groupname: "vehicle.platenumber",
                  filter: this.filter("worksheet.day") || []
                };
                return restHelper.index("reports/vehicles", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                {
                  id: "qty",
                  value: item => {
                    return Math.round(item.qty * 100) / 100 + " nap";
                  }
                }
              ]
            },
            {
              title: "Projektenként",
              icon: ProjectIcon,
              fetchData: item => {
                const params = {
                  group: "worksheet.project_id",
                  groupname: "project.name",
                  filter: this.filter("worksheet.day") || []
                };
                return restHelper.index("reports/vehicles", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                {
                  id: "qty",
                  value: item => {
                    return Math.round(item.qty * 100) / 100 + "  nap";
                  }
                }
              ]
            }
          ]
        },
        {
          title: "Jármű bevétel",
          icon: VehicleIcon,
          tabs: [
            {
              title: "Járművek szerint",
              icon: VehicleIcon,
              fetchData: item => {
                const params = {
                  group: "worksheet_vehicle.vehicle_id",
                  groupname: "vehicle.platenumber",
                  filter: this.filter("worksheet.day") || []
                };
                return restHelper.index("reports/vehicles-income", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                {
                  id: "qty",
                  value: item => {
                    return Math.round(item.qty * 100) / 100 + " nap";
                  }
                }
              ]
            },
            {
              title: "Projektenként",
              icon: ProjectIcon,
              fetchData: item => {
                const params = {
                  group: "worksheet.project_id",
                  groupname: "project.name",
                  filter: this.filter("worksheet.day") || []
                };
                return restHelper.index("reports/vehicles-income", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                {
                  id: "qty",
                  value: item => {
                    return Math.round(item.qty * 100) / 100 + "  nap";
                  }
                }
              ]
            }
          ]
        },
        {
          title: "Költségek",
          icon: UserCostIcon,
          tabs: [
            {
              title: "Típusonként",
              icon: CostIcon,
              fetchData: item => {
                const params = {
                  group: "user_cost.cost_id",
                  groupname: "cost.name",
                  filter: this.filter("user_cost.day") || []
                };
                return restHelper.index("reports/costs", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                { id: "qty" }
              ],
              child: {
                tabs: [
                  {
                    fetchData: item => {
                      const params = {
                        filter: [["cost_id", "exact", item.cost_id]].concat(
                          this.filter("user_cost.day") || []
                        ),
                        groupname: "user.name"
                      };
                      return restHelper.index("reports/costs", params);
                    },
                    columns: [
                      { id: "name" },
                      { id: "amount", type: "currency" },
                      { id: "project_name" },
                      { id: "day", type: "unixDate" },
                      { id: "description" }
                    ]
                  }
                ]
              }
            },
            //Költségek Felhasználónkánt
            {
              title: "Felhasználónként",
              icon: UsersIcon,
              fetchData: item => {
                const params = {
                  group: "user_cost.user_id",
                  groupname: "user.name",
                  filter: this.filter("user_cost.day") || []
                };
                return restHelper.index("reports/costs", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                { id: "qty" }
              ],
              child: {
                tabs: [
                  {
                    fetchData: item => {
                      const params = {
                        filter: [["user_id", "exact", item.user_id]].concat(
                          this.filter("user_cost.day") || []
                        ),
                        groupname: "cost.name"
                      };
                      return restHelper.index("reports/costs", params);
                    },
                    columns: [
                      { id: "name" },
                      { id: "amount", type: "currency" },
                      { id: "project_name" },
                      { id: "day", type: "unixDate" },
                      { id: "description" }
                    ]
                  }
                ]
              }
            },
            //Költségek Projektenként
            {
              title: "Projektenként",
              icon: ProjectIcon,
              fetchData: item => {
                const params = {
                  group: "user_cost.project_id",
                  groupname: "project.name",
                  filter: this.filter("user_cost.day") || []
                };
                return restHelper.index("reports/costs", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                { id: "qty" }
              ],
              child: {
                tabs: [
                  {
                    fetchData: item => {
                      const params = {
                        filter: [
                          ["project_id", "exact", item.project_id]
                        ].concat(this.filter("user_cost.day") || []),
                        groupname: "cost.name"
                      };
                      return restHelper.index("reports/costs", params);
                    },
                    columns: [
                      { id: "name" },
                      { id: "amount", type: "currency" },
                      { id: "user_name" },
                      { id: "day", type: "unixDate" },
                      { id: "description" }
                    ]
                  }
                ]
              }
            },
            //Költségek Naponta
            {
              title: "Naponként",
              icon: DailyIcon,
              fetchData: item => {
                const params = {
                  group: "user_cost.day",
                  groupname: "user_cost.day",
                  filter: this.filter("user_cost.day") || []
                };
                return restHelper.index("reports/costs", params);
              },
              columns: [
                { id: "name", type: "unixDate" },
                { id: "amount", type: "currency" }
              ],
              child: {
                tabs: [
                  {
                    fetchData: item => {
                      const params = {
                        filter: [["day", "exact", item.day]].concat(
                          this.filter("user_cost.day") || []
                        ),
                        groupname: "cost.name"
                      };
                      return restHelper.index("reports/costs", params);
                    },
                    columns: [
                      { id: "name" },
                      { id: "amount", type: "currency" },
                      { id: "user_name" },
                      { id: "day", type: "unixDate" },
                      { id: "description" }
                    ]
                  }
                ]
              }
            }
          ]
        },
        //Bérek
        {
          title: "Bérek",
          icon: PayIcon,
          tabs: [
            {
              title: "Felhasználónként",
              icon: UsersIcon,
              fetchData: item => {
                const params = {
                  group: "worksheet_user.user_id",
                  groupname: "user.name",
                  filter: this.filter("worksheet.day") || []
                };
                return restHelper.index("reports/pays", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                {
                  id: "qty",
                  value: item => {
                    return Math.round(item.qty * 100) / 100 + " óra";
                  }
                }
              ],
              child: {
                tabs: [
                  {
                    fetchData: item => {
                      const params = {
                        filter: [
                          ["worksheet_user.user_id", "exact", item.user_id]
                        ].concat(this.filter("worksheet.day") || []),
                        group: "worksheet.project_id",
                        groupname: "project.name"
                      };
                      return restHelper.index("reports/pays", params);
                    },
                    columns: [
                      { id: "name" },
                      { id: "amount", type: "currency" },
                      {
                        id: "qty",
                        value: item => {
                          return Math.round(item.qty * 100) / 100 + " óra";
                        }
                      }
                    ]
                  }
                ]
              }
            },
            {
              title: "Projektenként",
              icon: ProjectIcon,
              fetchData: item => {
                const params = {
                  group: "worksheet.project_id",
                  groupname: "project.name",
                  filter: this.filter("worksheet.day") || []
                };
                return restHelper.index("reports/pays", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                {
                  id: "qty",
                  value: item => {
                    return Math.round(item.qty * 100) / 100 + " óra";
                  }
                }
              ]
            },
            {
              title: "Naponként",
              icon: DailyIcon,
              fetchData: item => {
                const params = {
                  group: "worksheet.day",
                  groupname: "worksheet.day",
                  filter: this.filter("worksheet.day") || []
                };
                return restHelper.index("reports/pays", params);
              },
              columns: [
                { id: "name", type: "unixDate" },
                { id: "amount", type: "currency" },
                {
                  id: "qty",
                  value: item => {
                    return Math.round(item.qty * 100) / 100 + " óra";
                  }
                }
              ]
            }
          ]
        },
        //Járulékok
        {
          title: "Járulékok",
          icon: PayIcon,
          tabs: [
            {
              title: "Felhasználónként",
              icon: UsersIcon,
              fetchData: item => {
                const params = {
                  group: "worksheet_user.user_id",
                  groupname: "user.name",
                  filter: this.filter("worksheet.day") || []
                };
                return restHelper.index("reports/taxes", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                {
                  id: "qty",
                  value: item => {
                    return Math.round(item.qty * 100) / 100 + " nap";
                  }
                }
              ],
              child: {
                tabs: [
                  {
                    fetchData: item => {
                      const params = {
                        filter: [
                          ["worksheet_user.user_id", "exact", item.user_id]
                        ].concat(this.filter("worksheet.day") || []),
                        group: "worksheet.project_id",
                        groupname: "project.name"
                      };
                      return restHelper.index("reports/taxes", params);
                    },
                    columns: [
                      { id: "name" },
                      { id: "amount", type: "currency" },
                      {
                        id: "qty",
                        value: item => {
                          return Math.round(item.qty * 100) / 100 + " nap";
                        }
                      }
                    ]
                  }
                ]
              }
            },
            {
              title: "Projektenként",
              icon: ProjectIcon,
              fetchData: item => {
                const params = {
                  group: "worksheet.project_id",
                  groupname: "project.name",
                  filter: this.filter("worksheet.day") || []
                };
                return restHelper.index("reports/taxes", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                {
                  id: "qty",
                  value: item => {
                    return Math.round(item.qty * 100) / 100 + " nap";
                  }
                }
              ]
            },
            {
              title: "Naponként",
              icon: DailyIcon,
              fetchData: item => {
                const params = {
                  group: "worksheet.day",
                  groupname: "worksheet.day",
                  filter: this.filter("worksheet.day") || []
                };
                return restHelper.index("reports/taxes", params);
              },
              columns: [
                { id: "name", type: "unixDate" },
                { id: "amount", type: "currency" },
                {
                  id: "qty",
                  value: item => {
                    return Math.round(item.qty * 100) / 100 + " nap";
                  }
                }
              ]
            }
          ]
        },
        {
          title: "Haszon",
          icon: WalletIcon,
          tabs: [
            {
              fetchData: item => {
                const params = {
                  group: "project_id",
                  groupname: "name",
                  filter: this.filter("t.day") || []
                };
                return restHelper.index("reports/income", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                {
                  id: "days",
                  value: item => {
                    return (
                      Math.round(
                        (item.last_day - item.first_day) / (3600 * 24) + 1
                      ) +
                      (!item.isHeader ? "/" + item.work_days : "") +
                      " nap"
                    );
                  }
                },
                { id: "first_day", type: "unixDate", groupFunc: "min" },
                { id: "last_day", type: "unixDate", groupFunc: "max" }
              ],
              child: {
                tabs: [
                  {
                    fetchData: item => {
                      const params = {
                        filter: [
                          ["t.project_id", "exact", item.project_id]
                        ].concat(this.filter("t.day") || []),
                        group: "t.type",
                        groupname: "t.type"
                      };
                      return restHelper.index("reports/income", params);
                    },
                    columns: [
                      { id: "name" },
                      { id: "amount", type: "currency" }
                    ]
                  }
                ]
              }
            }
          ]
        },
        {
          title: "Kimenő számlák",
          icon: TaskIcon,
          tabs: [
            {
              //title: "Projektenként",
              //icon: ProjectIcon,
              fetchData: item => {
                const params = {
                  group: "project.id",
                  groupname: "project.name",
                  //filter: this.filter("invoice.delivery_date") || [],
                  filter: [["invoice.incoming", "exact", false]].concat(
                    this.filter("invoice.delivery_date") || []
                  )
                };
                return restHelper.index("reports/invoices", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                { id: "transaction_price", type: "currency" },
                { id: "remaining_price", type: "currency" },
                { id: "hold_price", type: "currency" }
              ]
            }
          ]
        },
        {
          title: "Bejövő számlák",
          icon: TaskIcon,
          tabs: [
            {
              //title: "Projektenként",
              //icon: ProjectIcon,
              fetchData: item => {
                const params = {
                  group: "project.id",
                  groupname: "project.name",
                  //filter: this.filter("invoice.delivery_date") || [],
                  filter: [["invoice.incoming", "exact", true]].concat(
                    this.filter("invoice.delivery_date") || []
                  )
                };
                return restHelper.index("reports/invoices", params);
              },
              columns: [
                { id: "name" },
                { id: "amount", type: "currency" },
                { id: "transaction_price", type: "currency" },
                { id: "remaining_price", type: "currency" },
                { id: "hold_price", type: "currency" }
              ]
            }
          ]
        }
      ]
    };
  }

  filter = field => {
    const dates = this.parseValue(this.state.dates);
    return dates
      ? [[field, "between", [dates.start.unix(), dates.end.unix()]]]
      : null;
  };

  parseValue = value => {
    if (typeof value === "string") {
      return dateHelper.getRange(value);
    } else {
      return value;
    }
  };

  onChange = (value, rangeKey) => {
    //console.log("onChange", value);
    const v = rangeKey ? rangeKey : value;
    const vs = rangeKey
      ? rangeKey
      : v
        ? { start: v.start.unix(), end: v.end.unix() }
        : null;
    if (vs) {
      sessionStorage.setItem("report", JSON.stringify({ dates: vs }));
    } else {
      sessionStorage.removeItem("report");
    }
    this.setState({
      dates: v,
      time: new Date().getTime()
    });
  };

  render() {
    //const { definition } = this.state;
    return (
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={12} sm={6} md={5} lg={4} xl={3}>
          <div
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 8,
              marginBottom: -16
            }}
          >
            <DatePicker
              key={this.state.time}
              onChange={this.onChange}
              value={this.state.dates}
              selectionType="range"
              numberOfCalendars={2}
              label="Időszak"
              singleDateRange={true}
              firstOfWeek={1}
            />
          </div>
        </Grid>
        <Grid item={true} xs={12} md={12}>
          <DataPreview
            key={this.state.time}
            sections={this.state.sections}
            //buttons={buttons}
            //item={item}
          />
        </Grid>
      </Grid>
    );
  }
}

Report.propTypes = {};

export default Report;
