const userMenuStyle = theme => ({
  container: {
    padding: "12px 10px",
    verticalAlign: "middle",
    flex: "1 0 0 0",
    display: "flex",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[300]
    }
  },
  containerSelected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "& svg": {
      fill: theme.palette.primary.contrastText
    }
  },
  itemText: {
    padding: "0 12px",
    flex: 1,
    display: "flex"
  },
  button: {
    padding: "2px!important",
    marginTop: -2,
    marginBottom: -2
  },
  icon: {
    fill: theme.palette.text.secondary
  },
  menuContainer: {
    //flex: 1,
    //display: "flex",
    alignContent: "center",
    alignItems: "center"
  },
  menuName: {
    paddingLeft: 10
  },
  menuButton: {
    color: `${
      theme.palette.type === "dark"
        ? "#fff"
        : theme.palette.primary.contrastText
    }!important`
  },
  menuOpenIncon: {
    margin: 8
  },
  item: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "& svg": {
        fill: theme.palette.primary.contrastText,
        color: theme.palette.primary.contrastText
      }
    }
  },
  itemIcon: {
    minWidth: 24,
    marginRight: theme.spacing(0.5)
  }
});

export default userMenuStyle;
