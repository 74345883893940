import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import modalStyle from "common/assets/style/modalStyle.jsx";
import settings from "variables/settings";
import classNames from "classnames";

class Modal extends React.Component {
  render() {
    const {
      classes,
      title,
      loading,
      Icon,
      yesText,
      inactiveOk,
      noText,
      onClose,
      onAccept,
      onCancel,
      disableIcon,
      disableCancel,
      disableOk,
      keepMounted,
      maxWidth,
      open,
      fullWidth,
      top,
      overlayLoading,
      error,
      yesStartIcon: YesStartIcon
    } = this.props;
    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
          scrollPaper: top ? classes.modalTop : undefined
        }}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        open={open}
        keepMounted={keepMounted}
        onClose={this.close}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classNames(classes.modalHeader, {
            error: error
          })}
        >
          <Button
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color={settings.actionColor}
            onClick={onClose}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          {!disableIcon && <Icon className={classes.titleIcon} />}
          <Typography
            style={disableIcon ? { textIndent: 0 } : {}}
            className={classes.modalTitle}
            variant="subtitle1"
          >
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.modalBody}>
          {loading && overlayLoading && (
            <div className={classes.loadingOverlay}>
              <CircularProgress
                className={classes.progressCenter}
                color="primary"
                //size={20}
              />
            </div>
          )}
          {this.props.children}
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          {!disableCancel && (
            <Button
              onClick={onCancel}
              color="default"
              disabled={loading === true}
            >
              {noText}
            </Button>
          )}{" "}
          {!disableOk && (
            <Button
              onClick={onAccept}
              color={settings.actionColor}
              disabled={loading || inactiveOk}
              startIcon={YesStartIcon ? <YesStartIcon /> : undefined}
            >
              {yesText}
            </Button>
          )}
          {loading && !overlayLoading && (
            <CircularProgress
              className={classes.progress}
              color="primary"
              size={20}
            />
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

Modal.defaultProps = {
  yesText: "Ok",
  noText: "Mégsem",
  Icon: WarningIcon,
  keepMounted: true,
  maxWidth: "sm"
};

Modal.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  Icon: PropTypes.any,
  yesStartIcon: PropTypes.any,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  yesText: PropTypes.string,
  noText: PropTypes.string,
  disableIcon: PropTypes.bool,
  inactiveOk: PropTypes.bool,
  open: PropTypes.bool,
  disableCancel: PropTypes.bool,
  disableOk: PropTypes.bool,
  keepMounted: PropTypes.bool,
  overlayLoading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  top: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.any
  ]),
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"])
};

export default withStyles(modalStyle)(Modal);
