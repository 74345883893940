import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import SidebarMenu from "common/components/SidebarMenu";
import { Route, Switch, Link } from "react-router-dom";
import UserMenu from "common/components/UserMenu";
import urlHelper from "common/utils/urlHelper";
import appStyle from "common/assets/style/appStyle";
import menu from "variables/menu";
import logo from "assets/img/logo.svg";
import strings from "variables/strings";
import Profile from "views/Profile";
import Home from "views/Home";
import SettingsMenu from "views/SettingsMenu";
import APP_VER from "variables/ver";
import dateHelper from "common/utils/dateHelper";
import restHelper from "common/utils/restHelper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import releases from "variables/releases.json";
import settings from "variables/settings";
import { Redirect } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import IsFavIcon from "@material-ui/icons/Star";
import NotFavIcon from "@material-ui/icons/StarBorderOutlined";
import Socket from "common/utils/Socket";
import DisconnectedIcon from "@material-ui/icons/OfflineBoltOutlined";
import { Grid, Paper } from "@material-ui/core";
import UserIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import HomeIcon from "@material-ui/icons/Home";
import GlobalMessaging from "common/components/GlobalMessaging";
//import Link from '@material-ui/core/Link';

const menuRoutes = (
  items,
  showSnackMessage,
  user,
  socket,
  path = "",
  reloadApp,
  refreshUser
) => {
  let itemsAll = items.slice();
  //Profil route beszúrása

  itemsAll.push({
    slug: "/",
    component: Home,
    items: [],
    exact: true
  });
  itemsAll.push({
    slug: "/profile",
    component: Profile,
    items: []
  });
  itemsAll.push({
    slug: "/settings",
    component: SettingsMenu,
    items: []
  });
  return itemsAll.map((item, key) => {
    if (!item.items || item.items.length === 0) {
      return (
        <Route
          key={key}
          path={"(/favourites)?" + path + item.slug}
          exact={item.exact}
          render={props => (
            <item.component
              showSnackMessage={showSnackMessage}
              user={user}
              socket={socket}
              menuItems={items}
              reloadApp={reloadApp}
              refreshUser={refreshUser}
            />
          )}
        />
      );
    } else
      return menuRoutes(
        item.items,
        showSnackMessage,
        user,
        socket,
        path + item.slug,
        reloadApp,
        refreshUser
      );
  });
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      mobileOpen: false,
      selectedMenu: {},
      snackMessage: "",
      showSnackMessage: false,
      menu: this.getMenu()
      //drawerWith: 240
      //anchor: "left",
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleItemClick = (item, slug) => {
    this.setState({ mobileOpen: !this.state.mobileOpen, selectedMenu: item });
  };

  handleHomeClick = () => {
    this.setState({ selectedMenu: {} });
  };

  showSnackMessage = (message, callback) => {
    this.setState({ showSnackMessage: true, snackMessage: message });
  };

  findPath = (path, items, parentPath, parent) => {
    if (!parentPath) {
      parentPath = "";
    }

    if (path === "/profile") {
      return {
        name: "Profilom",
        icon: UserIcon
      };
    }

    if (path === "/settings") {
      return {
        name: "Beállítások",
        icon: SettingsIcon
      };
    }
    if (path === "/") {
      return {
        name: "Nyitólap",
        icon: HomeIcon
      };
    }
    //const favs = this.getFavourites();
    //let items = favs.concat(menuItems);
    //console.log(favs);
    let result = [];
    for (let i in items) {
      if (!items[i].breadcrumbs) {
        items[i].breadcrumbs = [];
      }
      if (parent) {
        if (!items[i].parent) {
          items[i].breadcrumbs.push(parent);
        }
      }
      items[i].fullPath = parentPath + items[i].slug;
      if (
        items[i].breadcrumbs.length === 0 ||
        items[i].breadcrumbs[items[i].breadcrumbs.length - 1].fullPath !==
          items[i].fullPath
      ) {
        items[i].breadcrumbs.push(items[i]);
      }

      const regxp = new RegExp(`^${parentPath + items[i].slug}`);

      if (
        items[i].items.length === 0 &&
        (regxp.test(path.replace("/favourites", "")) ||
          parentPath + items[i].slug === path.replace("/favourites", ""))
      ) {
        result.push({
          len: items[i].slug ? items[i].slug.length : 0,
          item: items[i]
        });
        //return items[i];
      }
      if (items[i].items.length > 0) {
        const found = this.findPath(
          path,
          items[i].items,
          items[i].fullPath,
          items[i]
        );
        if (found) {
          result.push({ len: found.slug ? found.slug.length : 0, item: found });
          //return found;
        }
      }
    }
    result.sort((a, b) => (a.len < b.len ? 1 : -1));
    let ret = result.length > 0 ? result[0].item : null;
    return ret;
  };

  removeMenuItemsAndGetFavourites = (menuItems, path = "") => {
    //console.log("menuItems", this.props.user);
    let settings = this.getUserSettings();
    let cloned = menuItems.slice();
    if (!window.fullPaths) {
      window.fullPaths = {};
    }

    for (let i in cloned) {
      let item = Object.assign({}, cloned[i]);
      item.path = path + item.slug;
      if (!window.fullPaths[item.slug.replace("/", "")]) {
        window.fullPaths[item.slug.replace("/", "")] = item.path;
      }

      //console.log("item", item);
      if (!this.props.user.accessMenu(item.slug) && !item.fix) {
        //console.log("delete", item.slug);
        delete cloned[i];
      } else if (item.items && item.items.length > 0) {
        item.items = this.removeMenuItemsAndGetFavourites(
          item.items,
          item.path
        );
        cloned[i] = item;
      } else {
        const idx = settings.favourites.indexOf(item.path);
        if (idx >= 0) {
          item.idx = idx;
          cloned[i].isFavourite = true;
          this.favouriteTemp.push(
            Object.assign({}, item, {
              slug: item.path,
              fullPath: item.path,
              isFavourite: false
            })
          );
        }
      }
    }
    //console.log(cloned);
    return cloned.filter(function(el) {
      //console.log(el);
      return el != null;
    });
  };

  getMenu = () => {
    this.getMenuTemps();
    return this.menuTemp;
  };

  getFavourites = () => {
    this.getMenuTemps();
    return this.favouriteTemp;
  };

  getMenuTemps = () => {
    if (!this.menuTemp) {
      this.favouriteTemp = [];
      this.menuTemp = this.removeMenuItemsAndGetFavourites(menu.slice());
    }
  };

  clearMenuTemps = () => {
    this.menuTemp = null;
    this.favouriteTemp = null;
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged = () => {
    const url = urlHelper.get();
    //const mnu = this.getMenu();
    //this.menu = this.getMenu();
    const selectedMenu = this.findPath(url.path, this.getMenu());
    //console.log("selectedMenu", selectedMenu);
    //Default appbar settings
    this.setTitle(selectedMenu);
    this.setState({
      selectedMenu: selectedMenu ? selectedMenu : {}
      //menu: mnu
    });
  };

  setTitle = selectedMenu => {
    window.document.title = `${strings.shortName} - ${selectedMenu.name}`;
  };

  componentDidMount() {
    //console.log("user", this.props.user.data);
    const url = urlHelper.get();
    //const mnu = this.getMenu();
    //this.menu = this.getMenu();
    const selectedMenu = this.findPath(url.path, this.getMenu());
    //console.log("selectedMenu", selectedMenu);
    //Default appbar settings
    this.setTitle(selectedMenu);
    this.setState({
      selectedMenu: selectedMenu ? selectedMenu : {}
      //menu: mnu
    });
  }

  handleLogOut = evt => {
    evt.stopPropagation();
    this.props.user.resetUser();
    this.props.logout();
  };

  showProfile = () => {
    this.props.history.push("/profile");
    this.setState({
      selectedMenu: {
        name: "Profilom",
        icon: UserIcon,
        slug: "/profile"
      }
      //menu: mnu
    });
  };

  showSettings = () => {
    this.props.history.push("/settings");
    this.setState({
      selectedMenu: {
        name: "Beállítások",
        icon: SettingsIcon,
        slug: "/settings"
      }
      //menu: mnu
    });
  };

  toggleFavourite = id => evt => {
    //console.log("toggleFavourite", id);
    if (!id) {
      console.log("toggleFavourite id:NULL", this.state.selectedMenu);
      return;
    }
    let settings = this.getUserSettings();

    const favIndex = settings.favourites.indexOf(id);
    if (favIndex >= 0) {
      settings.favourites.splice(favIndex, 1);
    } else {
      settings.favourites.push(id);
    }
    this.clearMenuTemps();
    this.saveUserSettings(settings);
  };

  saveUserSettings = s => {
    if (settings.favourites === "local") {
      localStorage.setItem("userSettings", JSON.stringify(s));
      this.props.refreshUser({ settings: s });
      return;
    }
    restHelper.saveSettings(s).then(response => {
      this.props.refreshUser({ settings: s });
    });
  };

  getUserSettings = () => {
    let s =
      this.props.user && this.props.user.data && this.props.user.data.settings
        ? this.props.user.data.settings
        : null;

    if (settings.favourites === "local") {
      s = JSON.parse(localStorage.getItem("userSettings"));
    }

    if (!s) {
      s = {};
    }

    if (!s.favourites) {
      s.favourites = [];
    }

    return s;
  };

  isFavourite = id => {
    let settings = this.getUserSettings();
    //console.log("isFavourite", id, settings.favourites);
    return settings.favourites.indexOf(id) >= 0;
  };

  render() {
    const {
      classes /*, theme*/,
      user,
      socket,
      refreshUser,
      reloadApp
    } = this.props;
    const {
      open,
      mobileOpen,
      selectedMenu,
      showSnackMessage,
      snackMessage
    } = this.state;
    const v = localStorage.getItem("ver");
    //console.log("selectedMenu", selectedMenu);
    const isNew = !v || v < releases[releases.length - 1].ver;
    //const fontSize = Math.round(20 - strings.appName.length / 2);
    //const paddingBottom = fontSize < 20 ? 20 - fontSize + 3 : 0;
    //Token elútti rész megkeresése az url-ből, hogy oda legyen átirányítva a token
    const tokenRedirectUrl = this.props.history.location.pathname.split(
      "/token/"
    )[0];

    const isFavourite = this.state.selectedMenu
      ? this.isFavourite(this.state.selectedMenu.fullPath)
      : false;

    const drawer = (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        //anchor={anchor}
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          {/*<Link
            to={"/"}
            onClick={this.handleHomeClick}
            className={classes.logoLink}
          >
            {
              <img
                alt={strings.brand}
                title={strings.brand}
                src={logo}
                className={classes.logo}
              />
            }
            <div
              className={classes.appName}
              style={{ fontSize, paddingBottom }}
            >
              {strings.appName}
            </div>
          </Link>
          <div className={classes.appVer}>
            {dateHelper.unixToRelative(APP_VER[1])}
            <span className={classes.appVerNum}>{APP_VER[0]}</span>
            {isNew && user.accessMenu("/releases") ? (
              <Link to={settings.relesesSlug} className={classes.appVerNew}>
                ÚJ
              </Link>
            ) : (
              ""
            )}
            </div>
          <IconButton onClick={this.handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>*/}
        </div>
        <SidebarMenu
          items={this.getMenu()}
          favourites={this.getFavourites()}
          selectedMenu={selectedMenu}
          onClick={this.handleItemClick}
        />
        <div className={classes.appVer}>
          <div className={classes.appVerBottom}>
            <div style={{ marginBottom: 8 }}>
              <img
                alt={strings.brand}
                title={strings.brand}
                src={logo}
                className={classes.logo}
              />
            </div>
            {dateHelper.unixToRelative(APP_VER[1])}
            <span className={classes.appVerNum}>{APP_VER[0]}</span>
            {isNew && user.accessMenu("/releases") ? (
              <Link to={settings.relesesSlug} className={classes.appVerNew}>
                ÚJ
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </Drawer>
    );

    const drawerMobile = (
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={this.handleDrawerToggle}
        classes={{
          paper: classes.drawerPaperMobile
        }}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        <SidebarMenu
          items={this.getMenu()}
          favourites={this.getFavourites()}
          selectedMenu={selectedMenu}
          onClick={this.handleItemClick}
        />
        <div className={classes.appVer}>
          <div className={classes.appVerBottom}>
            <div style={{ marginBottom: 8 }}>
              <img
                alt={strings.brand}
                title={strings.brand}
                src={logo}
                className={classes.logo}
              />
            </div>
            {dateHelper.unixToRelative(APP_VER[1])}
            <span className={classes.appVerNum}>{APP_VER[0]}</span>
            {isNew && user.accessMenu("/releases") ? (
              <Link to={settings.relesesSlug} className={classes.appVerNew}>
                ÚJ
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </Drawer>
    );
    const disconnected =
      socket.inited && !this.props.connected && settings.socket;
    return (
      <div className={classes.root}>
        {/* Mobil Appbar */}
        <Hidden mdUp>
          <AppBar
            className={classes.appBar}
            color={disconnected ? "secondary" : "primary"}
          >
            <Toolbar>
              {disconnected && (
                <Tooltip title="Nincs kapcsolat a szerverrel, kattintson az újrakapcsolódáshoz">
                  <IconButton
                    //variant="contained"
                    color="inherit"
                    //size="small"
                    onClick={() => {
                      Socket.inited = false;
                      Socket.reconnect();
                    }}
                  >
                    <DisconnectedIcon className="pulse" />
                  </IconButton>
                </Tooltip>
              )}
              <Typography
                variant="h6"
                color="inherit"
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginTop: -1
                }}
                noWrap
              >
                <Link to="/" className={classes.headerTitle}>
                  {strings.appName}
                </Link>
              </Typography>
              <div style={{ flex: 1, marginLeft: "auto" }} />
              <UserMenu
                user={user}
                logOut={this.handleLogOut}
                showProfile={this.showProfile}
                showSettings={this.showSettings}
              />
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerToggle}
                //className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </Hidden>
        {/* Desktop Appbar */}
        <Hidden smDown>
          <AppBar
            className={classNames(classes.appBar, {
              //[classes.appBarShift]: open
            })}
            style={{ width: "calc(100% - " + this.state.drawerWith + "px)" }}
            color={disconnected ? "secondary" : "primary"}
          >
            <Toolbar disableGutters>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={open ? this.handleDrawerClose : this.handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
              {disconnected && (
                <Tooltip title="Nincs kapcsolat a szerverrel, kattintson az újrakapcsolódáshoz">
                  <IconButton
                    //variant="contained"
                    color="inherit"
                    //size="small"
                    onClick={() => {
                      Socket.inited = false;
                      Socket.reconnect();
                    }}
                  >
                    <DisconnectedIcon className="pulse" />
                  </IconButton>
                </Tooltip>
              )}

              <Typography
                variant="h6"
                color="inherit"
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginTop: -1
                }}
                noWrap
              >
                <Link to="/" className={classes.headerTitle}>
                  {strings.appName}
                </Link>
              </Typography>
              <div style={{ flex: 1, marginLeft: "auto" }} />
              <UserMenu
                user={user}
                logOut={this.handleLogOut}
                showProfile={this.showProfile}
                showSettings={this.showSettings}
              />
            </Toolbar>
          </AppBar>
        </Hidden>
        <div className={classes.appFrame}>
          {/*Panelek Modile, Desktop*/}
          <Hidden mdUp>{drawerMobile}</Hidden>
          <Hidden smDown implementation="css">
            {drawer}
          </Hidden>
          <main
            className={classNames(
              classes.content /*, classes[`content-${anchor}`]*/,
              {
                [classes.contentShift]: open
                //[classes[`contentShift-${anchor}`]]: open,
              }
            )}
          >
            <div className={classes.drawerHeader} />
            <Grid container spacing={2} justify="center">
              <Grid item xs={12} md={11} lg={11}>
                {/*<Breadcrumbs
                  maxItems={2}
                  aria-label="breadcrumb"
                  style={{ marginTop: 16 }}
                >
                  <Link color="inherit" to="/">
                    Nyitólap
                  </Link>
                  {selectedMenu &&
                    selectedMenu.breadcrumbs &&
                    selectedMenu.breadcrumbs.map((menu, menuIndex) => {
                      return (
                        <Typography key={menuIndex} color="textPrimary">
                          {menu.name}
                        </Typography>
                      );
                    })}
                  </Breadcrumbs>*/}
                <Toolbar disableGutters className={classes.pageToolbar}>
                  {this.state.selectedMenu && this.state.selectedMenu.icon ? (
                    <this.state.selectedMenu.icon
                      className={classes.headerIcon}
                    />
                  ) : (
                    ""
                  )}

                  <Typography variant="h6" className={classes.subHeaderTitle}>
                    {this.state.selectedMenu.breadcrumbs &&
                      this.state.selectedMenu.breadcrumbs.length > 1 &&
                      `${this.state.selectedMenu.breadcrumbs[0].name} / `}
                    {this.state.selectedMenu && this.state.selectedMenu.name}
                  </Typography>
                  {this.state.selectedMenu &&
                    ["/profile", "/settings", ""].indexOf(
                      this.state.selectedMenu.slug || ""
                    ) < 0 &&
                    settings.favourites && (
                    <Tooltip
                        disableFocusListener
                        title={
                        isFavourite
                          ? `Eltávolítás a kedvencekből: ${this.state.selectedMenu.name}`
                            : `Kedvencekhez ad: ${this.state.selectedMenu.name}`
                        }
                    >
                        <IconButton
                        onClick={this.toggleFavourite(
                          this.state.selectedMenu.fullPath
                          )}
                        style={{ marginLeft: "auto" }}
                      >
                          {isFavourite ? (
                          <IsFavIcon className={classes.isFavIcon} />
                          ) : (
                          <NotFavIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                  )}
                </Toolbar>

                <Paper className={classes.paper}>
                  <Switch>
                    <Redirect from="(.*)/token/:data" to={tokenRedirectUrl} />
                    {menuRoutes(
                      this.getMenu(),
                      this.showSnackMessage,
                      user,
                      socket,
                      "",
                      reloadApp,
                      refreshUser
                    )}
                    <Route path="/" exact />
                    <Redirect to={"/"} />
                  </Switch>
                </Paper>
              </Grid>
            </Grid>
          </main>
        </div>
        <GlobalMessaging />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={showSnackMessage}
          autoHideDuration={6000}
          onClose={() =>
            this.setState({ showSnackMessage: false, snackMessage: "" })
          }
          ContentProps={{
            "aria-describedby": "message-id",
            className: classes.snackbarRoot
          }}
          message={snackMessage}
          action={[
            <IconButton
              key="reconnect"
              variant="contained"
              color="inherit"
              size="small"
              onClick={() => {
                this.setState({
                  showSnackMessage: false,
                  snackMessage: ""
                });
              }}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  connected: PropTypes.bool
};
const mapDispatchToProps = dispatch => bindActionCreators(restHelper, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(withStyles(appStyle, { withTheme: true })(App));
