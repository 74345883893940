import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { FormControl, FormLabel, FormHelperText } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
class FormTableField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date().getTime()
    };
    this.value = props.value;
  }

  render() {
    const {
      id,
      name,
      error,
      value,
      type,
      //access,
      dirty,
      classes,
      helperText,
      height,
      columns
    } = this.props;

    const rows = value || [];
    return (
      <div style={{ padding: 16 }}>
        <Paper>
          <Table size="small" aria-label="table">
            <TableHead>
              <TableRow>
                {columns.map((column, columnIndex) => (
                  <TableCell key={columnIndex}>
                    {column.title || column.id}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((column, columnIndex) => (
                    <TableCell key={columnIndex}>{row[column.id]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );

    /*return (
      <AceEditor
          value={value ? JSON.stringify(value, null, '\t') : ""}
          onChange={newValue => onChange({ [id]: JSON.parse(newValue) })}
          ace={ace}
          name={id}
          theme="github"
          mode="json"
    theme="github"
          //schema={yourSchema}
      />
  );

    return (
      <TextField
        //inputRef={ref => (this.email = ref)}
        id={id}
        label={name}
        helperText={error || helperText || ""}
        error={error ? true : false}
        //defaultValue={defaultValue}
        //InputLabelProps={inputLabelProps}
        className={dirty && !error ? classes.dirtyTextField : ""}
        fullWidth
        disabled={access !== "w" ? true : false}
        //defaultValue={value ? value : ""}
        value={value ? value : ""}
        onChange={evt => onChange({ [id]: evt.target.value })}
        onKeyDown={this.onKeyDown}
        margin="normal"
        //variant="outlined"
        placeholder={placeholder}
        inputProps={ip}
        multiline={multiline}
        rows={rows}
        // eslint-disable-next-line
        InputProps={InputProps}
      />
    );*/
  }
}

FormTableField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ]),
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  format: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  access: PropTypes.string,
  helperText: PropTypes.string,
  dirty: PropTypes.bool,
  multiline: PropTypes.bool,
  columns: PropTypes.array
};

export default FormTableField;
