import React from "react";
import PropTypes from "prop-types";
//import { withStyles } from "@material-ui/core/styles";
import DataGrid from "common/components/DataGrid/DataGrid";
import dateHelper from "common/utils/dateHelper";
import Worksheet from "views/Worksheets/Worksheet";
import strings from "variables/strings";

const statuses = [
  { value: 1, label: "Piszkozat" },
  { value: 2, label: "Beadott" },
  { value: 3, label: "Jóváhagyott" }
];

class Worksheets extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("worksheets");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "worksheets",
        url: "worksheets",
        attributes: strings.attributes.worksheets,
        columns: [
          {
            id: "id",
            //type: "te",
            cell: item => (item.original.id ? "#" + item.original.id : ""),
            width: 70,
            detailLink: true
          },
          {
            id: "project_id",
            type: "select",
            datasource: {
              controller: "projects",
              objectField: "project",
              valueField: "id",
              search: ["project.name"],
              filter: [["project.active", "exact", true]],
              label: "name"
            }
          },
          {
            id: "day",
            type: "unixDate",
            defaultValue: new Date().getTime() / 1000
          },
          { id: "description", visible: true },
          { id: "status", type: "select", options: statuses },
          {
            id: "type",
            type: "select",
            options: strings.options.worksheets.type
          },
          {
            id: "foreman_id",
            type: "select",
            search: "foreman.name",
            visible: item => {
              return user.accessFunction("worksheet_access_all");
            },
            hidden: item => {
              return !user.accessFunction("worksheet_access_all");
            },
            datasource: {
              controller: "users",
              objectField: "foreman",
              valueField: "id",
              search: ["user.name"],
              filter: [
                ["user.active", "exact", true],
                ["function", "exact", "user_list_foreman"]
              ],
              sort: { "user.name": "asc" },
              label: item => {
                return item
                  ? item.subcontractor_id
                    ? item.name + " *"
                    : item.name
                  : "";
              }
            }
          },
          {
            id: "controller_id",
            type: "select",
            search: "controller.name",
            datasource: {
              controller: "users",
              objectField: "controller",
              valueField: "id",
              search: ["user.name"],
              filter: [
                ["user.active", "exact", true],
                ["function", "exact", "user_list_controller"]
              ],
              label: "name"
            }
          }
        ],
        fields: [
          { id: "project_id" },
          { id: "day" },
          { id: "description" },
          {
            id: "status",
            hidden: item => {
              if (user.accessFunction("worksheet_accept")) {
                return false;
              }
              return true;
            }
          },
          {
            id: "type",
            type: "select",
            options: strings.options.worksheets.type
          },
          { id: "foreman_id" },
          { id: "controller_id" }
        ]
      }
    };
  }

  componentDidMount() {}

  render() {
    const { definition } = this.state;
    const {
      user,
      onChildEdit,
      onChildCancelEdit,
      breadcrumbs,
      isDetailView
    } = this.props;
    return (
      <DataGrid
        isDetailView={isDetailView}
        user={user}
        onEdit={onChildEdit}
        onCancelEdit={onChildCancelEdit}
        breadcrumbs={breadcrumbs}
        definition={definition}
        FormComponent={Worksheet}
        ViewComponent={Worksheet}
        //FormComponentProps={{ user: user }}
        startEditRef={ref => (this.startEdit = ref)}
        FormFooter="CreatedUpdated"
        fieldAccessHandler={(column, item) => {
          //return user.accessField("worksheets", column.id);
          const access = user.accessField("worksheets", column.id);
          if (access) {
            if (column.id === "status") {
              return access;
            }
            return item && item.status > 1 ? "r" : access;
          }
          return access;
        }}
        access={item => {
          if (item && item.status > 1 && user.can([3, 4, 5])) {
            return "r";
          }
          return this.state.access;
        }}
        formFieldsInRow={3}
        title={item => {
          if (!item) {
            return "Tallózás";
          } else if (item.id) {
            return (
              "#" +
              item.id +
              " " +
              item.project.name +
              " - " +
              dateHelper.unixToDate(item.day)
            );
          } else {
            return "Új munkalap";
          }
        }}
      />
    );
  }
}

Worksheets.propTypes = {
  //classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};
export default Worksheets;
//export default withStyles(uteStyle, { withTheme: true })(Worksheets);
