import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";
import ServiceWorksheetPart from "views/Service/ServiceWorksheetPart";

class ServiceWorksheetParts extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const access = user.accessController("service-worksheet-parts");

    this.state = {
      access: access ? access : "",
      showDetails: false,
      definition: {
        id: "service-worksheet-parts",
        url: "service-worksheet-parts",
        attributes: strings.attributes["service-worksheet-parts"],
        condition: props.item
          ? {
            id: props.itemKey,
            value: props.item["id"]
          }
          : undefined,
        columns: [
          { id: "name", required: true, detailLink: true },
          { id: "qty", required: true, defaultValue: 1 },
          {
            id: "unit",
            type: "select",
            options: strings.options["service-worksheet-parts"].unit,
            required: true,
            defaultValue: 2
          },
          { id: "item_price", type: "currency", required: true },
          { id: "price", type: "currency", readOnly: true, footer: "sum" },
          {
            id: "invoice_item_id",
            type: "select",
            datasource: {
              controller: "invoice-items",
              objectField: "invoice_item",
              valueField: "id",
              label: item =>
                !item || !item.invoice || !item.name
                  ? ""
                  : `${item.invoice.invoice_number} - ${item.name}`,
              sort: { "invoice.invoice_number": "asc" },
              search: ["invoice.invoice_number"]
            },
            sort: { "invoice.invoice_number": "asc" },
            search: ["invoice.invoice_number"],
            changeValues: item => {
              item.name = item.invoice_item.name;
              item.qty = item.invoice_item.qty;
              item.item_price = item.invoice_item.item_price;
              item.unit = item.invoice_item.unit;
            }
          },
          {
            id: "service_vehicle_id",
            type: "select",
            datasource: {
              controller: "service-vehicles",
              objectField: "service_vehicle",
              valueField: "id",
              label: "platenumber",
              sort: { "service_vehicle.platenumber": "asc" },
              search: ["service_vehicle.platenumber"]
            },
            sort: { "service_vehicle.platenumber": "asc" },
            search: ["service_vehicle.platenumber"],
            visible: false,
            hidden: true,
            required: true,
            system: true
          },
          {
            id: "service_worksheet_id",
            type: "select",
            datasource: {
              controller: "service-worksheets",
              objectField: "service_worksheet",
              valueField: "id",
              label: "worksheet_number",
              sort: { "service_worksheet.worksheet_number": "asc" },
              search: ["service_worksheet.worksheet_number"]
            },
            sort: { "service_worksheet.worksheet_number": "asc" },
            search: ["service_worksheet.worksheet_number"],
            required: true
          },
          { id: "description", visible: false, multiline: true, rows: 5 }
        ]
      }
    };

    if (props.itemKey) {
      this.hideMasterField(props.itemKey);
    }
  }

  hideMasterField = key => {
    this.state.definition.columns.forEach(column => {
      if (column.id === key) {
        column.system = true;
        column.hidden = true;
        column.visible = false;
      }
    });
  };

  componentDidMount() {}

  toggleDetails = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  render() {
    const { definition, showDetails } = this.state;
    const {
      user,
      filterable,
      viewMode,
      showPaginationTop,
      showPaginationBottom,
      viewOnRowClick,
      isDetailView,
      height,
      //item,
      editItem,

      title,
      onChildEdit,
      onChildCancelEdit,
      breadcrumbs
    } = this.props;
    return (
      <div>
        <DataGrid
          user={user}
          onEdit={onChildEdit}
          onCancelEdit={onChildCancelEdit}
          breadcrumbs={breadcrumbs}
          definition={definition}
          FormComponent={ServiceWorksheetPart}
          ViewComponent={ServiceWorksheetPart}
          FormComponentProps={{
            user,
            showDetails,
            toggleDetails: this.toggleDetails,
            title
          }}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("service-worksheet-parts", column.id);
          }}
          filterable={filterable}
          viewMode={viewMode} /*#pdfTemplates#*/
          showPaginationTop={showPaginationTop}
          showPaginationBottom={showPaginationBottom}
          height={height}
          item={editItem}
          isDetailView={isDetailView}
          viewOnRowClick={viewOnRowClick}
          access={this.state.access}
          title={item => {
            if (!item) {
              if (title) {
                return title;
              }
              return viewMode
                ? strings.menu["service-worksheet-parts"].title
                : "Tallózás";
            } else if (item.id) {
              return (
                (isDetailView
                  ? strings.menu["service-worksheet-parts"].title + ": "
                  : "") + (item.name || item.id)
              );
            } else {
              return `Új ${strings.menu["service-worksheet-parts"].singularTitle}`;
            }
          }}
        />
      </div>
    );
  }
}

ServiceWorksheetParts.defaultProps = {
  filterable: true,
  viewMode: false,
  showPaginationBottom: true,
  showPaginationTop: false,
  viewOnRowClick: false
};

ServiceWorksheetParts.propTypes = {
  user: PropTypes.object.isRequired,

  item: PropTypes.object,
  editItem: PropTypes.object,
  itemKey: PropTypes.string,
  title: PropTypes.string,
  filterable: PropTypes.bool,
  viewMode: PropTypes.bool,

  showPaginationTop: PropTypes.bool,
  showPaginationBottom: PropTypes.bool,
  viewOnRowClick: PropTypes.bool,
  isDetailView: PropTypes.bool,
  showDetails: PropTypes.bool,
  toggleDetails: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ServiceWorksheetParts;
