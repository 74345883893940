import React from "react";
import PropTypes from "prop-types";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import restHelper from "common/utils/restHelper";
import App from "common/layouts/App";
import { Redirect } from "react-router-dom";
import ErrorPage404 from "views/ErrorPage404";
import LoginPage from "views/LoginPage";
import { Route, Switch } from "react-router-dom";
import userHelper from "common/utils/userHelper";
import { createTheme } from "common/utils/themeHelper";
import theme from "variables/theme";
import settings from "variables/settings";
import Socket from "common/utils/Socket";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
/**
 * Alkalmazás betöltő.
 * Megnézi, hogy a kért urlhez (company_name) tartozik-e cég. pl.: example.com:3333/nedik/company_name
 * Ha nem tartozik, hozzá a 404-es nam található oldalt jelenítünk meg
 * Ha tartozik hozzá cég a kliens megkapja a cég logoját, témáját, nevét.
 * Ha érvényes felhasználói token van a kliensen, akkor az is elküldésre kerül, az api ez esetben a user adatokat és a menüt is küldi.
 * Érvénytelen tokennel vagy annak hiányában a felhasználót az adott cég belépési képernyőjére irányítja
 */
const Loader = () => (
  <CircularProgress
    style={{
      position: "absolute",
      left: "50%",
      top: "50%",
      magrinTop: -20,
      magrinLeft: -20
    }}
  />
);

const AppHandler = props => {
  //Init socket if defined in the settings
  if (
    settings.socket &&
    !props.user.loading &&
    props.user.initialized &&
    props.user.data.id
  ) {
    Socket.init(settings.socket.ip, settings.socket.port, props.user.data.id);
  }

  //Loading / Initializing
  if (props.user.loading || !props.user.initialized) {
    return <Loader />;
  }

  //initialized no user
  if (
    !props.user.loading &&
    props.user.initialized &&
    !props.user.data.id &&
    props.history.pathname !== "/login"
  ) {
    return (
      <Redirect
        from={window.location.pathname}
        to={`/login?url=${window.location.pathname + window.location.hash}`}
      />
    );
  }

  /* //Error handling
  if (props.user.error || !props.user.initialized) {
    return <Loader />;
  }*/
  //console.log("JAJJJJJJ", props.user.error);

  return props.user.error ? (
    <ErrorPage404 />
  ) : (
    <App
      user={new userHelper(props.user.data)}
      history={props.history}
      location={props.location}
      socket={Socket}
      reloadApp={props.reloadApp}
      connected={props.socket.connected}
      refreshUser={props.refreshUser}
    />
  );
};

AppHandler.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.func.isRequired
};

//const AppHandler = (props) => {return <App company={{user: {}, menu: [{name: "fos", slug: "akármi", items: []}]}} history={window.locaton} />;};

class AppLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: createTheme(theme)
    };
  }

  componentDidMount() {
    if (window.isdev) {
      console.log("theme", this.state.theme);
    }
    this.props.auth();
  }

  reloadApp = state => {
    this.changeTheme(theme);
  };

  changeTheme = (theme = {}) => {
    this.setState({ theme: createTheme(theme) });
  };

  render() {
    const { theme /*, loading, error */ } = this.state;
    const { user, refreshUser } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Switch>
          {!user.data.id && (
            <Route
              path={"/login"}
              component={() => (
                <LoginPage
                  history={this.props.history}
                  match={this.props.match}
                />
              )}
            />
          )}
          {user.data.id && <Redirect from={"/login"} to={"/"} />}
          <Route
            path="/"
            render={() => (
              <AppHandler
                {...this.props}
                reloadApp={this.reloadApp}
                refreshUser={refreshUser}
                /*history={this.props.history}
                user={user.data}
                error={error}
                //store={this.props.store}
                loading={loading}*/
              />
            )}
          />
        </Switch>
      </MuiThemeProvider>
    );
  }
}

AppLoader.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.func.isRequired
  //theme: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  //a: console.log(state),
  user: state.user,
  socket: state.socket,
  store: state.store
});

const mapDispatchToProps = dispatch => bindActionCreators(restHelper, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppLoader);
