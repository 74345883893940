import React from "react";
import PropTypes from "prop-types";
import DataGrid from "common/components/DataGrid/DataGrid";
import strings from "variables/strings.json";

const vats = [
  { value: 0, label: "Áfa mentes" },
  { value: 0.27, label: "27%" },
  { value: 0.18, label: "18%" },
  { value: 0.08, label: "8%" },
  { value: 0.05, label: "5%" }
];

const units = [
  { value: 1, label: "Óra" },
  { value: 2, label: "Darab" },
  { value: 3, label: "Méter" },
  { value: 4, label: "Négyzetméter" },
  { value: 5, label: "Köbméter" },
  { value: 6, label: "Készlet" },
  { value: 7, label: "Üzemóra" },
  { value: 8, label: "Kilométer" },
  { value: 9, label: "Tonna" },
  { value: 10, label: "Nap" },
  { value: 11, label: "Garn" },
  { value: 12, label: "Éjszaka" },
  { value: 13, label: "Liter" }
];

class InvoiceItems extends React.Component {
  constructor(props) {
    super(props);
    const { user /*, item*/ } = props;
    const access = user.accessController("invoice-items");

    this.state = {
      access: access ? access : "",
      definition: {
        id: "invoice-items",
        url: "invoice-items",
        condition: {
          id: "invoice_id",
          value: this.props.item["id"]
        },
        attributes: strings.attributes["invoice-items"],
        columns: [
          { id: "name", detailLink: true },
          /*{
            id: "invoice_id",
            type: "select",
            datasource: {
              controller: "invoices",
              objectField: "invoice",
              valueField: "id",
              label: "name",
              sort: { "invoice.name": "asc" },
              search: ["invoice.name"]
            }
          },*/
          { id: "qty" },
          { id: "unit", type: "select", options: units },
          { id: "item_price", type: "currency", footer: "sum" },
          { id: "vat", type: "select", options: vats },
          {
            id: "project_id",
            type: "select",
            datasource: {
              controller: "projects",
              objectField: "project",
              valueField: "id",
              label: "name",
              sort: { "project.name": "asc" },
              search: ["project.name"]
            }
          },
          {
            id: "vehicle_id",
            type: "select",
            datasource: {
              controller: "vehicles",
              objectField: "vehicle",
              valueField: "id",
              search: ["vehicle.platenumber"],
              sort: { platenumber: "asc" },
              //filter: [["unit", "=", 1]],
              label: item => {
                return item ? item.platenumber : "";
              }
            },
            visible: item => {
              if (this.props.item.invoice_type === 2) {
                return true;
              }
              return false;
            },
            hidden: item => {
              if (this.props.item.invoice_type === 2) {
                return false;
              }
              return true;
            }
          },
          {
            id: "persons",
            visible: item => {
              if (this.props.item.invoice_type === 3) {
                return true;
              }
              return false;
            },
            hidden: item => {
              if (this.props.item.invoice_type === 3) {
                return false;
              }
              return true;
            }
          },
          {
            id: "person_price",
            type: "currency",
            visible: item => {
              if (this.props.item.invoice_type === 3) {
                return true;
              }
              return false;
            },
            hidden: item => {
              if (this.props.item.invoice_type === 3) {
                return false;
              }
              return true;
            }
          },
          { id: "description", visible: false },
          {
            id: "item_netto_price",
            type: "currency",
            filterable: false,
            visible: false,
            footer: "sum"
          },
          {
            id: "item_vat_price",
            type: "currency",
            filterable: false,
            visible: false,
            footer: "sum"
          },
          {
            id: "unit_price",
            type: "currency",
            filterable: false,
            visible: false,
            footer: "sum"
          },
          {
            id: "unit_netto_price",
            type: "currency",
            filterable: false,
            visible: false,
            footer: "sum"
          },
          {
            id: "unit_vat_price",
            type: "currency",
            filterable: false,
            visible: false,
            footer: "sum"
          }
        ]
      }
    };
  }

  componentDidMount() {}

  onSave = (item, isNew) => {
    const { refreshInvoice } = this.props;
    refreshInvoice();
  };

  onDelete = item => {
    const { refreshInvoice } = this.props;
    refreshInvoice();
  };

  render() {
    const { definition } = this.state;
    const {
      user,
      filterable,
      viewMode,
      showPaginationTop,
      showPaginationBottom,
      viewOnRowClick,
      isDetailView,
      height,
      editItem
    } = this.props;
    return (
      <div>
        <DataGrid
          user={user}
          definition={definition}
          filterable={filterable}
          viewMode={viewMode}
          showPaginationTop={showPaginationTop}
          showPaginationBottom={showPaginationBottom}
          height={height}
          item={editItem}
          isDetailView={isDetailView}
          viewOnRowClick={viewOnRowClick}
          startEditRef={ref => (this.startEdit = ref)}
          FormFooter="CreatedUpdated"
          fieldAccessHandler={column => {
            return user.accessField("invoice-items", column.id);
          }}
          access={this.state.access}
          title={item => {
            if (!item) {
              return "Tallózás";
            } else if (item.id) {
              return item.name;
            } else {
              return "Új Számla tételek";
            }
          }}
          onSave={this.onSave}
          onDelete={this.onDelete}
        />
      </div>
    );
  }
}

InvoiceItems.propTypes = {
  user: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  refreshInvoice: PropTypes.func.isRequired,
  editItem: PropTypes.object,
  itemKey: PropTypes.string,
  title: PropTypes.string,
  filterable: PropTypes.bool,
  viewMode: PropTypes.bool,
  showPaginationTop: PropTypes.bool,
  showPaginationBottom: PropTypes.bool,
  viewOnRowClick: PropTypes.bool,
  isDetailView: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default InvoiceItems;
