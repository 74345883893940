import React from "react";
import DataGridForm from "common/components/DataGrid/DataGridForm";

const UserCost = props => (
  <DataGridForm
    {...props}
    tabs={{
      id: "user-costs",
      documents: true,
      histories: true,
      items: {}
    }}
  />
);

export default UserCost;
